import { CART_UPDATE } from "../actions/order";

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CART_UPDATE:
      return { ...state, cart: action.cart };
    default:
      return state;
  }
}
