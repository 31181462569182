import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BootstrapTooltip from "react-bootstrap/Tooltip";

const Tooltip = ({ placement = "top", id, message, children }) => {
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={<BootstrapTooltip id={id}>{message}</BootstrapTooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

export default Tooltip;
