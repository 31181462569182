import { applyMiddleware, createStore } from "redux";
import reduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducers from "../reducers/index";
import { throttle } from "lodash";
import { loadState, saveState } from "./localStorage";

const tokenState = loadState();
const store = createStore(
  reducers,
  { auth: tokenState },
  composeWithDevTools(applyMiddleware(reduxThunk))
);
store.subscribe(
  throttle(() => {
    saveState({ token: store.getState().auth.token });
  })
);

export default store;
