import React, { Fragment } from "react";
import {
  getCurrentOrganizationObject,
  saveCookie
} from "../../utils/commonUtil";
import history from "../../history";
import { withRouter } from "react-router-dom";

//const DOMAIN = config.DOMAIN;

class PortalOrganizationSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.switchOrganization = this.switchOrganization.bind(this);
  }

  render() {
    const currentOrganization = getCurrentOrganizationObject(this.props.user);
    if (!currentOrganization) {
      return null;
    }

    const totalOrgItems = this.props.user.organizations.length;
    return (
      <li
        className="dropdown organization-dropdown"
        style={totalOrgItems > 1 ? {} : { cursor: "unset" }}
      >
        <div
          className="org-box dropdown-item"
          id="organizations-dropdown"
          data-toggle="dropdown"
        >
          <span>Organizations</span>
          <strong>{currentOrganization.organization.name}</strong>
        </div>
        {totalOrgItems > 1 && (
          <ul className="dropdown-menu">
            {this.renderDropDownItems(
              this.props.user.organizations,
              currentOrganization
            )}
          </ul>
        )}
      </li>
    );
  }

  renderDropDownItems(organizations, currentOrganization) {
    const dropDownitems = [];
    // render current Organization first and make it selected
    dropDownitems.push(
      this.renderDropDownItem(
        currentOrganization,
        organizations.length === 1,
        true,
        0
      )
    );
    organizations
      .filter(
        item => item.organization._id !== currentOrganization.organization._id
      )
      .forEach((item, index) =>
        dropDownitems.push(
          this.renderDropDownItem(
            item,
            index === organizations.length - 1,
            false,
            index + 1
          )
        )
      );
    return dropDownitems;
  }

  renderDropDownItem(organizationObj, isLast, isSelected, index) {
    const orgRole =
      organizationObj.roleRef && organizationObj.roleRef.displayName;
    const orgName = organizationObj.organization.name;
    const orgId = organizationObj.organization._id;
    return (
      <Fragment key={index}>
        <li className="heading" onClick={this.switchOrganization(orgId)}>
          <span>{orgRole}</span>
        </li>
        <li onClick={this.switchOrganization(orgId)}>
          <a
            className={`dropdown-item ${isSelected ? " active" : ""}`}
            href="#"
            onClick={e => e.preventDefault()}
          >
            {orgName}
          </a>
        </li>
      </Fragment>
    );
  }

  switchOrganization(organizationId) {
    const { renderSamePage = false } = this.props;
    return () => {
      saveCookie("org", organizationId);
      if (
        renderSamePage === true ||
        this.props.location.pathname === "/myProjects"
      ) {
        window.location.reload();
      } else {
        history.push("/myProjects");
      }
    };
  }
}

export default withRouter(PortalOrganizationSwitcher);
