import React, { useRef } from "react";

// Relying on parent component to pass onCloseHandler, as parent is the one
// which provides flag to show the modal. Hence, through onCloseHandler method
// parent can manage handling the state of it.
const Modal = ({
  show,
  id,
  topImg,
  modalMainClass,
  modalContentClass,
  modalHeaderClass,
  modalBodyClass,
  modalDialogueClass,
  maskClosable = true,
  title,
  titleIcon,
  children,
  onCloseHandler
}) => {
  const modalRef = useRef(null);
  const style = show ? { display: "block", paddingLeft: "0px" } : {};

  const onBackdropClick = () => {
    if (maskClosable) {
      onCloseHandler();
    }
  };

  const preventEvent = e => {
    e.stopPropagation();
  };

  return (
    <>
      <div
        className={`modal ${modalMainClass ? modalMainClass : ""} ${
          show ? "show" : ""
        }`}
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-modal="true"
        style={style}
        onClick={onBackdropClick}
      >
        <div
          className={`modal-dialog modal-dialog-centered ${
            modalDialogueClass ? modalDialogueClass : ""
          }`}
          ref={modalRef}
        >
          <div
            className={`modal-content ${modalContentClass}`}
            onClick={preventEvent}
          >
            {title && (
              <div className={`modal-header ${modalHeaderClass}`}>
                {!titleIcon && <div className="modal-title">{title}</div>}
                {titleIcon && (
                  <div className="modal-title">
                    <em className={`i-icon ${titleIcon}`} />
                    {title}
                  </div>
                )}
                <span
                  className="modal-close"
                  data-dismiss="modal"
                  onClick={onCloseHandler}
                >
                  <em className="i-icon icon-modal-close" />
                </span>
              </div>
            )}
            <div
              className={`modal-body ${modalBodyClass ? modalBodyClass : ""}`}
            >
              {topImg && (
                <div className="icon">
                  <img src={topImg} alt="In-App Chat" />
                </div>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
      {show && <div className={`modal-backdrop fade show`} />}
    </>
  );
};

export default Modal;
