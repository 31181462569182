import React, { useMemo } from "react";
import connect from "react-redux/es/connect/connect";
import Modal from "./modal";
import { showNotification, hideNotification } from "../../actions/ui";

const Notification = ({
  show,
  type = "info",
  message,
  description,
  hideNotification
}) => {
  if (!show) {
    return null;
  }

  const iconName = useMemo(() => {
    if (type) {
      const iconMap = {
        info: "i-icon icon-info",
        error: "i-icon icon-error"
      };

      return iconMap[type];
    }

    return "";
  }, [type]);

  return (
    <Modal
      show={show}
      id="custom-notification"
      modalDialogueClass="mw-700"
      title={message}
      onCloseHandler={hideNotification}
    >
      <div className="d-flex align-items-center">
        <em
          className={iconName}
          style={{ width: "3rem", height: "3rem", marginRight: "1rem" }}
        />
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <div className="modal-action">
        <button
          className="btn btn-primary btn-md"
          type="submit"
          onClick={hideNotification}
        >
          OK
        </button>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  ...state.ui.notification
});

const mapDispatchToProps = {
  showNotification,
  hideNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
