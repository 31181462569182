import {
  PROVINT_TOKEN_REMOVED,
  PROVINT_TOKEN_UPDATED
} from "../actions/provintAuth";

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROVINT_TOKEN_UPDATED:
      return { ...state, token: action.token };
    case PROVINT_TOKEN_REMOVED:
      return { ...state, token: {} };
    default:
      return state;
  }
}
