import history from "../history";
import { getRoleRefInCurrentOrganization } from "./commonUtil";

export const PAGEACCESS = {
  NOACCESS: 0,
  ALL: 1,
  VIEW: 2
};

const orgGeneral = "/organizations/general";
const orgMember = "/organizations/members";
const orgRoles = "/organizations/roles";
const orgCreateProject = "/createproject";
const orgCreateRole = "/organizations/createrole";
const orgEditRole = "/organizations/editrole";
const orgViewRole = "/organizations/viewrole";
const projDashboard = "/dashboard";
const projUser = "/chatusers";
const projMessage = "/exportmessage";
const projSecurity = "/security";
const projCompliance = "/qualys";
const projInfrastructure = "/infrastructure";
const projModeration = "/moderation";
const projChatbot = "/bots";
const projUsage = "/usage";
const projBilling = "/billing";
const projAnalytics = "/analytics";
const projSettings = "/settings";
const projUpgrade = "/upgradeproject";

function getAccess(permissions, key) {
  let i = 0;
  for (i = 0; i < permissions.length; i++) {
    if (permissions[i].substr(0, key.length) === key) {
      const access = permissions[i].substr(key.length + 2).toLowerCase();
      return access === "view" ? PAGEACCESS.VIEW : PAGEACCESS.ALL;
    }
  }
  return PAGEACCESS.NOACCESS;
}

export function isProjectPageAccessible(projectPageName, user, project) {
  const features =
    (project && project.config && project.config.features) || null;

  if (projectPageName.toLowerCase() === projDashboard) {
    return isPageAccessible(projDashboard, user, project.projectId);
  } else if (projectPageName.toLowerCase() === projMessage) {
    return (
      features &&
      features.messageExport &&
      features.messageExport.enabled &&
      isPageAccessible(projMessage, user, project.projectId)
    );
  } else if (projectPageName.toLowerCase() === projUser) {
    return isPageAccessible(projUser, user, project.projectId);
  } else if (projectPageName.toLowerCase() === projSecurity) {
    return (
      features &&
      features.securityScanning &&
      features.securityScanning.enabled &&
      isPageAccessible(projSecurity, user, project.projectId)
    );
  } else if (projectPageName.toLowerCase() === projCompliance) {
    return (
      features &&
      features.compliance &&
      features.compliance.enabled &&
      isPageAccessible(projCompliance, user, project.projectId)
    );
  } else if (projectPageName.toLowerCase() === projInfrastructure) {
    return (
      features &&
      features.infraMonitoring &&
      features.infraMonitoring.enabled &&
      isPageAccessible(projInfrastructure, user, project.projectId)
    );
  } else if (projectPageName.toLowerCase() === projModeration) {
    return (
      features &&
      features.moderation &&
      features.moderation.enabled &&
      isPageAccessible(projModeration, user, project.projectId)
    );
  } else if (projectPageName.toLowerCase() === projChatbot) {
    return (
      features &&
      features.chatBots &&
      features.chatBots.enabled &&
      isPageAccessible(projChatbot, user, project.projectId)
    );
  } else if (projectPageName.toLowerCase() === projUsage) {
    return isPageAccessible(projUsage, user, project.projectId);
  } else if (projectPageName.toLowerCase() === projBilling) {
    return (
      (project.projectType === "premium" ||
        project.projectType === "premiumwhitelabelapp") &&
      project.status.state === "active" &&
      isPageAccessible(projBilling, user, project.projectId)
    );
  } else if (projectPageName.toLowerCase() === projAnalytics) {
    return (
      features &&
      features.analytics &&
      features.analytics.enabled &&
      isPageAccessible(projAnalytics, user, project.projectId)
    );
  } else if (projectPageName.toLowerCase() === projSettings) {
    return isPageAccessible(projSettings, user, project.projectId);
  }
}

export function getDefaultProjectPageUrl(project, user) {
  if (!project || !project.projectId || project.projectId.length === 0) {
    return "";
  }

  if (!user) {
    console.log("no user");
    return "";
  }

  const roleRef = getRoleRefInCurrentOrganization(user);
  if (!roleRef) {
    return "";
  }

  const projectId = project.projectId;

  if (roleRef && (roleRef.name === "superAdmin" || roleRef.name === "admin")) {
    return `/dashboard/${projectId}`;
  }

  const projectPermissions =
    roleRef.projectPermissions &&
    roleRef.projectPermissions.length > 0 &&
    roleRef.projectPermissions[0];

  if (projectPermissions.permissions.length === 0) return "";

  let accessProjectPages = false;
  if (projectPermissions.projects.length > 0) {
    if (projectPermissions.projects[0].toLowerCase() === "all")
      accessProjectPages = true;
    else if (projectPermissions.projects.includes(projectId))
      accessProjectPages = true;
  }

  if (!accessProjectPages) return "";

  if (isProjectPageAccessible(projDashboard, user, project))
    return `${projDashboard}/${projectId}`;

  if (isProjectPageAccessible(projMessage, user, project))
    return `${projMessage}/${projectId}`;

  if (isProjectPageAccessible(projUser, user, project))
    return `${projUser}/${projectId}`;

  if (isProjectPageAccessible(projModeration, user, project))
    return `${projModeration}/${projectId}`;

  if (isProjectPageAccessible(projChatbot, user, project))
    return `${projChatbot}/${projectId}`;

  if (isProjectPageAccessible(projSecurity, user, project))
    return `${projSecurity}/harbor/${projectId}`;

  if (isProjectPageAccessible(projCompliance, user, project))
    return `${projCompliance}/login/${projectId}`;

  if (isProjectPageAccessible(projInfrastructure, user, project))
    return `${projInfrastructure}/${projectId}`;

  if (isProjectPageAccessible(projUsage, user, project))
    return `${projUsage}/${projectId}`;

  if (isProjectPageAccessible(projBilling, user, project))
    return `${projBilling}/${projectId}`;

  if (isProjectPageAccessible(projAnalytics, user, project))
    return `${projAnalytics}/${projectId}`;

  if (isProjectPageAccessible(projSettings, user, project))
    return `${projSettings}/${projectId}`;

  return "";
}

export function getDefaultOrganizationPageUrl(user) {
  if (!user) {
    console.log("no user");
    return "";
  }

  const roleRef = getRoleRefInCurrentOrganization(user);
  if (!roleRef) {
    return "";
  }

  if (roleRef && (roleRef.name === "superAdmin" || roleRef.name === "admin")) {
    return "/organizations/general";
  }

  const orgPermissions = roleRef.orgPermissions;
  if (orgPermissions.length === 0) return "";

  if (getAccess(orgPermissions, "ORG_GENERAL") !== PAGEACCESS.NOACCESS)
    return "/organizations/general";

  if (getAccess(orgPermissions, "ORG_MEMBERS") !== PAGEACCESS.NOACCESS)
    return "/organizations/members";

  if (getAccess(orgPermissions, "ORG_ROLES") !== PAGEACCESS.NOACCESS)
    return "/organizations/roles";

  return "";
}

export function isProjectAccessible(project, user) {
  return getDefaultProjectPageUrl(project, user).length > 0;
}

export function isOrganizationAccessible(user) {
  return getDefaultOrganizationPageUrl(user).length > 0;
}

export function getPageAccess(pathName, user, projectId) {
  if (pathName.length === 0) {
    return PAGEACCESS.ALL;
  }

  if (!user) {
    console.log("no user");
    return PAGEACCESS.NOACCESS;
  }

  const roleRef = getRoleRefInCurrentOrganization(user);
  if (!roleRef) {
    return PAGEACCESS.NOACCESS;
  }

  if (roleRef && (roleRef.name === "superAdmin" || roleRef.name === "admin")) {
    if (
      roleRef.name === "admin" &&
      pathName.substr(0, orgGeneral.length).toLowerCase() === orgGeneral
    ) {
      return PAGEACCESS.VIEW;
    } else return PAGEACCESS.ALL;
  }

  const orgPermissions = roleRef.orgPermissions;
  const projectPermissions =
    roleRef.projectPermissions &&
    roleRef.projectPermissions.length > 0 &&
    roleRef.projectPermissions[0];

  if (pathName.substr(0, orgGeneral.length).toLowerCase() === orgGeneral) {
    return getAccess(orgPermissions, "ORG_GENERAL");
  } else if (pathName.substr(0, orgMember.length).toLowerCase() === orgMember) {
    return getAccess(orgPermissions, "ORG_MEMBERS");
  } else if (
    pathName.substr(0, orgRoles.length).toLowerCase() === orgRoles ||
    pathName.substr(0, orgCreateRole.length).toLowerCase() === orgCreateRole ||
    pathName.substr(0, orgEditRole.length).toLowerCase() === orgEditRole ||
    pathName.substr(0, orgViewRole.length).toLowerCase() === orgViewRole
  ) {
    return getAccess(orgPermissions, "ORG_ROLES");
  } else if (
    pathName.substr(0, orgCreateProject.length).toLowerCase() ===
    orgCreateProject
  ) {
    return getAccess(orgPermissions, "ORG_CREATEPROJECT");
  }

  let accessProjectPages = false;
  if (projectPermissions.projects.length > 0) {
    if (projectPermissions.projects[0].toLowerCase() === "all")
      accessProjectPages = true;
    else if (projectPermissions.projects.includes(projectId))
      accessProjectPages = true;
  }

  let projectAccess = -1;
  if (
    pathName.substr(0, projDashboard.length).toLowerCase() === projDashboard
  ) {
    projectAccess = getAccess(projectPermissions.permissions, "PROJ_OVERVIEW");
  } else if (pathName.substr(0, projUser.length).toLowerCase() === projUser) {
    projectAccess = getAccess(projectPermissions.permissions, "PROJ_USERS");
  } else if (
    pathName.substr(0, projSecurity.length).toLowerCase() === projSecurity
  ) {
    projectAccess = getAccess(projectPermissions.permissions, "PROJ_SECURITY");
  } else if (
    pathName.substr(0, projModeration.length).toLowerCase() === projModeration
  ) {
    projectAccess = getAccess(
      projectPermissions.permissions,
      "PROJ_MODERATION"
    );
  } else if (
    pathName.substr(0, projChatbot.length).toLowerCase() === projChatbot
  ) {
    projectAccess = getAccess(projectPermissions.permissions, "PROJ_CHATBOT");
  } else if (
    pathName.substr(0, projMessage.length).toLowerCase() === projMessage
  ) {
    projectAccess = getAccess(
      projectPermissions.permissions,
      "PROJ_EXPORTMESSAGE"
    );
  } else if (
    pathName.substr(0, projCompliance.length).toLowerCase() === projCompliance
  ) {
    projectAccess = getAccess(
      projectPermissions.permissions,
      "PROJ_COMPLIANCE"
    );
  } else if (
    pathName.substr(0, projInfrastructure.length).toLowerCase() ===
    projInfrastructure
  ) {
    projectAccess = getAccess(
      projectPermissions.permissions,
      "PROJ_INFRASTRUCTURE"
    );
  } else if (pathName.substr(0, projUsage.length).toLowerCase() === projUsage) {
    projectAccess = getAccess(projectPermissions.permissions, "PROJ_USAGE");
  } else if (
    pathName.substr(0, projBilling.length).toLowerCase() === projBilling
  ) {
    projectAccess = getAccess(projectPermissions.permissions, "PROJ_BILLING");
  } else if (
    pathName.substr(0, projAnalytics.length).toLowerCase() === projAnalytics
  ) {
    projectAccess = getAccess(projectPermissions.permissions, "PROJ_ANALYTICS");
  } else if (
    pathName.substr(0, projSettings.length).toLowerCase() === projSettings
  ) {
    projectAccess = getAccess(projectPermissions.permissions, "PROJ_SETTINGS");
  } else if (
    pathName.substr(0, projUpgrade.length).toLowerCase() === projUpgrade
  ) {
    projectAccess = getAccess(
      projectPermissions.permissions,
      "PROJ_UPGRADEPROJECT"
    );
  }

  if (projectAccess > -1) {
    if (accessProjectPages) return projectAccess;
    else return PAGEACCESS.NOACCESS;
  }

  return PAGEACCESS.ALL;
}

export function redirectForUnauthorizedPage(pathName, user, projectId) {
  if (getPageAccess(pathName, user, projectId) === PAGEACCESS.NOACCESS) {
    history.push("/home");
  }
}

export function isPageEditable(pathName, user, projectId) {
  return getPageAccess(pathName, user, projectId) === PAGEACCESS.ALL;
}

export function isPageAccessible(pathName, user, projectId) {
  return getPageAccess(pathName, user, projectId) !== PAGEACCESS.NOACCESS;
}
