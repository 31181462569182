import React from "react";
import {
  ActionError,
  ActionSuccess
} from "../../utils/httpResponseErrorHandler";
import AlertMessage from "./alertMessage";

const ServerResponse = ({
  resultObj,
  showErrorOnly = false,
  customSuccessMessage,
  customErrorMessage,
  customClass,
  customContainerStyle,
  customIconStyle,
  topMargin,
  type = "large"
}) => {
  let alertType;
  let message = "";

  if (resultObj) {
    if (resultObj instanceof ActionError) {
      alertType = "error";
      message = customErrorMessage || resultObj.message;
    } else if (resultObj instanceof ActionSuccess) {
      if (showErrorOnly) {
        return null;
      }
      alertType = "success";
      message = customSuccessMessage || resultObj.message;
    }
    return (
      <AlertMessage
        message={message}
        alertType={alertType}
        customClass={customClass}
        topMargin={topMargin}
        customContainerStyle={customContainerStyle}
        customIconStyle={customIconStyle}
        type={type}
      />
    );
  } else {
    return null;
  }
};

export default ServerResponse;
