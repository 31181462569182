import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import connect from "react-redux/es/connect/connect";
import PeerToPeerChatImage from "../../../public/resources/site/images/peer-to-peer-chat.png";
import EasyToUseIcon from "../../../public/resources/common/icons/easy-to-use.svg";
import GoToMarketIcon from "../../../public/resources/common/icons/go-to-market.svg";
import CustomizedIcon from "../../../public/resources/common/icons/customized.svg";
import UCassProviderIcon from "../../../public/resources/common/icons/ucaas-providers.svg";
import GameDeveloperIcon from "../../../public/resources/common/icons/game-developer.svg";
import SocialCommunityIcon from "../../../public/resources/common/icons/social-communities.svg";
import HealthCareIcon from "../../../public/resources/common/icons/helthcare-proider.svg";
import CustomerServiceIcon from "../../../public/resources/common/icons/customer-service.svg";
import AnywhereIcon from "../../../public/resources/common/icons/anywhere.svg";
import Footer_v2 from "../common/footer_v2";
import {
  getSignupOrProjectRedirectionUrl,
  getTryForFreeRedirectionUrl
} from "../../../utils/planUtil";
import TitleFeatureBox from "../../common/titleFeatureBox";
import ExpandableCardsBlock from "../../common/expandableCardsBlock";

class PeerToPeerContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderBannerSection() {
    return (
      <div className="banner-section peer-to-peer-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5">
              <div className="content">
                <h1 className="wow fadeInDown">
                  <span className="text-orange">Peer-to-Peer</span> Chat
                </h1>
                <p className="wow fadeInUp">
                  Unleash the ultimate communications for your users quickly,
                  with our chat API & SDKs, designed with your app in mind.
                </p>
                <div className="action wow fadeInUp" data-wow-delay="0.4s">
                  <Link
                    to={getSignupOrProjectRedirectionUrl(this.props.isLoggedIn)}
                    className="btn btn-primary "
                  >
                    Get Started
                  </Link>
                </div>
                <div className="img-block d-block d-md-none wow fadeIn">
                  <img
                    src={PeerToPeerChatImage}
                    alt="Peer-to-Peer Chat Banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="animation-shape d-none d-md-block">
          <span className="shape1" />
          <span className="shape2" />
          <span className="shape3" />
          <span className="shape4" />
          <span className="shape5" />
        </div>
        <div className="tag-line">
          <div className="container">
            <p className="wow fadeInUp">HUMAN TO HUMAN CHAT</p>
          </div>
        </div>
      </div>
    );
  }

  renderMoreBasicsBody() {
    return (
      <>
        Enable your application to do more than just send a message! Users want
        increased abilities like message{" "}
        <Link to="/features?pkey=edit" target="_blank" className="text-blue">
          editing
        </Link>
        , &nbsp;
        <Link to="/features?pkey=copy" target="_blank" className="text-blue">
          copy
        </Link>
        , &nbsp;
        <Link
          to="/features?pkey=add_to_favorites"
          target="_blank"
          className="text-blue"
        >
          save
        </Link>{" "}
        and even &nbsp;
        <Link
          to="/features?pkey=pin_message"
          target="_blank"
          className="text-blue"
        >
          pin
        </Link>
        . With helpful tools like &nbsp;
        <Link
          to="/features?pkey=typing_indicator"
          target="_blank"
          className="text-blue"
        >
          typing indicators
        </Link>{" "}
        and &nbsp;
        <Link
          to="/features?pkey=time_stamp"
          target="_blank"
          className="text-blue"
        >
          time stamps
        </Link>
        , In-App Chat allows you to build functions into your app that are only
        expected from the very best, thus upping your game and increasing your
        likelihood of success.
      </>
    );
  }

  renderMoreFunBody() {
    return (
      <>
        If a picture is worth a thousand words, image what your users can say
        with &nbsp;
        <Link to="/features?pkey=gifs" target="_blank" className="text-blue">
          GIFs
        </Link>
        ? Users can access all of the fun stuff that comes along with messaging,
        like &nbsp;
        <Link
          to="/features?pkey=reactions"
          target="_blank"
          className="text-blue"
        >
          reacting
        </Link>
        , &nbsp;
        <Link to="/features?pkey=emojis" target="_blank" className="text-blue">
          sending emojis
        </Link>
        , and &nbsp;
        <Link
          to="/features?ckey=media_sharing"
          target="_blank"
          className="text-blue"
        >
          media sharing
        </Link>
        , all without users having to leave your application. The more time they
        spend in your app, the higher your chances of building lasting
        customers.
      </>
    );
  }

  renderMoreCapabilitiesBody() {
    return (
      <>
        Increase brand loyalty by including features that give users more
        capabilities! Increase their collaboration with features like &nbsp;
        <Link
          to="/features?pkey=group_messaging"
          target="_blank"
          className="text-blue"
        >
          group messaging
        </Link>
        , and &nbsp;
        <Link to="/features?pkey=file" target="_blank" className="text-blue">
          file sharing
        </Link>
        . &nbsp;
        <Link
          to="/features?pkey=thread_messaging"
          target="_blank"
          className="text-blue"
        >
          Threaded messages
        </Link>{" "}
        allow for side conversations that don’t disrupt the whole team, and
        mentions decrease the chances of important information slipping through
        the cracks.
      </>
    );
  }

  renderMoreProtection() {
    return (
      <>
        Not only does In-App Chat scan for &nbsp;
        <Link
          to="/features?pkey=container_vulnerability_scanning"
          target="_blank"
          className="text-blue"
        >
          container vulnerabilities
        </Link>{" "}
        and complete &nbsp;
        <Link
          to="/features?pkey=compliance_reporting"
          target="_blank"
          className="text-blue"
        >
          compliance reporting
        </Link>
        , it also allows for &nbsp;
        <Link
          to="/features?pkey=message_moderation"
          target="_blank"
          className="text-blue"
        >
          moderation
        </Link>{" "}
        to avoid uncontrolled communications through your app in professional
        settings. With the ability to &nbsp;
        <Link
          to="/features?pkey=block_user"
          target="_blank"
          className="text-blue"
        >
          block users
        </Link>
        , require &nbsp;
        <Link
          to="/features?pkey=message_moderation"
          target="_blank"
          className="text-blue"
        >
          message approval
        </Link>
        , and &nbsp;
        <Link
          to="/features?pkey=profanity_filter"
          target="_blank"
          className="text-blue"
        >
          profanity filters
        </Link>
        , your users can feel secure knowing their conversations are safe and
        secure.
      </>
    );
  }

  renderEasyLaunchSection() {
    const cards = [];
    cards.push({
      id: "moreBasic",
      title: "More Basics",
      wowDelay: "0.1s",
      bodyJsx: this.renderMoreBasicsBody()
    });
    cards.push({
      id: "moreFun",
      title: "More Fun",
      wowDelay: "0.2s",
      bodyJsx: this.renderMoreFunBody()
    });
    cards.push({
      id: "moreCapabilities",
      title: "More Capabilities",
      wowDelay: "0.3s",
      bodyJsx: this.renderMoreCapabilitiesBody()
    });
    cards.push({
      id: "moreProtection",
      title: "More Protection",
      wowDelay: "0.4s",
      bodyJsx: this.renderMoreProtection()
    });

    return (
      <div className="bg-gray">
        <div className="content-center-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="title-content center-title">
                  <div className="tag wow fadeInDown">easy launch</div>
                  <h2 className="wow fadeInUp">
                    Launch Your In-App Chat Easier
                  </h2>
                </div>
                <div className="content-icon-box-block">
                  <div className="row justify-content-center">
                    <TitleFeatureBox
                      title={"Easy-To-Use"}
                      description={
                        "Builds into your application easily with our powerful SDKs or APIs and scales with you."
                      }
                      imgSrc={EasyToUseIcon}
                      wowDelay={"0.1s"}
                    />

                    <TitleFeatureBox
                      title={"Go To Market Faster"}
                      description={
                        "Don’t waste time building subpar messaging when we enable you to get to market faster."
                      }
                      imgSrc={GoToMarketIcon}
                      imgClass={"mw-40"}
                      wowDelay={"0.2s"}
                    />

                    <TitleFeatureBox
                      title={"Customized To You"}
                      description={
                        "Offer your users all the features they expect, packaged to fit within your application and brand."
                      }
                      imgSrc={CustomizedIcon}
                      imgClass={"mw-33"}
                      wowDelay={"0.3s"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-collapse-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="title-content">
                  <h3 className="wow fadeInDown">
                    Exceed Your Users Expectations
                  </h3>
                  <p className="wow fadeInUp">
                    When you build your peer-to-peer chat with In-App Chat, not
                    only do you unlock the features your users expect in their
                    applications, you give them access to more than they could
                    have ever anticipated.
                  </p>
                </div>
                <div className="custom-accordion" id="users-expectations">
                  <ExpandableCardsBlock cards={cards} />
                </div>
                <div className="content-action pb-100 text-center">
                  <Link
                    to="/features"
                    className="btn btn-primary wow fadeInUp"
                    data-wow-delay="0.5s"
                  >
                    Learn More About In-App Features
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBuildChatSection() {
    return (
      <>
        <div className="content-center-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="title-content center-title">
                  <div className="tag wow fadeInDown">build chat</div>
                  <h2 className="wow fadeInUp mb-3">
                    Building Chat for Peer-to-Peer
                  </h2>
                  <p className="mw-600 wow fadeInUp">
                    Create a seamless and secure experience for your users, no
                    matter how they chat with our chat APIs!
                  </p>
                </div>
                <div className="content-icon-box-block left-icon-box white-icon-box">
                  <div className="row justify-content-center">
                    <TitleFeatureBox
                      title={"UCaaS Providers"}
                      description={
                        "Add Messaging To Your Platform For Better Team Communication."
                      }
                      imgSrc={UCassProviderIcon}
                      wowDelay={"0.1s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Customer Service"}
                      description={
                        "Create New & Preferred Methods of Conversation for Customer Satisfaction."
                      }
                      imgSrc={CustomerServiceIcon}
                      wowDelay={"0.2s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Healthcare Industry"}
                      description={
                        "Remain HIPAA Compliant While Better Reaching Your Patients."
                      }
                      imgSrc={HealthCareIcon}
                      wowDelay={"0.3s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Gamers"}
                      description={
                        "Keep Gamers In-App By Providing Easy Methods of Team Cooperation."
                      }
                      imgSrc={GameDeveloperIcon}
                      wowDelay={"0.4s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Social Communities"}
                      description={
                        "Increase Your User Involvement and Expand Your Reach."
                      }
                      imgSrc={SocialCommunityIcon}
                      wowDelay={"0.5s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Anywhere Chat Happens!"}
                      description={
                        "If Your Users Are Talking, Provide Them with a Better Way Today!"
                      }
                      imgSrc={AnywhereIcon}
                      wowDelay={"0.6s"}
                      titleBelowIcon={true}
                    />
                  </div>
                </div>
                <div className="content-action pb-100">
                  <Link
                    to={"/conversational-ai"}
                    className="btn btn-primary wow fadeInUp"
                    data-wow-delay="0.7s"
                  >
                    Explore More Using Chatbots
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderBeyondLimitSection() {
    return (
      <div className="content-center-block bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="title-content center-title">
                <div className="tag wow fadeInDown">Beyond limit</div>
                <h2 className="wow fadeInUp mb-3">
                  Exceed Your Own Expectations
                </h2>
                <p className="mw-800 wow fadeInUp">
                  You’ve already put your heart and soul into your application,
                  why spread yourself thin when it comes to adding
                  communications? Our customizable chat APIs and SDKs allow you
                  to build the capabilities you want, without ever having to
                  worry about missing pieces or compliance issues. In-App Chat
                  has you covered!
                </p>
              </div>
              <div className="content-box-block">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="box-block">
                      <h3>White Label Ready</h3>
                      <p>
                        Keep your identity with our white-label ready solution!
                        We know how important it is to maintain your image with
                        your users. That’s why In-App Chat blends in with your
                        product to help your app stand out!
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="box-block">
                      <h3>Get Off The Ground Faster!</h3>
                      <p>
                        Stop wasting precious time! When it comes to going to
                        market, we know time matters. When you build in-house
                        you are committing to more time spent in development and
                        more money spent in the long run. In-App Chat cuts out
                        all the waste and gives you more features faster than
                        developing in-house. We get your app off the ground and
                        into the hands of your users faster!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-action pb-100">
                <Link
                  to={getTryForFreeRedirectionUrl(this.props.isLoggedIn)}
                  className="btn btn-primary wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  Get Started For Free
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <SiteHeader fixedHeaderOnScroll={false} isDark selected="p2p" />
        <div className="content-wrapper main-content">
          {this.renderBannerSection()}
          {this.renderEasyLaunchSection()}
          {this.renderBuildChatSection()}
          {this.renderBeyondLimitSection()}
        </div>
        <Footer_v2 />
      </div>
    );
  }
}

function mapStatsToProps(stats) {
  return {
    user: stats.user.user,
    isLoggedIn: stats.user.isLoggedIn,
    projects: stats.project.projects
  };
}

export default connect(mapStatsToProps, {})(PeerToPeerContent);
