import React, { Component } from "react";
import ErrorIcon from "../public/resources/portal/images/error-icon.svg";
import SitePageContainer from "../components/common/sitePageContainer";

export default function(ComposedComponent) {
  class NetworkDetector extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isDisconnected: false
      };
    }

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener("online", this.handleConnectionChange);
      window.addEventListener("offline", this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener("online", this.handleConnectionChange);
      window.removeEventListener("offline", this.handleConnectionChange);
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? "online" : "offline";
      /* if (condition === "online") {
        const webPing = setInterval(() => {
          fetch("//google.com", {
            mode: "no-cors"
          })
            .then(() => {
              if (this.state.isDisconnected) {
                this.setState({ isDisconnected: false }, () => {
                  return clearInterval(webPing);
                });
              } else {
                clearInterval(webPing);
              }
            })
            .catch(() => this.setState({ isDisconnected: true }));
        }, 2000);
        return;
      } */

      return this.setState({ isDisconnected: condition === "offline" });
    };

    render() {
      const { isDisconnected } = this.state;
      return (
        <div>
          {isDisconnected && (
            <SitePageContainer>
              <div className="content-wrapper text-center">
                <div className="content-form-block">
                  <div className="row">
                    <div className="col-12 col-md-12 col-md-12 col-lg-12">
                      <div className="error-info-block">
                        <div className="error-content-block">
                          <div className="vector" />
                          <div className="content">
                            <div className="error-icon">
                              <img
                                src={ErrorIcon}
                                className="img-fluid"
                                alt="In-App Chat Error"
                              />
                            </div>
                            <div className="title mt-3">
                              No Internet Connection
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SitePageContainer>
          )}
          {!isDisconnected && <ComposedComponent {...this.props} />}
        </div>
      );
    }
  }

  return NetworkDetector;
}
