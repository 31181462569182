import React, { Component } from "react";
import SiteHeader from "../../../common/siteHeader";
import WebchatDocSidebar from "./webchatDocSidebar";
import ChatbotsCategoryNavBar from "../chatbots/chatbotsCategoryNavBar";
import OneWebchatTitleBlock from "./oneWebchatTitleBlock";
import reply1Img from "../../../../public/resources/site/images/reply-naming-conventions1.png";
import reply2Img from "../../../../public/resources/site/images/reply-naming-conventions2.png";
import addRoutingImg1 from "../../../../public/resources/site/images/add-routing-intents1.png";
import addRoutingImg2 from "../../../../public/resources/site/images/add-routing-intents2.png";
import CodeBlock from "../codeBlock";
import { WEBCHAT_DEFAULT_REPLIES_SUGGESTION_CHIPS } from "../code";

class Docs1WebchatDefaultReplies extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <ChatbotsCategoryNavBar />
        <main className="content-wrapper main-content">
          <OneWebchatTitleBlock />
          <div className="content-left-block connecting-grid-block --scrollpage">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xl-3 tab-inner-content">
                  <WebchatDocSidebar />
                </div>
                <div className="col-lg-8 col-xl-9">
                  <div className="tab-content tutorials-details-block">
                    <div className="tab-pane fade show active" id="onechatbot">
                      <h2>
                        <strong>Guides</strong>
                      </h2>
                      <h3>Default Replies</h3>
                      <h3 id="naming-conventions" className="top-gap">
                        Default reply naming conventions
                      </h3>
                      <p className="mb-4 mb-lg-5">
                        In the event that your chatbot does not recognize what
                        the user is sayingor typing, the “default reply” or
                        “fallback intent” is triggered.
                      </p>
                      <p>
                        By default, your NLU provider will have settings for
                        these. For example, Google Dialogflow ES has the default
                        name as “Default Fallback Intent” — as long as the
                        default names have not been changed for your NLU
                        provider then you can skip this guide. Just in case,
                        here are the correct default reply settings for various
                        NLU providers.
                      </p>
                      <p>
                        Choose provider(s) below to get details on proper
                        default reply naming conventions.
                      </p>
                      <ul className="field-list">
                        <li>Google Dialogflow ES</li>
                        <li>Amazon Lex</li>
                        <li>IBM Watson Assistant</li>
                      </ul>
                      <ul>
                        <li>
                          Google Dialogflow ES
                          <div className="info-content font-weight-normal font-16 mt-2">
                            <p>
                              By default Google Dialogflow ES names the default
                              reply “Default Fallback Intent” with an Action
                              setting to “input.unknown” — as long as these
                              settings have not been changed then Google
                              Dialogflow is ready for use with 1Webchat.
                            </p>
                            <p>
                              If the settings had been changed previously then
                              you can do either of these so that the default
                              reply is properly recognized by 1Webchat:
                            </p>
                            <ul>
                              <li>
                                Ensure the name of the default reply is “Default
                                Fallback Intent” by editing the top title field
                                and clicking save
                              </li>
                              <li>
                                Alternatively, ensure that the Action setting in
                                the default reply intent is set to
                                “input.uknown”
                              </li>
                            </ul>
                            <div className="image-block mb-5">
                              <img src={reply1Img} alt="Google Dialogflow ES" />
                            </div>
                          </div>
                        </li>
                        <li>
                          Amazon Lex
                          <div className="info-content font-weight-normal font-16 mt-2">
                            <p>
                              By default Amazon Lex names the default reply
                              “FallbackIntent” — as long as this setting has not
                              been changed then Amazon Lex is ready for use with
                              1Webchat.
                            </p>
                            <p>
                              If the setting has been changed previously then
                              you can complete this step below so that the
                              default reply is properly recognized by 1Webchat:
                            </p>
                            <ul>
                              <li>
                                Ensure that the default reply intent is named
                                “FallbackIntent” — then click Save and Build.
                              </li>
                            </ul>
                            <div className="image-block mb-5">
                              <img src={reply2Img} alt="Amazon Lex" />
                            </div>
                          </div>
                        </li>
                        <li>
                          IBM Watson Assistant
                          <div className="info-content font-weight-normal font-16 mt-2">
                            No steps are required to conform to default reply
                            naming conventions for Watson.
                          </div>
                        </li>
                      </ul>
                      <div className="info-content font-weight-normal font-16 mt-2">
                        <h3 id="priority-chatbot" className="top-gap">
                          Default Reply Priority Chatbot
                        </h3>
                        <p>
                          The default reply response in the bot set for priority
                          can be an important way to guide your users in the use
                          of all the bots within your 1Webchat agent.
                        </p>
                        <p>
                          Your Default Reply Priority Chatbot response can be
                          the end users “map” to understand what they can and
                          can’t do.
                        </p>
                        <p>
                          Let’s take for example a 1Webchat agent with these 3
                          bots under its command:
                        </p>
                        <ul>
                          <li>
                            Account Automation bot - helps your employees set up
                            new customer accounts
                          </li>
                          <li>
                            Ticket Creation bot - lets your team request help
                            from IT
                          </li>
                          <li>HR bot - sick day and vacation notifications</li>
                        </ul>
                        <p>The end user types something like this:</p>
                        <p>
                          <span className="text-lightorange">User:</span> What
                          can you do?
                        </p>
                        <p>
                          In this case, the intent was to learn what the options
                          were to obtain help from the 1Webchat agent, but it
                          does not match to an intent. All three bots return no
                          response for this request as they have no such intent
                          set up. All three bots return a default reply, with no
                          intent, or action matched. In this case, Support bot
                          was pre-selected to be the Default Reply Priority
                          Chatbot, so only Support bot’s default reply will be
                          sent back to the user.
                        </p>
                        <p>
                          This scenario elaborates why it is important to
                          consider the content of the default reply message in
                          the bot selected for priority. Here’s an example:
                        </p>
                        <p>
                          <span className="text-lightorange">Support bot</span>:
                          "Sorry I didn’t understand. I can help you with
                          choosing the right product, submitting a support
                          request, or order tracking.”
                        </p>
                        <p>
                          Support bot’s default reply is set to inform the user
                          about the popular intents for all of the bots under
                          1Webchat’s command.
                        </p>
                        <p>
                          By careful consideration of the content of the Default
                          Reply Priority Intent you will help your end users
                          navigate through multiple bots in the same chat
                          interface.
                        </p>
                      </div>
                      <div className="info-content font-weight-normal font-16 mt-2">
                        <h3 id="priority-chatbot" className="top-gap">
                          Add Routing Intents for Disambiguation
                        </h3>
                        <p>
                          Although this should be avoided, in some cases
                          multiple bots under the command of 1Webchat will have
                          the same or similar training phrases.
                        </p>
                        <p>
                          <strong>Example:</strong>
                        </p>
                        <p>
                          The following 2 bots are combined in a 1Webchat
                          widget: Subscription bot and Technical Support bot.
                        </p>
                        <p>
                          <strong>Subscription bot: </strong>
                          Contains an intent “Get pricing” with a training
                          phrase “How much does it cost?”
                        </p>
                        <p>
                          <strong>Technical Support bot: </strong>
                          Contains an intent “Technical support pricing” with a
                          training phrase “How much is it?”
                        </p>
                        <p>
                          In this example, 2 bots within a single 1Webchat have
                          the same or similar training phrases for pricing
                          questions about different aspects of the company: one
                          is for pricing of the subscription itself, the other
                          is for pricing of the technical support add-on.
                        </p>
                        <p>
                          This would lead to confusion as 1Webchat may not
                          determine which bot the question should be routed to.
                        </p>
                        <p>
                          If such a situation exists for your bot stack, the
                          solution is to create a new intent that will serve as
                          a “Routing Intent” for all the bots that have similar
                          training phrases.
                        </p>
                        <p>
                          <strong>How to set up a new “Routing Intent”</strong>
                        </p>
                        <ul className="field-list">
                          <li>
                            Choose one of the bots (e.g. Support bot) and create
                            a new intent called “Price Routing Intent” or
                            similar (depending on the topic). This can be any of
                            the bots in either Dialogflow, Lex or Watson. For
                            these examples we use Dialogflow
                          </li>
                          <li>
                            Add all the training phrases that are similar to the
                            new “Price Routing Intent” — in this example we
                            would add “How much does it cost” and “How much is
                            it” to our new routing intent.
                            <div className="image-block mb-5">
                              <img
                                src={addRoutingImg1}
                                alt="Google Dialogflow ES"
                              />
                            </div>
                          </li>
                          <li>
                            Set the response for the new routing intent to
                            handle the disambiguation — for example, the
                            response can be “Would you like the pricing for our
                            subscription plans or technical support packages?”
                            <div className="image-block mb-5">
                              <img
                                src={addRoutingImg2}
                                alt="Google Dialogflow ES"
                              />
                            </div>
                            <p>
                              <strong>*Note: </strong>
                              This step is best accomplished with Suggestion
                              Chips (quick reply buttons) because you can set
                              the hidden value of the button to be something
                              specific such as “subscription plan pricing
                              request” which will trigger the correct bot
                              without issues.
                            </p>
                            <p>
                              <strong>
                                Example Suggestion Chips payload used for
                                routing intents
                              </strong>
                            </p>
                            <CodeBlock
                              code={WEBCHAT_DEFAULT_REPLIES_SUGGESTION_CHIPS}
                              language="json"
                            />
                          </li>
                          <li>
                            Adjust the original 2 pricing intents (for
                            Subscription bot: “Get pricing,” and for Technical
                            Support bot: “Technical support pricing”) to contain
                            the options set in the routing intent response. When
                            an end-user responds to the message from Step 3, it
                            will trigger the correct bot. In this example, we
                            add “subscription plans” and/or “subscription plan
                            pricing request” as training phrases for the intent
                            “Get pricing” in Subscription bot. We also add
                            “technical support” and/or “technical support
                            pricing request” as training phrases for the intent
                            “Technical support pricing” in the Technical Support
                            bot.
                          </li>
                          <li>
                            Finally, remove the training phrases that were
                            causing ambiguity in the original bots. In this
                            example, we remove “How much does it cost” from
                            Subscription bot’s training phrases for the “Get
                            pricing” intent. We also remove “How much is it?”
                            From the Technical Support bot’s training phrases
                            for the “Technical support pricing” intent.
                          </li>
                        </ul>
                        <p>
                          Publish your changes in the NLU providers and test the
                          1Webchat agent with the original phrase that was
                          causing confusion. The routing intent should now be
                          triggered which guides the end-user to the correct
                          intent in either of the bots with similar intent
                          topics.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Docs1WebchatDefaultReplies;
