import React from "react";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import config from "./config/config";

const bugsnagClient = bugsnag({
  apiKey: config.BUGSNAG_API_KEY,
  releaseStage: config.ENV
});
bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
