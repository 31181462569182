import { getProjectToken, PROVINT_TOKEN_UPDATED } from "../actions/provintAuth";
import { loadCookie, saveCookie } from "../utils/commonUtil";

export const loadState = () => {
  try {
    const tokenState = localStorage.getItem("state");
    if (tokenState === null) {
      return undefined;
    }
    const tokenStateObj = JSON.parse(tokenState);
    const cookieToken = loadCookie(`token`);

    if (typeof cookieToken === "undefined") {
      console.log("Loading token from local storage to cookie");
      if (tokenStateObj && tokenStateObj.token && tokenStateObj.token.token) {
        saveCookie(`token`, tokenStateObj.token.token);
      }
    }
    return tokenStateObj;
  } catch (e) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const tokenStateSerialized = JSON.stringify(state);
    localStorage.setItem("state", tokenStateSerialized);
  } catch (e) {
    console.log("Error in saving state", e);
  }
};

export const loadProjectToken = (
  projectId,
  user,
  dispatch,
  onSuccess,
  onFailure,
  successData
) => {
  let projectData;
  try {
    // Load from localstorage
    projectData = localStorage.getItem("projectTokens");
    if (projectData) {
      if (!projectId) throw "Project id is undefined";
      const projectDataObj = JSON.parse(projectData);

      if (
        projectDataObj &&
        projectDataObj.findIndex(p => p.id === projectId) >= 0
      ) {
        const token = projectDataObj.filter(p => p.id === projectId)[0].token;
        // save to cookie
        saveCookie(`token-${projectId}`, token.accessToken);
        dispatch({
          type: PROVINT_TOKEN_UPDATED,
          token: token
        });
        onSuccess();
        return;
      }
    }
    // Get new token
    if (!projectId || !user || !user.userId) {
      throw "ProjectId/User/UserId not defined";
    }
    getProjectToken(projectId, user.userId, dispatch)
      .then(() => {
        onSuccess();
      })
      .catch(error => {
        onFailure({
          provIntTokenError: true,
          errorObj: error.actionDataObj,
          successData: successData
        });
        console.log("Exception while getting project token :" + error);
      });
  } catch (e) {
    onFailure();
    console.log("Exception while getting project token :" + e);
  }
  //return projectData;
};

export const loadProjectUrl = projectId => {
  try {
    const projectsObj = JSON.parse(localStorage.getItem("projectUrls"));
    if (projectId === null || projectsObj === null) {
      throw "No Project Url found";
    }

    const projectIndex = projectsObj.findIndex(
      project => projectId === project.id
    );
    if (projectIndex < 0) {
      throw "No Project Url found";
    }
    const projectUrlObj = projectsObj[projectIndex];

    if (projectUrlObj && projectUrlObj.url) return projectUrlObj.url;
  } catch (e) {
    console.log("Unable to load project url from storage. " + e);
  }
  return undefined;
};

export const saveProjectUrl = (projectId, state) => {
  try {
    if (!projectId) throw "Invalid projectId";
    const projectData = { id: projectId, url: state };
    let projectUrls = JSON.parse(localStorage.getItem("projectUrls"));
    if (projectUrls == null) projectUrls = [projectData];
    else {
      projectUrls = projectUrls.filter(p => p.id !== projectId);
      projectUrls = [...projectUrls, projectData];
    }
    localStorage.setItem(`projectUrls`, JSON.stringify(projectUrls));
    console.log("Saved project URLs to storage.", +JSON.stringify(projectUrls));
  } catch (e) {
    console.log("Unable to save project url." + e);
  }
};
