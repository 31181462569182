import React, { Fragment } from "react";
import history from "../../../history";
import { withRouter, Link } from "react-router-dom";
import { ProjectContext } from "../context/projectContextProvider";
import connect from "react-redux/es/connect/connect";
import gradientLogo from "../../../public/resources/common/images/gradient-logo-mark.svg";
import textLogo from "../../../public/resources/common/images/logo-text.svg";
import { isOneChatbotIncluded } from "../../../utils/commonUtil";

import {
  InAppChatDashboardMenus,
  OrganizationMenus,
  WidgetDashboardMenus
} from "./menus";

class DashboardSideBar extends React.Component {
  menuOptions = [];
  static contextType = ProjectContext;

  constructor(props) {
    super(props);
    this.state = {
      oneChatbotIncluded: false
    };
    this.handleClickOutsideOptions = this.handleClickOutsideOptions.bind(this);
    this.sidebarOptionsRef = React.createRef();
    this.getPageMenus = this.getPageMenus.bind(this);
  }

  handleClickOutsideOptions(event) {
    if (
      this.sidebarOptionsRef &&
      this.sidebarOptionsRef.current &&
      !this.sidebarOptionsRef.current.contains(event.target) &&
      this.props.sidebarToggle
    ) {
      this.props.onSidebarToggleClick();
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutsideOptions);
    //this.getCategories();
    const include = isOneChatbotIncluded(this.props.user);
    this.setState({ oneChatbotIncluded: include });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideOptions);
  }

  getPageMenus() {
    const { isDashboardSideBar } = this.props;
    const { project } = this.context;

    if (isDashboardSideBar) {
      return project?.projectType === "1widget"
        ? WidgetDashboardMenus
        : InAppChatDashboardMenus;
    }

    return OrganizationMenus;
  }

  render() {
    const menuConfig = this.getPageMenus();

    return (
      <aside
        className={`main-icon-sidebar ${
          this.props.sidebarToggle ? "open" : ""
        }`}
        ref={this.sidebarOptionsRef}
      >
        <div className="sidebar-wrapper">
          <Link to="/home" className="logo">
            <span className="logo-img">
              <img src={gradientLogo} alt="In-App Chat" />
            </span>
            <span className="logo-text">
              <img src={textLogo} alt="In-App Chat" />
            </span>
          </Link>
          <div className="sidebar-menu-block">
            <ul className="sidebar-menu">
              {menuConfig.map((item, index) =>
                this.renderMenuItem(item, index)
              )}
            </ul>
          </div>
        </div>
      </aside>
    );
  }

  redirectPage(project) {
    if (project && project.projectId) {
      let i = 0;
      for (i = 0; i < InAppChatDashboardMenus.length; i++) {
        const item = InAppChatDashboardMenus[i];
        let j = 0;
        if (!item) {
          continue;
        }

        for (j = 0; j < item.items.length; j++) {
          if (
            item.items.showMenuOptionFn &&
            item.items.showMenuOptionFn(project, this.props.user) === true
          ) {
            history.push(item.items.urlSupplier(project && project.projectId));
            return;
          }
        }
      }
      history.push(`/myprojects`);
    } else {
      let i = 0;
      for (i = 0; i < OrganizationMenus.length; i++) {
        const item = OrganizationMenus[i];
        if (item.showMenuOptionFn(project, this.props.user) === true) {
          history.push(item.urlSupplier());
          return;
        }
      }
      history.push(`/home`);
    }
  }

  renderMenuItem(menuOption) {
    const subMenuItems = menuOption.items.map((subitem, index) =>
      this.renderSubMenuItem(subitem, index)
    );
    return (
      <Fragment key={menuOption.name}>
        {subMenuItems && subMenuItems.length > 0 && (
          <li className="menu-item line-item">
            <span className="line" />
            <span className="title">{menuOption.name}</span>
          </li>
        )}
        {subMenuItems}
      </Fragment>
    );
  }

  renderSubMenuItem(menuOption, index) {
    const project = this.context && this.context.project;
    const { oneChatbotIncluded } = this.state;
    const {
      id,
      name,
      iconClass,
      urlSupplier,
      urlPrefix,
      showMenuOptionFn,
      disabled
    } = menuOption;
    const user = this.props.user;
    /*if(id == 'messages'){
            debugger;
        }*/
    if (showMenuOptionFn(project, user) === true) {
      return (
        <li className="menu-item" key={index}>
          <Link
            to={urlSupplier(project && project.projectId)}
            className={`menu-link ${this.props.active === id ? "active" : ""} ${
              disabled ? "disabled" : ""
            }`}
          >
            <span className="icon">
              <em className={iconClass} />
            </span>
            <span className="text">
              {name}
              {name === "Bots" && !oneChatbotIncluded && (
                <em
                  className={`i-icon icon-lock ml-2`}
                  style={{ position: "absolute", top: "5px" }}
                />
              )}
            </span>
          </Link>
        </li>
      );
    } else {
      if (Array.isArray(urlPrefix)) {
        if (
          urlPrefix.findIndex(prefix =>
            this.props.location.pathname.match(prefix)
          ) > 0
        ) {
          this.redirectPage(project);
        }
      } else if (this.props.location.pathname.match(urlPrefix)) {
        this.redirectPage(project);
      }
    }
    return null;
  }
}

function mapStatsToProps(stats) {
  return {
    user: stats.user.user
  };
}

export default connect(mapStatsToProps, {})(withRouter(DashboardSideBar));
