import history from "../history";

export function getTryForFreeRedirectionUrl(isLoggedIn) {
  const nextPage = isLoggedIn ? "/starter/project/create" : "/signup";
  return nextPage;
}

export function handleTryForFreeRedirection(isLoggedIn) {
  //const nextPage = isLoggedIn ? '/starter/project/create' : '/starter/signup';
  history.push(getTryForFreeRedirectionUrl(isLoggedIn));
}

export function getSignupOrProjectRedirectionUrl(isLoggedIn) {
  const nextPage = isLoggedIn ? "/myprojects" : "/signup";
  return nextPage;
}

export function handleBuyNowRedirection(isLoggedIn, projectId) {
  //const nextPage = isLoggedIn ? '/paid/packages' : '/professional/signup';
  const nextPage = isLoggedIn ? "/professional/instancetype" : "/signup";
  if (projectId) {
    history.push(nextPage + "/" + projectId);
  } else {
    history.push(nextPage);
  }
}

export function handleProfessionalRedirection(isLoggedIn, isShared) {
  //const nextPage = isLoggedIn ? '/paid/packages' : '/professional/signup';
  const instanceType = isShared ? "shared" : "dedicated";
  const actionType = isLoggedIn ? "/project/create" : "/signup";
  const nextPage = "/professional/" + instanceType + actionType;
  history.push(nextPage);
}
