import { endpoint, Request, restCall } from "../utils/restCallUtil";
import { isEmpty } from "lodash";
import {
  ActionError,
  genericFetchApiCatchHandler,
  genericHttpErrorHandler,
  genericHttpSuccessHandler,
  handleHttpResponse
} from "../utils/httpResponseErrorHandler";
//import config from "../config/config";

//var jsonPath = require('jsonpath');

//const DOMAIN = config.DOMAIN;

export const CART_UPDATE = "cart_update";

const AddCartUrl = endpoint("/users/cart");
const GetCartUrl = endpoint("/users/cart");
const CreateOrderUrl = endpoint("/users/order");
const CreateOrderSalesUrl = endpoint("/users/sales/order");
const ConfirmOrderUrl = endpoint("/users/order/confirm");
const GetMarketplaceCartEstimates = cartId =>
  endpoint(`users/cart/${cartId}/estimate`);
const ClearCartUrl = cartId => endpoint(`users/cart/${cartId}/clear`);

export function addBuyNowCart(cartItems) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      addCart(cartItems, "user", "buynow", dispatch, resolve, reject);
    });
  };
}

export function addToMarketplaceCart(projectId, productItem) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // Check if cart is present in redux. As per logic, it should always be present
      // in redux as getCart api is being called.If cart present, we will modify cart
      // object in client and then send updated one to server.
      // If cart not found error thrown,
      // cart will be undefined. In that case create new cart.
      let newCartItemsObj = [];
      const currentCart = getState().order.cart;
      if (currentCart && !isEmpty(currentCart)) {
        console.debug("Current cart " + JSON.stringify(currentCart));
        let currentCartItems = currentCart.cartItems;

        // First check if product is already present in cart.

        /*/const productsInCart = jsonPath.query(currenActive UserstCartItems, '$[*].productItems[*].product');
                console.log('Checking products in cart ' + JSON.stringify(productsInCart));

                const productFound = productsInCart.find(product => {
                    if (product._id) {
                        return productItem.product === product._id
                    } else {
                        return productItem.product === product;
                    }
                });*/

        if (checkForOtherProjectItem(currentCart, projectId)) {
          reject &&
            reject({
              actionDataObj: new ActionError(
                -1,
                "Only Products related to single project " +
                  "can be added in cart. If you want to add product for this project, please clear/order the cart and then add it."
              )
            });
          return;
        }

        if (searchProductInCart(currentCart, projectId, productItem.product)) {
          reject &&
            reject({
              actionDataObj: new ActionError(
                -1,
                "Product is already present in cart."
              )
            });
          return;
        }

        let projectIdFound = false;
        const newCartItems = currentCartItems.map(currentCartItem => {
          const currentProductItems = currentCartItem.productItems;
          const currentProjectId = currentCartItem.project.projectId;

          const newProductItems = currentProductItems.map(
            currentProductItem => {
              const newProductItem = {};
              newProductItem.product =
                currentProductItem.product._id || currentProductItem.product;
              newProductItem.variation = currentProductItem.variation;
              // we don't need price to send. Server doesn't accept even.
              delete newProductItem.variation["price"];
              return newProductItem;
            }
          );

          // Push the product under project id bucket for cartItems
          if (currentProjectId === projectId) {
            newProductItems.push(productItem);
            projectIdFound = true;
          }

          const newCartItemObj = {};

          newCartItemObj.projectId = currentProjectId;
          newCartItemObj.productItems = newProductItems;
          return newCartItemObj;
        });

        if (projectIdFound === false) {
          const newCartItem = {};
          newCartItem.projectId = projectId;
          newCartItem.productItems = [productItem];
          newCartItems.push(newCartItem);
        }
        newCartItemsObj = newCartItems;
      } else {
        const newCartItems = [];
        const newCartItem = {};
        newCartItem.projectId = projectId;
        newCartItem.productItems = [productItem];
        newCartItems.push(newCartItem);
        newCartItemsObj = newCartItems;
      }

      addCart(
        newCartItemsObj,
        "user",
        "marketplace",
        dispatch,
        resolve,
        reject,
        getCartSuccessHandler(dispatch, resolve)
      );
    });
  };
}

export function searchProductInCart(
  cartObj,
  searchProjectId,
  searchProductItem
) {
  if (!cartObj || !cartObj.cartItems || cartObj.cartItems.length === 0) {
    return false;
  }
  let cartItems = cartObj.cartItems;
  const found = cartItems.find(cartItem => {
    const productItems = cartItem.productItems;
    const projectId = cartItem.project.projectId;
    const productFound = productItems.find(productItem => {
      const productId = productItem.product._id || productItem.product;
      return productId === searchProductItem;
    });
    return productFound && projectId === searchProjectId;
  });
  return !!found;
}

export function checkForOtherProjectItem(cartObj, inputProjectId) {
  if (!cartObj || !cartObj.cartItems || cartObj.cartItems.length === 0) {
    return false;
  }

  let cartItems = cartObj.cartItems;
  const found = cartItems.find(cartItem => {
    const projectId = cartItem.project.projectId;
    return projectId !== inputProjectId;
  });
  return !!found;
}

function addCart(
  cartItems,
  idType,
  cartType,
  dispatch,
  resolve,
  reject,
  customSuccessHandler
) {
  const cartObj = {};
  cartObj.idType = idType;
  cartObj.cartType = cartType;
  cartObj.cartItems = cartItems;

  let successHandler;
  if (!customSuccessHandler) {
    successHandler = genericHttpSuccessHandler(resolve);
  } else {
    successHandler = customSuccessHandler;
  }

  const request = Request({
    url: AddCartUrl,
    method: "POST",
    headerObj: {
      "content-type": "application/json"
    },
    body: JSON.stringify(cartObj),
    authEnabled: true
  });

  restCall(request, dispatch)
    .then(response => {
      handleHttpResponse(
        response,
        successHandler,
        genericHttpErrorHandler(reject)
      );
    })
    .catch(error => {
      genericFetchApiCatchHandler(error, reject);
    });
}

export function getMarketplaceCart() {
  return getCart(null, "marketplace");
}

function getCart(cartId, cartType) {
  let url = GetCartUrl;
  let queryParams = "";
  if (cartType) {
    queryParams += `cartType=${cartType}`;
  }
  if (cartId) {
    if (queryParams.length !== 0) {
      queryParams += "&";
    }
    queryParams += `cartId=${cartId}`;
  }
  if (queryParams.length > 0) {
    url += `?${queryParams}`;
  }

  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: url,
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            getCartSuccessHandler(dispatch, resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

function getCartSuccessHandler(dispatch, resolve) {
  return actionSuccess => {
    const cartObj = actionSuccess.data;
    dispatch({
      type: CART_UPDATE,
      cart: cartObj
    });
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
}

/*function getCartMock(requestObj, dispatch) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const httpResponse = {};
            httpResponse.status = 200;
            httpResponse.json = () => {
                return new Promise((resolve) => {
                    const responseData = {};
                    responseData.success = true;
                    responseData.message = "Success";
                    responseData.result = {
                        cartId: "abc123"
                    };
                    resolve(responseData);
                });
            }
            resolve(httpResponse);
        }, 1000);
    })
}*/

export function createOrder(cartId, userOrSessionId, idType) {
  const orderObj = { cartId, userOrSessionId, idType };
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: CreateOrderUrl,
        method: "POST",
        headerObj: {
          "content-type": "application/json"
        },
        body: JSON.stringify(orderObj),
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function createSalesOrder(cartId, userOrSessionId, idType, cartType) {
  const orderObj = { cartId, userOrSessionId, idType };
  return dispatch => {
    return new Promise((resolve, reject) => {
      let successHandler = genericHttpSuccessHandler(resolve);
      if (cartType === "marketplace") {
        successHandler = createSalesOrderSuccessHandler(dispatch, resolve);
      }
      const request = Request({
        url: CreateOrderSalesUrl,
        method: "POST",
        headerObj: {
          "content-type": "application/json"
        },
        body: JSON.stringify(orderObj),
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            successHandler,
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

function createSalesOrderSuccessHandler(dispatch, resolve) {
  return actionSuccess => {
    dispatch({
      type: CART_UPDATE,
      cart: {}
    });
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
}

export function confirmOrder(orderId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: ConfirmOrderUrl,
        method: "POST",
        headerObj: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ orderId }),
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getMarketplaceCartEstimates(cartId) {
  const url = GetMarketplaceCartEstimates(cartId);
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: url,
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function clearMarketplaceCart() {
  const cartObj = {};
  // cartObj.idType = idType;
  // cartObj.cartType = cartType;
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const currentCart = getState().order.cart;
      if (currentCart && !isEmpty(currentCart)) {
        const cartId = currentCart._id;
        const url = ClearCartUrl(cartId);
        const request = Request({
          url: url,
          method: "POST",
          headerObj: {
            "content-type": "application/json"
          },
          body: JSON.stringify(cartObj),
          authEnabled: true
        });

        restCall(request, dispatch)
          .then(response => {
            handleHttpResponse(
              response,
              getCartSuccessHandler(dispatch, resolve),
              genericHttpErrorHandler(reject)
            );
          })
          .catch(error => {
            genericFetchApiCatchHandler(error, reject);
          });
      } else {
        reject({
          actionDataObj: new ActionError(
            -1,
            "Cart is already empty",
            null,
            null
          )
        });
      }
    });
  };
}
