import AlertMessage from "../../common/alertMessage";
import history from "../../../history";
import React from "react";
import MyProjectsHeader from "../myProjects/myProjectsHeader";

const DashboardErrorComp = ({ message, displayBuyNow }) => {
  return (
    <div className="login-flow-page">
      <MyProjectsHeader showSidebar={false} />
      <div className="login-top-bg no-bg-line create-project">
        <div className="container" style={{ textAlign: "center" }}>
          <div
            className="main-login-content-block"
            style={{ marginTop: "100px" }}
          >
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="col-8 text-center">
                <div>
                  <AlertMessage
                    message={message}
                    customContainerStyle={{ justifyContent: "center" }}
                    customClass="text-center"
                    showIcon={false}
                    alertType="error"
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginTop: "10px" }}>
              <div className="col-12">
                <button
                  onClick={() => history.push("/myProjects")}
                  className="btn btn-primary"
                  style={{ display: "inline-block" }}
                >
                  Projects
                </button>
                {displayBuyNow && (
                  <button
                    onClick={() => history.push("/pricing")}
                    className="ml-3 btn btn-primary"
                    style={{ display: "inline-block" }}
                  >
                    Buy Now
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardErrorComp;
