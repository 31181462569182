import React, { Component } from "react";
import SolutionPage from "../pages/solution";

export default function(solutionType) {
  return class extends Component {
    render() {
      return <SolutionPage {...this.props} type={solutionType} />;
    }
  };
}
