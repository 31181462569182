import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DefaultAvatar from "../../public/resources/common/icons/icon-user.svg";
import connect from "react-redux/es/connect/connect";
import { logoutUser } from "../../actions/users";
import { getAuthorizedImage } from "../../utils/commonUtil";
import OrganizationSwitcher from "./organizationSwitcher";
import { getDefaultOrganizationPageUrl } from "../../utils/pageAccessUtil";

class ProfileDropdown extends Component {
  //static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      profileDropdownOpen: false
    };
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onProfileClick = this.onProfileClick.bind(this);
    this.handleClickOutsideOptions = this.handleClickOutsideOptions.bind(this);
    this.optionsRef = React.createRef();
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  onProfileClick(e) {
    e.preventDefault();
    this.setState({
      profileDropdownOpen: !this.state.profileDropdownOpen
    });
  }

  handleClickOutsideOptions(event) {
    if (
      this.optionsRef &&
      this.optionsRef.current &&
      !this.optionsRef.current.contains(event.target) &&
      this.state.profileDropdownOpen === true
    ) {
      this.setState({ profileDropdownOpen: false });
    }
  }

  addEventListener() {
    document.addEventListener("mousedown", this.handleClickOutsideOptions);
  }

  removeEventListener() {
    document.removeEventListener("mousedown", this.handleClickOutsideOptions);
  }

  componentDidMount() {
    this.addEventListener();
  }

  componentWillUnmount() {
    this.removeEventListener();
  }

  render() {
    //if(this.context) {
    //const {user} = this.context;
    const { containerClass = "" } = this.props;
    const { name, profilePicThumb, email } = this.props.user;

    const AvatarSrc = profilePicThumb
      ? getAuthorizedImage(profilePicThumb)
      : DefaultAvatar;
    const noImageClass = profilePicThumb ? "" : "no-img";

    /*style={{top: '30px', left: 'auto', right : '-2px', marginTop: '10px',
                paddingTop: '5px', maxWidth: '180px'}}*/
    const defaultOrgUrl = getDefaultOrganizationPageUrl(this.props.user);
    return (
      <div
        className={`nav-item profile dropdown custom-dropdown ${containerClass}`}
        key={this.props.key}
        ref={this.optionsRef}
      >
        <a
          className="nav-link"
          href="#"
          data-toggle="dropdown"
          id="profile-dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={this.onProfileClick}
        >
          <img
            src={AvatarSrc}
            className={`profile-img ${noImageClass}`}
            alt="user"
          />
          {/*<i className="ri-arrow-down-s-line arrow"/>*/}
        </a>
        <ul
          className={`dropdown-menu ${
            this.state.profileDropdownOpen ? "show" : ""
          }`}
          aria-labelledby="profile-dropdown"
          style={
            this.state.profileDropdownOpen
              ? { display: "block" }
              : { display: "none" }
          }
        >
          <li>
            <div className="email-menu">
              <span
                className="name"
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  maxWidth: "250px"
                }}
              >{`${name}`}</span>
              <span className="email">{email}</span>
            </div>
          </li>
          <OrganizationSwitcher user={this.props.user} renderSamePage={true} />
          <li className="dropdown-item">
            <Link to="/accountAndProfile">Account and Profile</Link>
          </li>
          {defaultOrgUrl !== "" && (
            <li className="dropdown-item">
              <Link to={defaultOrgUrl}>Organizations Settings</Link>
            </li>
          )}
          <li className="dropdown-item">
            <a style={{ cursor: "pointer" }} onClick={this.onLogoutClick}>
              Log Out
            </a>
          </li>
          {/*<a className="dropdown-item">Version : {packageJson.version}</a>*/}
        </ul>
      </div>
    );
    //}
    //return null;
  }
}

ProfileDropdown.propTypes = {
  name: PropTypes.string
};

function mapStateToProps(state) {
  return {
    user: state.user.user
  };
}

export default connect(mapStateToProps, { logoutUser })(ProfileDropdown);
