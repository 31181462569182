import {
  chatEndpoint,
  endpoint,
  Request,
  restCall,
  socketServerWidgetEndpointWithoutTenant
} from "../utils/restCallUtil";
import {
  genericFetchApiCatchHandler,
  genericHttpErrorHandler,
  genericHttpSuccessHandler,
  handleHttpResponse
} from "../utils/httpResponseErrorHandler";

export const createOrganizationUrlSupplier = () =>
  endpoint("organizations/create");
export const OrganizationUrlSupplier = organization =>
  endpoint(`organizations/${organization}`);
export const createRoleUrlSupplier = organization =>
  endpoint(`organizations/${organization}/role`);
export const updateRoleUrlSupplier = (organization, roleId) =>
  endpoint(`organizations/${organization}/role/${roleId}`);
export const deleteRoleUrlSupplier = (organization, roleId) =>
  endpoint(`organizations/${organization}/role/${roleId}`);
export const memberByRoleIdUrlSupplier = (organization, roleId) =>
  endpoint(`organizations/${organization}/role/${roleId}/users`);
export const getRoleUrlSupplier = (organization, roleId) =>
  endpoint(`organizations/${organization}/role/${roleId}`);
export const getAllRolesUrlSupplier = organization =>
  endpoint(`organizations/${organization}/role`);
export const getUsageSummaryUrlSupplier = organization =>
  endpoint(`organizations/${organization}/usageSummary`);
export const getMsgSentCountUrlSupplier = organization =>
  chatEndpoint(`portal/organizations/${organization}/sentMsgCount`);
export const getWidgetAnalyticsUrlSupplier = organization =>
  socketServerWidgetEndpointWithoutTenant(
    `portal/organizations/${organization}/widget/analytics`
  );

export function createOrganization(organizationName, phone, options) {
  let bodyObj = { organizationName };
  if (phone && phone.length > 0) {
    bodyObj = { ...bodyObj, phone };
  }
  if (options) {
    bodyObj = { ...bodyObj, ...options };
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: createOrganizationUrlSupplier(),
        method: "POST",
        body: JSON.stringify(bodyObj),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function updateOrganization(organization, orgName) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: OrganizationUrlSupplier(organization),
        method: "POST",
        body: JSON.stringify({ name: orgName }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function createRole(organizationId, role) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: createRoleUrlSupplier(organizationId),
        method: "POST",
        body: JSON.stringify(role),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function updateRole(organizationId, roleId, role) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: updateRoleUrlSupplier(organizationId, roleId),
        method: "PUT",
        body: JSON.stringify(role),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function deleteRole(organizationId, roleId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: deleteRoleUrlSupplier(organizationId, roleId),
        method: "DELETE",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getRole(organizationId, roleId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: getRoleUrlSupplier(organizationId, roleId),
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getMemberByRoleId(organizationId, roleId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: memberByRoleIdUrlSupplier(organizationId, roleId),
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getAllRoles(organizationId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: getAllRolesUrlSupplier(organizationId),
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getOrgWebchatAnalytics(organizationId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: getWidgetAnalyticsUrlSupplier(organizationId),
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getOrgUsageSummary(organizationId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: getUsageSummaryUrlSupplier(organizationId),
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getOrgMessageSentCount(organizationId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: getMsgSentCountUrlSupplier(organizationId),
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}
