import React, { Component } from "react";
import ErrorIcon from "../../public/resources/portal/images/error-icon.svg";
import config from "../../config/config";
import { Link } from "react-router-dom";

class InternalServerError extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 col-md-12 col-md-12 col-lg-12">
          <div className="error-info-block">
            <div className="error-content-block">
              <div className="vector" />
              <div className="content">
                <div className="error-icon">
                  <img
                    src={ErrorIcon}
                    className="img-fluid"
                    alt="In-App Chat Error"
                  />
                </div>
                <div className="title">Internal Error.</div>
                <p>Please try again later.</p>
                <div className="help-link">
                  If the problem persists, please contact{" "}
                  <Link to={config.SUPPORT_URL} className="link">
                    customer support
                  </Link>
                  .
                </div>
                <div className="action mt-3">
                  <Link to="/home">
                    <button className="btn btn-lg btn-primary">Home</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InternalServerError;
