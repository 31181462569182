import {
  genericFetchApiCatchHandler,
  genericHttpErrorHandler,
  genericHttpSuccessHandler,
  handleHttpResponse
} from "../utils/httpResponseErrorHandler";

export function getLatestBlogs() {
  return (/*dispatch*/) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };
      const getBlogUrl =
        "https://blog.inappchat.io/api/feed-inappchat-news.php?cat=chatops";

      fetch(getBlogUrl, requestOptions)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          console.log("error", error);
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}
