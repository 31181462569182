export const WEBCHAT_DEFAULT_REPLIES_SUGGESTION_CHIPS = `{
  "action": "response",
  "type": "suggestionChips",
  "output": "button",
  "data": {
      "messages": [
          "Would you like the pricing for our subscription plans or technical support packages?"
      ],
      "options": [
          {
              "label": "Subscription plans",
              "value": "subscription plan pricing request"
          },
          {
              "value": "Technical support",
              "label": "technical support pricing request"
          }
      ]
  },
  "platform": "dialogflow"
}`;

export const WEBCHAT_DIALOGFLOW_SUGGESTION_CHIPS = `{
    "action": "response",
    "type": "suggestionChips",
    "output": "button",
    "data": {
        "messages": [
           "Which email do you want?"
        ],
        "options": [
            {
              "label": "Support Email",
              "value": "Support Email"
            },
            {
              "value": "Sales Email",
              "label": "Sales Email"
            }
        ]
    },
    "platform": "dialogflow"
}`;

export const WEBCHAT_LEX_SUGGESTION_CHIPS = `{
    "action": "response",
    "type": "suggestionChips",
    "output": "button",
    "data": {
        "messages": [
            "Which email do you want?"
        ],
        "options": [
            {
                "label": "Support Email",
                "value": "Support Email"
            },
            {
                "value": "Sales Email",
                "label": "Sales Email"
            }
        ]
    },
    "platform": "dialogflow"
}`;
