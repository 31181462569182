module.exports = {
  BACKEND_HOST: "prov-dev.inappchat.io",
  CHATSERVER_HOST: "socket-dev.inappchat.io",
  PROVINTSERVER_HOST: "localhost:5007",
  GLOBAL_HOST: "global-dev.inappchat.io",
  CHATBOT_HOST: "onechat-backend-dev.inappchat.io/api",
  SOCKET_SERVER_WIDGET_HOST: "onewebchat-socket-dev.inappchat.io",
  PROTOCOL: "https:",
  DOMAIN: "localhost",
  NAMESPACE_PREFIX: "",
  SKIP_PREFLIGHT_CHECK: true,
  CLOUD_HOSTED_ENABLED: true,
  ON_PREMISE_ENABLED: true,
  CHARGEBEE_SITE: "ripbullnetworks-test",
  CHARGEBEE_KEY: "test_0IhxZculGmzLbnkZSqTi5HP9wcddpRSo6h",
  IS_HTTPS: false,
  JS_SAMPLEAPP_URL: "https://js-client-qa.ripbullertc.com",
  ALLOW_GOOGLE_CRAWL: "0",
  JS_SDK_FEATURE: true,
  MAGICLINK_FEATURE: false,
  MODERATION_FEATURE: true,
  GROUP_FEATURE: true,
  ANNOUNCE_FEATURE: true,
  PREFERENCE_FEATURE: true,
  ONBOARDAUTH_FEATURE: true,
  DATAEXPORT_FEATURE: true,
  ANALYTICS_FEATURE: true,
  RECAPTCHA_KEY: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  ALGOLIA_SEARCH_KEY: "5f76359854488e7ac61e27d5a71986b4",
  ALGOLIA_APP_ID: "82V9L5XZDV",
  ALGOLIA_INDEX_NAME: "staging_inapp_data",
  KEYCLOAK_URL: "https://dev-keycloak.inappchat.io",
  USE_IDENTITY_PROVIDER: true,
  GOOGLE_CLIENT_ID:
    "879210835565-ts9gi7vpth8r4iac54g4hk200jvm2bo6.apps.googleusercontent.com",
  AUTH0_HOST: "https://auth.inappchat.io",
  AUTH0_CLIENT_ID: "JciNmkPxChwXsZqgejXhuZJqlSvjjdOW",
  AUTH0_REDIRECT_PAGE: "login",
  GA_CODE: "",
  GADS_CODE: "",
  SIGNUP_EVENT_ID: "",
  BOOKING_EVENT_ID: "",
  CHATBOTAUTOMATION_EVENT_ID: "",
  PURCHASE_EVENT_ID: "",
  CALENDLY_LINK: "https://calendly.com/ripbull/30min",
  HUBSPOT_PORTALID: "22677078",
  HUBSPOT_CONTACTSUPPORT_FORMID: "a4148693-5a9b-4601-b9ee-0482565680e2",
  HUBSPOT_CONTACTUS_FORMID: "b2c696dc-9e8f-4ccf-9703-29dbbf80912e",
  ANDROID_SDK_SAMPLE_REPO:
    "https://github.com/RipBullNetworks/Demo_App_Android",
  IOS_SDK_SAMPLE_REPO: "https://github.com/RipBullNetworks/Demo_App_iOS",
  INAPPCHAT_ONECHATBOT_S3_URL:
    "https://inappchat-1chatbot.s3.us-west-1.amazonaws.com/onewebchat.js"
};
