import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import SuccessDeskImage from "../../../public/resources/site/images/successfull-desktop.jpg";
import SuccessMobileImage from "../../../public/resources/site/images/successfull-mobile.jpg";
import Footer_v2 from "../common/footer_v2";

class SubscribeMailSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <main className="content-wrapper main-content thankyou-content">
          <div className="container">
            <div className="thankyou-content-block">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                  <div className="img-block">
                    <img
                      src={SuccessDeskImage}
                      className="img-fluid d-none d-lg-block"
                      alt="Successfully Subscribed"
                    />
                    <img
                      src={SuccessMobileImage}
                      className="img-fluid d-block d-lg-none"
                      alt="Successfully Subscribed"
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                  <div className="desc">
                    <h1 className="h3">Successfully Subscribed!</h1>
                    <p>
                      We look forward to providing you with information on
                      product updates and the latest industry news.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="action">
              <Link to="/home" className="btn btn-primary">
                Back to Home
              </Link>
            </div>
          </div>
        </main>
        <Footer_v2 />
      </>
    );
  }
}

export default SubscribeMailSuccess;
