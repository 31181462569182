import React, { Component } from "react";
import SiteHeader from "../../../common/siteHeader";
import NluTitleBlock from "./nluTitleBlock";
import ChatbotsDocSidebar from "./chatbotsDocSidebar";
import ChatbotsCategoryNavBar from "./chatbotsCategoryNavBar";

class DocsChatbotNluGD extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <ChatbotsCategoryNavBar />
        <main className="content-wrapper main-content">
          <NluTitleBlock />
          <div className="content-left-block connecting-grid-block --scrollpage">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xl-3 tab-inner-content">
                  <ChatbotsDocSidebar />
                </div>
                <div className="col-lg-8 col-xl-9">
                  <div className="tab-content tutorials-details-block">
                    <div className="tab-pane fade show active" id="dialogflow">
                      <h2>How to get credentials</h2>
                      <h3>Required fields</h3>
                      <ul className="field-list">
                        <li>Service Account Key</li>
                        <li>Default language</li>
                      </ul>
                      <ul>
                        <li>
                          Service Account Key
                          <ul>
                            <li>
                              Go to{" "}
                              <a
                                href="https://cloud.google.com/dialogflow/es/docs/quick/setup#sa-create"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                https://cloud.google.com/dialogflow/es/docs/quick/setup#sa-create
                              </a>{" "}
                              and follow the steps except for Step 5. In Step 5,
                              type “DialogFlow API Admin” to filter and choose:
                              <ul>
                                <li>
                                  <strong>Dialogflow API Admin</strong>
                                  <br /> Can query for intent; read & write
                                  session properties; read & write agent
                                  properties.
                                </li>
                              </ul>
                            </li>
                            <li>
                              After selecting DialogFlow API Admin click on “+
                              Add Another Role”Type “DialogFlow Intent Admin” to
                              filter and choose:
                              <ul>
                                <li>
                                  <strong>Dialogflow Intent Admin</strong>
                                  <br /> Can read & write intents
                                </li>
                              </ul>
                            </li>
                            <li>
                              Download the JSON file to your computer and then
                              upload it to the UI.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Default language
                          <ul>
                            <li>
                              <p>
                                Go to your DialogFlow ES agent, select the
                                settings icon, select Languages, and view the
                                “Default language” which will most likely be
                                “English - en”. However, if you have something
                                else like “English (United States) - en-US” then
                                it should selected in the drop down.
                              </p>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default DocsChatbotNluGD;
