export const SHOW_TOAST = "show_toast";
export const HIDE_TOAST = "hide_toast";
export const API_CALL_START = "api_call_start";
export const API_CALL_FINISH = "api_call_finish";
export const SHOW_NOTIFICATION = "show_notification";
export const HIDE_NOTIFICATION = "hide_notification";

export function showToast(message) {
  return function(dispatch) {
    dispatch({
      type: SHOW_TOAST,
      toast: getShowToastActionObject(message)
    });
  };
}

export function showSuccessToast(message) {
  return function(dispatch) {
    dispatch({
      type: SHOW_TOAST,
      toast: getShowToastActionObject(message, "success")
    });
  };
}

export function showErrorToast(message) {
  return function(dispatch) {
    dispatch({
      type: SHOW_TOAST,
      toast: getShowToastActionObject(message, "error")
    });
  };
}

export function apiCallStart(dispatch, apiCallKey) {
  dispatch({
    type: API_CALL_START,
    apiCallKey
  });
}

export function apiCallFinish(dispatch, apiCallKey) {
  dispatch({
    type: API_CALL_FINISH,
    apiCallKey
  });
}

export function hideToast() {
  return function(dispatch) {
    dispatch({
      type: HIDE_TOAST
    });
  };
}

export function showNotification({ message, description, type = "info" }) {
  return dispatch =>
    dispatch({
      type: SHOW_NOTIFICATION,
      data: {
        message,
        description,
        type
      }
    });
}

export function hideNotification() {
  return dispatch =>
    dispatch({
      type: HIDE_NOTIFICATION
    });
}

function getShowToastActionObject(message, type) {
  let object = {
    visible: true,
    message: message
  };

  if (type) {
    if (type === "success") {
      object = { ...object, success: true };
    } else if (type === "error") {
      object = { ...object, error: true };
    }
  }
  return object;
}
