import cookie from "react-cookies";
import { ActionSuccess } from "./httpResponseErrorHandler";
import config from "../config/config";
import { endpoint } from "./restCallUtil";
import moment from "moment";
import { isPageAccessible } from "./pageAccessUtil";

const DOMAIN = config.DOMAIN;
const IS_HTTPS = config.IS_HTTPS;

export function getProjecturlDomain() {
  let projecturlDomain = "";
  if (config.NAMESPACE_PREFIX && config.NAMESPACE_PREFIX !== "")
    projecturlDomain = "." + config.NAMESPACE_PREFIX;

  if (config.NAMESPACE_BASE_URL && config.NAMESPACE_BASE_URL !== "")
    projecturlDomain += "." + config.NAMESPACE_BASE_URL;

  return projecturlDomain;
}

export function getCurrentOrganization(user) {
  const cookieOrganization = getOrganizationFromCookie(user);
  if (cookieOrganization) {
    return cookieOrganization.organization;
  }

  if (user.organizations.length === 0) {
    return null;
  }

  const firstOrganization = user.organizations[0].organization;
  saveCookie("org", firstOrganization._id);
  return firstOrganization;
}

export function getCurrentOrganizationObject(user) {
  const cookieOrganization = getOrganizationFromCookie(user);
  if (cookieOrganization) {
    return cookieOrganization;
  }

  if (user.organizations.length === 0) {
    return null;
  }

  const firstOrganization = user.organizations[0].organization;
  saveCookie("org", firstOrganization._id);
  return user.organizations[0];
}

export function isOneChatbotIncluded(user) {
  let included = false;
  const organization = getCurrentOrganization(user);
  if (organization) {
    const config = organization?.config;
    included = config?.inappChat && config?.onechatbotIncluded;
  }
  return included;
}

export function isWebchatWidgetIncluded(user) {
  let included = false;
  const organization = getCurrentOrganization(user);
  if (organization) {
    const config = organization?.config;
    included = !config?.inappChat && config?.oneWebchatWidgetIncluded;
  }
  return included;
}

export function getRoleInCurrentOrganization(user) {
  const cookieOrganization = getOrganizationFromCookie(user);
  if (cookieOrganization) {
    return cookieOrganization.role;
  }

  if (user.organizations.length === 0) {
    return null;
  }

  return (user.organizations && user.organizations[0].role) || null;
}

export function getRoleRefInCurrentOrganization(user) {
  if (!user) {
    return null;
  }

  const cookieOrganization = getOrganizationFromCookie(user);
  if (cookieOrganization) {
    return cookieOrganization.roleRef;
  }

  if (user.organizations && user.organizations.length === 0) {
    return null;
  }

  return (user.organizations && user.organizations[0].roleRef) || null;
}

function filterOrganization(user, organizationId) {
  return user.organizations.filter(
    (item) => item.organization._id === organizationId
  );
}

function getOrganizationFromCookie(user) {
  const currentOrganizationId = loadCookie("org");
  if (currentOrganizationId) {
    const result = filterOrganization(user, currentOrganizationId);
    if (result && result[0]) {
      return result[0];
    }
  }
  return null;
}

export function processApiResult(resultObj) {
  const result = {};
  result.loading = false;
  if (resultObj instanceof ActionSuccess) {
    result.data = resultObj.data;
    result.success = true;
  } else {
    result.success = false;
    result.message = resultObj.message;
  }
  return result;
}

export function getUniquePlayerId() {
  var nav = window.navigator;
  var screen = window.screen;
  var guid = nav.mimeTypes.length;
  guid += nav.userAgent.replace(/\D+/g, "");
  guid += nav.plugins.length;
  guid += screen.height || "";
  guid += screen.width || "";
  guid += screen.pixelDepth || "";

  return guid;
}

const referrerMap = {
  free: "/free/project/create",
  hosted: "/deployment/select",
  onpremise: "/paid/packages",
  buynow: "/paid/packages",
};
// This implementation is to handle multiple flows to signup/login
// page and based on the flow it should decide the next page after
// signup or login is successful. Here referrer is the the page from
// which signup/login page is coming. and context page is the next page
// after signup/login page. The default page is "home" page.
export function getContextPageFromReferrer(referrer) {
  return (referrer && referrerMap[referrer]) || "/myprojects";
}

export function getProjectDeploymentType(project) {
  if (project.config.deployment && project.config.deployment.type) {
    return project.config.deployment.type;
  } else {
    return null;
  }
}

export function getProjectDeploymentTypeUIString(project) {
  if (project.config.deployment && project.config.deployment.type) {
    const deploymentType = project.config.deployment.type;
    if (
      deploymentType === "hostedshared" ||
      deploymentType === "hosteddedicated"
    ) {
      return "PREMIUM - CLOUD HOSTED";
    } else if (deploymentType === "onpremise") {
      return "PREMIUM - ON PREMISE";
    } else {
      return "PREMIUM";
    }
  } else {
    return null;
  }
}

export function createBasicAuth(username, password) {
  const combination = username + ":" + password;
  return "Basic " + btoa(combination);
}

export function getFeatureConfigObject(project, feature) {
  return project.config.features[feature];
}

export function checkForFreeProject(projects) {
  return (
    projects.length !== 0 &&
    projects.filter((p) => isFreeProject(p)).length > 10
  );
}

export function isFreeProject(project) {
  return (
    project.projectType === "free" ||
    project.projectType === "freesampleapp" ||
    project.projectType === "freesdk"
  );
}

export function isSampleAppProject(project) {
  return (
    project.projectType === "freesampleapp" ||
    project.projectType === "premiumwhitelabelapp"
  );
}

export function saveCookie(name, value, exdays = 0) {
  const cookieOptions = {
    path: "/",
    domain: DOMAIN,
  };
  if (IS_HTTPS) {
    cookieOptions["secure"] = true;
  }

  if (exdays > 0) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    cookieOptions["expires"] = d;
  }

  if (config.NAMESPACE_PREFIX && config.NAMESPACE_PREFIX !== "") {
    name = name + "-" + config.NAMESPACE_PREFIX;
  }
  cookie.save(name, value, cookieOptions);
}

export function loadCookie(name) {
  if (config.NAMESPACE_PREFIX && config.NAMESPACE_PREFIX !== "") {
    name = name + "-" + config.NAMESPACE_PREFIX;
  }
  return cookie.load(name);
}

export function removeCookie(name) {
  const cookieOptions = {
    path: "/",
    domain: DOMAIN,
  };

  if (config.NAMESPACE_PREFIX && config.NAMESPACE_PREFIX !== "") {
    name = name + "-" + config.NAMESPACE_PREFIX;
  }
  cookie.remove(name, cookieOptions);
}

export function getAuthorizedImage(imageUrl) {
  const token = loadCookie("token");
  return endpoint(imageUrl) + "?token=" + token;
}

///marketplace?key=DIRECT_MESSAGING&pid=5ea06e8134e76126a1cac078
export function getMarketplaceCategoryUrl(categoryCode, projectId) {
  return `/features?key=${categoryCode}&pid=${projectId}`;
}

export function getMarketplaceProductUrl(
  productSharedSku,
  projectId,
  projectType
) {
  let productSku = "HOSTEDDED_" + productSharedSku;
  if (projectType === "onpremise") {
    productSku = "ONPREMISE_" + productSharedSku;
  }
  return `/product?key=${productSku}&pid=${projectId}`;
}

export function getMarketplaceProjectUrl(projectId) {
  return `/features?pid=${projectId}`;
}

export function getProjectFromId(projects, projectId) {
  projects.map((proj) => {
    if (proj.projectId === projectId) return proj;
  });
  return {};
}

const PACKAGE_SKUS = {
  HOSTEDSHARED_CHAT_BASIC_SDK_FREE: "HOSTEDSHARED_CHAT_BASIC_SDK_FREE",
  HOSTEDDED_CHAT_BASIC_SDK: "HOSTEDDED_CHAT_BASIC_SDK",
  HOSTEDSHARED_CHAT_BASIC_SDK: "HOSTEDSHARED_CHAT_BASIC_SDK",
  HOSTEDDED_CHAT_ADVANCED_SDK: "HOSTEDDED_CHAT_ADVANCED_SDK",
  HOSTEDSHARED_CHAT_ADVANCED_SDK: "HOSTEDSHARED_CHAT_ADVANCED_SDK",
};

const UPGRADE_TO_DEDICATED_STATUS = {
  REQUESTED: "requested",
  INPROGRESS: "inprogress",
  COMPLETED: "completed",
};

Object.freeze(PACKAGE_SKUS);

export function getPriceDisplayValue(value) {
  return `$ ${value}`;
}

export function shouldShowUpgradeButton(project, user) {
  let showUpgradePlanBtn = false;
  const productSku = project && project.productSku;
  const upgradeStatus = project && project.upgrade && project.upgrade.status;

  if (
    productSku === PACKAGE_SKUS.HOSTEDSHARED_CHAT_BASIC_SDK_FREE ||
    productSku === PACKAGE_SKUS.HOSTEDDED_CHAT_BASIC_SDK ||
    productSku === PACKAGE_SKUS.HOSTEDSHARED_CHAT_BASIC_SDK ||
    productSku === PACKAGE_SKUS.HOSTEDSHARED_CHAT_ADVANCED_SDK
  ) {
    if (upgradeStatus) {
      if (upgradeStatus === UPGRADE_TO_DEDICATED_STATUS.COMPLETED)
        showUpgradePlanBtn = true;
    } else {
      showUpgradePlanBtn = true;
    }
  }

  if (showUpgradePlanBtn) {
    showUpgradePlanBtn = isPageAccessible(
      "/upgradeproject",
      user,
      project.projectId
    );
  }

  return showUpgradePlanBtn;
}

export function getUsersDisplayValue(value) {
  if (value % 1000 === 0) {
    const newValue = `${value / 1000}K`;
    return newValue; //newValue === 10 ? `${value / 1000}K` : `+${value / 1000}K`;
  } else {
    return `${value}`;
  }
}

export { PACKAGE_SKUS, UPGRADE_TO_DEDICATED_STATUS };

/*export function validateProjectUrl(projectUrl) {
    if (!projectUrl) {
        return "Please Enter Namespace !";
    }
    if (projectUrl.length <= 2) {
        return "Must be at least 3 characters long.";
    }
    if (projectUrl.length > 30) {
        return "Max characters allowed is 30";
    }

    // Commenting below regex as look behing is not working in Iphone browsers.
    //const regex = /^(?!-)[a-zA-Z0-9-]+(?<!-)$/;
    const regex = /^[a-z0-9]{1}[a-z0-9\-]+[a-z0-9]{1}$/;
    const result = projectUrl.match(regex);
    if (result === null) {
        return "Must be lowercase letters,numbers or '-'(middle only) only";
    }
    return null;
}*/

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // Out or retries
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export function getImageUrl(path) {
  return `https://${config.CHATSERVER_HOST}/${config.API_VERSION}/${path}`;
}

export function convertEpochToDate(epochTime, format = "MM/DD/YY") {
  if (!epochTime) return "NA";
  return moment(new Date(epochTime / 1000)).format(format);
}

export function getFormattedTime(time, format, isEpoch = true) {
  time = time < 0 ? 0 : time;
  if (isEpoch) return moment.unix(time).format(format) || "NA";
  else return moment(time).format(format) || "NA";
}

export function capitalizeFirstLetter(string) {
  if (!string || string.length < 1) return string;
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isExternalUrl(url) {
  return (
    url?.substr(0, 7) === "http://" ||
    url?.substr(0, 8) === "https://" ||
    url?.substr(0, 3) === "www"
  );
}

export function useIdentityProvider(project) {
  const useInAppServer = project.config && project.config.useInAppUserServer;
  const useIdentityProvider = config.USE_IDENTITY_PROVIDER;
  return !useInAppServer && useIdentityProvider;
}

export function getAuth0RedirectUrl() {
  const redirectUrl = `${config.PROTOCOL}//${config.DOMAIN}/${config.AUTH0_REDIRECT_PAGE}`;
  return redirectUrl;
}

export function getAuth0LoginUrl() {
  const redirectUrl = getAuth0RedirectUrl();
  const authLoginUrl = `${config.AUTH0_HOST}/authorize?response_type=code&client_id=${config.AUTH0_CLIENT_ID}&connection=google-oauth2&redirect_uri=${redirectUrl}&scope=openid email profile offline_access`;
  return authLoginUrl;
}

export function getAuth0RedirectToUrl(type) {
  let domain = config.DOMAIN;
  let protocol = config.PROTOCOL;
  if (domain === "localhost") {
    domain = `${config.DOMAIN}:3000`;
    protocol = "http:";
  }

  const host = `${protocol}//${domain}`;
  switch (type) {
    case "login":
    case "signup":
      return `${host}/authenticate`;
    case "logout":
      return `${host}/login`;
  }
  return "";
}

export function getAuth0Url(type) {
  const host = config.AUTH0_HOST;
  const client = config.AUTH0_CLIENT_ID;
  const redirectUrl = getAuth0RedirectToUrl(type);
  switch (type) {
    case "login":
      return `${host}/authorize?client_id=${client}&redirect_uri=${redirectUrl}&scope=openid email profile offline_access&response_type=code`;
    case "signup":
      return `${host}/authorize?client_id=${client}&redirect_uri=${redirectUrl}&scope=openid email profile offline_access&response_type=code&screen_hint=signup`;
    case "logout":
      return `${host}/v2/logout?client_id=${client}&returnTo=${redirectUrl}`;
  }
}

export function numberAbbreviator(num, digits) {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i -= 1) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function generateWidgetScript(widgetUrl) {
  return `
    <!-- 1Webchat -->
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.js"></script>
    <script src="${widgetUrl}"></script>
    <script src="${config.INAPPCHAT_ONECHATBOT_S3_URL}"></script>
  `;
}

export function splitFullName(fullName) {
  if (!fullName) {
    return null;
  }

  return fullName.split(" ");
}

export function getFullName(firstName, lastName) {
  return [firstName, lastName].filter((item) => !!item).join(" ");
}

export function getDifferenceInDays(start, end) {
  const diffInMs = Math.abs(end - start);
  return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
}

export function getFreeTrialStatus(user) {
  const organization = getCurrentOrganization(user);

  if (organization) {
    let status = {};
    const orgConfig = organization.config;

    if (orgConfig) {
      const { subscriptionPlanId, contractPeriod } = orgConfig;
      const { start, end } = contractPeriod || {};
      if (subscriptionPlanId === "1Webchate-free-trial") {
        if (start && end) {
          const currentDate = new Date().getTime();

          if (start <= currentDate && currentDate <= end) {
            status = {
              isFree: true,
              isExpired: false,
              leftDays: getDifferenceInDays(new Date(end), new Date()),
            };
          } else {
            status = {
              isFree: true,
              isExpired: true,
              leftDays: 0,
            };
          }
        } else {
          status = {
            isFree: true,
            isExpired: true,
            leftDays: 0,
          };
        }
      } else {
        status = {
          isFree: false,
        };
      }
    }

    return status;
  }

  return null;
}
