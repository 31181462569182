import React, { Component } from "react";
import aiassitantImg from "../../../../public/resources/site/images/ai-assistant.svg";

class WatsonConfigTitleBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tutorials-img-block pb-0">
        <div className="container">
          <div className="tutorials-content-block">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="title-content mb-0">
                  <h1 className="font-lg">Watson Configuration Guide</h1>
                  <div className="info-content">
                    Be sure to bookmark this page for reference when setting up
                    your Watson Assistant bot. <br />
                    <br />
                    Note: If your Watson chatbot does not conduct slot filling
                    or have any conversation steps beyond just a simple
                    question/answer (see Scenario #1 below) then skip this
                    guide.
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="image-block text-md-right">
                  <img src={aiassitantImg} alt="NLU Connecting Guide" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WatsonConfigTitleBlock;
