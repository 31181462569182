import config from "../../../config/config";
import {
  isPageAccessible,
  isProjectPageAccessible
} from "../../../utils/pageAccessUtil";

export const InAppChatDashboardMenus = [
  {
    name: "OVERVIEW",
    items: [
      {
        id: "dashboard",
        name: "Dashboard",
        iconClass: "i-icon icon-dashboard",
        containerClass: "",
        urlSupplier: projectId => `/dashboard/${projectId}`,
        urlPrefix: "/dashboard",
        showMenuOptionFn: (project, user) =>
          isProjectPageAccessible("/dashboard", user, project)
      }
      // {
      //   id: "analytics",
      //   name: "Analytics",
      //   iconClass: "i-icon icon-analytics",
      //   containerClass: "",
      //   urlSupplier: projectId => `/analytics/${projectId}`,
      //   urlPrefix: "/analytics",
      //   showMenuOptionFn: (project, user) =>
      //     config.ANALYTICS_FEATURE &&
      //     isProjectPageAccessible("/analytics", user, project)
      // }
    ]
  },
  {
    name: "CHAT & USERS",
    items: [
      {
        id: "messages",
        name: "Messages",
        iconClass: "i-icon icon-messages",
        containerClass: "",
        urlPrefix: ["/exportMessage", "/exportMessagePermissions"],
        urlSupplier: projectId => `/exportMessage/${projectId}`,
        showMenuOptionFn: (project, user) =>
          config.DATAEXPORT_FEATURE &&
          isProjectPageAccessible("/exportmessage", user, project)
      },
      {
        id: "users",
        name: "Users",
        iconClass: "i-icon icon-users",
        containerClass: "",
        urlSupplier: projectId => `/chatUsers/${projectId}`,
        urlPrefix: "/users",
        showMenuOptionFn: (project, user) =>
          isProjectPageAccessible("/chatusers", user, project)
      },
      {
        id: "moderation",
        name: "Moderation",
        iconClass: "i-icon icon-moderation",
        containerClass: "",
        urlPrefix: "/moderation",
        urlSupplier: projectId => `/moderation/${projectId}`,
        showMenuOptionFn: (project, user) =>
          config.MODERATION_FEATURE &&
          isProjectPageAccessible("/moderation", user, project)
      },
      {
        id: "chatbots",
        name: "Bots",
        iconClass: "i-icon icon-bots",
        containerClass: "",
        urlPrefix: "/bots",
        urlSupplier: projectId => `/bots/${projectId}`,
        showMenuOptionFn: (project, user) =>
          isProjectPageAccessible("/bots", user, project)
      }
    ]
  },
  /*{
      name: "STRUCTURE & SAFETY",
      items: DashboardSideBar.securitySafetyOptionsConfig
    }, inappdisable */
  // {
  //   name: "BILLING & DATA",
  //   items: DashboardSideBar.billingOptionsConfig
  // },
  {
    name: "DOCUMENTS",
    items: [
      {
        id: "docs",
        name: "Documents",
        iconClass: "i-icon icon-documents",
        containerClass: "",
        urlSupplier: () => `/docs`,
        urlPrefix: "/docs",
        showMenuOptionFn: () => true
      }
    ]
  },
  {
    name: "SETTINGS & SUPPORT",
    items: [
      {
        id: "settings",
        name: "Settings",
        iconClass: "i-icon icon-settings",
        containerClass: "",
        urlSupplier: projectId => `/settings/${projectId}`,
        urlPrefix: "/settings",
        showMenuOptionFn: (project, user) =>
          isProjectPageAccessible("/settings", user, project)
      },
      {
        id: "support",
        name: "Support",
        iconClass: "i-icon icon-support",
        containerClass: "",
        urlSupplier: () => `/contact-support`,
        urlPrefix: "/contact-support",
        showMenuOptionFn: () => true
      }
    ]
  }
];

export const WidgetDashboardMenus = [
  {
    name: "OVERVIEW",
    items: [
      {
        id: "dashboard",
        name: "Dashboard",
        iconClass: "i-icon icon-dashboard",
        containerClass: "",
        urlSupplier: projectId => `/dashboard/${projectId}`,
        urlPrefix: "/dashboard",
        showMenuOptionFn: (project, user) =>
          isProjectPageAccessible("/dashboard", user, project)
      }
    ]
  },
  {
    name: "OVERVIEW",
    items: [
      {
        id: "oneWebchat",
        name: "1Webchat",
        iconClass: "i-icon icon-add-round",
        containerClass: "",
        urlPrefix: "/bots",
        urlSupplier: projectId => `/bots/${projectId}`,
        showMenuOptionFn: (project, user) =>
          isProjectPageAccessible("/bots", user, project)
      }
    ]
  },
  {
    name: "CRM TOOLS",
    items: [
      {
        id: "live-chat",
        name: "Live Chat",
        iconClass: "i-icon icon-livechat",
        containerClass: "",
        urlSupplier: () => ``,
        urlPrefix: "",
        showMenuOptionFn: () => true,
        disabled: true
      },
      {
        id: "contacts",
        name: "Contacts",
        iconClass: "i-icon icon-users",
        containerClass: "",
        urlSupplier: () => ``,
        urlPrefix: "",
        showMenuOptionFn: () => true,
        disabled: true
      }
    ]
  },
  {
    name: "DOCUMENTS",
    items: [
      {
        id: "docs",
        name: "Documents",
        iconClass: "i-icon icon-documents",
        containerClass: "",
        urlSupplier: () => `/docs`,
        urlPrefix: "/docs",
        showMenuOptionFn: () => true
      }
    ]
  },
  {
    name: "SETTINGS & SUPPORT",
    items: [
      {
        id: "settings",
        name: "Settings",
        iconClass: "i-icon icon-settings",
        containerClass: "",
        urlSupplier: projectId => `/settings/${projectId}`,
        urlPrefix: "/settings",
        showMenuOptionFn: (project, user) =>
          isProjectPageAccessible("/settings", user, project)
      },
      {
        id: "support",
        name: "Support",
        iconClass: "i-icon icon-support",
        containerClass: "",
        urlSupplier: () => `/contact-support`,
        urlPrefix: "/contact-support",
        showMenuOptionFn: () => true
      }
    ]
  }
];

export const OrganizationMenus = [
  {
    id: "general",
    name: "General",
    iconClass: "i-icon icon-general",
    containerClass: "",
    urlSupplier: () => `/organizations/general`,
    urlPrefix: "/general",
    showMenuOptionFn: (project, user) =>
      isPageAccessible(
        "/organizations/general",
        user,
        project && project.projectId
      )
  },
  {
    id: "members",
    name: "Members",
    iconClass: "i-icon icon-member",
    containerClass: "",
    urlSupplier: () => `/organizations/members`,
    urlPrefix: "/members",
    showMenuOptionFn: (project, user) =>
      isPageAccessible(
        "/organizations/members",
        user,
        project && project.projectId
      )
  },
  {
    id: "roles",
    name: "Roles",
    iconClass: "i-icon icon-role",
    containerClass: "",
    urlSupplier: () => `/organizations/roles`,
    urlPrefix: ["/createrole", "/editrole", "/viewrole"],
    showMenuOptionFn: (project, user) =>
      isPageAccessible(
        "/organizations/roles",
        user,
        project && project.projectId
      )
  }
];

// static securitySafetyOptionsConfig = [
//   {
//     id: "infrastructure",
//     name: "Infrastructure",
//     iconClass: "i-icon icon-infrastructure",
//     containerClass: "",
//     urlSupplier: projectId => `/infrastructure/${projectId}`,
//     urlPrefix: "/infrastructure",
//     showMenuOptionFn: (project, user) =>
//       isProjectPageAccessible("/infrastructure", user, project)
//   },
//   {
//     id: "security",
//     name: "Security",
//     iconClass: "i-icon icon-security",
//     containerClass: "",
//     urlSupplier: projectId => `/security/harbor/${projectId}`,
//     urlPrefix: "/security",
//     showMenuOptionFn: (project, user) =>
//       isProjectPageAccessible("/security", user, project)
//   },
//   {
//     id: "compliance",
//     name: "Compliance",
//     iconClass: "i-icon icon-compliance",
//     containerClass: "",
//     urlSupplier: projectId => `/qualys/login/${projectId}`,
//     urlPrefix: ["/qualys", "compliance"],
//     showMenuOptionFn: (project, user) =>
//       isProjectPageAccessible("/qualys", user, project)
//   }
// ];

// static billingOptionsConfig = [
//   {
//     id: "usage",
//     name: "Usage",
//     iconClass: "i-icon icon-usage",
//     containerClass: "",
//     urlSupplier: projectId => `/usage/${projectId}`,
//     urlPrefix: "/usage",
//     showMenuOptionFn: (project, user) =>
//       isProjectPageAccessible("/usage", user, project)
//   },
//   {
//     id: "billing",
//     name: "Billing",
//     iconClass: "i-icon icon-billing",
//     containerClass: "",
//     urlSupplier: projectId => `/billing/${projectId}`,
//     urlPrefix: "/billing",
//     showMenuOptionFn: (project, user) =>
//       isProjectPageAccessible("/billing", user, project)
//   }
// ];

// static headerMenuOptionConfig = [
//   {
//     id: "myprojects",
//     name: "My Projects",
//     iconClass: "ri-microsoft-fill",
//     containerClass: "d-md-none",
//     urlSupplier: () => "/myprojects",
//     showMenuOptionFn: () => true
//   },
//   {
//     id: "support",
//     name: "Support",
//     iconClass: "ri-question-fill",
//     containerClass: "d-md-none",
//     urlSupplier: () => config.SUPPORT_URL,
//     showMenuOptionFn: () => true
//   },
//   {
//     id: "docs",
//     name: "Docs",
//     iconClass: "ri-book-2-line",
//     containerClass: "d-md-none",
//     urlSupplier: () => "/documentation",
//     showMenuOptionFn: () => true
//   }
// ];
