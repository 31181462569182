import {
  API_CALL_FINISH,
  API_CALL_START,
  HIDE_TOAST,
  SHOW_TOAST,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION
} from "../actions/ui";

const INITIAL_STATE = {
  toast: { visible: false },
  apiCallStates: {},
  notification: {
    show: false,
    type: "info",
    message: "",
    description: ""
  }
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SHOW_TOAST:
      return { ...state, toast: action.toast };
    case HIDE_TOAST:
      return { ...state, toast: { visible: false } };
    case SHOW_NOTIFICATION:
      return { ...state, notification: { show: true, ...action.data } };
    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: { show: false, message: "", description: "" }
      };
    case API_CALL_START:
      return {
        ...state,
        apiCallStates: newApiCallState(
          state.apiCallStates,
          action.apiCallKey,
          true
        )
      };
    case API_CALL_FINISH:
      return {
        ...state,
        apiCallStates: newApiCallState(
          state.apiCallStates,
          action.apiCallKey,
          false
        )
      };
    default:
      return state;
  }
}

function newApiCallState(currentState, apiCallKey, isLoading) {
  return { ...currentState, [apiCallKey]: { isLoading } };
}
