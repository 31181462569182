import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import ThanksDeskImage from "../../../public/resources/site/images/thank-you-desktop.jpg";
import ThanksMobileImage from "../../../public/resources/site/images/thank-you-mobile.jpg";
import Footer_v2 from "../common/footer_v2";

class ContactSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <main className="content-wrapper main-content thankyou-content">
          <div className="container">
            <div className="thankyou-content-block">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-5">
                  <div className="img-block">
                    <img
                      src={ThanksDeskImage}
                      className="img-fluid d-none d-lg-block"
                      alt="Thank you"
                    />
                    <img
                      src={ThanksMobileImage}
                      className="img-fluid d-block d-lg-none"
                      alt="Thank you"
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                  <div className="desc">
                    <h1 className="h3">Thank you for contacting us.</h1>
                    <p>One of our representatives will get back to you soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="action">
              <Link to="/home" className="btn btn-primary">
                Back to Home
              </Link>
            </div>
          </div>
        </main>
        <Footer_v2 />
      </>
    );
  }
}

export default ContactSuccess;
