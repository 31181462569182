import React, { Component } from "react";
import PropTypes from "prop-types";

const UserContext = React.createContext();

class UserContextProvider extends Component {
  render() {
    const { user, children } = this.props;

    return (
      <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
    );
  }
}

UserContextProvider.propTypes = {
  user: PropTypes.object
};

export { UserContext };
export default UserContextProvider;
