import React, { Component } from "react";
import SiteHeader from "../../../common/siteHeader";
import ChatbotsDocSidebar from "./chatbotsDocSidebar";
import ChatbotsCategoryNavBar from "./chatbotsCategoryNavBar";
import OneChatbotTitleBlock from "./oneChatbotTitleBlock";

class Docs1ChatbotSessionTimeout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <ChatbotsCategoryNavBar />
        <main className="content-wrapper main-content">
          <OneChatbotTitleBlock />
          <div className="content-left-block connecting-grid-block --scrollpage">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xl-3 tab-inner-content">
                  <ChatbotsDocSidebar />
                </div>
                <div className="col-lg-8 col-xl-9">
                  <div className="tab-content tutorials-details-block">
                    <div className="tab-pane fade show active" id="onechatbot">
                      <h2>
                        <strong>Guides</strong>
                      </h2>
                      <h3>Session Timeout</h3>
                      <h3 id="naming-conventions" className="top-gap">
                        Currently it is only possible to utilize 1Chatbot with
                        Dialogflow, Lex, and Watson with their most common,
                        default session state lengths:
                      </h3>
                      <table className="table-border">
                        <tr>
                          <td>Dialogflow ES</td>
                          <td>20 Minutes</td>
                        </tr>
                        <tr>
                          <td>Lex</td>
                          <td>5 Minutes</td>
                        </tr>
                        <tr>
                          <td>Watson Assistant</td>
                          <td>5 Minutes</td>
                        </tr>
                      </table>
                      <p>
                        A “session” represents a conversation between 1Chatbot
                        and an end-user. During a session certain variables may
                        be saved in a buffer such as the end-user’s name. This
                        helps with the ease of conversation flow as information
                        is “remembered” temporarily and the user does not have
                        to repeat themselves.
                      </p>
                      <p className="mb-3 mb-lg-4">
                        Different NLU providers such as Dialogflow, Lex, and
                        Watson have different parameters for how long sessions
                        last (and how long certain variables are saved).
                      </p>
                      <ul className="dot-list mb-3">
                        <li>
                          <ul>
                            <li className="mb-3">
                              By default, <strong>Dialogflow ES</strong> has a
                              session length of 20-minutes.
                            </li>
                            <li className="mb-3">
                              By default, <strong>Lex</strong> has a design
                              length of 5-minutes (although you can specify an
                              amount up to 24-hours during bot creation).
                            </li>
                            <li>
                              By default, <strong>Watson</strong> has a session
                              length of 5-minutes for Lite plans, with potential
                              to extend session length to 24-hours or 7-days for
                              Plus and Enterprise plans, respectively.
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <p className="mb-3 mb-lg-4">
                        1Chatbot will supersede the settings for session length
                        and adopt the parameters in Table 1 above.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Docs1ChatbotSessionTimeout;
