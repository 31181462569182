import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Form, Field, reduxForm } from "redux-form";
import SubmitButton from "../../common/submitButton";
import { addProject, validateProject } from "../../../actions/projects";
import { addBuyNowCart, createOrder } from "../../../actions/order";
import {
  getCurrentOrganization,
  getProjecturlDomain
} from "../../../utils/commonUtil";
import {
  validateProjectName,
  validateProjectUrl
} from "../../../utils/validationUtil";
import history from "../../../history";
import { ActionError } from "../../../utils/httpResponseErrorHandler";
import LoadingDisplay from "../../common/loadingDisplay";
import InputText_v2 from "../../common/inputText_v2";
import { isPageAccessible } from "../../../utils/pageAccessUtil";
import sideImage from "../../../public/resources/portal/images/first-project.svg";
import { showErrorToast } from "../../../actions/ui";
import ErrorCodes from "../../../utils/errorCodes";

class CreateProjectContent_v4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectType: "freesdk"
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.isFreeProject = this.isFreeProject.bind(this);
  }

  componentDidMount() {}

  UNSAFE_componentWillMount() {
    const { projectName, projectUrl, projectType } = this.props.location;
    if (projectName && projectUrl) {
      this.props.initialize({
        projectName: projectName,
        projectUrl: projectUrl
      });
      this.setState({ projectType: projectType });
    } else {
      const defaultProjType = this.isFreeProject() ? "freesdk" : "premium";
      this.setState({ projectType: defaultProjType });
    }
  }

  isFreeProject() {
    const { projectType } = this.props;

    return ["free", "freesampleapp", "freesdk"].includes(projectType);
  }

  render() {
    const { isProjectLoaded, handleSubmit } = this.props;

    if (!isProjectLoaded) {
      return <LoadingDisplay />;
    }

    return (
      <div className="content create-project-block">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="image-block text-center">
              <img src={sideImage} alt="Create new project" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="project-card-content">
              <div className="title">
                <h1>Create new project</h1>
                <div className="info-content">
                  Choose a name for your project, as well as a namespace for its
                  URL
                </div>
              </div>
              <Form
                id="login-form"
                onSubmit={handleSubmit(this.handleFormSubmit)}
              >
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      containerClass="form-group"
                      placeholder="e.g. Transport Layers"
                      label="Project Name"
                      labelClass="font-16"
                      name="projectName"
                      id="projectName"
                      type="text"
                      inputTextClass="form-control custom-input"
                      component={InputText_v2}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      containerClass="form-group"
                      placeholder="e.g. transportlayers"
                      label="Project Namespace"
                      labelClass="font-16"
                      name="projectUrl"
                      id="projectUrl"
                      type="text"
                      inputTextClass="form-control custom-input"
                      component={InputText_v2}
                    >
                      <strong className="text-right d-block">
                        {getProjecturlDomain()}
                      </strong>
                    </Field>
                  </div>
                </div>
                <ul className="dots-list-black">
                  <li>Project name must be at least 3 characters long</li>
                  <li>
                    Project namespace must be at least 3 characters, and may
                    only contain lowercase letters, numbers, or hypens.
                  </li>
                </ul>
                <SubmitButton
                  isPrimary={false}
                  containerClass="action-btn"
                  buttonClass="btn-orange-gradient with-bg-icon"
                  isSubmitting={this.state.isSubmitting}
                >
                  Create Project
                </SubmitButton>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleFormSubmit(values) {
    const canCreateProject = isPageAccessible(
      "/createproject",
      this.props.user,
      ""
    );
    if (!canCreateProject) {
      this.setState({
        resultObj: new ActionError(
          -1,
          "You are not authorized to create project"
        )
      });
      return;
    }

    this.setState({ isSubmitting: true });
    const organization = getCurrentOrganization(this.props.user);
    const nameSpace = values.projectUrl;
    let sdkType = "premiumwhitelabelapp";
    const packageType =
      this.props.projectType !== "premium" ? "essential" : "inclusive";

    if (this.props.location.search) {
      const params = new URLSearchParams(this.props.location.search);
      const type = params.get("type");

      if (type === "1widget") {
        sdkType = type;
      }
    }

    const params = {
      name: values.projectName,
      nameSpace: nameSpace,
      projectType: sdkType,
      organization: organization._id,
      packageType: packageType,
      useInAppUserServer: true
    };

    this.props
      .addProject(params)
      .then(() => {
        // Go to myProjects Page
        history.push("/myProjects");
      })
      .catch(error => {
        console.log(error);
        if (
          error.actionDataObj.serverErrorCode === ErrorCodes.PROJECT_LIMIT_ERROR
        ) {
          this.props.showErrorToast(
            "Project creation limit exceeded. Please upgrade your plan."
          );
        } else {
          this.props.showErrorToast(error.actionDataObj.message);
        }
        this.setState({
          resultObj: error.actionDataObj,
          isSubmitting: false
        });
      });
  }
}

const validate = values => {
  const errors = {};

  const projectNameResult = validateProjectName(values.projectName);
  if (projectNameResult) errors.projectName = projectNameResult;

  const projectUrlResult = validateProjectUrl(values.projectUrl);
  if (projectUrlResult) errors.projectUrl = projectUrlResult;

  return errors;
};

function mapStatsToProps(stats) {
  return {
    user: stats.user.user,
    projects: stats.project.projects,
    isProjectLoaded: stats.project.isProjectLoaded
  };
}

const reduxFormComp = reduxForm({
  form: "addProjectForm",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
});

export default reduxFormComp(
  connect(mapStatsToProps, {
    addProject,
    validateProject,
    addBuyNowCart,
    createOrder,
    showErrorToast
  })(withRouter(CreateProjectContent_v4))
);
