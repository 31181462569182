import React from "react";

const AlertMessage = ({
  message,
  alertType,
  showIcon = true,
  children,
  customClass,
  customContainerStyle,
  customIconStyle,
  topMargin,
  type = "large"
}) => {
  let className = "alert-success";
  let iconClass = "fad fa-shield-check";

  if (alertType === "error") {
    className = "alert-danger";
    iconClass = "fad fa-exclamation-triangle";
  } else if (alertType === "warning") {
    className = "alert-warning";
    iconClass = "fad fa-exclamation-triangle";
  }

  let containerStyle = {};
  if (customContainerStyle) {
    containerStyle = { ...containerStyle, ...customContainerStyle };
  }
  if (topMargin) {
    containerStyle.marginTop = topMargin;
  }

  let iconStyle; //= {marginLeft : '8px'};
  if (customIconStyle) {
    iconStyle = { ...iconStyle, ...customIconStyle };
  }

  if (type === "small") {
    containerStyle = { ...containerStyle, padding: "8px" };
  }

  return (
    <div
      className={`${customClass || ""} alert  ${className}`}
      style={containerStyle}
    >
      {showIcon && (
        <i className={iconClass} style={iconStyle} aria-hidden="true" />
      )}
      {message || children}
    </div>
  );
};

export default AlertMessage;
