import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import Footer_v2 from "../common/footer_v2";
import CustomerSupportHeader from "./customerSupportHeader";
import { withRouter } from "react-router-dom";
import history from "../../../history";

class ContactSupportSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let showProjectsButton = false;
    if (this.props.isLoggedIn === true) {
      if (this.props.projects.length >= 0) {
        showProjectsButton = true;
      }
    }
    const destination = showProjectsButton ? "/myprojects" : "/home";
    const buttonName = showProjectsButton ? "All Projects" : "Home";
    return (
      <>
        <CustomerSupportHeader fixedHeaderOnScroll={true} />
        <main className="content-wrapper without-sidebar mt-0">
          <div className="main-content-wrapper support-content-wrapper thankyou-content-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="note text-center mb-80">
                    <h1>
                      <strong>Thank you</strong>
                    </h1>
                    <p className="font-16 mb-2">for the submission.</p>
                    {/*<p className="font-16 mb-2">Your ticket no. for Jira is <span
                        className="text-black">#5176982134</span></p>*/}
                    <p className="font-16 mb-4">
                      We have got your request,
                      <br />
                      we will contact you soon.
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary btn-md pre-icon"
                      onClick={() => history.push(destination)}
                    >
                      <em className="i-icon icon-back-circle mr-2" />
                      {buttonName}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer_v2 />
      </>
    );
  }
}

function mapStatsToProps(stats) {
  return {
    isLoggedIn: stats.user.isLoggedIn,
    projects: stats.project.projects
  };
}

export default connect(mapStatsToProps, {})(withRouter(ContactSupportSuccess));
