import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { saveCookie, loadCookie } from "../../../utils/commonUtil";

const PrivacyPolicyBanner = () => {
  const policyAccepted = loadCookie(`privacy-policy`) === "true" || false;

  useEffect(() => {
    if (policyAccepted) {
      document.body.classList.remove("noPlcyAccept");
    } else {
      document.body.classList.add("noPlcyAccept");
    }
  });

  const cookieAccepted = () => {
    saveCookie("privacy-policy", true, 365);
    if (loadCookie(`privacy-policy`) === "true") {
      const el = document.querySelector(".accept-cookie-block");
      if (el) el.style.display = "none";
      document.body.classList.remove("noPlcyAccept");
    }
  };

  return (
    <Fragment>
      {!policyAccepted && (
        <div className="accept-cookie-block">
          <div className="container">
            <div className="info">
              <div className="description">
                This website uses cookies to provide visitors with the best
                experience. By continuing to use this site you consent to our
                use of cookie as described in our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </div>
              <div className="action">
                <button
                  className="btn btn-primary btn-md"
                  onClick={cookieAccepted}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

PrivacyPolicyBanner.propTypes = {};

export default PrivacyPolicyBanner;
