import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import Footer_v2 from "../common/footer_v2";
import uiKitImage from "../../../public/resources/site/images/uikit-image.svg";
import publishImage from "../../../public/resources/site/icons/bullhorn.svg";
import githubImage from "../../../public/resources/site/icons/github.svg";
import sketchImage from "../../../public/resources/site/icons/sketch.svg";
import demoImage from "../../../public/resources/site/images/demos-image-01.svg";
import DocCategoryNavBar from "./docCategoryNavBar";

class DocsUiKitHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selTab: "ios"
    };
    this.onTabItemClick = this.onTabItemClick.bind(this);
  }

  onTabItemClick(e, item) {
    e.preventDefault();
    this.setState({ selTab: item });
  }

  renderBannerSection() {
    return (
      <div className="tutorials-img-block">
        <div className="container">
          <div className="tutorials-content-block">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="title-content mb-md-0">
                  <h1 className="font-lg">UIKit for Chat</h1>
                  <div className="info-content">
                    <p>
                      Enables an easy and fast integration of standard chat
                      features into new or existing client apps. Overall theme
                      and individual components, such as colors and fonts, can
                      be fully customized.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="image-block text-md-right">
                  <img src={uiKitImage} alt="Ui-kit Docs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChatDocItemSection() {
    const { selTab } = this.state;
    const items = [
      {
        id: "ios",
        title: "iOS",
        icon: "icon-apple"
      },
      {
        id: "android",
        title: "Android",
        icon: "icon-android"
      },
      {
        id: "react",
        title: "React",
        icon: "icon-react"
      }
    ];

    return (
      <div className="content-left-block documentation-tab-block pt-0">
        <div className="container">
          <h3>Documentation</h3>
          <div className="documentation-tab">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                {items.map(item => (
                  <a
                    key={item.id}
                    className={`nav-link ${selTab === item.id ? "active" : ""}`}
                    href={`#${item.id}`}
                    onClick={e => this.onTabItemClick(e, item.id)}
                  >
                    <em className={`i-icon ${item.icon}`} />
                    {item.title}
                  </a>
                ))}
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              {items.map(item => (
                <div
                  key={item.id}
                  className={`tab-pane fade ${
                    selTab === item.id ? "show active" : ""
                  }`}
                >
                  <div className="card-items">
                    <div className="card-item">
                      <div className="inner-content">
                        <div className="card-title">
                          <img src={publishImage} alt="publish" />
                          Publish
                        </div>
                        <div className="info-content">
                          {`Learn to publish UIKit for ${item.title}`}
                        </div>
                        <a href="#" className="view-link-orange">
                          Learn more...
                        </a>
                      </div>
                    </div>
                    <div className="card-item">
                      <div className="inner-content">
                        <div className="card-title">
                          <img src={githubImage} alt="github" />
                          {`${item.title} Sample app`}
                        </div>
                        <div className="info-content">
                          {`${item.title} repository on GitHub`}
                        </div>
                        <a href="#" className="view-link-orange">
                          Visit the link
                        </a>
                      </div>
                    </div>
                    <div className="card-item">
                      <div className="inner-content">
                        <div className="card-title">
                          <img src={sketchImage} alt="sketch" />
                          Sketch Library
                        </div>
                        <div className="info-content">
                          {`Sketch library for ${item.title}`}
                        </div>
                        <a href="#" className="view-link-orange">
                          Download
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDemoSection() {
    return (
      <div className="content-left-block demos-block pt-0">
        <div className="container">
          <h3 className="mb-4">Demos</h3>
          <div className="demo-content-block">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="demo-content-item">
                  <div className="card-image">
                    <img src={demoImage} alt="In-App Chat demo" />
                  </div>
                  <div className="card-content">
                    <h4>In-App Chat</h4>
                    <div className="info-content">
                      <p>
                        Create a full-featured chat experience for your users
                        that's seamless and secure.
                      </p>
                    </div>
                    <div className="text-right">
                      <a
                        href="#"
                        onClick={e => e.preventDefault()}
                        className="view-link-purple"
                        title="Coming Soon"
                      >
                        Coming Soon <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRelatedSection() {
    const relatedItems = [
      {
        title: "Chat SDKs",
        description:
          "Build a messaging experience that provides the latest and most essential features with In-App Chat API.",
        routePath: "/docs/chatsdk"
      },
      {
        title: "Chatbots",
        description:
          "Create a chatbot to automate duties and workflows, or orchestrate multiple chatbots to function together in one app.",
        routePath: "/docs/chatbots"
      }
    ];

    return (
      <div className="content-left-block Related-block pt-0">
        <div className="container">
          <h3 className="mb-4">Related</h3>
          <div className="related-items">
            <div className="row">
              {relatedItems.map(item => (
                <div className="col-md-6" key={item.title}>
                  <Link to={item.routePath} className="related-item">
                    <h4 className="title-text">{item.title}</h4>
                    <div className="info-content">
                      <p>{item.description}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <DocCategoryNavBar />
        <main className="content-wrapper main-content">
          {this.renderBannerSection()}
          {this.renderChatDocItemSection()}
          {/* this.renderDemoSection() */}
          {this.renderRelatedSection()}
        </main>
        <Footer_v2 />
      </>
    );
  }
}

export default DocsUiKitHome;
