import React, { Component } from "react";
import SitePageContainer from "../../common/sitePageContainer";
import SubscribeMailSuccess from "../home/subscribeMailSuccess";
import ContactSuccess from "../contactUs/contactSuccess";
import ContactSupportSuccess from "../contactSupport/contactSupportSuccess";
import ChatbotAutomationSuccess from "../solution/chatbotAutomationSuccess";

class SuccessPage extends Component {
  render() {
    const type = this.props.type;
    return (
      <SitePageContainer pageName={type}>
        {type === "subscribeMail" && <SubscribeMailSuccess />}
        {type === "contactThanks" && <ContactSuccess />}
        {type === "contactSupportThanks" && <ContactSupportSuccess />}
        {type === "chatbotAutomationThanks" && <ChatbotAutomationSuccess />}
      </SitePageContainer>
    );
  }
}

export default SuccessPage;
