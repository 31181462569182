import React from "react";

/** Eventually we will make one single input text component
 * for now making different one, since site has different styling
 */

/**
 * To prefill values in form, use this.props.initialize
 */
class InputText_v2 extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
    this.state = {
      showFocused: false
    };
  }

  onChangeHandler(event) {
    const { input, valueTrim, isFileInput } = this.props;
    let curValue = event.target.value;
    if (!valueTrim || valueTrim === true)
      curValue = event.target.value.trimLeft();

    if (input) {
      const { onChange } = input;
      if (isFileInput) onChange && onChange(event);
      else onChange && onChange(curValue);
    }
  }

  onBlurHandler() {
    this.setState({ showFocused: false });
    /*const {input,valueTrim} = this.props;
        let curValue = event.target.value;
        if(curValue) {
          if (!valueTrim || valueTrim === true)
            curValue = event.target.value.trim();
        }

        if (input) {
            const {onBlur} = input;
            onBlur && onBlur(curValue);
        }
        if (this.state.showFocused && !(curValue)) {
            this.setState({showFocused: false});
        }*/
  }

  onFocusHandler() {
    this.setState({ showFocused: true });
    /*if (!this.state.showFocused) {
            this.setState({showFocused: true});
        }*/
  }

  render() {
    const {
      containerClass,
      name,
      id,
      type,
      inputTextClass,
      placeholder,
      label,
      labelJsx,
      labelClass,
      labelFor,
      labelTextStyle,
      readOnly = false,
      containerStyle,
      errorStyle,
      inputTextStyle,
      meta,
      showValidIcon = true,
      errorPadding = false,
      children,
      textboxInfo,
      input,
      accept,
      disabled
    } = this.props;

    let value = input.value;

    let finalStyle = inputTextStyle;

    let finalErrorStyle = {
      background: "rgba(235, 53, 64, 0.1)",
      color: "red",
      backgroundColor: "white"
    };
    if (errorStyle) {
      finalErrorStyle = { ...finalErrorStyle, ...errorStyle };
    }

    if (readOnly) {
      finalStyle = {
        ...finalStyle,
        /*color: 'grey',*/
        cursor: "no-drop",
        backgroundColor: "transparent"
      };
    }

    let labelJsxContent = null;
    if (typeof labelJsx !== "undefined") {
      labelJsxContent = React.createElement(labelJsx, this.props);
    }

    var contClass = containerClass;
    if (/*value || */ this.state.showFocused) {
      contClass = containerClass + " focused";
      if (type === "file") {
        contClass = containerClass;
      }
    }

    if (value) {
      contClass = contClass + " has-data";
    }

    const hasError = meta && meta.error && meta.touched;
    const isValid = meta && !meta.error && meta.touched;

    const className =
      hasError && !readOnly
        ? `error ${inputTextClass || ""}`
        : `${inputTextClass ? inputTextClass : ""} ${
            showValidIcon && isValid ? "valid" : ""
          }`;

    const inputEle = (
      <>
        <input
          name={name}
          id={id}
          type={type}
          className={className}
          placeholder={placeholder}
          value={value}
          style={finalStyle}
          readOnly={readOnly}
          onChange={this.onChangeHandler}
          onBlur={this.onBlurHandler}
          onFocus={this.onFocusHandler}
          accept={accept || ""}
          disabled={disabled}
        />
        {hasError && !readOnly && (
          <small className="error" style={finalErrorStyle}>
            <div
              ref={el => {
                this.errorEle = el;
              }}
              style={{ paddingLeft: `${errorPadding ? "8px" : "0px"}` }}
            >
              {meta.error}
            </div>
          </small>
        )}
      </>
    );

    return (
      <div className={contClass} style={containerStyle}>
        {label && (
          <label
            className={labelClass}
            htmlFor={labelFor}
            style={labelTextStyle}
          >
            {label}
          </label>
        )}
        {labelJsxContent && (
          <label
            className={labelClass}
            htmlFor={labelFor}
            style={labelTextStyle}
          >
            {labelJsxContent}
          </label>
        )}
        {inputEle}
        {children}
        {textboxInfo && (
          <div className="info pt-3" style={{ fontSize: " 13px" }}>
            {textboxInfo}
          </div>
        )}
      </div>
    );
  }
}

export default InputText_v2;
