import {
  GET_USER_SUCCESS,
  EDIT_USER_SUCCESS,
  USER_LOGIN_SUCCESS,
  USER_NOT_LOGGEDIN
} from "../actions/users";

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
    case GET_USER_SUCCESS:
    case EDIT_USER_SUCCESS:
      return { ...state, user: action.user, isLoggedIn: true };
    case USER_NOT_LOGGEDIN:
      return { isLoggedIn: false };
    default:
      return state;
  }
}
