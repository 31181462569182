import React, { Component } from "react";
import SiteHeader from "../../../common/siteHeader";
import ChatbotsDocSidebar from "./chatbotsDocSidebar";
import ChatbotsCategoryNavBar from "./chatbotsCategoryNavBar";
import OneChatbotTitleBlock from "./oneChatbotTitleBlock";
import reply1Img from "../../../../public/resources/site/images/reply-naming-conventions1.png";
import reply2Img from "../../../../public/resources/site/images/reply-naming-conventions2.png";

class Docs1ChatbotDefaultReplies extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <ChatbotsCategoryNavBar />
        <main className="content-wrapper main-content">
          <OneChatbotTitleBlock />
          <div className="content-left-block connecting-grid-block --scrollpage">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xl-3 tab-inner-content">
                  <ChatbotsDocSidebar />
                </div>
                <div className="col-lg-8 col-xl-9">
                  <div className="tab-content tutorials-details-block">
                    <div className="tab-pane fade show active" id="onechatbot">
                      <h2>
                        <strong>Guides</strong>
                      </h2>
                      <h3>Default Replies</h3>
                      <h3 id="naming-conventions" className="top-gap">
                        Default reply naming conventions
                      </h3>
                      <p className="mb-4 mb-lg-5">
                        In the event that your chatbot does not recognize what
                        the user is sayingor typing, the “default reply” or
                        “fallback intent” is triggered.
                      </p>
                      <p>
                        By default, your NLU provider will have settings for
                        these. For example, Google Dialogflow ES has the default
                        name as “Default Fallback Intent” — as long as the
                        default names have not been changed for your NLU
                        provider then you can skip this guide. Just in case,
                        here are the correct default reply settings for various
                        NLU providers.
                      </p>
                      <p>
                        Choose provider(s) below to get details on proper
                        default reply naming conventions.
                      </p>
                      <ul className="field-list">
                        <li>Google Dialogflow ES</li>
                        <li>Amazon Lex</li>
                        <li>IBM Watson Assistant</li>
                      </ul>
                      <ul>
                        <li>
                          Google Dialogflow ES
                          <div className="info-content font-weight-normal font-16 mt-2">
                            <p>
                              By default Google Dialogflow ES names the default
                              reply “Default Fallback Intent” with an Action
                              setting to “input.unknown” — as long as these
                              settings have not been changed then Google
                              Dialogflow is ready for use with 1Chatbot.
                            </p>
                            <p>
                              If the settings had been changed previously then
                              you can do either of these so that the default
                              reply is properly recognized by 1Chatbot:
                            </p>
                            <ul>
                              <li>
                                Ensure the name of the default reply is “Default
                                Fallback Intent” by editing the top title field
                                and clicking save
                              </li>
                              <li>
                                Alternatively, ensure that the Action setting in
                                the default reply intent is set to
                                “input.uknown”
                              </li>
                            </ul>
                            <div className="image-block mb-5">
                              <img src={reply1Img} alt="Google Dialogflow ES" />
                            </div>
                          </div>
                        </li>
                        <li>
                          Amazon Lex
                          <div className="info-content font-weight-normal font-16 mt-2">
                            <p>
                              By default Amazon Lex names the default reply
                              “FallbackIntent” — as long as this setting has not
                              been changed then Amazon Lex is ready for use with
                              1Chatbot.
                            </p>
                            <p>
                              If the setting has been changed previously then
                              you can complete this step below so that the
                              default reply is properly recognized by 1Chatbot:
                            </p>
                            <ul>
                              <li>
                                Ensure that the default reply intent is named
                                “FallbackIntent” — then click Save and Build.
                              </li>
                            </ul>
                            <div className="image-block mb-5">
                              <img src={reply2Img} alt="Amazon Lex" />
                            </div>
                          </div>
                        </li>
                        <li>
                          IBM Watson Assistant
                          <div className="info-content font-weight-normal font-16 mt-2">
                            No steps are required to conform to default reply
                            naming conventions for Watson.
                          </div>
                        </li>
                      </ul>
                      <div className="info-content font-weight-normal font-16 mt-2">
                        <h3 id="priority-chatbot" className="top-gap">
                          Default Reply Priority Chatbot
                        </h3>
                        <p>
                          The default reply response in the bot set for priority
                          can be an important way to guide your users in the use
                          of all the bots within your 1Chatbot agent.
                        </p>
                        <p>
                          Your Default Reply Priority Chatbot response can be
                          the end users “map” to understand what they can and
                          can’t do.
                        </p>
                        <p>
                          Let’s take for example a 1Chatbot agent with these 3
                          bots under its command:
                        </p>
                        <ul>
                          <li>
                            Account Automation bot - helps your employees set up
                            new customer accounts
                          </li>
                          <li>
                            Ticket Creation bot - lets your team request help
                            from IT
                          </li>
                          <li>HR bot - sick day and vacation notifications</li>
                        </ul>
                        <p>The end user types something like this:</p>
                        <p>
                          <span className="text-lightorange">User:</span> New
                          account submission
                        </p>
                        <p>
                          In this case, the intent was to access the Account
                          Automation bot, but it does not match to an intent
                          (probably this is something you will add later in the
                          Account Automation bot training), but for now it does
                          not match. Both Ticket Creation bot and HR bot also
                          return no response for this request as they have no
                          such capabilities. All three bots return a default
                          reply, with no intent, or action matched. In this
                          case, HR bot was pre-selected to be the Default Reply
                          Priority Chatbot, so only HR bot’s default reply will
                          be sent back to the user.
                        </p>
                        <p>
                          This scenario elaborates why it is important to
                          consider the content of the default reply message in
                          the bot selected for priority. Here’s an example:
                        </p>
                        <p>
                          <span className="text-skyblue">HR bot</span>: "Sorry I
                          didn’t understand. I can help you with setting up new
                          accounts, requesting IT support, or sick day/vacation
                          notifications.”
                        </p>
                        <p>
                          HR bot’s default reply is set to inform the user about
                          the popular intents for all of the bots under
                          1Chatbot’s command.
                        </p>
                        <p>
                          By careful consideration of the content of the Default
                          Reply Priority Intent you will help your end users
                          navigate through multiple bots in the same chat
                          interface.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Docs1ChatbotDefaultReplies;
