import React from "react";
import HashAnchor from "../../../../common/hashAnchor";
import Note from "./note";
import CodeBlock from "../../codeBlock";

import IOSDiagram from "../../../../../public/images/ios-diagram.png";

const CODE_EXAMPLES = [
  `platform :ios, '10.0'
    target '<YourProjectNameHere>' do
    use_frameworks!
    pod 'eRTC_SDK_iOS', '~> 0.1.3'
end`,
  `ERTCConfig *config = [[ERTCConfig alloc] init];
config.nameSpace = @”YOUR_NAME_SPACE”
config.apiKey = @”YOUR_API_KEY”;
config.environment = 1 // Optional 1-QA, 2-Staging, 3-Production

[[eRTCSDK alloc] initWithConfig:config withSuccess:^(BOOL isValid, NSString *errMsg) {
    //your code
} andFailure:^(NSError *error) {
    //handle error
}];`,
  `[eRTCSDK validateNameSpaceWithWorkSpaceName:@”YOUR_NAMESPACE”] withSuccess:^(BOOL isValid, NSString *errMsg) {

} andFailure:^(NSError *error) {

}];`,
  `NSDictionary *param = @{
    @"loginType": @"email/phone",
    @"appUserId": @"APP_USER_ID",
    @"password": @"PASSWORD",
    @"fcmToken": @"FCM_TOKEN"
};

[[eRTCUserAuthentication sharedInstance] userAuthenticationWithParam:param andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"loginType": @"USER_NAME",
  @"appUserId": @"YOUR_APP_USER_ID",
  @"currentPassword": @"YOUR_CURRENT_PASSWORD",
  @"newPassword": @"YOUR_NEW_PASSWORD"
};

[[eRTCUserAuthentication sharedInstance] changePasswordWithParam:param
 andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"loginType": @"YOUR_USER_NAME",
  @"appUserId": @"YOUR_APP_USER_ID"
};

[[eRTCUserAuthentication sharedInstance] forgotPasswordWithParam:param
 andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCCoreDataManager sharedInstance] fetchChatUserListWithCompletionHandler:^(id ary, NSError *err) {

}];`,
  `[[eRTCCoreDataManager sharedInstance] getMentionedUserListWithCompletionHandler:^(id ary, NSError *err) {

}];`,
  `[[eRTCChatManager sharedChatInstance] getChatReationUserListWithMsgId: @"MESSAGE_UNIQUE_ID" andEmojiCode: @"EMOJI_CODE" andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCAppUsers sharedInstance] logoutUserWithCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCAppUsers sharedInstance] subscribeToUserMetaData:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCAppUsers sharedInstance] getUserDetails:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"userId" : @"USER_ID",
  @"profileStatus": @"STATUS_STRING",
  @"loginType": @"email"
};

[[eRTCAppUsers sharedInstance] updateUserProfileData:param
 andFileData:PROFILE_IMAGE_BASE64_DATA
 andCompletion:^(id json, NSString * errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCAppUsers sharedInstance] removeProfilePic:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @ {
  @"action" : @"<Action>",
  @"MuteNotificationType": @"<Level>",
};
[[eRTCSDK sharedInstance] muteNotifications:param andCompletion:^(id json, NSString * errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCSDK sharedInstance] getSettings:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @ {
  @"userId" : @"Your User ID"
};
[[eRTCSDK sharedInstance] metaDataOn:prem andCompletion:^(id json, NSString * errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `val status = AvailabilityStatus.AUTO or AvailabilityStatus.AWAY or AvailabilityStatus.INVISIBLE or AvailabilityStatus.DND`,
  `NSDictionary *param = @ {
    @"Status" : @"Your Availability Status"
};
[[eRTCSDK sharedInstance] setUserAvailabilityStatus:param andCompletion:^(id json, NSString * errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCSDK sharedInstance] getUserAvailabilityStatus:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"groupType" = @"private",
  @"name" = @"YOUR_GROUP_NAME",
  @"participants" = @[
    @"App_User_ID",
    @"App_User_ID"
  ];
};

[[eRTCChatManager sharedChatInstance]
CreatePrivateGroup:param withGroupImage:BASE64_IMAGE_DATA
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCChatManager sharedChatInstance]getuserGroups:@{}
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"groupId" : @"GROUP_ID"
}

[[eRTCChatManager sharedChatInstance]getGroupByGroupId:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"groupId" : @"GROUP_ID"
  @"groupName" = @"YOUR_GROUP_NAME";
  @"groupDesc" = @"YOUR_GROUP_DESCRIPTION";
}

[[eRTCChatManager sharedChatInstance]
updateGroupDetails:param andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"groupId" = @"GROUP_ID",
  @"participants" = @[
    @"xxx@yourdomain.com",
    @"yyy@yourdomain.com",
  ]
};

[[eRTCChatManager sharedChatInstance]groupAddParticipants:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"groupId" = @"GROUP_ID",
  @"participants" = @[
    @"xxx@yourdomain.com",
    @"yyy@yourdomain.com",
  ]
};

[[eRTCChatManager sharedChatInstance]groupRemoveParticipants:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"action" = @"make",
  @"eRTCUserId" = @"ERTC_USER_ID",
  @"groupId" = @"GROUP_ID",
  @"targetAppUserId" = @"APP_USER_ID"
};

[[eRTCChatManager sharedChatInstance]groupmakeDismissAdmin:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"action" = @"dismiss",
  @"eRTCUserId" = @"ERTC_USER_ID",
  @"groupId" = @"GROUP_ID",
  @"targetAppUserId" = @"APP_USER_ID"
};

[[eRTCChatManager sharedChatInstance]groupmakeDismissAdmin:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"groupId" = @"GROUP_ID",
  @"participants" = @[
    @"CURRENT_USER_LOGIN_ID"
  ]
};

[[eRTCChatManager sharedChatInstance]groupRemoveParticipants:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"groupId" = @"GROUP_ID",
};

[[eRTCSDK sharedChatInstance]groupRemoveImage:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"searchedText" = @"SEARCH_TEXT",   @"channelType" = @"CHANNEL_TYPE",   @"Action" = @"ACTION"
};

[[eRTCSDK sharedChatInstance]getSearchedGroups:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCSDK sharedInstance] subscribeToGroupUpdate:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"recipientAppUserId": @"RECIPIENT_APP_USERID",
  @"sendereRTCUserId": @"SENDER_RTC_USERID",
};

[[eRTCChatManager sharedChatInstance] getChatThreadIDWithParam:param
andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCChatManager sharedChatInstance]
getActiveThreads:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `// First, Create/ Get Thread
NSDictionary *param = @ {
  @"sendereRTCUserId": @"<sendereRTCUserId>",
  @"recipientAppUserId":@"<appUserId>",
};

[[eRTCChatManager sharedChatInstance] getChatThreadIDWithParam:[NSDictionary
  dictionaryWithDictionary:dictParam] andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {
  NSString *strThreadId = [NSString stringWithFormat:@"%@",json[@"result"][@"threadId"]];
  // Handle thread
} andFailure:^(NSError * _Nonnull error) {
  // Handle error
}];

// Then send message based on message type
// TEXT MESSAGE
NSDictionary *param = @ {
  @"sendereRTCUserId": @"<sendereRTCUserId>",
  @"eRTCUserId":@"<appUserId>",
  @"message": @"<text >",
  @"threadId": @"<text >",
  @"mentions": NSMutableArray *arrayMentioned
};

[[eRTCChatManager sharedChatInstance] sendTextMessageWithParam:[NSDictionary dictionaryWithDictionary:dictParam] andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {
  NSDictionary *dictResponse = (NSDictionary *)json;
  // Handle response
} andFailure:^(NSError * _Nonnull error) {
  // Handle error
}];

// IMAGE MESSAGE
NSDictionary *param = @ {
  @"sendereRTCUserId": @"<sendereRTCUserId>",
  @"threadId": @"<text >"
};
NSData *data = UIImageJPEGRepresentation(image, 1.0);

[[eRTCChatManager sharedChatInstance] sendPhotoMediaItemWithParam:[NSDictionary dictionaryWithDictionary:dictParam] andFileData:data andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {
  NSDictionary *dictResponse = (NSDictionary *)json;
  if (dictResponse[@"success"] != nil) {
    BOOL success = [dictResponse[@"success"] boolValue];
    if (success == true) {
      // Handle Success
    }else{
      // Handle error
    }
  }
} andFailure:^(NSError * _Nonnull error) {
  // Handle error
}];

// GIF MESSAGE
NSDictionary *param = @ {
  @"sendereRTCUserId": @"<sendereRTCUserId>",
  @"gify": @"<sendereRTCUserId>",
  @"threadId": @"<text >"
};

[[eRTCChatManager sharedChatInstance] sendGIFFileWithParam:dictParam andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {
  NSDictionary *dictResponse = (NSDictionary *)json;

  if (dictResponse[@"success"] != nil) {
    BOOL success = [dictResponse[@"success"] boolValue];
    if (success == true) {
      // Handle Success
    }else{
      // Handle error
    }
  }
} andFailure:^(NSError * _Nonnull error) {
  // Handle error
}];

// AUDIO MESSAGE
NSDictionary *param = @ {
  @"sendereRTCUserId": @"<sendereRTCUserId>",
  @"threadId": @"<text >"
};

[[eRTCChatManager sharedChatInstance] sendAudioMediaItemWithParam:dictParam andFileData:data andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {
  NSDictionary *dictResponse = (NSDictionary *)json;
  if (dictResponse[@"success"] != nil) {
    BOOL success = [dictResponse[@"success"] boolValue];
    if (success == true) {
      // Handle Success
    }else{
      // Handle error
    }
  }
} andFailure:^(NSError * _Nonnull error) {
  // Handle error
}];

// CONTACT MESSAGE
NSDictionary *contact = @ {
  @"numbers": [NSArray arrayWithObjects:@{@"number": @"<phoneNumber>", @"type": @"<phoneNumberType>"},nil]
};
NSDictionary *param = @ {
  @"sendereRTCUserId": @"<sendereRTCUserId>",
  @"threadId": @"<text >",
  @"contact": contact
};

[[eRTCChatManager sharedChatInstance] sendContactMessageWithParam:dictParam andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {
  NSDictionary *dictResponse = (NSDictionary *)json;
  if (dictResponse[@"success"] != nil) {
    BOOL success = [dictResponse[@"success"] boolValue];
    if (success == true) {
      // Handle Success
    }else{
      // Handle error
    }
  }
} andFailure:^(NSError * _Nonnull error) {
  // Handle error
}];

// LOCATION MESSAGE
NSNumber *lat = @1.312434;
NSNumber *lon = @100.312434;
NSDictionary *location = @ {
  @"latitude": lat,
  @"longitude": lon,
  @"address": @"<address>",
};
NSDictionary *param = @ {
  @"sendereRTCUserId": @"<sendereRTCUserId>",
  @"threadId": @"<text >",
  @"location": location
};
[[eRTCChatManager sharedChatInstance] sendLocationMessageWithParam:dictParam andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {
  NSDictionary *dictResponse = (NSDictionary *)json;
  if (dictResponse[@"success"] != nil) {
    BOOL success = [dictResponse[@"success"] boolValue];
    if (success == true) {
      // Handle Success
    }else{
      // Handle error
    }
  }
} andFailure:^(NSError * _Nonnull error) {
  // Handle error
}];
`,
  `[[eRTCChatManager sharedChatInstance]
getUserChatHistoryWithThreadID:@”THREAD_ID”
andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `[[eRTCChatManager sharedChatInstance]
getUserReplyThreadChatHistoryWithThreadID: @”THREAD_ID”
withParentID:@”MsgUniqueId” andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `[[eRTCSDK sharedInstance] sendReactionWWithThreadID:@”THREAD_ID”
withMetaData:params andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `[[eRTCSDK sharedInstance] messageMetaDataOn:@”THREAD_ID”
andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `#define ThreadID @"threadId"
#define DidReceiveEventList @"didReceveEventList"
// First, observe the didReceveEventList event on Thead chat Views.
- (void)viewDidLoad {
  [super viewDidLoad];
  ...
  [[NSNotificationCenter defaultCenter] addObserver:self
    selector:@selector(didReceiveEventList:)
    name:DidReceiveEventList
    object:nil];
}
// Then, handle the received data
NSString *currentThreadId;

-(void)didReceiveEventList:(NSNotification *) notification{
  NSDictionary *userData = notification.object;
  NSString *threadId = userData[@"thread"][ThreadID];
  if (threadId != NULL && [currentThreadId isEqualToString:threadId]){
    // UPDATE UI accordingly
  }
}`,
  `[[eRTCSDK sharedInstance] getMessageByThreadId:@”THREAD_ID”
messageId:@”MessageID” andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `[[eRTCSDK sharedInstance] getChatThreadMessage:@”THREAD_ID”
messageId:@”MessageID” andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `NSArray *details = @[];
[[eRTCChatManager sharedChatInstance]
ForwardMultiMessageWithParam:details  andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"msgUniqueId" : @"MESSAGE_UNIQUE_ID",
  {@"chats" : @[ @{@"msgUniqueId" : @"MESSAGE_UNIQUE_ID"}}],
  @"threadId" : @"THREAD_ID",
  @"deleteType": @"everyone/self",
  @"msgCorrelationId": [[NSDate date] timeIntervalSince1970]
};

[[eRTCChatManager sharedChatInstance]
DeleteMessageWithParam:param andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {
  [Helper showAlertOnController:@"eRTC"  withMessage:error.localizedDescription onController:self];
}];`,
  `NSDictionary *param = @{
  @"message" : @"UPDATED_MESSAGE_STRING",
  @"threadId" : @"ThreadID",
  @"msgUniqueId": @"MESSAGE_UNIQUE_ID"
};

[[eRTCChatManager sharedChatInstance]
editMessageWithParam:param andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"pageSize": @10,
  @"currentMsgId": @"YOUR_FIRST_MESSAGE_ID" //optional
  @"direction": @"past/future"
};

[[eRTCChatManager sharedChatInstance]
loadPreviousChatHistoryWithThreadID:@"THREAD_ID" parameters:param andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCChatManager sharedChatInstance]
skipChatRestore:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"searchedText": “SEARCH_TEXT”
};

[[eRTCChatManager sharedChatInstance]
searchMessages:param andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"searchedText": “SEARCH_TEXT”
};

[[eRTCChatManager sharedChatInstance]
globalSearch:param andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSDictionary *param = @{
  @"eRTCUserId": @"ERTC_USER_ID",
  @"msgStatusEvent": @"MESSAGE_STATUS",
  @"sendereRTCUserId": @"SENDER_ERTC_USER_ID"
  @"msgUniqueId" : @"MESSAGE_UNIQUE_ID",
  @"tenantId" : @"TENANT_ID",
  @"threadId": @"THREAD_ID",
  @"timeStamp": TIMESTAMP
};

[[eRTCChatManager sharedChatInstance]
updateMessageWithReadStatus:param]`,
  `[[NSNotificationCenter defaultCenter]
addObserver:self
selector:@selector(your_method:)
name:DidUpdateChatNotification
object:nil];`,
  `[[NSNotificationCenter defaultCenter]
addObserver:self
selector:@selector(your_method:)
name:DidUpdateChatNotification
object:nil];`,
  `[[NSNotificationCenter defaultCenter]
addObserver:self
selector:@selector(your_method:)
name:DidUpdateChatNotification
object:nil];`,
  `NSDictionary *param = @{
  @"appUserId": @"YOUR_APP_USERID",
  @"blockUnblock": @"block/unblock" // block or unblock
};

[[eRTCChatManager sharedChatInstance]
ContactblockUnblock:param andCompletion:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCCoreDataManager sharedInstance]
isMarkFavouriteWithMessageUniqueId:@"MESSAGE_UNIQUEID"
andMarkFavourite:TRUE/FALSE
andCompletionHandler:^(BOOL isMarkFavourite) {

}];`,
  `[[eRTCChatManager sharedChatInstance]
getAllFavoriteMessage:^(id _Nonnull json, NSString * _Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCChatManager sharedChatInstance]
getAllFavoriteMessageByThreadId:@”THREAD_ID” messageId:@”MessageID” andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `[[NSNotificationCenter defaultCenter]
addObserver:self
selector:@selector(your_controller_method:)
name:DidRecievedTypingStatusNotification
object:nil];`,
  `NSDictionary *param = @{
  @"appUserId" = @"CUREENT_APP_USERID",
  @"eRTCUserId" = @"CUREENT_USER_LOGINID",
  @"typingStatusEvent" = @"on/off"
};

[[eRTCChatManager sharedChatInstance] sendTypingStatus:param];`,
  `NSDictionary *param = @ {
  @"msgType" : @"<gify, image, video, audio, pdf etc.>",
  @"sendereRTCUserId": @"<sendereRTCUserId>",
  @"path":@"<path>",
  @"localFilePath":@"<localFilePath>"
};

[eRTCChatManager downloadMediaMessage:param
  andCompletionHandler:^(NSDictionary * _Nonnull details, NSError * _Nonnull error, NSData * _Nonnull data)
{
  if (!error && data && details[LocalFilePath] != NULL){
    NSString *msgId = details[MsgUniqueId];
    NSString *threadId = details[ThreadID];
    NSURL * fileURL = [NSURL URLWithString:[@"file://" stringByAppendingString:details[LocalFilePath]]];
    // Update UI with fileURL
  }else{
    // Handle error
  }
}];`,
  `NSDictionary *param = @ {
  @"action" : @"<Action>",
  @"MuteNotificationType": @"<Level>",
  @”timePeriod”:<Time Period>”,
  @”threadid”:<threadid>”,
  @”chatType”:<chatType>”
};
[[eRTCSDK sharedInstance] muteNotifications:param andCompletion:^(id json, NSString * errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `[[eRTCSDK sharedInstance] getNotificationSettings:@”THREAD_ID” messageId:@”MessageID” andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `[[eRTCSDK sharedInstance] chatMetaDataOn:@”THREAD_ID” messageId:@”MessageID” andCompletionHandler:^(id ary, NSError *err) {

}];`,
  `NSMutableDictionary * dictMsgFollowUnfollow = [NSMutableDictionary new];
[dictMsgFollowUnfollow setValue:@true/false forKey:@"follow"];
[dictMsgFollowUnfollow setValue:<Message> forKey:@"message"];
[dictMsgFollowUnfollow setValue:<ThreadID> forKey:"thread_id"];
[dictMsgFollowUnfollow setValue:<MessageID> forKey:"message_id"];
[dictMsgFollowUnfollow setValue:@"true" forKey:@"isStarred"];

[[eRTCChatManager sharedChatInstance] followUnFollowChatMessage:dictMsgFollowUnfollow
andCompletion:^(id Nonnull json, NSString * Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`,
  `NSMutableDictionary * dictMsgFollowThread = [NSMutableDictionary new];
[dictMsgFollowThread setValue:”single” forKey:@"threadType"];
[dictMsgFollowThread setValue:<message> forKey:@"message"];
[dictMsgFollowThread setValue:[NSNumber numberWithInt:20] forKey:@"pageSize"];
[dictMsgFollowThread setValue:”past” forKey:"direction"];
[dictMsgFollowThread setValue:<messageID> forKey:"currentMsgId"];
[dictMsgFollowThread setValue:[NSNumber numberWithInt:1] forKey:@"replyChatId"];
[dictMsgFollowThread setValue:[NSNumber numberWithInt:0] forKey:@"fetchAll"];
[dictMsgFollowThread setValue:[NSNumber numberWithInt:0] forKey:@"incldueCurrent"];
[dictMsgFollowThread setValue:[NSNumber numberWithInt:0] forKey:@"follow"];

[[eRTCChatManager sharedChatInstance] getFollowThreads:dictMsgFollowThread
andCompletion:^(id Nonnull json, NSString * Nonnull errMsg) {

} andFailure:^(NSError * _Nonnull error) {

}];`
];

const IOSChatSDKContent = () => {
  const renderOverviewContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="overview" offset={-150}>
          <h2 className="h3">Overview</h2>
        </HashAnchor>
        <p className="mb-4">
          InApp Chat SDK: employing true efficiency and ease.
        </p>
        <p className="mb-4">
          The following principles are integral in InApp Chat
        </p>
        <ul className="field-list">
          <li>
            Modularity: Code is segregated into multiple modules and follows a
            chain of responsibility model
          </li>
          <li>
            Ease of Integration: Maven artifacts can be downloaded from a public
            hosted URL. InApp Chat SDK can be integrated into any mobile app
            within minutes
          </li>
          <li>
            Scalability: SDK is highly scalable and can support Enterprise
            traffic
          </li>
          <li>
            Data ownership: SDK provides a data layer to effectively manage and
            control data
          </li>
          <li>Firebase powered by Google: Highly reliable and scalable</li>
          <li>Reactive using RxAndroid</li>
        </ul>
        <h3>Features</h3>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>Individual messages</li>
              <li>Push notifications</li>
              <li>
                Text, Image, Video, Audio, Location, Contact and gif messages
              </li>
              <li>Typing Indicators</li>
              <li>Delivered and Read receipts</li>
              <li>Chat history</li>
              <li>Chat user list</li>
              <li>Contact details</li>
              <li>E2E Encryption</li>
              <li>Public/Private Channel</li>
              <li>Thread Chat</li>
              <li>Favorite Messages</li>
              <li>Chat Reaction</li>
              <li>Follow/Unfollow Thread</li>
              <li>Forward Chat</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  const renderQuickstart = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="quickstart" offset={-150}>
          <h2 className="h3 mb-3">Quickstart</h2>
        </HashAnchor>
        <p className="mb-4">
          First, on Command Line, go to your project path and write{" "}
          <strong>pod init</strong>. It will create a pod file.{" "}
          <strong>Then</strong> write below the lines in your pod file and then
          on command line, again, write <strong>pod install</strong>.{" "}
          <strong>This</strong> will install the required SDK in your
          application.
        </p>
      </div>
    </div>
  );

  const renderHowtosContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="how-to-add-sdk" offset={-150}>
          <h2 className="h3 mb-3">How-Tos</h2>
        </HashAnchor>
        <p className="mb-2">How to add InApp Chat SDK to your project?</p>
        <p className="mb-2">
          Integration with an existing project is simple. You can add the core
          libraries using Pod.
        </p>
        <h3>Pod installation</h3>
        <p className="mb-2">
          To download the SDK, you will need to include the pod manually:
        </p>
        <CodeBlock code={CODE_EXAMPLES[0]} language="objectivec" />
        <h3 className="mb-4">
          How to initialize InApp Chat SDK to start using it?
        </h3>
        <h3>Initializing the Chat SDK</h3>
        <p className="mb-2">
          {`Now open your application’s Appdelegate class and find the `}
          <a href="#" className="highlight" target="_blank" rel="noreferrer">
            didFinishLaunchingWithOptions
          </a>
          {` method. Add the following to setup the SDK:`}
        </p>
        <CodeBlock code={CODE_EXAMPLES[1]} language="objectivec" />
        <table className="table-border w-100">
          <tr>
            <td>API</td>
            <td>apiKey</td>
          </tr>
          <tr>
            <td>parameter</td>
            <td>
              To initialize the SDK, we have to add two parameters: <br />
              <strong>ApiKey:</strong> will be sent to you in your mailbox
              <br />
              <strong>Namespace:</strong> will be sent to you in your mailbox
            </td>
          </tr>
        </table>
      </div>
    </div>
  );

  const render1ChatbotContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="onechatbot" offset={-150}>
          <h2 className="h3">Using 1Chatbot with the SDK</h2>
        </HashAnchor>
        <p className="mt-4 mb-4">
          Once a 1Chatbot agent has been created in the portal the chatbot is
          considered as a user within the eRTC SDK.
        </p>
        <p className="mt-4 mb-2">
          Some functions will not apply to 1Chatbot such as:
        </p>
        <ul className="field-list">
          <li>
            <h3>User Login</h3>
          </li>
          <li>
            <h3>Change password</h3>
          </li>
        </ul>
        <p className="mt-4 mb-2">
          Special consideration should be given to other functions such as:
        </p>
        <ul className="field-list">
          <li>
            <strong>Get user details: </strong>The response will include isBot =
            true/false which allows for handling of the bot in various ways. For
            example: choose whether or not to label the 1Chatbot agent as a bot
            in the group chat.
          </li>
          <li>
            <strong>Send message: </strong>1Chatbot agents only support Text
            Messages with the type MessageType.Text.
          </li>
        </ul>
      </div>
    </div>
  );

  const renderAPIContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="api-doc" offset={-150}>
          <h2 className="h3">API Documentation</h2>
        </HashAnchor>
        <h2>Tenant Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Namespace validation</h3>
                <p className="mt-4 mb-4">
                  This will validate the namespace information provided by the
                  user and return Boolean value if information is validated or
                  not.
                </p>
                <CodeBlock code={CODE_EXAMPLES[2]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>Validate(namespace)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>YOUR_NAMESPACE:</strong> Namespace information
                      will be sent to you in your mailbox.
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>User Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>User Login</h3>
                <p className="mt-4 mb-4">
                  After a namespace validation user has to login with valid
                  credentials, these credentials will be received in his mailbox
                  along with namespace information.
                </p>
                <CodeBlock code={CODE_EXAMPLES[3]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>login(username:string, password:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>Username:</strong> your email id
                      <br />
                      <strong>Password:</strong> your password
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Change password</h3>
                <p className="mt-4 mb-4">
                  With this api user can change his/her existing password to a
                  new password.
                </p>
                <CodeBlock code={CODE_EXAMPLES[4]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      changePassword(oldPassword:string, newPassword:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>oldPassword:</strong> enter your old password
                      <br />
                      <strong>newPassword:</strong> enter your new password
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Forgot password</h3>
                <p className="mt-4 mb-4">
                  This will trigger a new password to the provided email
                  address.
                </p>
                <CodeBlock code={CODE_EXAMPLES[5]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>forgetPassword(emailID:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>emailID:</strong> your username(in your case it is
                      your email ID)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Chat Users</h3>
                <p className="mt-4 mb-4">
                  This api call returns all the chat users data for the logged
                  in users.
                </p>
                <CodeBlock code={CODE_EXAMPLES[6]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>chatUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Mentioned users</h3>
                <p className="mt-4 mb-4">
                  This api call returns all the mentioned users data for the
                  logged in users.
                </p>
                <CodeBlock code={CODE_EXAMPLES[7]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Reaction Users</h3>
                <p className="mt-4 mb-4">
                  This api call returns all the reaction users data for the
                  logged in users.
                </p>
                <CodeBlock code={CODE_EXAMPLES[8]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getReactionedUser</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>Reaction Unicode:</strong> Ex: 👍
                      <br />
                      <strong>message ID:</strong> messageId from Message Record
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Logout</h3>
                <p className="mt-4 mb-4">
                  This will log out the user and invalidate the current ongoing
                  session.
                </p>
                <CodeBlock code={CODE_EXAMPLES[9]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>
                  User Metadata On – MQTT subscription (To get user updates in
                  real time)
                </h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock code={CODE_EXAMPLES[10]} language="objectivec" />
              </li>
              <li>
                <h3>Get user details</h3>
                <p className="mt-4 mb-4">
                  To get the user profile details use the following api call:
                </p>
                <Note>
                  The response will include isBot = true/false which allows for
                  handling of the bot in various ways. For example: choose
                  whether or not to label the 1Chatbot agent as a bot in the
                  group chat.
                </Note>
                <CodeBlock code={CODE_EXAMPLES[11]} language="objectivec" />

                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getUserById(userID:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>userId:</strong> emailId
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Update user profile (with & without profile image)</h3>
                <p className="mt-4 mb-4">
                  To update the profile update and profile image use the
                  following api call:
                </p>
                <CodeBlock code={CODE_EXAMPLES[12]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>updateProfile(profileStatus:strin g,image:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>profileStatus:</strong> ex-I am using InApp Chat!
                      <br />
                      <strong>filePath:</strong> profile picture path
                      <br />
                      <strong>mediaType:</strong> it is IMAGE
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove profile pic</h3>
                <p className="mt-4 mb-4">
                  To remove the profile image use the following api call:
                </p>
                <CodeBlock code={CODE_EXAMPLES[13]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeProfilePic</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Set Global notification settings</h3>
                <p className="mt-4 mb-4">
                  This is used to set global notification settings. Global level
                  is used to set notification at application level.
                </p>
                <CodeBlock code={CODE_EXAMPLES[14]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>muteNotification</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>action:</strong> allow/mention/none
                      <br />
                      <strong>MuteNotificationType:</strong>{" "}
                      MuteNotificationType.USER_LEVEL
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get global notification settings</h3>
                <p className="mt-4 mb-4">
                  This is used to get global notification settings.
                </p>
                <CodeBlock code={CODE_EXAMPLES[15]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>metaDataOn(Global notification setting)</h3>
                <p className="mt-4 mb-4">
                  This one is used to update notification settings in real time.
                  It is used to update the UI for parallel devices(Multi login.
                </p>
                <CodeBlock code={CODE_EXAMPLES[16]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>metaDataOn(userId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>userId:</strong> Your UserID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Set user availability status</h3>
                <p className="mt-4 mb-4">
                  This is used to set the user's availability status. Status can
                  be of 4 types.
                </p>
                <CodeBlock code={CODE_EXAMPLES[17]} language="objectivec" />
                <CodeBlock code={CODE_EXAMPLES[18]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>setUserAvailability</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>Status:</strong> AUTO, AWAY, INVISIBLE & DND
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get user availability status</h3>
                <p className="mt-4 mb-4">
                  This is used to get user availability status pings.
                </p>
                <CodeBlock code={CODE_EXAMPLES[19]} language="objectivec" />
              </li>
            </ul>
          </li>
        </ul>
        <h2>Group Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Create Channel</h3>
                <p className="mt-4 mb-4">
                  This is used to create a new Channel. Channel name,
                  description, participants, and channel type needs to be
                  provided to this api call.
                </p>
                <CodeBlock code={CODE_EXAMPLES[20]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      createPrivateGroup(groupRecord: GroupRecord)
                      <br />
                      createPublicGroup(groupRecord: string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupRecord:</strong> GroupRecord
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get all channels</h3>
                <p className="mt-4 mb-4">
                  This is used to get all channels for a given user. This is the
                  list where the current user is a participant of that channel.
                </p>
                <CodeBlock code={CODE_EXAMPLES[21]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get channel info by groupId</h3>
                <p className="mt-4 mb-4">This is used to get channel info.</p>
                <CodeBlock code={CODE_EXAMPLES[22]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getGroupById(groupId: string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> it is groupID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Update Channel details (Only Admin can update)</h3>
                <p className="mt-4 mb-4">
                  This api is used to update the channel information.
                </p>
                <CodeBlock code={CODE_EXAMPLES[23]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      updateGroupDetail(groupId:string,
                      groupName:string,groupDesc:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> groupID
                      <br />
                      <strong>groupName:</strong> group Name
                      <br />
                      <strong>groupDesc:</strong> group Description
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Add participants</h3>
                <p className="mt-4 mb-4">
                  This is used to add participants for a channel. Channel unique
                  id and new participant details are required in this call.
                </p>
                <CodeBlock code={CODE_EXAMPLES[24]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>addParticipants(groupId:string, userList:List)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                      <br />
                      <strong>userList:</strong> list of participant(user)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove participants</h3>
                <p className="mt-4 mb-4">
                  This is used to remove participants from a channel.
                </p>
                <CodeBlock code={CODE_EXAMPLES[25]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeParticipants(groupId:string, userList:List)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                      <br />
                      <strong>userList:</strong> list of participant(user)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Add admin</h3>
                <p className="mt-4 mb-4">
                  This is used to create users as admin.
                </p>
                <CodeBlock code={CODE_EXAMPLES[26]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>addAdmin(groupId:string, userId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                      <br />
                      <strong>userId:</strong> user id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove admin</h3>
                <p className="mt-4 mb-4">
                  This api is used to remove users as admin.
                </p>
                <CodeBlock code={CODE_EXAMPLES[27]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeAdmin(groupId:string, userId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                      <br />
                      <strong>userId:</strong> user id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Exit channel</h3>
                <p className="mt-4 mb-4">This api is used to exit a channel.</p>
                <CodeBlock code={CODE_EXAMPLES[28]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>exitGroup(groupId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove channel picture</h3>
                <p className="mt-4 mb-4">
                  This api is used to remove channel pictures.
                </p>
                <CodeBlock code={CODE_EXAMPLES[29]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeGroupPic(groupId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Search Channels</h3>
                <p className="mt-4 mb-4">
                  This api is used to search channels.
                </p>
                <CodeBlock code={CODE_EXAMPLES[30]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getSearchedChannels</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>searchedText:</strong> Ex-Dev
                      <br />
                      <strong>channelType:</strong> private/public(optional)
                      <br />
                      <strong>Action:</strong> optional
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Group Metadata On</h3>
                <p className="mt-4 mb-4">
                  This function will return a group record in real time. The
                  user will need to add this function when he/she needs a
                  realtime group updated on the device.
                </p>
                <CodeBlock code={CODE_EXAMPLES[31]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Chat Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Create Thread</h3>
                <p className="mt-4 mb-4">
                  To initiate a chat between two users, we must first create a
                  thread. Then we must return a thread id on successful creation
                  of a thread. And any further chat message is posted to that
                  unique thread id. If there is a thread that already exists and
                  we call createThread again, the existing thread id will be
                  returned in that case.
                </p>
                <CodeBlock code={CODE_EXAMPLES[32]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>creatThread(appUserId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>appUserId:</strong> it is user’s email id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Threads</h3>
                <p className="mt-4 mb-4">This api is used to get threads.</p>
                <CodeBlock code={CODE_EXAMPLES[33]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getThread</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Send Message</h3>
                <p className="mt-4 mb-4">
                  A chat between two users is considered a thread of
                  communication. To initiate a chat between users, you need to
                  create threads and send messages over that threadID.
                </p>
                <Note>
                  1Chatbot agents only support Text Messages with the type
                  MessageType.Text.
                </Note>
                <CodeBlock code={CODE_EXAMPLES[34]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>sendMessage(threadId:string, message:Message)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>message:</strong> sending message object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Messages</h3>
                <p className="mt-4 mb-4">
                  This api is used to get messages. This is used to fetch the
                  main chat history. Here we have used pagination.
                </p>
                <CodeBlock code={CODE_EXAMPLES[35]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getMessage(threadId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get ChatThread Messages</h3>
                <p className="mt-4 mb-4">
                  This api is used to get chat thread messages. This is used to
                  fetch chat thread history. We use pagination to achieve this.
                </p>
                <CodeBlock code={CODE_EXAMPLES[36]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      getUserReplyThreadChatHistoryWithThreadID(threadId:string,
                      parentMsgId:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>parentMsgId:</strong> from that message thread is
                      initiated. that message’s message id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Send Reaction</h3>
                <p className="mt-4 mb-4">
                  This api is used to send reactions on received or sent
                  messages.
                </p>
                <CodeBlock code={CODE_EXAMPLES[37]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      sendReaction(threadId:string,
                      messageMetaData:MessageMetaData)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> it is user’s threadID
                      <br />
                      <strong>messageMetaData:</strong> MessageMetaData
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message metadata On</h3>
                <p className="mt-4 mb-4">
                  This api is used to turn your message metadata on.It is used
                  to update messages in real time. When a user sends a
                  message,this function enables the reception of that message.
                  This function adds the message in your recycler view and
                  updates the UI in real time.
                </p>
                <CodeBlock code={CODE_EXAMPLES[38]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>messageMetaDataOn(threadId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> it is user’s threadID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Thread data update on main thread</h3>
                <p className="mt-4 mb-4">
                  This api is used to update thread data on the main thread.
                </p>
                <CodeBlock code={CODE_EXAMPLES[39]} language="objectivec" />
              </li>
              <li>
                <h3>Get Message by messageId</h3>
                <p className="mt-4 mb-4">
                  This api is used to get messages by messageId from main chat.
                </p>
                <CodeBlock code={CODE_EXAMPLES[40]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      getMessageByThreadId(threadId:string, messageId:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>messageId:</strong> message id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get chat thread message by messageId</h3>
                <p className="mt-4 mb-4">
                  This api is used to get chat thread messages by messageId from
                  thread messages.
                </p>
                <CodeBlock code={CODE_EXAMPLES[41]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      getChatThreadMessage(threadId:strin g,messageId:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>messageId:</strong> message id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Forward chat</h3>
                <p className="mt-4 mb-4">
                  This api is used to forward a chat. You can forward a message
                  to multiple users and channels.
                </p>
                <CodeBlock code={CODE_EXAMPLES[42]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      {`forwardChat(message:Message,
                        userList:List<UserRecord>,
                        groupList:List<GroupRecord>,
                        chatType:ChatType)`}
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>message:</strong> Message
                      <br />
                      <strong>userList:</strong> list of users
                      <br />
                      <strong>groupList:</strong> list of groups
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Delete Message</h3>
                <p className="mt-4 mb-4">
                  This api is used to delete a message. There are two options
                  for outgoing messages: deleteForMe and deleteForEveryOne. The
                  only option for incoming messages is delete for me.
                </p>
                <CodeBlock code={CODE_EXAMPLES[43]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      {`deleteMessage(deleteType: string,
                        threadId:string,
                        messageRecords:MessageRecord)`}
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>deleteType:</strong> self/everyone
                      <br />
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>messageRecords:</strong> message record object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Edit Message</h3>
                <p className="mt-4 mb-4">This api is used to edit a message.</p>
                <CodeBlock code={CODE_EXAMPLES[44]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`editMessage(threadId:string, message:Message)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>message:</strong> message object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Chat Restore</h3>
                <p className="mt-4 mb-4">This api is used to restore chat.</p>
                <CodeBlock code={CODE_EXAMPLES[45]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Skip Chat Restore</h3>
                <p className="mt-4 mb-4">
                  This api is used to skip chat restarts.
                </p>
                <CodeBlock code={CODE_EXAMPLES[46]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Search message(local search)</h3>
                <p className="mt-4 mb-4">
                  This api is used to search text in messages or in files
                  locally.
                </p>
                <CodeBlock code={CODE_EXAMPLES[47]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`searchMessage(searchedText:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>searchedText:</strong> text(Ex-address)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Search message(global search)</h3>
                <p className="mt-4 mb-4">
                  This api is used to search text in messages or in files
                  globally(from server).
                </p>
                <CodeBlock code={CODE_EXAMPLES[48]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`globalSearch(searchedText:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>searchedText:</strong> text(Ex-address)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Mark as Read</h3>
                <p className="mt-4 mb-4">
                  This api is used to update the read status.
                </p>
                <CodeBlock code={CODE_EXAMPLES[49]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`markAsRead(threadId:string, parentMessageId: String)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadId
                      <br />
                      <strong>parentMessageId:</strong> parent message id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message Read status – MQTTListener</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock code={CODE_EXAMPLES[50]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`msgReadStatus(threadID:string, parentMessageId: String, chatType: ChatType)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>parentMessageId:</strong> parent message id
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message On – MQTT Listener (for normal message)</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock code={CODE_EXAMPLES[51]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`messageOn(threadID:string, parentMessageId: String, chatType: ChatType)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>parentMessageId:</strong> parent message id
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message On – MQTT Listener (Edit/Delete/Favorite)</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock code={CODE_EXAMPLES[52]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>messageOn</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Block/unblock user</h3>
                <p className="mt-4 mb-4">
                  This api call is used to block/unblock users.
                </p>
                <CodeBlock code={CODE_EXAMPLES[53]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`blockUnblock(action:string, userId:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>action:</strong> action to be attempt
                      <br />
                      <strong>userId:</strong> it is user’s logged-in email
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Mark as favorite</h3>
                <p className="mt-4 mb-4">
                  This api call used to mark chat(message) as
                  favorite/unfavorite.
                </p>
                <CodeBlock code={CODE_EXAMPLES[54]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`markAsFavorite(threadId:string, messageList: List<MessageRecord>, isFavorite: Boolean)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>messageList:</strong> message list
                      <br />
                      <strong>isFavorite:</strong> mark message as
                      favorite/unfavorite
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get all Favorite Messages</h3>
                <p className="mt-4 mb-4">
                  This api is used to get all favorite messages.
                </p>
                <CodeBlock code={CODE_EXAMPLES[55]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getAllFavoriteMessage</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get all favorite thread specific messages</h3>
                <p className="mt-4 mb-4">
                  This api call used to get the users all favorite thread
                  specific messages.
                </p>
                <CodeBlock code={CODE_EXAMPLES[56]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`getAllFavoriteMessage(threadID:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Typing Indicator Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Typing indicator subscription</h3>
                <p className="mt-4 mb-4">
                  It will throw you a chat event. You will display it on your
                  action bar.
                </p>
                <CodeBlock code={CODE_EXAMPLES[57]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`subscribe(threadID:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Typing indicator publish</h3>
                <p className="mt-4 mb-4">
                  Users have to call this function when the user starts typing
                  and stops typing. This function will turn typing state on or
                  off.
                </p>
                <CodeBlock code={CODE_EXAMPLES[58]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`publish(threadID:string, state:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>state:</strong> on/off
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Download Media Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Download Media</h3>
                <p className="mt-4 mb-4">This api is used to download media.</p>
                <CodeBlock code={CODE_EXAMPLES[59]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`downloadMediaMessage(msgType: string, sendereRTCUserId: string, path: string, localFilePath: string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>sendereRTCUserId:</strong> sender ID
                      <br />
                      <strong>path:</strong> it is link from where media
                      supposed to be download
                      <br />
                      <strong>localFilePath:</strong> it is path where
                      downloaded media to be stored
                      <br />
                      <strong>msgType:</strong> it is type of media i.e. image,
                      video, pdf etc.
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Notification Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Mute notification</h3>
                <p className="mt-4 mb-4">
                  This api is used to mute notifications. This is found in
                  thread level Notification settings. You can mute a particular
                  user or channel.
                </p>
                <CodeBlock code={CODE_EXAMPLES[60]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`muteNotifications(action:string, muteNotificationType: MuteNotificationType, timePeriod: SettingAppliedFor, threadId: String, chatType:ChatType)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>action:</strong> all/mentions/none
                      <br />
                      <strong>muteNotificationType:</strong>{" "}
                      USER_LEVEL/THREAD_LEVEL
                      <br />
                      <strong>timePeriod:</strong> always/1 Day/3 Days/1 Week/2
                      Weeks/1 Month
                      <br />
                      <strong>threadID:</strong> threadID
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get notification settings</h3>
                <p className="mt-4 mb-4">
                  This api is used to get notification settings.
                </p>
                <CodeBlock code={CODE_EXAMPLES[61]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`getSettings(threadID:String)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>
                  Chat metadata on for notification setting – MQTT Listener
                </h3>
                <p className="mt-4 mb-4">
                  This api is used to get chat metadata for notification – MQTT
                  Listener. You can get a real time update on the notification
                  settings. It is used for parallel devices(Multi login case).
                </p>
                <CodeBlock code={CODE_EXAMPLES[62]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`chatMetaDataOn(threadID: String)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Follow/Unfollow Thread Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Follow/Unfollow thread</h3>
                <p className="mt-4 mb-4">
                  This api is used to follow/unfollow threads.
                </p>
                <CodeBlock code={CODE_EXAMPLES[63]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`followThread(threadID:string, messageRecord:MessageRecord, isFollowed: Boolean)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                      <br />
                      <strong>messageRecord:</strong> Message Record Object
                      <br />
                      <strong>isFollowed:</strong> true/false
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get follow thread list</h3>
                <p className="mt-4 mb-4">
                  This api is used to get the following thread list.
                </p>
                <CodeBlock code={CODE_EXAMPLES[64]} language="objectivec" />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`getFollowThreads(threadID:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>FCM implementation Module</h2>
        <p className="mt-4 mb-4">
          You need FCM registration before Login API. Write the below line in
          your application
        </p>
        <p className="mt-4 mb-4">
          For redirection to an activity,enter MainActivity, You must set Intent
          using the below code
        </p>
        <p className="mt-4 mb-4">
          (e.g. In MainActivity); To manage thread-based notification, when a
          user clicks Notification they must write:
        </p>
      </div>
    </div>
  );

  const renderResources = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="resources" offset={-150}>
          <h2 className="h3">Resources</h2>
        </HashAnchor>
        <h3 className="h3">Modules</h3>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>Core SDK module</li>
              <li>Permission module</li>
              <li>Attachment module</li>
              <li>Data Module(core-cache)</li>
              <li>MQTT module</li>
              <li>{`Core Http Module (Rest Apis => core-remote)`}</li>
              <li>Core Download</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  const renderDesignDiagram = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="design-diagram" offset={-150}>
          <h2 className="h3">Design diagram</h2>
        </HashAnchor>
        <img src={IOSDiagram} alt="ios-design-diagram" />
      </div>
    </div>
  );

  return (
    <div className="document-content chatsdk-content">
      <h1 className="h2">iOS SDK</h1>
      {renderOverviewContent()}
      {renderQuickstart()}
      {renderHowtosContent()}
      {render1ChatbotContent()}
      {renderAPIContent()}
      {renderResources()}
      {renderDesignDiagram()}
    </div>
  );
};

export default IOSChatSDKContent;
