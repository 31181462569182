import cookie from "react-cookies";
import config from "../config/config";
import {
  endpoint,
  endpointWithoutApiVersion,
  endpoint_v2,
  Request,
  restCall
} from "../utils/restCallUtil";
import {
  genericFetchApiCatchHandler,
  genericHttpErrorHandler,
  genericHttpSuccessHandler,
  handleHttpResponse
} from "../utils/httpResponseErrorHandler";
import { TOKEN_RECEIVED, TOKEN_REMOVED } from "./auth";
import {
  getAuth0Url,
  getUniquePlayerId,
  saveCookie
} from "../utils/commonUtil";
import { apiCallFinish, apiCallStart } from "./ui";
// import { logoutChargebeePortalSession } from "./billing";

const SignUpEndpoint = endpoint("auth0/signUp");
const SignUPEndpoint_v2 = endpoint_v2("auth0/signUp");
const GoogleLoginEndPoint = endpoint("auth0/googleLogin");
const Auth0LoginEndPoint = endpoint("auth0/token");
const VerifyEmailEndPoint = endpoint("verifyEmail");
const ResendEmailVerificationEndPoint = endpoint("getVerificationLink");
const RequestReverificationEmail = email =>
  endpoint(`requestReVerification/${email}`);
const LoginEndpoint = endpoint("auth0/login");
const RecaptchaTokenValidateEndpoint = endpoint("verifyRecaptcha");
const UserEndPoint = endpoint("users");

const deleteAccountEndPoint = endpoint(`users`);
const changeOwnerEndPoint = organization =>
  endpoint(`organizations/${organization}/changeOwner`);
const InviteUserEndPoint = organization =>
  endpoint(`organizations/${organization}/inviteUser`);
const AcceptInvitationEndpoint = (organization, encryptedEmail) =>
  endpoint(`acceptInvitation/${organization}/${encryptedEmail}`);
const RequestReinvitationEndpoint = (organization, encryptedEmail) =>
  endpoint(`requestReInvite/${organization}/${encryptedEmail}`);
const GetUsersEndpoint = organization =>
  endpoint(`organizations/${organization}/users`);
const RemoveUserEndPoint = (organization, user) =>
  endpoint(`/organizations/${organization}/user/${user}`);
const ResendInvitationEndPoint = organization =>
  endpoint(`/organizations/${organization}/reInviteByAdmin`);
const GetUsersByIdsEndPoint = endpoint("users/getUsers");
const ForgotPasswordEndPoint = endpoint(`auth0/forgotPassword`);
const ChangePasswordEndPoint = endpoint("users/auth0/changePassword");
const setPasswordEndPoint = endpoint("users/setPassword");
const signupWithEmail = endpointWithoutApiVersion("V3/signUp");

export const USER_LOGIN_SUCCESS = "user_login_success";
export const USER_LOGIN_ERROR = "user_login_error";
export const USER_NOT_LOGGEDIN = "user_not_loggedin";
export const GET_USER_SUCCESS = "get_user_success";
export const EDIT_USER_SUCCESS = "edit_user_success";

export const GET_USER_CALL_KEY = "GET_USER";

const DOMAIN = config.DOMAIN;

export function signUpUser({
  email,
  name,
  password,
  organizationName,
  position,
  department,
  industry,
  numOfEmployees,
  subscribeBlog,
  sendMarketingEmail,
  acceptTermCondition,
  invitedOrg,
  context,
  playerId
}) {
  return dispatch => {
    let bodyObj = {
      email,
      name,
      password,
      playerId,
      position,
      department,
      industry,
      numOfEmployees,
      subscribeBlog,
      sendMarketingEmail,
      acceptTermCondition
    };
    if (!invitedOrg) {
      bodyObj = { ...bodyObj, organizationName };
    }
    if (context) {
      bodyObj = { ...bodyObj, context };
    }
    return new Promise((resolve, reject) => {
      const request = Request({
        url: SignUpEndpoint,
        method: "POST",
        body: JSON.stringify(bodyObj),
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function signUpUser_v2({
  email,
  name,
  password,
  country,
  acceptTermCondition,
  context,
  playerId
}) {
  return dispatch => {
    let bodyObj = {
      email,
      name,
      password,
      country,
      playerId,
      acceptTermCondition
    };
    if (context) {
      bodyObj = { ...bodyObj, context };
    }
    return new Promise((resolve, reject) => {
      const request = Request({
        url: SignUPEndpoint_v2,
        method: "POST",
        body: JSON.stringify(bodyObj),
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function auth0Login(code, redirectUri) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: Auth0LoginEndPoint,
        method: "POST",
        body: JSON.stringify({ code, redirectUri }),
        headerObj: {
          "content-type": "application/json",
          "Accept-Encoding": "identity"
        }
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            loginSuccessHandler(dispatch, resolve),
            loginErrorHandler(dispatch, reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function signupWithFreeTrial(email) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: signupWithEmail,
        method: "POST",
        body: JSON.stringify({ email }),
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            loginSuccessHandler(dispatch, resolve),
            loginErrorHandler(dispatch, reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function googleLogin(code, redirectUri) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: GoogleLoginEndPoint,
        method: "POST",
        body: JSON.stringify({ code, redirectUri }),
        headerObj: {
          "content-type": "application/json"
        }
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            loginSuccessHandler(dispatch, resolve),
            loginErrorHandler(dispatch, reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function verifyEmail(id, code, playerId) {
  const body = {};
  body.code = code;
  if (playerId) {
    body.playerId = playerId;
  }
  return dispatch => {
    const finalUrl = `${VerifyEmailEndPoint}/${id}`;
    return new Promise((resolve, reject) => {
      const request = Request({
        url: finalUrl,
        method: "POST",
        body: JSON.stringify(body),
        headerObj: {
          "content-type": "application/json"
        }
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            verifyEmailSuccessHandler(dispatch, resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function resendEmailVerification(
  email,
  playerId,
  context,
  organization
) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: ResendEmailVerificationEndPoint,
        method: "POST",
        body: JSON.stringify({
          email: email,
          playerId: playerId,
          organization: organization,
          context: context
        }),
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

function verifyEmailSuccessHandler(dispatch, resolve) {
  return actionSuccess => {
    const resultData = actionSuccess.data;

    // Handle token object. Save access token in cookie and send token object to redux (in turn local
    // storage) also
    const tokenData = resultData?.token || "";
    if (tokenData) {
      saveCookie(`token`, tokenData.token);
      dispatch({
        type: TOKEN_RECEIVED,
        token: tokenData
      });
    }
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
}

export function loginUser(email, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: LoginEndpoint,
        method: "POST",
        body: JSON.stringify({
          email,
          password,
          playerId: getUniquePlayerId()
        }),
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            loginSuccessHandler(dispatch, resolve),
            loginErrorHandler(dispatch, reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function validateRecaptchaToken(recaptchaToken) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: RecaptchaTokenValidateEndpoint,
        method: "POST",
        body: JSON.stringify({ recaptchaToken: recaptchaToken }),
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

function loginSuccessHandler(dispatch, resolve) {
  return actionSuccess => {
    const resultData = actionSuccess.data;
    const userData = resultData.user;
    dispatch({
      type: USER_LOGIN_SUCCESS,
      user: userData
    });
    const tokenData = resultData.token;
    saveCookie(`token`, tokenData.token);
    dispatch({
      type: TOKEN_RECEIVED,
      token: tokenData
    });
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
}

function loginErrorHandler(dispatch, reject) {
  return actionError => {
    dispatch({
      type: USER_LOGIN_ERROR
    });
    genericHttpErrorHandler(reject)(actionError);
  };
}

export function logoutUser() {
  return dispatch => {
    clearData(dispatch);
    const url = getAuth0Url("logout");
    redirect(url);
    /* logoutChargebeePortalSession()(dispatch)
      .then(() => {
        clearData(dispatch);
        redirect("/home");
      })
      .catch(error => {
        console.error("Error ", error);
        // Ignore error and clear data.
        clearData(dispatch);
        redirect("/home");
      }); */
  };
}

function clearData(dispatch) {
  // remove all the cookies.
  const cookies = cookie.loadAll();
  Object.keys(cookies).forEach(key => {
    if (key && key.search("privacy-policy") < 0)
      cookie.remove(key, { path: "/", domain: DOMAIN });
  });

  // clear local storage as well.
  localStorage.clear();

  dispatch({
    type: TOKEN_REMOVED
  });
}

function redirect(path) {
  // not using history push here , as it maintains state in redux store
  window.location.href = path;
}

export function getUser() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiCallStart(dispatch, GET_USER_CALL_KEY);
      const request = Request({
        url: UserEndPoint,
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });
      //restCall(request, dispatch)
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            getUserSuccessHandler(dispatch, resolve, GET_USER_SUCCESS),
            getUserErrorHandler(dispatch, reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

function getUserSuccessHandler(dispatch, resolve, type) {
  return actionSuccess => {
    const userData = actionSuccess.data;
    dispatch({
      type: type,
      user: userData
    });
    apiCallFinish(dispatch, GET_USER_CALL_KEY);
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
}

function getUserErrorHandler(dispatch, reject) {
  return actionError => {
    apiCallFinish(dispatch, GET_USER_CALL_KEY);
    genericHttpErrorHandler(reject)(actionError);
    if (
      actionError &&
      actionError.httpCode === 401 &&
      actionError.serverErrorCode === "AU0003"
    ) {
      dispatch(logoutUser());
    }
  };
}

export function deleteAccount(password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: deleteAccountEndPoint,
        method: "DELETE",
        body: JSON.stringify({ password }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });
      //restCall(request, dispatch)
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function changeOwner(organizationId, newOwnerId, password) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: changeOwnerEndPoint(organizationId),
        method: "POST",
        body: JSON.stringify({ newOwnerId, password }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });
      //restCall(request, dispatch)
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function inviteUser(organizationId, email, roleId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: InviteUserEndPoint(organizationId),
        method: "POST",
        body: JSON.stringify({ email, roleId }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });
      //restCall(request, dispatch)
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function acceptInvitation(organization, encryptedEmail, code) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: AcceptInvitationEndpoint(organization, encryptedEmail),
        method: "POST",
        body: JSON.stringify({ code }),
        headerObj: {
          "content-type": "application/json"
        }
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function requestResendInvite(organization, encryptedEmail, code) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: RequestReinvitationEndpoint(organization, encryptedEmail),
        method: "POST",
        body: JSON.stringify({ code }),
        headerObj: {
          "content-type": "application/json"
        }
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function forgotPassword(email) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: ForgotPasswordEndPoint,
        method: "POST",
        body: JSON.stringify({ email: email }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: false
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function setPassword(newPassword) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: setPasswordEndPoint,
        method: "POST",
        body: JSON.stringify({ newPassword }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

/*function acceptUserRestCallMock(requestObj, dispatch) {
    return new Promise((resolve, reject) => {
        setTimeout(()=>{
            const httpResponse = {};
            httpResponse.status = 200;
            httpResponse.json =  () => {
                return new Promise((resolve) => {
                    const responseData = {};
                    responseData.success =  true;
                    responseData.message = "Success";
                    responseData.result = {
                        "message": "Accepted invitation successfully!",
                        "signupRequired": true,
                        "organization": {
                            "_id": "5cce9a6384a245383b17a89d",
                            "name": "chintanorg"
                        },
                        "email" : "chintan.vaghani2008@gmail.com"
                    };
                    resolve(responseData);
                });
            }
            resolve(httpResponse);
        }, 1000);
    })
}*/

export function getUsers(organization) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: GetUsersEndpoint(organization),
        method: "GET",
        authEnabled: true
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function removeUser(organization, user) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: RemoveUserEndPoint(organization, user),
        method: "DELETE",
        authEnabled: true
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function editUser(name, email, password, imgSrc) {
  return dispatch => {
    var formData = new FormData();
    var formDataBody;
    if (name) {
      formData.append("name", name);
    }
    if (password) {
      formData.append("password", password);
    }
    if (imgSrc) {
      formData.append("profilePic", imgSrc, "profilePic.png");
      formDataBody = formData;
    } else {
      if (imgSrc === null) {
        formData.append("profilePic", null);
        formDataBody = formData;
      } else {
        formDataBody = new URLSearchParams(formData);
      }
    }
    return new Promise((resolve, reject) => {
      const request = Request({
        url: UserEndPoint,
        method: "POST",
        body: formDataBody,
        authEnabled: true
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function changePassword(currentPassword, newPassword) {
  const playerId = getUniquePlayerId();
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: ChangePasswordEndPoint,
        method: "POST",
        body: JSON.stringify({ currentPassword, newPassword, playerId }),
        authEnabled: true,
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function dispatchUserNotLoggedIn() {
  return dispatch => {
    dispatch({
      type: USER_NOT_LOGGEDIN
    });
  };
}

export function getUsersByUserIds(userIds) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: GetUsersByIdsEndPoint,
        method: "POST",
        body: JSON.stringify({ userIds: userIds }),
        authEnabled: true,
        headerObj: {
          "content-type": "application/json"
        }
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function resendInvitation(organization, email) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: ResendInvitationEndPoint(organization),
        method: "POST",
        body: JSON.stringify({ email: email }),
        authEnabled: true,
        headerObj: {
          "content-type": "application/json"
        }
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

/**
 * This is after email link is expired.
 */
export function requestReverificationEmail(
  email,
  code,
  playerId
  /*, context*/
) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: RequestReverificationEmail(email),
        method: "POST",
        body: JSON.stringify({ code: code, playerId: playerId }),
        headerObj: {
          "content-type": "application/json"
        }
      });
      restCall(request, dispatch)
        .then(response =>
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          )
        )
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}
