import React from "react";
import { CopyBlock, a11yLight } from "react-code-blocks";

const CodeBlock = ({ code, language }) => {
  return (
    <div className="code-block">
      <CopyBlock
        language={language}
        text={code}
        showLineNumbers={false}
        theme={a11yLight}
        wrapLines={true}
        codeBlock
      />
    </div>
  );
};

export default CodeBlock;
