import React, { Component } from "react";
import { connect } from "react-redux";
import { dispatchUserNotLoggedIn, getUser } from "../actions/users";
import { getCurrentOrganization, loadCookie } from "../utils/commonUtil";
import { getProjects } from "../actions/projects";
import UserContextProvider from "../components/common/context/userContextProvider";
import { ActionSuccess } from "../utils/httpResponseErrorHandler";
import InternalServerErrorPage from "../components/site/pages/internalServerError";

export default function(ComposedComponent) {
  class OptionalAuthentication extends Component {
    constructor(props) {
      super(props);
      this.state = { userLoad: false };
    }

    componentDidMount() {
      this.processAuth();
    }

    processAuth() {
      const token = loadCookie(`token`);
      if (token) {
        if (!this.props.user) {
          this.props
            .getUser()
            .then(result => {
              this.setState({
                userLoad: true,
                resultObj: result.actionDataObj
              });
              try {
                if (
                  !(this.props.projects && this.props.projects.isProjectLoaded)
                ) {
                  const organizationId = getCurrentOrganization(
                    result.actionDataObj.data
                  )?._id;
                  if (organizationId) {
                    this.props
                      .getProjects(organizationId)
                      .then(() => {})
                      .catch(error => {
                        console.log(error);
                      });
                  }
                }
              } catch (e) {
                console.log(e);
              }
            })
            .catch(error => {
              this.setState({ userLoad: true, resultObj: error.actionDataObj });
            });
        } else {
          this.setState({ userLoad: true });
          try {
            if (!(this.props.projects && this.props.projects.isProjectLoaded)) {
              const organizationId = getCurrentOrganization(this.props.user)
                ?._id;
              if (organizationId) {
                this.props
                  .getProjects(organizationId)
                  .then(result => {
                    console.log(result);
                  })
                  .catch(error => {
                    console.log(error);
                  });
              }
            }
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        this.props.dispatchUserNotLoggedIn();
      }
    }

    render() {
      const token = loadCookie(`token`);

      if (!token) {
        return <ComposedComponent {...this.props} />;
      }

      if (this.state.userLoad) {
        if (this.props.user) {
          return this.renderUsingContextProvider(ComposedComponent);
        } else {
          if (this.state.resultObj instanceof ActionSuccess) {
            return this.renderUsingContextProvider(ComposedComponent);
          } else {
            return <InternalServerErrorPage />;
          }
        }
      } else {
        //TODO: add loading design
        return null; //<div>Loading</div>
      }
    }

    renderUsingContextProvider(ComposedComponent) {
      return (
        <UserContextProvider user={this.props.user}>
          <ComposedComponent {...this.props} />
        </UserContextProvider>
      );
    }
  }

  function mapStatsToProps(stats) {
    return {
      user: stats.user.user,
      projects: stats.project.projects,
      isProjectLoaded: stats.project.isProjectLoaded
    };
  }

  return connect(mapStatsToProps, {
    getUser,
    getProjects,
    dispatchUserNotLoggedIn
  })(OptionalAuthentication);
}
