import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class ChatbotsCategoryNavBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const navItems = [
      {
        title: "Docs Home",
        url: "/docs",
        match: "nomatch"
      },
      {
        title: "NLU Connecting Guide",
        url: "/docs/chatbots/nlu/google-dialogflow",
        match: "/docs/chatbots/nlu"
      },
      {
        title: "1Chatbot",
        url: "/docs/chatbots/1chatbot/default-replies",
        match: "/docs/chatbots/1chatbot"
      },
      {
        title: "1Webchat",
        url: "/docs/chatbots/1webchat/default-replies",
        match: "/docs/chatbots/1webchat"
      }
    ];
    const loc = this.props?.location?.pathname || "";
    return (
      <div className="nav-breadcrumb">
        <div className="container">
          <ul className="list-item">
            {navItems.map(item => (
              <li key={item.title}>
                <Link
                  to={item.url}
                  className={
                    loc.toLowerCase().includes(item.match) ? "active" : ""
                  }
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(ChatbotsCategoryNavBar);
