import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import Footer_v2 from "../common/footer_v2";
import chatDocImage from "../../../public/resources/site/images/chat-docs-image.svg";
import integrateImage from "../../../public/resources/site/icons/join-inner.svg";
import githubImage from "../../../public/resources/site/icons/github.svg";
import demoImage from "../../../public/resources/site/images/demos-image-01.svg";
import DocCategoryNavBar from "./docCategoryNavBar";
import config from "../../../config/config";

class DocsChatHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selTab: "ios"
    };
    this.onTabItemClick = this.onTabItemClick.bind(this);
  }

  onTabItemClick(e, item) {
    e.preventDefault();
    this.setState({ selTab: item });
  }

  renderBannerSection() {
    return (
      <div className="tutorials-img-block">
        <div className="container">
          <div className="tutorials-content-block">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="title-content mb-md-0">
                  <h1 className="font-lg">Chat SDK Docs</h1>
                  <div className="info-content">
                    <p>
                      Build a messaging experience that provides the latest and
                      most essential features with In-App Chat API.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="image-block text-md-right">
                  <img src={chatDocImage} alt="Chat Docs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChatDocItemSection() {
    const { selTab } = this.state;
    const items = [
      {
        id: "ios",
        title: "iOS",
        icon: "icon-apple",
        url: "/docs/chatsdk/ios",
        disabled: false,
        sampleAppRepo: config.IOS_SDK_SAMPLE_REPO
      },
      {
        id: "android",
        title: "Android",
        icon: "icon-android",
        url: "/docs/chatsdk/android",
        sampleAppRepo: config.ANDROID_SDK_SAMPLE_REPO
      },
      {
        id: "js",
        title: "JavaScript",
        icon: "icon-js",
        url: "/docs/chatsdk/javascript",
        disabled: true,
        sampleAppRepo: ""
      }
    ];

    return (
      <div className="content-left-block documentation-tab-block pt-0">
        <div className="container">
          <h3>Documentation</h3>
          <div className="documentation-tab">
            <nav>
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                {items.map(item => (
                  <a
                    key={item.id}
                    className={`nav-link ${selTab === item.id ? "active" : ""}`}
                    href={`#${item.id}`}
                    onClick={e => this.onTabItemClick(e, item.id)}
                  >
                    <em className={`i-icon ${item.icon}`} />
                    {item.title}
                  </a>
                ))}
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              {items.map(item => (
                <div
                  key={item.id}
                  className={`tab-pane fade ${
                    selTab === item.id ? "show active" : ""
                  }`}
                >
                  <div className="card-items">
                    <div className="card-item">
                      <div className="inner-content">
                        <div className="card-title">
                          <img src={integrateImage} alt="integrate" />
                          Integrate
                        </div>
                        <div className="info-content">
                          {`Learn to integrate Chat SDK for ${item.title}`}
                        </div>
                        {item.disabled ? (
                          <a href="#" className="coming-soon">
                            Coming Soon
                          </a>
                        ) : (
                          <Link to={item.url} className="view-link-orange">
                            Learn more...
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="card-item">
                      <div className="inner-content">
                        <div className="card-title">
                          <img src={githubImage} alt="github" />
                          {`${item.title} Sample app`}
                        </div>
                        <div className="info-content">
                          {`${item.title} repository on GitHub`}
                        </div>
                        {item.sampleAppRepo ? (
                          <a
                            href={item.sampleAppRepo}
                            className="view-link-orange"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Visit the link
                          </a>
                        ) : (
                          <a href="#" className="coming-soon">
                            Coming Soon
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDemoSection() {
    return (
      <div
        className="content-left-block demos-block pt-0"
        style={{ display: "none" }}
      >
        <div className="container">
          <h3 className="mb-4">Demos</h3>
          <div className="demo-content-block">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="demo-content-item">
                  <div className="card-image">
                    <img src={demoImage} alt="In-App Chat demo" />
                  </div>
                  <div className="card-content">
                    <h4>In-App Chat</h4>
                    <div className="info-content">
                      <p>
                        Create a full-featured chat experience for your users
                        that's seamless and secure.
                      </p>
                    </div>
                    <div className="text-right">
                      <a
                        href="#"
                        onClick={e => e.preventDefault()}
                        className="view-link-purple"
                        title="Coming Soon"
                      >
                        Coming Soon <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRelatedSection() {
    const relatedItems = [
      /* {
        title: "UIKit for Chat",
        description:
          "Quickly integrate standard chat features into your app. Customize individual and overall theme components such as fonts and colors.",
        routePath: "/docs/uikit"
      }, */
      {
        title: "Chatbots",
        description:
          "Create a chatbot to automate duties and workflows, or orchestrate multiple chatbots to function together in one app.",
        routePath: "/docs/chatbots"
      }
    ];

    return (
      <div className="content-left-block Related-block pt-0">
        <div className="container">
          <h3 className="mb-4">Related</h3>
          <div className="related-items">
            <div className="row">
              {relatedItems.map(item => (
                <div className="col-md-6" key={item.title}>
                  <Link to={item.routePath} className="related-item">
                    <h4 className="title-text">{item.title}</h4>
                    <div className="info-content">
                      <p>{item.description}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <DocCategoryNavBar />
        <main className="content-wrapper main-content">
          {this.renderBannerSection()}
          {this.renderChatDocItemSection()}
          {this.renderDemoSection()}
          {this.renderRelatedSection()}
        </main>
        <Footer_v2 />
      </>
    );
  }
}

export default DocsChatHome;
