import React from "react";

const TitleFeatureBox = ({
  title,
  description,
  titleBelowIcon,
  titleClass = "",
  imgClass,
  imgSrc,
  lg,
  wowDelay,
  title2 = "",
  title2Class = "",
  imgAlt = ""
}) => {
  let currentlg = "4";
  if (lg != undefined || lg != null) {
    currentlg = lg;
  }
  const titleDiv = (
    <div className={`name ${titleClass}`}>
      {typeof title === "function" ? title() : title}
    </div>
  );
  const title2Div =
    title2 !== "" ? (
      <div className={`name ${title2Class}`}>
        {typeof title2 === "function" ? title2() : title2}
      </div>
    ) : null;
  return (
    <div
      className={`col-12 col-sm-12 col-md-6 col-lg-${currentlg} wow fadeInUp`}
      data-wow-delay={wowDelay}
    >
      <div className="icon-box-block">
        <div className="box-title">
          <div className="icon">
            <img className={imgClass} src={imgSrc} alt={imgAlt || title} />
          </div>
          {title2Div}
          {!titleBelowIcon && titleDiv}
        </div>
        {titleBelowIcon && titleDiv}
        {description && <p>{description}</p>}
      </div>
    </div>
  );
};

export default TitleFeatureBox;
