import React from "react";
import HashAnchor from "../../../../common/hashAnchor";
import Note from "./note";
import CodeBlock from "../../codeBlock";

import IOSDiagram from "../../../../../public/images/ios-diagram.png";

const CODE_EXAMPLES = [
  `repositories {
    mavenCentral()
    maven { url "http://artifactory.pantepic.com/artifactory/ext-sdk-new" }
 }`,
  `implementation ('com.ripbull.coresdk:ripbull-chat-sdk:1.1.1.release-rc1@aar'){
    transitive = true
 }`,
  `compileOptions {
  sourceCompatibility JavaVersion.VERSION_1_8
  targetCompatibility JavaVersion.VERSION_1_8
}`,
  `android.useAndroidX=true
`,
  `try {
// set your api key here
val config =
Configuration.Builder().context(this).apiKey("edu6qgwsjxxxxxApiKey").build()

// SDK initialize
eRTCSDK.initializeWithConfig(config)

} catch (Exception e) {
    // Handle any exceptions
    e.printStackTrace();
}`,
  `disposable.add(
  eRTCSDK.tenant().validate(namespace)
    .subscribeOn(Schedulers.io()).observeOn(
      AndroidSchedulers.mainThread()
    ).subscribe(
      Consumer<Result> { this.success(it) }, error
    )
)`,
  `disposable.add(
  eRTCSDK.tenant().login(AccountDetails.username(username, password))
    .subscribeOn(Schedulers.io()).observeOn(
      AndroidSchedulers.mainThread()
    ).subscribe({ this.success(it, username) }, error)
)`,
  `disposable.add(
  eRTCSDK.tenant().changePassword(
    AccountDetails.changePwd(
      oldPassword, newPassword
    )
  ).subscribeOn(Schedulers.io()).observeOn(
    AndroidSchedulers.mainThread()
  ).subscribe(
    Consumer<Result> { this.success(it) }, error
  )
)`,
  `disposable.add(
  eRTCSDK.tenant().forgotPassword(AccountDetails.forgotPwd(namespace))
    .subscribeOn(Schedulers.io()).observeOn(
      AndroidSchedulers.mainThread()
    ).subscribe(Consumer<Result> { this.success(it) }, error
  )
)`,
  `disposable.add(
  eRTCSDK.user().chatUsers
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onUserListSuccess,
      this::onUserListError
    )
)`,
  `disposable.add(
  eRTCSDK.user().mentionedUsers
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ userRecords ->

    }, { throwable ->

    })
)`,
  `disposable.add(
  eRTCSDK.user().getReactionedUsers(reactionUnicodes, msgId, threadID, ChatType.CHAT_THREAD)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ userRecords ->

    }, { throwable ->

    })
)`,
  `disposable.add(
  eRTCSDK.user().logout().subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
      //clear session locally
    }, { throwable ->
      //error
    })
)`,
  `disposable.add(
  eRTCSDK.user()
    .subscribeToUserMetaData()
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ userRecord ->

    }, { throwable ->

    })
)`,
  `disposable.add(
  eRTCSDK.user().getUserById(userId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onUserSuccess,
      this::onUserError
    )
)`,
  `disposable.add(
  eRTCSDK.user().updateProfile(
    profileStatus,
    this.filePath.let { if(it.isNullOrEmpty()) "" else it },
    AttachmentManager.MediaType.IMAGE.toString()
  )
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
      //Success
    }, { throwable ->

    })
)`,
  `disposable.add(   eRTCSDK.user().removeProfilePic()
.subscribeOn(Schedulers.io())
.observeOn(AndroidSchedulers.mainThread())
.subscribe({
  //Success
}, { throwable ->

})
)`,
  `disposable.add(
  eRTCSDK.notification()
    .muteNotifications(action, MuteNotificationType.USER_LEVEL)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ dataString->
      //Success
    }, { throwable ->
    })
)`,
  `disposable.add(
  eRTCSDK.notification().getSettings()
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ dataString->
      dataString?.let { notificationSettings ->
        //set notification settings on UI
      }
    }, { throwable ->
      //Error handling
    })
)`,
  `disposable.add(   eRTCSDK.user()
.metaDataOn(ERTCApplication.getSession(activity!!).getUserId())
.subscribeOn(Schedulers.io())
.observeOn(AndroidSchedulers.mainThread())
.subscribe({ userMetaDataRecord ->
  userMetaDataRecord.notificationSettings?.let { notificationSettings ->
    //set notification settings on UI
  }
}, { throwable ->

})
)`,
  `disposable.add(
  eRTCSDK.user().setUserAvailability(status)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
      //Success
    }, { throwable ->
      //Error
    })
)`,
  `eRTCSDK.user().userAvailabilityStatus`,
  `val groupRecord = GroupRecord(
  groupName,
  groupDesc,
  this.filePath.let { if(it.isNullOrEmpty()) "" else it },
  selectedUser
)

//Create Private Group
disposable.add(
  eRTCSDK.group().createPrivateGroup(groupRecord)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onGroupCreateSuccess, this::onGroupCreateError
    )
)

//Create Public Group
disposable.add(
  eRTCSDK.group().createPublicGroup(groupRecord)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onGroupCreateSuccess, this::onGroupCreateError
    )
)`,
  `disposable.add(
  eRTCSDK.group().groups
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onListSuccess, this::onListError
    )
)`,
  `disposable.add(
  eRTCSDK.group().getGroupById(groupId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ groupRecord ->
      //Success
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(eRTCSDK.group().updateGroupDetail(
  groupId,
  groupName,
  groupDesc,
  this.filePath.let { if(it.isNullOrEmpty()) "" else it }
).subscribeOn(Schedulers.io())
  .observeOn(AndroidSchedulers.mainThread())
  .subscribe(
    this::onGroupUpdateSuccess, this::onGroupUpdateError
  )
)`,
  `disposable.add(
  eRTCSDK.group().addParticipants(groupId, userList)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ groupRecord ->
      //Success
    {,{ throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.group().removeParticipants(groupId, userList)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ groupRecord ->
      //Success
    },{ throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.group().addAdmin(groupId, userId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ groupRecord ->
      //Success
    },{ throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.group().removeAdmin(groupId, userId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ groupRecord ->
      //Success
    },{ throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.group().exitGroup(groupId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ groupRecord ->
      //Success
    },{ throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.group().removeGroupPic(groupDetail?.groupId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
      //Success
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.group().getSearchedChannels(searchedText, channelType, joined)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ channelList ->
      //Success
    },{ throwable ->
      //Error
    })
)`,
  `disposable.add(   eRTCSDK.group()
.subscribeToGroupUpdate()
.subscribeOn(Schedulers.io())
.observeOn(AndroidSchedulers.mainThread())
.subscribe({ groupRecord ->
  //Success
},{ throwable ->
  //Error
})
)`,
  `disposable.add(
  eRTCSDK.chat().createThread(appUserId)
    .subscribeOn(Schedulers.io()).observeOn(
      AndroidSchedulers.mainThread()
    ).subscribe({ threadId ->
      //store threadId and use it in application
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat().getThreads()
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onThreadListSuccess, this::onThreadListError
    )
)`,
  `// For TEXT
val message = Message(
  message = textMessage,
  chatType = chatType,
  mentions = mention,
  mentioned_users = mentionList
)

// IMAGE
val message = Message(
  media = Media(filePath, MessageType.IMAGE),
  chatType = chatType
)

// GIF
val message = Message(
  media = Media(filePath, MessageType.GIF),
  chatType = chatType
)

// VIDEO
val message = Message(
  media = Media(filePath, MessageType.VIDEO),
  chatType = chatType
)

// AUDIO
val message = Message(
  media = Media(filePath, MessageType.AUDIO),
  chatType = chatType
)

// FILE
val message = Message(
  file = com.ripbull.coresdk.chat.model.File(filePath, MessageType.FILE),
  chatType = chatType
)

// CONTACT
val message = Message(
  contact = Contact(contactName, phoneRecords, emailRecords, CONTACT),
  chatType = chatType
)

// LOCATION
val message = Message(
  location = Location(address, latLng.latitude, latLng.longitude, LOCATION),
  chatType = chatType
)

// GIPHY
val message = Message(
  giphy = Giphy(url, GIPHY),
  chatType = chatType
)`,
  `disposable.add(
  eRTCSDK.chat()
    .sendMessage(threadID!!, message)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ messageRecord ->
      //add this message on your list and update listview.
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat().getMessages(threadID)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onMessageListSuccess, this::onMessageListError
    )
)`,
  `disposable.add(
  eRTCSDK.chat().getChatThreadMessages(threadID, parentMsgId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onMessageListSuccess, this::onMessageListError
    )
)`,
  `disposable.add(
  eRTCSDK.chat()
    .sendReaction(
      threadID,
      messageMetaData
    )
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ chatReactionRecord ->
      //use this data and update UI(list or recyclerview)
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat().messageMetaDataOn(threadID!!)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ messageMetaDataRecord ->
      messageMetaDataReord.chatReactionRecord?.let { it ->
        //update UI when you will receive reaction record via MQTT
      }
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat().sourceOnMain()
    .filter(ChatEvent.filterType(EventType.THREAD_MESSAGE_METADATA))
    .filter { it.threadId == threadID }
    .subscribe({ chatEvent ->
      chatEvent.threadMetadata?.let { it ->
        //update UI with latest thread meta data
      }
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat()
    .getMessage(threadId, forwardMessageId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ messageRecord ->
      //Success
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat()
    .getChatThreadMessage(threadId, forwardMessageId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ messageRecord ->
      //Success
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat()
    .forwardChat(messageList, contactIdList, groupThreadIdList, chatType)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
      //Success
    }, { throwable ->
      //Error
    })
)`,
  `/* there are two option for outgoing message deleteForMe and deleteForEveryOne And one option for incoming message is delete for me*/
disposable.add(
  eRTCSDK.chat()
    .deleteMessage(deleteType, threadId, messageRecords)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ messageRecords ->
      //update all these record’s UI to message is deleted
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat()
    .editMessage(threadID, message)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ messageRecord ->
      //update UI of edited message in list or recyclerview
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat().chatRestore()
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
      //Success
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat().chatSkipRestore()
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
      //Success
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat()
    .searchMessages(searchedText)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onSearchedListSuccess, this::onSearchedListError
    )
)`,
  `disposable.add(
  eRTCSDK.chat()
    .globalSearch(searchedText)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onSearchedListSuccess, this::onSearchedListError
    )
)`,
  `//you can use this method for thread also.
//In that case you have to pass parentMsgId in the second parameter.

eRTCSDK.chat()
  .markAsRead(it, "")
  .subscribeOn(Schedulers.io())
  .observeOn(AndroidSchedulers.mainThread())
  .subscribe({
  }, {
    it.printStackTrace()
  })`,
  `//you can use this method for thread also.
//In that case you have to pass parentMsgId in the second parameter.

disposable.add(
  eRTCSDK.chat()
    .msgReadStatus(threadID, "", chatType)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ networkEvet ->
      //notify message status changed(update UI)
      markAsRead()
    }, { throwable ->
      //Error
    })
)`,
  `//you can use this method for thread also.
//In that case you have to pass parentMsgId in the second parameter.

disposable.add(
  eRTCSDK.chat()
    .messageOn(threadID, "", chatType)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ networkEvet ->
      networkEvent.messageRecord()
      //add this message record in your list or recycler view and update UI
    }, { throwable ->
      //Error
    })
)`,
  `//for the main chat window or you can use this method to get notification also by this method you will be notified on every message.

disposable.add(
  eRTCSDK.chat()
    .messageOn()
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .filter(NetworkEvent.filterType(EventType.MESSAGE_UPDATED)).subscribe ({
      val messageRecord = it.messageRecord()
      if(messageRecord.updateType == MessageUpdateType.DELETE){
        // update UI for delete
      } else if (messageRecord.updateType == MessageUpdateType.EDIT){
        // update UI for edit
      } else if (messageRecord.updateType == MessageUpdateType.FAVORITE{
        // update UI for favorite
      }
    },{ throwable ->
      //Error
    })
)`,
  `val action: String = if (user.blockedStatus.equals(AppConstants.USER_BLOCKED)) {
  "unblock"
} else {
  “block”
}

disposable.add(
  eRTCSDK.chat()
    .blockUnblock(action, user.id!!)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
      //update UI on success
    }, { throwable ->
      //Error
    })
)`,
  `/***********
msgFavList = ArrayList<MessageRecord>
isFavorite = true/false
***********/

disposable.add(
  eRTCSDK.chat()
    .markAsFavorite(threadId, msgFavList, isFavorite)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ messageList ->
      //update UI on success
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat()
    .getAllFavoriteMessages()
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onMessageListSuccess, this::onMessageListError
    )
)`,
  `disposable.add(
  eRTCSDK.chat()
    .getAllFavoriteMessages(threadID)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe(
      this::onMessageListSuccess, this::onMessageListError
    )
)`,
  `disposable.add(
  eRTCSDK.typing()
    .subscribe(threadID)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ typingIndicatorRecord ->
      //you got typing indication for this thread id so you have to display it on UI
    }, { it.printStackTrace() })
)`,
  `//when you are typing you have to execute this method

disposable.add(
  eRTCSDK.typing()
    .publish(threadID, state)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ }, { it.printStackTrace() })
)`,
  `disposable.add(
  eRTCSDK.chat()
    .downloadMedia(msgId, serverUrl, dirPath, mediaType)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({
    }, { throwable ->
      //Error
    })
)`,
  `//you can use this method for thread also.
//in that case you have to pass parentMsgId in the second parameter.

disposable.add(
  eRTCSDK.chat()
    .downloadOn(threadID, "", chatType)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ networkEvet ->
      //Update UI with download status(make it completed)
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.notification()
    .muteNotifications(action, MuteNotificationType.THREAD_LEVEL, it, chatType)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ muteSetting ->
      //update UI
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.notification()
    .getSettings(threadID)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ muteSetting ->
      //update UI
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat()
    .chatMetaDataOn(threadID)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ muteSetting ->
      //update UI
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat().followThread(threadId, messageRecord, isFollowed)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ messageRecord ->
      //update UI
    }, { throwable ->
      //Error
    })
)`,
  `disposable.add(
  eRTCSDK.chat().getFollowThreads(threadId)
    .subscribeOn(Schedulers.io())
    .observeOn(AndroidSchedulers.mainThread())
    .subscribe({ list ->
      //update UI
    }, { throwable ->
      //Error
    })
)`,
  `eRTCSDK.registerFCMToServer()`,
  `val intent = Intent(applicationContext, MainActivity::class.java)
intent.flags = Intent.FLAG_ACTIVITY_SINGLE_TOP or Intent.FLAG_ACTIVITY_CLEAR_TOP
eRTCSDK.getIntentForFCM(intent)`,
  `overridefunonNewIntent(intent: Intent?) {
  super.onNewIntent(intent)  if (intent != null) {    eRTCSDK.fcm().clearNotification()  }}`
];

const AndroidChatSDKContent = () => {
  const renderOverviewContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="overview" offset={-150}>
          <h2 className="h3">Overview</h2>
        </HashAnchor>
        <p className="mb-4">
          InApp Chat SDK: employing true efficiency and ease.
        </p>
        <p className="mb-4">
          The following principles are integral in InApp Chat-
        </p>
        <ul className="field-list">
          <li>
            Modularity: Code is segregated into multiple modules and follows a
            chain of responsibility model
          </li>
          <li>
            Ease of Integration: Maven artifacts can be downloaded from a public
            hosted URL. InApp Chat SDK can be integrated into any mobile app
            within minutes
          </li>
          <li>
            Scalability: SDK is highly scalable and can support Enterprise
            traffic
          </li>
          <li>
            Data ownership: SDK provides a data layer to effectively manage and
            control data
          </li>
          <li>Firebase powered by Google: Highly reliable and scalable</li>
          <li>Reactive using RxAndroid</li>
        </ul>
        <h3>Features</h3>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>Individual messages</li>
              <li>Push notifications</li>
              <li>
                Text, Image, Video, Audio, Location, Contact and gif messages
              </li>
              <li>Typing Indicators</li>
              <li>Delivered and Read receipts</li>
              <li>Chat history</li>
              <li>Chat user list</li>
              <li>Contact details</li>
              <li>E2E Encryption</li>
              <li>Public/Private Channel</li>
              <li>Thread Chat</li>
              <li>Favorite Messages</li>
              <li>Chat Reaction</li>
              <li>Follow/Unfollow Thread</li>
              <li>Forward Chat</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  const renderQuickstart = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="quickstart" offset={-150}>
          <h2 className="h3 mb-3">Quickstart</h2>
        </HashAnchor>
        <p className="mb-4">
          To start with an existing app, just initialize the SDK in your
          Application’s onCreate() method and start using the SDK features.
        </p>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                Please note that we are using Java 8 in our SDK, so you must add
                Java 8 support in compile options
              </li>
              <li>
                We are using AndroidX in our SDK, so you must add AndroidX
                support in gradle.properties
              </li>
            </ul>
          </li>
        </ul>
        <p className="mb-4">
          Once you do these prerequisites, you are ready to start using our SDK!
        </p>
      </div>
    </div>
  );

  const renderHowtosContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="how-to-add-sdk" offset={-150}>
          <h2 className="h3 mb-3">How-Tos</h2>
        </HashAnchor>
        <p className="mb-2">How to add InApp Chat SDK to your project?</p>
        <p className="mb-2">
          Integration with an existing project is simple. You can add the core
          libraries using Gradle.
        </p>
        <h3>Gradle</h3>
        <p className="mb-2">
          To download the SDK, you will need to include the repository manually:
        </p>
        <CodeBlock code={CODE_EXAMPLES[0]} language="java" />
        <p className="mb-2">
          Then add this to your <span className="highlight">dependencies</span>{" "}
          area:
        </p>
        <CodeBlock language="java" code={CODE_EXAMPLES[1]} />
        <p className="mb-2">You also need to enable Java 8:</p>
        <CodeBlock language="java" code={CODE_EXAMPLES[2]} />
        <h3>Android X</h3>
        <p className="mb-2">
          Make sure you've added the following to your{" "}
          <span className="highlight">gradle.properties</span> file:
        </p>
        <CodeBlock language="java" code={CODE_EXAMPLES[3]} />
        <h3>How to initialize InApp Chat SDK to start using it?</h3>
        <HashAnchor id="how-to-initialise-sdk" offset={-150}>
          <h2 className="h3 mb-3">Initializing the Chat SDK</h2>
        </HashAnchor>
        <p className="mb-2">
          Now open your application’s main class and find the{" "}
          <span className="highlight">onCreate</span> method. Add the following
          to setup the SDK:
        </p>
        <CodeBlock language="java" code={CODE_EXAMPLES[4]} />
        <table className="table-border w-100">
          <tr>
            <td>API</td>
            <td>apiKey</td>
          </tr>
          <tr>
            <td>parameter</td>
            <td>
              To initialize the SDK, we have to add parameters: <br />
              -&nbsp;&nbsp;<strong>ApiKey:</strong> will be sent to you in your
              mailbox
              <br />
              -&nbsp;&nbsp;<strong>Namespace:</strong> will be sent to you in
              your mailbox
              <br />
              -&nbsp;&nbsp;<strong>Context:</strong> will be sent to you in your
              mailbox
            </td>
          </tr>
        </table>
      </div>
    </div>
  );

  const render1ChatbotContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="onechatbot" offset={-150}>
          <h2 className="h3">Using 1Chatbot with the SDK</h2>
        </HashAnchor>
        <p className="mt-4 mb-4">
          Once a 1Chatbot agent has been created in the portal the chatbot is
          considered as a user within the eRTC SDK.
        </p>
        <p className="mt-4 mb-2">
          Some functions will not apply to 1Chatbot such as:
        </p>
        <ul className="field-list">
          <li>
            <h3>User Login</h3>
          </li>
          <li>
            <h3>Change password</h3>
          </li>
        </ul>
        <p className="mt-4 mb-2">
          Special consideration should be given to other functions such as:
        </p>
        <ul className="field-list">
          <li>
            <strong>Get user details: </strong>The response will include isBot =
            true/false which allows for handling of the bot in various ways. For
            example: choose whether or not to label the 1Chatbot agent as a bot
            in the group chat.
          </li>
          <li>
            <strong>Send message: </strong>1Chatbot agents only support Text
            Messages with the type MessageType.Text.
          </li>
        </ul>
      </div>
    </div>
  );

  const renderAPIContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="api-doc" offset={-150}>
          <h2 className="h3">API Documentation</h2>
        </HashAnchor>
        <h2>Tenant Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Namespace validation</h3>
                <p className="mt-4 mb-4">
                  This will validate the namespace information provided by the
                  user and return Boolean value if information is validated or
                  not.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[5]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>Validate(namespace)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>namespace:</strong> Namespace information will be
                      sent to you in your mailbox.
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>User Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>User Login</h3>
                <p className="mt-4 mb-4">
                  After a namespace validation user logs in with valid
                  credentials, these credentials will be received in their
                  mailbox along with namespace information.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[6]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>login(username:string, password:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>Username:</strong> your email id
                      <br />
                      <strong>Password:</strong> your password
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Change password</h3>
                <p className="mt-4 mb-4">
                  With this api user can change his/her existing password to a
                  new password.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[7]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      changePassword(oldPassword:string, newPassword:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>oldPassword:</strong> enter your old password
                      <br />
                      <strong>newPassword:</strong> enter your new password
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Forgot password</h3>
                <p className="mt-4 mb-4">
                  This will trigger a new password to the provided email
                  address.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[8]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>forgetPassword(emailID:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>emailID:</strong> your username(in your case it is
                      your email ID)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Chat Users</h3>
                <p className="mt-4 mb-4">
                  This api call returns all the chat users data for the logged
                  in users.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[9]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>chatUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Mentioned users</h3>
                <p className="mt-4 mb-4">
                  This api call returns all the mentioned users data for the
                  logged in users.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[10]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Reaction Users</h3>
                <p className="mt-4 mb-4">
                  This api call returns all the reaction users data for the
                  logged in users.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[11]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getReactionedUser</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>Reaction Unicode:</strong> Ex: 👍
                      <br />
                      <strong>message ID:</strong> messageId from Message Record
                      <br />
                      <strong>Thread ID:</strong> threadID of User/Group
                      <br />
                      <strong>ChatType:</strong> ChatType.CHAT_THREAD
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Logout</h3>
                <p className="mt-4 mb-4">
                  This will log out the user and invalidate the current ongoing
                  session.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[12]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>
                  User Metadata On – MQTT subscription (To get user updates in
                  real time)
                </h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="java" code={CODE_EXAMPLES[13]} />
              </li>
              <li>
                <h3>Get user details</h3>
                <p className="mt-4 mb-4">
                  To get the user profile details use the following api call:
                </p>
                <Note>
                  The response will include isBot = true/false which allows for
                  handling of the bot in various ways. For example: choose
                  whether or not to label the 1Chatbot agent as a bot in the
                  group chat.
                </Note>
                <CodeBlock language="java" code={CODE_EXAMPLES[14]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getUserById(userID:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>userId:</strong> emailId
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Update user profile (with & without profile image)</h3>
                <p className="mt-4 mb-4">
                  To update the profile update and profile image use the
                  following api call:
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[15]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>updateProfile(profileStatus:strin g,image:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>profileStatus:</strong> ex-I am using InApp Chat!
                      <br />
                      <strong>filePath:</strong> profile picture path
                      <br />
                      <strong>mediaType:</strong> it is IMAGE
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove profile pic</h3>
                <p className="mt-4 mb-4">
                  To remove the profile image use the following api call:
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[16]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeProfilePic</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Set Global notification settings</h3>
                <p className="mt-4 mb-4">
                  This is used to set global notification settings. Global level
                  is used to set notification at application level.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[17]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>muteNotification</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>action:</strong> allow/mention/none
                      <br />
                      <strong>MuteNotificationType:</strong>{" "}
                      MuteNotificationType.USER_LEVEL
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get global notification settings</h3>
                <p className="mt-4 mb-4">
                  This is used to get global notification settings.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[18]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>metaDataOn(Global notification setting)</h3>
                <p className="mt-4 mb-4">
                  This one is used to update notification settings in real time.
                  It is used to update the UI for parallel devices(Multi login.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[19]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>metaDataOn(userId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>userId:</strong> Your UserID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Set user availability status</h3>
                <p className="mt-4 mb-4">
                  This is used to set the user's availability status. Status can
                  be of 4 types.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[20]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>setUserAvailability</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>Status:</strong> AUTO, AWAY, INVISIBLE & DND
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get user availability status</h3>
                <p className="mt-4 mb-4">
                  This is used to get user availability status pings.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[21]} />
              </li>
            </ul>
          </li>
        </ul>
        <h2>Group Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Create Channel</h3>
                <p className="mt-4 mb-4">
                  This is used to create a new channel. In order for this call
                  to run, the user must provide channel name, description,
                  participants, and channel types.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[22]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      createPrivateGroup(groupRecord: GroupRecord)
                      <br />
                      createPublicGroup(groupRecord: string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupRecord:</strong> GroupRecord
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get all channels</h3>
                <p className="mt-4 mb-4">
                  This is used to get all channels for a given user. This is a
                  list where the current user is a participant of the called
                  channel.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[23]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>groups</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get channel info by groupId</h3>
                <p className="mt-4 mb-4">This is used to get channel info.</p>
                <CodeBlock language="java" code={CODE_EXAMPLES[24]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getGroupById(groupId: string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> it is groupID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Update Channel details (Only Admin can update)</h3>
                <p className="mt-4 mb-4">
                  This api is used to update the channel information.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[25]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      updateGroupDetail(groupId:string,
                      groupName:string,groupDesc:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> groupID
                      <br />
                      <strong>groupName:</strong> group Name
                      <br />
                      <strong>groupDesc:</strong> group Description
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Add participants</h3>
                <p className="mt-4 mb-4">
                  This is used to add participants for a channel. Channel unique
                  id and new participant details are required for this call.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[26]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>addParticipants(groupId:string, userList:List)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                      <br />
                      <strong>userList:</strong> list of participant(user)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove participants</h3>
                <p className="mt-4 mb-4">
                  This is used to remove participants from a channel.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[27]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeParticipants(groupId:string, userList:List)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                      <br />
                      <strong>userList:</strong> list of participant(user)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Add admin</h3>
                <p className="mt-4 mb-4">
                  This is used to create users as admin.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[28]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>addAdmin(groupId:string, userId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                      <br />
                      <strong>userId:</strong> user id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove admin</h3>
                <p className="mt-4 mb-4">
                  This api is used to remove users as admin.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[29]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeAdmin(groupId:string, userId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                      <br />
                      <strong>userId:</strong> user id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Exit channel</h3>
                <p className="mt-4 mb-4">This api is used to exit a channel.</p>
                <CodeBlock language="java" code={CODE_EXAMPLES[30]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>exitGroup(groupId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove channel picture</h3>
                <p className="mt-4 mb-4">
                  This api is used to remove channel pictures.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[31]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeGroupPic(groupId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>groupId:</strong> group id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Search Channels</h3>
                <p className="mt-4 mb-4">
                  This api is used to search channels.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[32]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getSearchedChannels</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>searchedText:</strong> Ex-Dev
                      <br />
                      <strong>channelType:</strong> private/public(optional)
                      <br />
                      <strong>Action:</strong> optional
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Group Metadata On</h3>
                <p className="mt-4 mb-4">
                  This function will return a group record in real time. Users
                  need to add this function when he/she needs a realtime group
                  updated on the device.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[33]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Chat Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Create Thread</h3>
                <p className="mt-4 mb-4">
                  To initiate a chat between two users, first a thread must be
                  created. A thread id is then returned on successful creation
                  and any further chat message is posted to that unique thread
                  id. If there is a thread that already exists and createThread
                  is called, the existing thread id will be returned.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[34]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>creatThread(appUserId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>appUserId:</strong> it is user’s email id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Threads</h3>
                <p className="mt-4 mb-4">This api is used to get threads.</p>
                <CodeBlock language="java" code={CODE_EXAMPLES[35]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getThread</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Send Message</h3>
                <p className="mt-4 mb-4">
                  A chat between two users is considered a thread of
                  communication. To initiate a chat between users, you need to
                  create threads and send messages over that threadID.
                </p>
                <Note>
                  1Chatbot agents only support Text Messages with the type
                  MessageType.Text.
                </Note>
                <CodeBlock language="java" code={CODE_EXAMPLES[36]} />
                <CodeBlock language="java" code={CODE_EXAMPLES[37]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>sendMessage(threadId:string, message:Message)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>message:</strong> sending message object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Messages</h3>
                <p className="mt-4 mb-4">
                  This api is used to get messages. This is used to fetch the
                  main chat history. Here we have used pagination.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[38]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getMessage(threadId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get ChatThread Messages</h3>
                <p className="mt-4 mb-4">
                  This api is used to get chat thread messages. This is used to
                  fetch chat thread history. Here we use pagination.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[39]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      getChatThreadMessages(threadId:string, parentMsgId:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>parentMsgId:</strong> from that message thread is
                      initiated. that message’s message id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Send Reaction</h3>
                <p className="mt-4 mb-4">
                  This api is used to send reactions on received or sent
                  messages.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[40]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      sendReaction(threadId:string,
                      messageMetaData:MessageMetaData)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> it is user’s threadID
                      <br />
                      <strong>messageMetaData:</strong> MessageMetaData
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message metadata On</h3>
                <p className="mt-4 mb-4">
                  This api is used to turn your message metadata on.It is used
                  to update messages in real time. When a user sends a
                  message,this function enables the reception of that message.
                  This function adds the message in your recycler view and
                  updates the UI in real time.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[41]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>messageMetaDataOn(threadId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> it is user’s threadID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Thread data update on main thread</h3>
                <p className="mt-4 mb-4">
                  This api is used to update thread data on the main thread.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[42]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Message by messageId</h3>
                <p className="mt-4 mb-4">
                  This api is used to get messages by messageId from main chat.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[43]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getMessage(threadId:string, messageId:string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>messageId:</strong> message id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get chat thread message by messageId</h3>
                <p className="mt-4 mb-4">
                  This api is used to get chat thread messages by messageId from
                  thread messages.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[44]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      getChatThreadMessage(threadId:strin g,messageId:string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>messageId:</strong> message id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Forward chat</h3>
                <p className="mt-4 mb-4">
                  This api is used to forward a chat. You can forward a message
                  to multiple users and channels.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[45]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      {`forwardChat(message:Message,
                        userList:List<UserRecord>,
                        groupList:List<GroupRecord>,
                        chatType:ChatType)`}
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>message:</strong> Message
                      <br />
                      <strong>userList:</strong> list of users
                      <br />
                      <strong>groupList:</strong> list of groups
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Delete Message</h3>
                <p className="mt-4 mb-4">
                  This api is used to delete a message. There are two options to
                  delete outgoing messages: deleteForMe and deleteForEveryOne.
                  The only option to delete incoming messagesZ is deleteForMe.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[46]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      {`deleteMessage(deleteType: string,
                        threadId:string,
                        messageRecords:MessageRecord)`}
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>deleteType:</strong> self/everyone
                      <br />
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>messageRecords:</strong> message record object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Edit Message</h3>
                <p className="mt-4 mb-4">This api is used to edit a message.</p>
                <CodeBlock language="java" code={CODE_EXAMPLES[47]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`editMessage(threadId:string, message:Message)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>message:</strong> message object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Chat Restore</h3>
                <p className="mt-4 mb-4">
                  This api is used to restore chat functions.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[48]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>chatRestore</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Skip Chat Restore</h3>
                <p className="mt-4 mb-4">
                  This api is used to skip chat restore functions.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[49]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>mentionedUsers</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Search message(local search)</h3>
                <p className="mt-4 mb-4">
                  This api is used to search text in messages or in files
                  locally.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[50]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`searchMessage(searchedText:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>searchedText:</strong> text(Ex-address)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Search message(global search)</h3>
                <p className="mt-4 mb-4">
                  This api is used to search text in messages or in files
                  globally(from server).
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[51]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`globalSearch(searchedText:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>searchedText:</strong> text(Ex-address)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Mark as Read</h3>
                <p className="mt-4 mb-4">
                  This api is used to update the read status.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[52]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`markAsRead(threadId:string, parentMessageId: String)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadId
                      <br />
                      <strong>parentMessageId:</strong> parent message id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message Read status – MQTTListener</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="java" code={CODE_EXAMPLES[53]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`msgReadStatus(threadID:string, parentMessageId: String, chatType: ChatType)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>parentMessageId:</strong> parent message id
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message On – MQTT Listener (for normal message)</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="java" code={CODE_EXAMPLES[54]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`messageOn(threadID:string, parentMessageId: String, chatType: ChatType)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>parentMessageId:</strong> parent message id
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message On – MQTT Listener (Edit/Delete/Favorite)</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="java" code={CODE_EXAMPLES[55]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>messageOn</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Block/unblock user</h3>
                <p className="mt-4 mb-4">
                  This api call is used to block/unblock users.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[56]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`blockUnblock(action:string, userId:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>action:</strong> action to be attempt
                      <br />
                      <strong>userId:</strong> it is user’s logged-in email
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Mark as favorite</h3>
                <p className="mt-4 mb-4">
                  This api call used to mark chat(message) as
                  favorite/unfavorite.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[57]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`markAsFavorite(threadId:string, messageList: List<MessageRecord>, isFavorite: Boolean)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>messageList:</strong> message list
                      <br />
                      <strong>isFavorite:</strong> mark message as
                      favorite/unfavorite
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get all Favorite Messages</h3>
                <p className="mt-4 mb-4">
                  This api is used to return all messages marked favorite.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[58]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getAllFavoriteMessage</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get all favorite thread specific messages</h3>
                <p className="mt-4 mb-4">
                  This api call used to get the users all favorite thread
                  specific messages.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[59]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`getAllFavoriteMessage(threadID:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Typing Indicator Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Typing indicator subscription</h3>
                <p className="mt-4 mb-4">
                  It will throw you a chat event. You will display it on your
                  action bar.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[60]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`subscribe(threadID:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Typing indicator publish</h3>
                <p className="mt-4 mb-4">
                  Users have to call this function when the user starts typing
                  and stops typing. This function will turn typing state on or
                  off.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[61]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`publish(threadID:string, state:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> threadID
                      <br />
                      <strong>state:</strong> on/off
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Download Media Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Download Media</h3>
                <p className="mt-4 mb-4">This api is used to download media.</p>
                <CodeBlock language="java" code={CODE_EXAMPLES[62]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`downloadMedia(msgId:string, serverUrl:string, dirPath:string, mediaType:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>msgId:</strong> it is message in form of media
                      <br />
                      <strong>serverUrl:</strong> it is link from where media
                      supposed to be download
                      <br />
                      <strong>dirPath:</strong> it is path where downloaded
                      media to be stored
                      <br />
                      <strong>mediaType:</strong> it is type of media i.e.
                      image, video, pdf etc.
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Download Media On – MQTT Listener</h3>
                <p className="mt-4 mb-4">
                  This api call is used to download media on MQTT Listener.
                  Users can call thread through this method as well, passing
                  parentMsgId in the second parameter.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[63]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`downloadOn(threadID:string, parentMessageId:String, chatType: ChatType)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                      <br />
                      <strong>parentMessageId:</strong> parent message id
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Notification Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Mute notification</h3>
                <p className="mt-4 mb-4">
                  This api call is used to mute notification. This is the thread
                  level to alter Notification settings. You can mute a
                  particular user or channel through this method.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[64]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`muteNotifications(action:string, muteNotificationType: MuteNotificationType, timePeriod: SettingAppliedFor, threadId: String, chatType:ChatType)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>action:</strong> all/mentions/none
                      <br />
                      <strong>muteNotificationType:</strong>{" "}
                      USER_LEVEL/THREAD_LEVEL
                      <br />
                      <strong>timePeriod:</strong> always/1 Day/3 Days/1 Week/2
                      Weeks/1 Month
                      <br />
                      <strong>threadID:</strong> threadID
                      <br />
                      <strong>chatType:</strong> ChatType(single, group,
                      single_chat_thread, group_chat_thread)
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get notification settings</h3>
                <p className="mt-4 mb-4">
                  This api is used to get notification settings.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[65]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`getSettings(threadID:String)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>
                  Chat metadata on for notification setting – MQTT Listener
                </h3>
                <p className="mt-4 mb-4">
                  This api is used to get chat metadata for notification – MQTT
                  Listener. You can get a real time update on the notification
                  settings. It is used for parallel devices(Multi login case).
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[66]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`chatMetaDataOn(threadID: String)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Follow/Unfollow Thread Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Follow/Unfollow thread</h3>
                <p className="mt-4 mb-4">
                  This api is used to follow/unfollow threads.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[67]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`followThread(threadID:string, messageRecord:MessageRecord, isFollowed: Boolean)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                      <br />
                      <strong>messageRecord:</strong> Message Record Object
                      <br />
                      <strong>isFollowed:</strong> true/false
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get follow thread list</h3>
                <p className="mt-4 mb-4">
                  This api is used to get the following thread list.
                </p>
                <CodeBlock language="java" code={CODE_EXAMPLES[68]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`getFollowThreads(threadID:string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> threadID
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>FCM implementation Module</h2>
        <p className="mt-4 mb-4">
          You need FCM registration before Login API. Write the below line to
          call the method:
        </p>
        <CodeBlock language="java" code={CODE_EXAMPLES[69]} />
        <p className="mt-4 mb-4">
          For redirection to an activity, say MainActivity, You need to set
          Intent using the below code:
        </p>
        <CodeBlock language="java" code={CODE_EXAMPLES[70]} />
        <p className="mt-4 mb-4">
          To manage thread-based notification, When a user clicked on
          Notification you need to write the below piece of code(e.g. In
          MainActivity):
        </p>
        <CodeBlock language="java" code={CODE_EXAMPLES[71]} />
      </div>
    </div>
  );

  const renderResources = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="resources" offset={-150}>
          <h2 className="h3">Resources</h2>
        </HashAnchor>
        <h3 className="h3">Modules</h3>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>Core SDK module</li>
              <li>Permission module</li>
              <li>Attachment module</li>
              <li>Data Module(core-cache)</li>
              <li>MQTT module</li>
              <li>{`Core Http Module (Rest Apis => core-remote)`}</li>
              <li>Core Download</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  const renderDesignDiagram = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="design-diagram" offset={-150}>
          <h2 className="h3">Design diagram</h2>
        </HashAnchor>
        <img src={IOSDiagram} alt="ios-design-diagram" />
      </div>
    </div>
  );

  return (
    <div className="document-content chatsdk-content">
      <h1 className="h2">Android SDK</h1>
      {renderOverviewContent()}
      {renderQuickstart()}
      {renderHowtosContent()}
      {render1ChatbotContent()}
      {renderAPIContent()}
      {renderResources()}
      {renderDesignDiagram()}
    </div>
  );
};

export default AndroidChatSDKContent;
