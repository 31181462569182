import React, { Component } from "react";
import nluImg from "../../../../public/resources/site/images/nlu-connecting.svg";

class NluTitleBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tutorials-img-block pb-0">
        <div className="container">
          <div className="tutorials-content-block">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="title-content mb-0">
                  <h1 className="font-lg">NLU Connecting Guide</h1>
                  <div className="info-content">
                    Learn how to get the credentials you'll need to add your NLU
                    provider bots to In-App Chat. Once added, you can combine
                    these bots to create a 1Chatbot or widget for your site or
                    app.
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="image-block text-md-right">
                  <img src={nluImg} alt="NLU Connecting Guide" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NluTitleBlock;
