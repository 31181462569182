import React, { Component } from "react";
import DocumentationPage from "../pages/documentation";

export default function(docType) {
  return class extends Component {
    render() {
      return <DocumentationPage {...this.props} type={docType} />;
    }
  };
}
