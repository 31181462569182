import React, { Component } from "react";
import PropTypes from "prop-types";
import ProjectContextProvider from "../portal/context/projectContextProvider";
import { getHeader } from "../../utils/seoutils";

/**
 *  This container is created to separate CSS for site pages and main pages
 */

export default class PortalPageContainer extends Component {
  static propTypes = {
    wrapProjectContext: PropTypes.bool,
    className: PropTypes.string
  };

  componentDidMount() {
    setTimeout(() => window.scrollTo(0, 0), 100);
    document.body.classList.add("portal");
    document.body.classList.add("nochatbot");
    if (this.props.customBodyClass) {
      document.body.classList.add(this.props.customBodyClass);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("portal");
    document.body.classList.remove("nochatbot");
    if (this.props.customBodyClass) {
      document.body.classList.remove(this.props.customBodyClass);
    }
  }

  render() {
    let children = this.props.children;
    if (this.props.wrapProjectContext) {
      children = (
        <ProjectContextProvider>{this.props.children}</ProjectContextProvider>
      );
    }
    return (
      <div className={`${this.props.className || ""}`}>
        {getHeader(this.props.pageName)}
        {children}
      </div>
    );
  }
}
