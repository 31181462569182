import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import connect from "react-redux/es/connect/connect";
import puzzleImage from "../../../public/resources/site/images/puzzle-img.svg";
import botFactoryImage from "../../../public/resources/site/images/bot-factory.svg";
import chatWidgetImage from "../../../public/resources/site/images/chat-widget.svg";
import Footer_v2 from "../common/footer_v2";
// import { getSignupOrProjectRedirectionUrl } from "../../../utils/planUtil";
import { getLatestBlogs } from "../../../actions/blog";

class OneChatbotContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderBannerSection() {
    return (
      <div className="page-banner-section pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="content">
                <h1>A fundamental shift is taking place</h1>
                <p>
                  The Fourth Industrial Revolution has seen a rise in smart
                  machines with the ability to analyze issues, interact with
                  real people or other machines, and automate organizational
                  practices - all without the need of human intervention.
                </p>
                <p>
                  One of the primary drivers of this revolution has been the
                  need to modify and adapt in real-time to the changing needs of
                  customers. With a feature like 1Chatbot, you can provide your
                  customers an error-free experience while freeing up your team
                  to focus on other areas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAllSolutionBlocks() {
    const blocks = [
      {
        id: "1chatbot",
        title: "1Chatbot",
        iconClass: "icon-1chatbot",
        description:
          "Create powerful chatbots by combining multiple NLU providers into a single chat agent."
      },
      {
        id: "bot-factory",
        title: "Bot Factory",
        iconClass: "icon-bot-factory",
        description:
          "Do you have an idea for a bot? The bot factory is awaiting instructions on how to bring your dream bot to life!"
      },
      {
        id: "chat-widgets",
        title: "Chat Widgets",
        iconClass: "icon-chat-widget",
        description:
          "Add a simple JS chat widget to your website for basic chat automation. Colors and fonts may be modified."
      },
      {
        id: "chat-doc",
        title: "Chatbot Documentation",
        iconClass: "icon-chat-doc",
        description:
          "Visit the developer hub to find step-by-step documentation for adding your chatbots."
      }
    ];

    return (
      <div className="card-block">
        <div className="container">
          <div className="row">
            {blocks.map(item => {
              return (
                <div
                  className="col-12 col-sm-12 col-md-6 col-lg-6"
                  key={item.id}
                >
                  <div className="card solution-card">
                    <div className="card-body">
                      <h2 className="card-title">
                        <em className={`i-icon ${item.iconClass}`} />
                        {item.title}&nbsp;
                        <span style={{ fontSize: "12px" }}>(Coming Soon)</span>
                      </h2>
                      <p className="card-text">{item.description}</p>
                      <div className="card-action">
                        {/* inappdisable <a href={`#${item.id}`} className="link orange-link stretched-link">Learn More{" "}<em className="i-icon icon-right-arrow-orange" /></a> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  render1ChatbotSection() {
    return (
      <div className="content-left-block" id="1chatbot">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="content">
                <h2>
                  <span className="text-lightorange">1ChatBot:</span> The future
                  of Automated Chat
                </h2>
                <p className="desc">
                  By combining a variety of powerful NLU Providers, you can
                  create a chatbot that has the ability to perform multiple
                  functions without the possibility of human error. 1Chatbot
                  brings all the pieces together and provides a new way of
                  looking at chat automation.
                </p>
                {/* <div className="d-flex">
                  <Link to="chatbot-1.html" class="btn btn-orange mt-1">
                    View Documentation
                  </Link>
                </div> */}
                {/* inappdisable <Link
                  to={getSignupOrProjectRedirectionUrl(this.props.isLoggedIn)}
                  className="btn btn-orange mw-180 mt-1"
                >
                  Get Started
                </Link> */}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="img-block">
                <img src={puzzleImage} alt="Automated Chat" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBotFactorySection() {
    return (
      <div className="content-right-block" id="bot-factory">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-2 order-md-1">
              <div className="img-block text-center text-md-left">
                <img src={botFactoryImage} alt="Bot Factory" />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 order-1 order-md-2">
              <div className="content">
                <h2 className="">
                  Welcome to the
                  <br />
                  <span className="text-lightorange">Bot Factory!</span>
                  <span className="font-24"> (Coming Soon)</span>
                </h2>
                <p className="desc">
                  Creating the bot of your dreams has never been easier. The{" "}
                  <strong>Bot Factory</strong> allows you to provide the intents
                  and responses for your custom bot. Once you have added the bot
                  to your project, it is automatically available for use.
                </p>
                <p className="desc">
                  The factory is open day and night - no safety equipment
                  required.
                </p>
                {/* inappdisable <a href="#" className="btn btn-orange mw-180 mt-1">
                  Watch Demo
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChatwidgetsSection() {
    return (
      <div className="content-left-block" id="chat-widgets">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="content">
                <h2>
                  Need a chatbot in a hurry? Try our{" "}
                  <span className="text-lightorange">chat widget</span>!
                </h2>
                <p className="desc">
                  You need to add a chatbot for basic interactions with a user,
                  but you don't have the time to build one from scratch. No
                  problem! When you use our JS web widget, you can quickly
                  program the bot with your requirement as well as customize the
                  colors and fonts to match your site's theme.
                </p>
                {/* inappdisable <a href="#" className="btn btn-orange mw-180 mt-1">
                  Watch Demo
                </a> */}
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="img-block">
                <img src={chatWidgetImage} alt="Chat widget" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderGetStartedSection() {
    return (
      <div className="get-started-block">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="content">
                <h2>Ready to get started?</h2>
                <p className="desc">
                  Create an account, or check out or plan pricing
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <div className="action">
                {/* inappdisable <Link
                  to={getSignupOrProjectRedirectionUrl(this.props.isLoggedIn)}
                  className="btn btn-orange"
                >
                  Get Started
                </Link> */}
                <Link to="/pricing" className="link orange-link">
                  View Pricing
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark selected="m2m" />
        <main className="content-wrapper main-content new-content">
          {this.renderBannerSection()}
          {/* this.renderAllSolutionBlocks() */}
          {this.render1ChatbotSection()}
          {/* this.renderBotFactorySection() */}
          {/* this.renderChatwidgetsSection() */}
          {/* this.renderGetStartedSection() */}
        </main>
        <Footer_v2 />
      </>
    );
  }
}

function mapStatsToProps(stats) {
  return {
    user: stats.user.user,
    isLoggedIn: stats.user.isLoggedIn
  };
}

export default connect(mapStatsToProps, { getLatestBlogs })(OneChatbotContent);
