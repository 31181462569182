import React, { Component } from "react";
import SitePageContainer from "../../common/sitePageContainer";
import InternalServerError from "../../common/internalServerError";

class InternalServerErrorPage extends Component {
  render() {
    return (
      <SitePageContainer>
        <div className="content-wrapper text-center">
          <div className="content-form-block">
            <InternalServerError />
          </div>
        </div>
      </SitePageContainer>
    );
  }
}

export default InternalServerErrorPage;
