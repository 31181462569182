import { TOKEN_RECEIVED, TOKEN_REMOVED } from "../actions/auth";

const INITIAL_STATE = {};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOKEN_RECEIVED:
      return { ...state, token: action.token };
    case TOKEN_REMOVED:
      return { ...state, token: {} };
    default:
      return state;
  }
}
