import { getAuthToken } from "../actions/auth";
import { getProvintAuthToken } from "../actions/provintAuth";
import config from "../config/config";
import { loadProjectUrl } from "../store/localStorage";

export function endpoint(urlSuffix) {
  const protocol = config.PROTOCOL || window.location.protocol;
  return `${protocol}//${config.BACKEND_HOST}/${config.API_VERSION}/${urlSuffix}`;
}

export function endpoint_v2(urlSuffix) {
  const protocol = config.PROTOCOL || window.location.protocol;
  return `${protocol}//${config.BACKEND_HOST}/v2/${urlSuffix}`;
}

export function endpointWithoutApiVersion(urlSuffix) {
  const protocol = config.PROTOCOL || window.location.protocol;
  return `${protocol}//${config.BACKEND_HOST}/${urlSuffix}`;
}

export function chatEndpoint(urlSuffix) {
  const protocol = config.PROTOCOL || window.location.protocol;
  return `${protocol}//${config.CHATSERVER_HOST}/${config.API_VERSION}/${urlSuffix}`;
}

export function globalEndpoint(urlSuffix) {
  const protocol = config.PROTOCOL || window.location.protocol;
  return `${protocol}//${config.GLOBAL_HOST}/${config.API_VERSION}/${urlSuffix}`;
}

export function provintEndpoint(tenantId, userId, urlSuffix) {
  let baseUrl = loadProjectUrl(tenantId);
  if (baseUrl && !baseUrl.endsWith("/")) {
    baseUrl = baseUrl + "/";
  }
  return `${baseUrl}${config.API_VERSION}/tenants/${tenantId}/${userId}/${urlSuffix}`;
}

export function provEndpoint(tenantId, userId, urlSuffix) {
  const protocol = config.PROTOCOL || window.location.protocol;
  let baseUrl = `${protocol}//${config.BACKEND_HOST}`;
  if (baseUrl && !baseUrl.endsWith("/")) {
    baseUrl = baseUrl + "/";
  }
  return `${baseUrl}${config.API_VERSION}/tenants/${tenantId}/${userId}/${urlSuffix}`;
}

export function provintBasicEndpoint(tenantId, urlSuffix) {
  let baseUrl = loadProjectUrl(tenantId);
  if (baseUrl && !baseUrl.endsWith("/")) {
    baseUrl = baseUrl + "/";
  }
  return `${baseUrl}${config.API_VERSION}/${urlSuffix}`;
}

export function alphabotServiceEndpoint(tenantId, urlSuffix) {
  return `${config.PROTOCOL}//${config.CHATBOT_HOST}/tenants/${tenantId}/${urlSuffix}`;
}

export function alphabotWidgetServiceEndpoint(urlSuffix) {
  return `${config.PROTOCOL}//${config.CHATBOT_HOST}/${urlSuffix}`;
}

export function socketServerWidgetEndpoint(tenantId, urlSuffix) {
  return `${config.PROTOCOL}//${config.SOCKET_SERVER_WIDGET_HOST}/${config.API_VERSION}/tenants/${tenantId}/${urlSuffix}`;
}

export function socketServerWidgetEndpointWithoutTenant(urlSuffix) {
  return `${config.PROTOCOL}//${config.SOCKET_SERVER_WIDGET_HOST}/${config.API_VERSION}/${urlSuffix}`;
}

export const Request = ({
  url,
  method = "GET",
  headerObj,
  body,
  authEnabled = false,
  cors = true
}) => {
  const requestObject = {};
  requestObject.authEnabled = authEnabled;
  requestObject.execute = (tokenString = null) => {
    if (url) {
      const headers = headerObj ? new Headers(headerObj) : new Headers();
      if (authEnabled) {
        tokenString && headers.append("Authorization", tokenString);
      }
      let requestParams = {
        method: method,
        headers: headers
      };
      if (cors) {
        requestParams.mode = "cors";
      } else {
        requestParams.mode = "no-cors";
      }

      if (body) {
        requestParams = { ...requestParams, body: body };
      }
      return fetch(url, requestParams);
    } else {
      throw new Error("Url is not specified");
    }
  };
  return requestObject;
};

export function restCall(request, dispatch) {
  if (request.authEnabled) {
    return getAuthToken(dispatch).then(result =>
      request.execute(`Bearer ${result.accessToken}`)
    );
  } else {
    return request.execute();
  }
}

export function provintRestCall(tenantId, userId, request, dispatch) {
  if (request.authEnabled) {
    return getProvintAuthToken(tenantId, userId, dispatch).then(result =>
      request.execute(`Bearer ${result.accessToken}`)
    );
  } else {
    return request.execute();
  }
}
