import React, { Component } from "react";
import SignUpContent_v2 from "../signup/signUpContent_v2";
import SitePageContainer from "../../common/sitePageContainer";
import { SEO_SIGNUP } from "../../../utils/seoutils";

class SignUpPage_v2 extends Component {
  render() {
    return (
      <SitePageContainer pageName={SEO_SIGNUP}>
        <SignUpContent_v2
          referrer={this.props.referrer}
          organization={this.props.organization}
          email={this.props.email}
        />
      </SitePageContainer>
    );
  }
}

export default SignUpPage_v2;
