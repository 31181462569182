//const extend = require('util')._extend;
const development = require("./env/dev");
const local = require("./env/local");
const qa = require("./env/qa");
const qa_new = require("./env/qa_new");
const staging = require("./env/staging");
const production = require("./env/production");

const rbnv2Local = require("./env/rbnv2/local");
const rbnv2Dev = require("./env/rbnv2/dev");

const env = process.env.REACT_APP_NODE_ENV || "local";
const version = process.env.REACT_APP_VERSION || "v1";
const defaults = {
  NODE_PATH: "src/",
  API_VERSION: "v1",
  /*SUPPORT_URL: "https://ripbullnetworks.atlassian.net/servicedesk/customer/portal/2",*/
  SUPPORT_URL: "/contact-support",
  BUGSNAG_API_KEY: "b106034aa6dba6e811821179eac07435",
  ENV: env,
  IS_HTTPS: true,
  NAMESPACE_BASE_URL: "inappchat.io",
  MAX_UPLOADFILES_SUPPORT: 5,
  MAX_UPLOADFILE_SIZE: 10,
  AUTH0_REDIRECT_PAGE: "login"
};

console.log(env, version);

var envConfig = {
  v1: {
    development,
    local,
    production,
    qa,
    qa_new,
    staging
  },
  v2: {
    development: rbnv2Dev,
    local: rbnv2Local,
    production,
    qa,
    qa_new,
    staging
  }
}[version][env];

console.log(envConfig);

var config = Object.assign(defaults, envConfig);
export default config;
