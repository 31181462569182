import React, { Component } from "react";
import SiteHeader from "../../../common/siteHeader";
import ChatbotsDocSidebar from "./chatbotsDocSidebar";
import ChatbotsCategoryNavBar from "./chatbotsCategoryNavBar";
import chatWidgetImg from "../../../../public/resources/site/images/installing-chat-widget.svg";
import chatWidget1Img from "../../../../public/resources/site/images/chat-bots-screen-1.jpg";
import chatWidget2Img from "../../../../public/resources/site/images/chat-bots-screen-2.jpg";
import chatWidget3Img from "../../../../public/resources/site/images/chat-bots-screen-3.jpg";
import chatWidgetDataImg from "../../../../public/resources/site/images/chat-bots-with-data.png";

class DocsChatbotChatWidget extends Component {
  constructor(props) {
    super(props);
  }

  renderTitleBlock() {
    return (
      <div className="tutorials-img-block pb-0">
        <div className="container">
          <div className="tutorials-content-block">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="title-content mb-0">
                  <h1 className="font-lg">Installing a Chat Widget</h1>
                  <div className="info-content">
                    Ready to add a chat widget to your site or app? The process
                    of creating and installing a bot can be completed in just
                    minutes!
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="image-block text-md-right">
                  <img src={chatWidgetImg} alt="Installing a Chat Widget" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <ChatbotsCategoryNavBar />
        <main className="content-wrapper main-content">
          {this.renderTitleBlock()}
          <div className="content-left-block connecting-grid-block --scrollpage">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xl-3 tab-inner-content">
                  <ChatbotsDocSidebar />
                </div>
                <div className="col-lg-8 col-xl-9">
                  <div className="tab-content tutorials-details-block">
                    <div
                      className="tab-pane fade show active"
                      id="installing-Widget"
                    >
                      <h3>Prerequisites</h3>
                      <p>
                        In order to integrate the chat widget, you will need the
                        following:
                      </p>
                      <ul className="field-list">
                        <li>Access to your website's code base</li>
                        <li>The chat widget JavaScript snippet</li>
                      </ul>
                      <ul>
                        <li>
                          Get the chat widget JavaScript
                          <div className="info-content font-weight-normal font-16 mt-2">
                            <p>
                              In the In-App Chat portal, click 'Bots' in the
                              side-panel. This will take you to the page where
                              you will provide the options necessary to create
                              your widget. Select the button labeled{" "}
                              <strong>Install Chat Widget</strong>
                            </p>
                            <div className="image-block mb-5">
                              <img src={chatWidgetDataImg} alt="Widget" />
                            </div>
                            <p>
                              The modal that appears will allow you to determine
                              things like your bot's name, description, greeting
                              message, etc. Once you are satisfied with the
                              options you have provided, select the{" "}
                              <strong>Next</strong> button.
                            </p>
                            <div className="image-block mb-5">
                              <img src={chatWidget1Img} alt="Widget" />
                            </div>
                            <p>
                              Next you will provide the whitelisted domains that
                              your widget will be added to.
                            </p>
                            <div className="image-block mb-5">
                              <img src={chatWidget2Img} alt="Widget" />
                            </div>
                            <p>
                              Once the JavaScript snippet has been created,
                              select the <strong>Copy Script</strong> button,
                              and proceed to step 2.
                            </p>
                            <div className="image-block mb-5">
                              <img src={chatWidget3Img} alt="Widget" />
                            </div>
                          </div>
                        </li>
                        <li>
                          Insert the JavaScript snippet on your website
                          <div className="info-content font-weight-normal font-16 mt-2">
                            <p>
                              Paste the snippet you have copied into your HTML
                              {"<"}head{">"} element via your Content Management
                              System, or through your app's code. In order to
                              only have to do this once, insert the snippet into
                              a header or template file that is shared across
                              all pages of your website.
                            </p>
                            <p>
                              Note: Make sure the JavaScript is inserted into
                              every page you want the widget deployed on. If you
                              only have the widget inserted under
                              inappchat.io/pricing, for example, it would not be
                              deployed on inappchat.io/contactus without having
                              to repeat this step.
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default DocsChatbotChatWidget;
