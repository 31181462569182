import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import connect from "react-redux/es/connect/connect";
import PeerToMachineChatImage from "../../../public/resources/site/images/peer-to-machine-chatbot.png";
import SeamlessNativeImage from "../../../public/resources/site/images/seamless-native.png";
import NLPImage from "../../../public/resources/site/images/natural-language-processing.png";
import CustomizeChatBotIcon from "../../../public/resources/common/icons/customize-chatbot.svg";
import AutomateDutiesIcon from "../../../public/resources/common/icons/automate-duties.svg";
import StartCollaborateIcon from "../../../public/resources/common/icons/start-collaborating.svg";
import AdjustControlIcon from "../../../public/resources/common/icons/adjust-and-control.svg";
import MonitorSystemIcon from "../../../public/resources/common/icons/monitor-system.svg";
import RecognizeTrendsIcon from "../../../public/resources/common/icons/recognize-trends.svg";
import Footer_v2 from "../common/footer_v2";
import { getSignupOrProjectRedirectionUrl } from "../../../utils/planUtil";
import TitleFeatureBox from "../../common/titleFeatureBox";
import ExpandableCardsBlock from "../../common/expandableCardsBlock";

class PeerToMachineContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderBannerSection() {
    return (
      <>
        <div className="banner-section peer-machine-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-7">
                <div className="content">
                  <h1 className="wow fadeInDown">
                    Intelligent Virtual Agents Powering{" "}
                    <span className="text-orange">Peer-to-Machine</span>{" "}
                    Interactions.
                  </h1>
                  <div className="action wow fadeInUp" data-wow-delay="0.4s">
                    <Link
                      to={getSignupOrProjectRedirectionUrl(
                        this.props.isLoggedIn
                      )}
                      className="btn btn-primary "
                    >
                      Get Started
                    </Link>
                  </div>
                  <div className="img-block d-block d-md-none wow fadeIn">
                    <img
                      src={PeerToMachineChatImage}
                      alt="Peer to Machine Chat Banner"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="animation-shape d-none d-md-block">
            <span className="shape1" />
            <span className="shape2" />
            <span className="shape3" />
            <span className="shape4" />
            <span className="shape5" />
          </div>
          <div className="tag-line">
            <div className="container">
              <p className="wow fadeInUp">HUMAN TO CHATBOT CHAT</p>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderReduceResponseBody() {
    return (
      <>
        Users expect instant responses and timely service. Unless you have an
        army of operators standing by 24/7, you will never be able to meet this
        expectation. Enter the chatbot. Chatbots never get distracted, sick, or
        take breaks. Plus, there’s no limit to the number of requests a chatbot
        can handle. Let the virtual assistant take the brunt of the load,
        allowing you to scale faster and improve customer service.
      </>
    );
  }

  renderProvideRelevantBody() {
    return (
      <>
        Chatbots have access to more data than any human brain can store and
        efficiently retrieve in a timely manner. Use yours to answer queries
        with pertinent information, freeing up your techs and customer service
        reps to engage with more complex needs, instead of tying them up with
        simple and often mundane requests.
      </>
    );
  }

  renderEmpowerUserBody() {
    return (
      <>
        By giving your users more options to reach out, you are empowering them
        to choose how they receive service based on their preferences. When
        paired with our chat API and Natural Language Processing, you can
        improve those conversations being had, even when left in the hands of a
        chatbot.
      </>
    );
  }

  renderCustomerServiceBody() {
    return (
      <>
        Support teams in any business often struggle to keep up with massive
        inflows of customer queries. Chatbots are able to handle multiple
        queries simultaneously without skipping a beat. Allowing businesses to
        offload common questions like “Where’s my order?” to an intelligent
        chatbot. This frees up your support team to tackle more important
        requests like billing discrepancies and lost items.
      </>
    );
  }

  renderLeadNurturingBody() {
    return (
      <>
        Chatbots work around the clock, allowing your business to stay in-touch
        with leads and customers at any time of the day, no matter the timezone.
        Use a chatbot to respond to queries after normal business hours. Or
        setup email sequences to keep customers active and informed and move
        leads further down the sales funnel.
      </>
    );
  }

  renderAutomateProcessBody() {
    return (
      <>
        Organisations today want to collect and consume copious amounts of data.
        For a good reason too, because data-driven decision making is helping
        businesses thrive with tangible results. However, collecting and
        organising data eats large portions of time and manpower. If that
        process could be handed to a chatbot instead, organisations would have
        the ability to mine data 24/7 and receive scheduled reports. Paving the
        way for smarter and faster decision making.
      </>
    );
  }

  renderFeaturesSection() {
    const customerSatisfactionCards = [];
    customerSatisfactionCards.push({
      id: "reduceResponse",
      title: "Reduce Response Times",
      wowDelay: "0.1s",
      bodyJsx: this.renderReduceResponseBody()
    });
    customerSatisfactionCards.push({
      id: "provideRelevant",
      title: "Provide Relevant Information",
      wowDelay: "0.2s",
      bodyJsx: this.renderProvideRelevantBody()
    });
    customerSatisfactionCards.push({
      id: "empowerUser",
      title: "Empower Your Users",
      wowDelay: "0.3s",
      bodyJsx: this.renderEmpowerUserBody()
    });

    const streamlineRecurringCards = [];
    streamlineRecurringCards.push({
      id: "customerService",
      title: "Customer Service",
      wowDelay: "0.1s",
      bodyJsx: this.renderCustomerServiceBody()
    });
    streamlineRecurringCards.push({
      id: "leadNurturing",
      title: "Lead Nurturing",
      wowDelay: "0.2s",
      bodyJsx: this.renderLeadNurturingBody()
    });
    streamlineRecurringCards.push({
      id: "automateProcess",
      title: "Automating Internal Processes",
      wowDelay: "0.3s",
      bodyJsx: this.renderAutomateProcessBody()
    });

    return (
      <div className="bg-gray">
        <div className="content-center-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="title-content center-title">
                  <div className="tag wow fadeInDown">features</div>
                  <h2 className="wow fadeInUp">
                    Optimize User Engagement & Streamline Business Processes
                  </h2>
                </div>
                <div className="content-icon-box-block center-icon-box">
                  <div className="row justify-content-center">
                    <TitleFeatureBox
                      title={
                        "Customize The Chatbot To Work With Essential Plugins & Scripts"
                      }
                      imgSrc={CustomizeChatBotIcon}
                      wowDelay={"0.1s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Use Your Chatbot To Automate Duties & Workflows"}
                      imgSrc={AutomateDutiesIcon}
                      wowDelay={"0.2s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={
                        "Start Collaborating With Teams Better, Cheaper, & Faster"
                      }
                      imgSrc={StartCollaborateIcon}
                      wowDelay={"0.3s"}
                      titleBelowIcon={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-collapse-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="title-content">
                  <h3 className="wow fadeInDown">
                    Improve Customer Satisfaction
                  </h3>
                  <p className="wow fadeInUp">
                    With an intelligent chatbot you can effectively improve
                    customer satisfaction by reducing response times, providing
                    relevant information, and empowering users to take control
                    of their interactions.
                  </p>
                </div>
                <div className="custom-accordion" id="improve-customer">
                  <ExpandableCardsBlock cards={customerSatisfactionCards} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="content-collapse-block pb-100">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="title-content">
                  <h3 className="wow fadeInDown">
                    Streamline Recurring Processes
                  </h3>
                  <p className="mw-900 wow fadeInUp">
                    Automation via a chatbot can streamline many recurring tasks
                    - from data entry to reporting and more! Streamlining
                    recurring tasks, like customer service and invoicing leads,
                    to reduced costs due to a reduction in manpower and
                    resources and an increase in customer satisfaction and
                    retention.
                  </p>
                </div>
                <div className="custom-accordion" id="streamline-recurring">
                  <ExpandableCardsBlock cards={streamlineRecurringCards} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDeviceControlSection() {
    return (
      <>
        <div className="content-center-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="title-content center-title">
                  <div className="tag wow fadeInDown">device control</div>
                  <h2 className="wow fadeInUp">Transform The Way You Work</h2>
                  <p className="mw-600 wow fadeInUp">
                    From device monitoring to system control, use chatbots to
                    connect and control your IoT devices.
                  </p>
                </div>
                <div className="content-icon-box-block center-icon-box white-icon-box">
                  <div className="row justify-content-center">
                    <TitleFeatureBox
                      title={() => (
                        <>
                          Adjust & Control <br /> Your Environment
                        </>
                      )}
                      imgAlt="Adjust & Control Your Environment"
                      imgSrc={AdjustControlIcon}
                      wowDelay={"0.1s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={() => (
                        <>
                          Monitor Systems & <br />
                          Notify Of Changes
                        </>
                      )}
                      imgAlt="Monitor Systems & Notify Of Changes"
                      imgSrc={MonitorSystemIcon}
                      wowDelay={"0.2s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={() => (
                        <>
                          Recognize Trends & <br />
                          Anticipate Issues
                        </>
                      )}
                      imgAlt="Recognize Trends & Anticipate Issues"
                      imgSrc={RecognizeTrendsIcon}
                      wowDelay={"0.3s"}
                      titleBelowIcon={true}
                    />
                  </div>
                </div>
                <div className="content-action pb-100">
                  <Link
                    to={"/iot-controller"}
                    className="btn btn-primary wow fadeInUp"
                    data-wow-delay="0.4s"
                  >
                    Explore More ChatOps
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderBlogSection() {
    return (
      <div className="content-blog-block bg-gray">
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="blog-block">
                <div className="title">
                  Seamless Native & Web SDK Integrations
                </div>
                <p>
                  Objective C and Java framework to supercharge your{" "}
                  <span className="text-orange">iOS</span>,{" "}
                  <span className="text-orange">Android</span>, or{" "}
                  <span className="text-orange">Web App</span> with cognitive
                  virtual assistants for a seamless experience across different
                  devices and browsers.
                </p>
                <div className="image-block">
                  <img
                    src={SeamlessNativeImage}
                    alt="Seamless Native & Web SDK Integrations"
                  />
                </div>
              </div>
            </div>
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-6 wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="blog-block">
                <div className="title">
                  Natural Language Processing & Machine Learning Services
                </div>
                <p>
                  In-App Chat can use any{" "}
                  <span className="text-orange">NLP</span> and{" "}
                  <span className="text-orange">ML</span> service, whether it’s
                  Dialogflow, IBM Watson, Cortana, or AWS.
                </p>
                <a
                  href="https://blog.inappchat.io/natural-language-processing/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More About The Importance Of NLP{" "}
                  <em className="ri-arrow-right-line" />
                </a>
                <div className="image-block">
                  <img
                    src={NLPImage}
                    alt="Seamless Native & Web SDK Integrations"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <SiteHeader fixedHeaderOnScroll={false} isDark selected="p2m" />
        <div className="content-wrapper main-content">
          {this.renderBannerSection()}
          {this.renderFeaturesSection()}
          {this.renderDeviceControlSection()}
          {this.renderBlogSection()}
        </div>
        <Footer_v2 />
      </div>
    );
  }
}

function mapStatsToProps(stats) {
  return {
    user: stats.user.user,
    isLoggedIn: stats.user.isLoggedIn,
    projects: stats.project.projects
  };
}

export default connect(mapStatsToProps, {})(PeerToMachineContent);
