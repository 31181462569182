import React, { Component } from "react";
import { getCurrentOrganization } from "../../../utils/commonUtil";
import connect from "react-redux/es/connect/connect";
import {
  getAllRoles,
  getMemberByRoleId,
  deleteRole,
  updateOrganization
} from "../../../actions/organization";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../common/context/userContextProvider";
import {
  changeOwner,
  getUsers,
  inviteUser,
  resendInvitation
} from "../../../actions/users";
import AddNewAdminModal from "./addNewAdminModal";
import ChangeMemberRolesModal from "./changeMemberRolesModal";
import LoadingDisplay from "../../common/loadingDisplay";
import { showErrorToast, showSuccessToast } from "../../../actions/ui";
import ConfirmationModal from "../../common/confirmationModal";
import history from "../../../history";
import { isPageEditable } from "../../../utils/pageAccessUtil";

class OrganizationRolesContent extends Component {
  organization;
  static contextType = UserContext;
  data = [];
  rolesData = [];
  assignMembersToDeleteRole = [];

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "active",
      isLoading: true,
      ascendingSorting: true,
      addMemberModalShow: false,
      selectedRoleForInvite: "",
      deleteRoleResultObj: null,
      ownerChangeSuccess: false,
      deleteRoleLoading: false,
      getMemberByRoleIdLoading: false,
      memberByRoleId: "",
      inviteUserLoading: false,
      dropDownMenuId: "",
      roleToDelete: null,
      needToChangeMemberRoleBeforeDelete: false,
      resendInviteEmail: "",
      searchInputText: ""
    };

    this.optionsRef = React.createRef();
    this.onClickTab = this.onClickTab.bind(this);
    this.onAddNewAdminClick = this.onAddNewAdminClick.bind(this);
    this.onDuplicateRoleClick = this.onDuplicateRoleClick.bind(this);
    this.onEditRoleClick = this.onEditRoleClick.bind(this);
    this.onViewRoleClick = this.onViewRoleClick.bind(this);
    this.onDeleteRoleClick = this.onDeleteRoleClick.bind(this);
    this.fetchRoles = this.fetchRoles.bind(this);
    this.ownerChangeSuccess = this.ownerChangeSuccess.bind(this);
    this.onDropDownMenuClick = this.onDropDownMenuClick.bind(this);
    this.handleClickOutsideOptions = this.handleClickOutsideOptions.bind(this);
    this.onChangeSearchInput = this.onChangeSearchInput.bind(this);
    this.onSortingClick = this.onSortingClick.bind(this);
  }

  componentDidMount() {
    this.fetchRoles();
    document.addEventListener("mousedown", this.handleClickOutsideOptions);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideOptions);
  }

  handleClickOutsideOptions(event) {
    if (
      this.optionsRef &&
      this.optionsRef.current &&
      !this.optionsRef.current.contains(event.target) &&
      this.state.dropDownMenuId !== ""
    ) {
      this.setState({ dropDownMenuId: "" });
    }
  }

  onAddNewAdminClick(e, roleId) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      selectedRoleForInvite: roleId,
      addMemberModalShow: !this.state.addMemberModalShow,
      dropDownMenuId: ""
    });
  }

  onDuplicateRoleClick(e, data) {
    e.stopPropagation();
    e.preventDefault();

    history.push({ pathname: "/organizations/createrole", role: data });
  }

  onEditRoleClick(e, data) {
    e.stopPropagation();
    e.preventDefault();

    history.push({
      pathname: "/organizations/editrole",
      search: `id=${data.id}`
    });
  }

  onViewRoleClick(e, data) {
    if (!this.state.getMemberByRoleIdLoading) {
      history.push({
        pathname: "/organizations/viewrole",
        search: `id=${data.id}`
      });
    }
  }

  ownerChangeSuccess() {
    this.setState({ ownerChangeSuccess: true });
  }

  onDeleteRoleClick(e, role) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ getMemberByRoleIdLoading: true, memberByRoleId: role.id });
    this.assignMembersToDeleteRole = [];
    const organization = getCurrentOrganization(this.context.user);
    this.props
      .getMemberByRoleId(organization._id, role.id)
      .then(result => {
        const resultObj = result.actionDataObj;
        this.assignMembersToDeleteRole =
          (resultObj.data &&
            resultObj.data.map(item => {
              return {
                name: item.name || "NA",
                email: item.email,
                id: item._id
              };
            })) ||
          [];
        this.setState({
          dropDownMenuId: "",
          roleToDelete: role,
          needToChangeMemberRoleBeforeDelete:
            this.assignMembersToDeleteRole.length > 0,
          getMemberByRoleIdLoading: false,
          memberByRoleId: ""
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          dropDownMenuId: "",
          roleToDelete: role,
          needToChangeMemberRoleBeforeDelete: false,
          getMemberByRoleIdLoading: false,
          memberByRoleId: ""
        });
      });
  }

  removeRole = () => {
    this.setState({ deleteRoleLoading: true });
    const organization = getCurrentOrganization(this.context.user);
    this.props
      .deleteRole(organization._id, this.state.roleToDelete.id)
      .then(() => {
        this.removeRoleFromUI(this.state.roleToDelete.id);
        setTimeout(() => {
          this.setState({ roleToDelete: null });
        }, 2000);
        //this.props.showSuccessToast('Admin user removed successfully');
        this.setState({ deleteRoleLoading: false });
        this.props.showSuccessToast("Role deleted successfully");
      })
      .catch(error => {
        console.log(error);
        //this.props.showErrorToast('Error in removing user');
        this.setState({ deleteRoleLoading: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  };

  removeRoleFromUI(removedRoleId) {
    this.rolesData = this.rolesData.filter(item => item.id !== removedRoleId);
  }

  onDropDownMenuClick(e, id) {
    e.stopPropagation();
    if (!this.state.getMemberByRoleIdLoading) {
      this.setState({ dropDownMenuId: id });
    }
  }

  onChangeSearchInput(e) {
    this.setState({ searchInputText: e.target.value });
  }

  fetchRoles() {
    const organization = getCurrentOrganization(this.context.user);
    this.setState({ isLoading: true });
    this.props
      .getAllRoles(organization._id)
      .then(result => {
        const resultObj = result.actionDataObj;
        this.rolesData = resultObj.data || [];

        this.sortRoleData(this.state.ascendingSorting);
        this.setState({ isLoading: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  }

  onClickTab(e, curTab) {
    e.preventDefault();
    if (this.state.activeTab !== curTab) {
      this.setState({ searchInputText: "" });
    }
    this.setState({ activeTab: curTab });
  }

  render() {
    let filterRoles = this.rolesData;
    if (this.state.searchInputText.trim() !== "") {
      filterRoles = this.rolesData.filter(item =>
        item.displayName
          .toLowerCase()
          .includes(this.state.searchInputText.toLowerCase().trim())
      );
    }

    let totalRolesText = `${this.rolesData.length} Role${
      this.rolesData.length > 1 ? "s" : ""
    }`;
    if (this.rolesData.length !== filterRoles.length) {
      totalRolesText = `${filterRoles.length} of ${totalRolesText}`;
    }

    const isEditable = isPageEditable(
      "/organizations/roles",
      this.context.user
    );

    return (
      <>
        <div className="overview-box">
          <div className="title border-bottom pb-3">
            <h2 className="h4">{totalRolesText}</h2>
            <div className="action-block">
              <div className="search-input-block">
                <div className="search-input">
                  <div className="form-group pre-icon-input">
                    <span className="pre-icon">
                      <em className="i-icon icon-search" />
                    </span>
                    <input
                      type="text"
                      className="form-control custom-input input-light"
                      placeholder="Search"
                      value={this.state.searchInputText}
                      onChange={this.onChangeSearchInput}
                    />
                  </div>
                </div>
              </div>
              {isEditable && (
                <button
                  type="button"
                  className="btn btn-primary btn-md"
                  onClick={() => history.push("/organizations/createrole")}
                >
                  Create Role
                </button>
              )}
            </div>
          </div>
          <div className="record-table-list">
            <div className="table-responsive">
              <table className="table">
                <thead>{this.renderHeaderRow()}</thead>
                <tbody>{this.renderRoleData(filterRoles, isEditable)}</tbody>
              </table>
            </div>
          </div>
        </div>
        {this.renderDropDowns(filterRoles)}
        {this.state.addMemberModalShow && (
          <AddNewAdminModal
            show={this.state.addMemberModalShow}
            onHide={() =>
              this.setState({
                addMemberModalShow: false,
                selectedRoleForInvite: ""
              })
            }
            currentRoleId={this.state.selectedRoleForInvite}
            allRoles={this.rolesData}
            user={this.context.user}
            inviteUser={this.props.inviteUser}
            fetch={() => {}}
          />
        )}
        {this.state.needToChangeMemberRoleBeforeDelete
          ? this.renderChangeMemberRoleModal()
          : this.renderDeleteRoleConfirmationModal()}
      </>
    );
  }

  renderChangeMemberRoleModal() {
    if (this.state.roleToDelete === null) return null;

    return (
      <ChangeMemberRolesModal
        show={this.state.roleToDelete !== null}
        onHide={() => this.setState({ roleToDelete: null })}
        roleToDelete={this.state.roleToDelete}
        memberList={this.assignMembersToDeleteRole}
        user={this.context.user}
        submitHandler={this.removeRole}
        submitButtonLoading={this.state.deleteRoleLoading}
        submitResult={this.state.deleteRoleResultObj}
      />
    );
  }

  renderDeleteRoleConfirmationModal() {
    if (this.state.roleToDelete === null) return null;

    const message = "Delete role?";
    const subMessage = "You will no longer be able to assign this role.";

    return (
      <ConfirmationModal
        actionText={message}
        actionSubText={subMessage}
        actionButtonText="Delete"
        actionHandler={this.removeRole}
        actionButtonBtnClass={"btn btn-lg btn-outline-danger"}
        cancelButtonBtnClass={"btn btn-lg btn-outline-secondary text-gray"}
        show={this.state.roleToDelete !== null}
        cancelHandler={() => this.setState({ roleToDelete: null })}
        actionButtonLoading={this.state.deleteRoleLoading}
        actionResult={this.state.deleteRoleResultObj}
      />
    );
  }

  renderRoleData(roleData, isEditable) {
    const items = [];
    if (this.state.isLoading) {
      items.push(
        <tr key={"loading1"}>
          <td colSpan="5">
            <LoadingDisplay marginTop="20px" height="50px" />
          </td>
        </tr>
      );
      return items;
    }

    if (roleData.length === 0) {
      items.push(
        <tr key={"norole"}>
          <td colSpan="5" style={{ textAlign: "center" }}>
            No Roles Found
          </td>
        </tr>
      );
      return items;
    }

    roleData.forEach((item, index) => {
      items.push(this.renderRoles(item, isEditable, index, roleData.length));
    });
    return items;
  }

  renderRoles(item, isEditable, index, totalItems) {
    const { id, displayName, keyRole, roleType } = item;
    const showOptions = /*this.isSuperAdmin() && name !== "superAdmin" &&*/ isEditable;
    const showDropUp = totalItems >= 4 && index === totalItems - 1;
    const type = roleType === "custom" ? "" : "SYSTEM ROLE";

    return (
      <tr
        key={id}
        style={{ cursor: "pointer" }}
        onClick={e => {
          this.onViewRoleClick(e, item);
        }}
      >
        <td>
          {displayName} {type && <span className="role-tag">SYSTEM ROLE</span>}
        </td>
        <td>{keyRole}</td>
        <td className="action">
          {showOptions && (
            <a
              href="#"
              id="user-dropdown"
              className="icon-action"
              data-horizontal-offset="-20"
              data-vertical-offset={showDropUp ? "60" : "-65"}
              data-jq-dropdown={`#jq_${id}`}
              onClick={e => this.onDropDownMenuClick(e, item._id)}
            >
              <em className="i-icon icon-vertical-dots" />
            </a>
          )}
        </td>
      </tr>
    );
  }

  renderDropDowns(adminData) {
    const items = [];
    adminData &&
      adminData.length > 0 &&
      adminData.map((item, index) => {
        const { id, name, roleType } = item;
        const showDropUp =
          adminData.length >= 4 && index === adminData.length - 1;
        const isSystemRole = roleType.toLowerCase() === "system";
        const isSuperAdminRole = name.toLowerCase() === "superadmin";

        items.push(
          <div
            id={`jq_${id}`}
            key={`jq_${id}`}
            className={`jq-dropdown jq-dropdown-tip jq-dropdown-anchor-right ${
              showDropUp ? "jq-dropdown-anchor-down" : ""
            }`}
          >
            <ul className="jq-dropdown-menu">
              {!isSuperAdminRole && (
                <li className="dropdown-item">
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={e => this.onAddNewAdminClick(e, id)}
                  >
                    Send invitation
                  </a>
                </li>
              )}
              {!isSystemRole && (
                <li className="dropdown-item">
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={e => this.onEditRoleClick(e, item)}
                  >
                    Edit role
                  </a>
                </li>
              )}
              <li className="dropdown-item">
                <a
                  href="#"
                  className="dropdown-item"
                  onClick={e => this.onDuplicateRoleClick(e, item)}
                >
                  Duplicate role
                </a>
              </li>
              {!isSystemRole && (
                <li className="dropdown-item">
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={e => this.onDeleteRoleClick(e, item)}
                  >
                    Delete role
                  </a>
                </li>
              )}
            </ul>
          </div>
        );
      });
    return items;
  }

  onSortingClick() {
    this.setState({ ascendingSorting: !this.state.ascendingSorting });
    this.sortRoleData(!this.state.ascendingSorting);
  }

  sortRoleData(isAscending) {
    if (isAscending)
      this.rolesData.sort((a, b) =>
        a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
      );
    else
      this.rolesData.sort((a, b) =>
        a.displayName.toLowerCase() < b.displayName.toLowerCase() ? 1 : -1
      );
  }

  renderHeaderRow() {
    return (
      <tr>
        <th>Role Name</th>
        <th>Key Role</th>
        <th />
      </tr>
    );
  }
}

export default connect(null, {
  updateOrganization,
  getUsers,
  changeOwner,
  inviteUser,
  resendInvitation,
  deleteRole,
  getMemberByRoleId,
  getAllRoles,
  showSuccessToast,
  showErrorToast
})(withRouter(OrganizationRolesContent));
