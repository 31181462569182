import React, { useMemo, useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import { getFreeTrialStatus } from "../../utils/commonUtil";

const FreeTrialStatus = ({ history, user, project }) => {
  const trialStatus = useMemo(() => getFreeTrialStatus(user), [user]);

  const onClickCTA = useCallback(
    (e) => {
      e.preventDefault();
      history.push(`/1webchat-plan/${project?.projectId}`);
    },
    [history, project]
  );

  if (!trialStatus || !trialStatus.isFree) {
    return (
      <Link
        className="nav-link btn btn-orange upgrade-plan-btn"
        to={`/1webchat-plan/${project?.projectId}`}
      >
        <em className="i-icon icon-upgrade" />
        <span>Upgrade Plan</span>
      </Link>
    );
  }

  return (
    <div className="free-trial-status">
      <h3 className="free-trial-status-title">
        {trialStatus.isExpired
          ? "Your free trial has ended"
          : `Free trial: ${trialStatus.leftDays} days left`}
      </h3>
      <a href="#" className="free-trial-status-cta" onClick={onClickCTA}>
        {trialStatus.isExpired ? "Upgrade now" : "Add billing info"}
      </a>
    </div>
  );
};

export default withRouter(FreeTrialStatus);
