import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "./public/stylesheets/css/final.css";

import bugsnagClient from "./bugsnagClient";
import InternalServerErrorPage from "./components/site/pages/internalServerError";

const ErrorBoundary = bugsnagClient.getPlugin("react");

ReactDOM.render(
  <ErrorBoundary FallbackComponent={InternalServerErrorPage}>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
