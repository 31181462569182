import React from "react";
import { createPortal } from "react-dom";
import {
  DocSearchButton,
  DocSearchModal,
  useDocSearchKeyboardEvents
} from "@docsearch/react";

import "@docsearch/css";
import searchIcon from "../../../public/resources/common/icons/search.svg";
import featureCommandIcon from "../../../public/resources/common/icons/feather-command.svg";
import searchMobileIcon from "../../../public/resources/common/icons/search-white.svg";

const SearchDoc = ({ apiId, apiKey, indexName }) => {
  let isChatbotClass = false;
  const searchButtonRef = React.useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [initialQuery, setInitialQuery] = React.useState(null);

  const onOpen = React.useCallback(() => {
    setIsOpen(true);
    if (document.body.classList.contains("chatbot")) {
      document.body.classList.remove("chatbot");
      document.body.classList.add("nochatbot");
      isChatbotClass = true;
    }
    document.body.classList.add("overflow-hidden");
  }, [setIsOpen]);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
    if (isChatbotClass) {
      document.body.classList.add("chatbot");
      document.body.classList.remove("nochatbot");
      isChatbotClass = false;
    }
    document.body.classList.remove("overflow-hidden");
  }, [setIsOpen]);

  const onInput = React.useCallback(
    event => {
      setIsOpen(true);
      setInitialQuery(event.key);
    },
    [setIsOpen, setInitialQuery]
  );

  useDocSearchKeyboardEvents({
    isOpen,
    onOpen,
    onClose,
    onInput,
    searchButtonRef
  });

  function debounce(func, wait = 100) {
    let lastTimeout = null;
    return function(...args) {
      const that = this;
      return new Promise((resolve, reject) => {
        if (lastTimeout) {
          clearTimeout(lastTimeout);
        }
        lastTimeout = setTimeout(() => {
          lastTimeout = null;
          Promise.resolve(func.apply(that, args))
            .then(resolve)
            .catch(reject);
        }, wait);
      });
    };
  }

  return (
    <React.Fragment>
      <DocSearchButton
        ref={searchButtonRef}
        onClick={onOpen}
        style={{ display: "none" }}
      />

      {isOpen &&
        createPortal(
          <DocSearchModal
            appId={apiId}
            apiKey={apiKey}
            indexName={indexName}
            onClose={onClose}
            initialScrollY={window.scrollY}
            initialQuery={initialQuery}
            transformSearchClient={searchClient => {
              return {
                ...searchClient,
                search: debounce(searchClient.search, 1000)
              };
            }}
          />,
          document.body
        )}

      <button type="button" className="search-btn" onClick={onOpen}>
        <span className="search-icon">
          <img src={searchIcon} alt="Search" />
        </span>
        <span className="title-text">Search...</span>
        <span className="ctrl-btn">
          Ctrl K |{" "}
          <img src={featureCommandIcon} alt="command" width={11} height={11} />{" "}
          K
        </span>
      </button>
      <span className="icons d-flex d-xl-none" onClick={onOpen}>
        <img src={searchMobileIcon} alt="Search Mobile" />
      </span>
    </React.Fragment>
  );
};

export default SearchDoc;
