import React, { Component } from "react";
import SiteHeader from "../../../common/siteHeader";
import WatsonConfigTitleBlock from "./watsonConfigTitleBlock";
import ChatbotsDocSidebar from "./chatbotsDocSidebar";
import ChatbotsCategoryNavBar from "./chatbotsCategoryNavBar";
import wt1Img from "../../../../public/resources/site/images/watson-1.png";
import wt2Img from "../../../../public/resources/site/images/watson-2.png";
import wt3Img from "../../../../public/resources/site/images/watson-3.png";
import wt4Img from "../../../../public/resources/site/images/watson-4.png";
import wt5Img from "../../../../public/resources/site/images/watson-5.png";
import wt6Img from "../../../../public/resources/site/images/watson-6.png";
import wt7Img from "../../../../public/resources/site/images/watson-7.png";
import wt8Img from "../../../../public/resources/site/images/watson-8.png";
import wt9Img from "../../../../public/resources/site/images/watson-9.png";

class DocsChatbotNluWTConfigGuide extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <ChatbotsCategoryNavBar />
        <main className="content-wrapper main-content">
          <WatsonConfigTitleBlock />
          <div className="content-left-block connecting-grid-block --scrollpage">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xl-3 tab-inner-content">
                  <ChatbotsDocSidebar />
                </div>
                <div className="col-lg-8 col-xl-9">
                  <div className="tab-content tutorials-details-block">
                    <div
                      className="tab-pane fade show active"
                      id="watson config guide"
                    >
                      <div className="info-content">
                        <h3>Overview</h3>
                        <p>
                          Please follow these guidelines for your Watson bot if
                          you are orchestrating multiple NLU providers into a
                          single 1Chatbot agent. For example, if your 1Chatbot
                          agent has both DialogFlow or Lex and a Watson agent,
                          then these guidelines will ensure you can leverage the
                          power of multiple frameworks into one.
                        </p>
                        <h3>Why is this necessary?</h3>
                        <p>
                          While DialogFlow ES and Lex provide the session state
                          information needed in order for 1Chatbot to make good
                          decisions about conversational routing, in the case of
                          Watson these parameters are not available. For this
                          reason, it is required to add these helper variables
                          throughout your Watson Dialog or Action agent. Total
                          set up time depends on how many actions and/or intents
                          are active in the Watson agent, although generally
                          this should take just 15-minutes to an hour.
                        </p>
                        <h3>Introduction: Different conversation types</h3>
                        <p>
                          If you are using Watson Actions and/or Dialog there
                          are generally two types of conversations the bot
                          conducts:
                        </p>
                      </div>
                      <ul>
                        <li>
                          Scenario #1
                          <div className="info-content font-weight-normal font-16 mt-2">
                            <p>
                              A single step conversation: Watson Actions or
                              Dialog recognizes the utterance and displays the
                              response to the user, in this case the “session”
                              is closed.
                            </p>
                            <div className="info-example mb-3">
                              <span className="title-text">Example:</span>
                              <div className="right-content">
                                <p>
                                  <span className="text-lightorange">
                                    User:
                                  </span>{" "}
                                  "What time is it?"
                                </p>
                                <p>
                                  <span className="text-skyblue">Watson:</span>{" "}
                                  "It's 12PM."
                                </p>
                              </div>
                            </div>
                            <p>
                              This represents a simple question/answer flow.
                            </p>
                          </div>
                        </li>
                        <li>
                          Scenario #2
                          <div className="info-content font-weight-normal font-16 mt-2">
                            <p>
                              A multiple step conversation: Watson Actions or
                              Dialog takes the user through a series of steps
                              (denoted by “Next Step” in the Action builder and
                              “slot filling” in Dialog). In these cases, Watson
                              is collecting information from the user in order
                              to process some final request.
                            </p>
                            <div className="info-example mb-3">
                              <span className="title-text">Example:</span>
                              <div className="right-content">
                                <p>
                                  <span className="text-lightorange">
                                    User:
                                  </span>{" "}
                                  “I want to pay my credit card bill”
                                </p>
                                <p>
                                  <span className="text-skyblue">Watson:</span>{" "}
                                  “Please enter the last 4-digits of the account
                                  number.”
                                </p>
                                <p>
                                  <span className="text-lightorange">
                                    User:
                                  </span>{" "}
                                  “1234”
                                </p>
                                <p>
                                  <span className="text-skyblue">Watson:</span>{" "}
                                  “How much would you like to pay?”
                                </p>
                                <p>
                                  <span className="text-lightorange">
                                    User:
                                  </span>{" "}
                                  “$1000”
                                </p>
                                <p>
                                  <span className="text-skyblue">Watson:</span>{" "}
                                  “Should I use the default payment method on
                                  file?”
                                </p>
                                <p>
                                  <span className="text-lightorange">
                                    User:
                                  </span>{" "}
                                  “Yes”
                                </p>
                                <p>
                                  <span className="text-skyblue">Watson:</span>{" "}
                                  “You’re all set! Payment for $1000 to account
                                  number ending in 1234 has been made.
                                  Confirmation AB123.”
                                </p>
                              </div>
                            </div>
                            <p>
                              In this case there are 3-follow steps that require
                              the user to enter information, and a 4th
                              confirmation step which closes the session. Step 4
                              could have also been something like “Need anything
                              else?” which would have kept the user in the
                              session, if the user answered “No” to that,
                              another final step would have been something like
                              “Thank you for contacting us today!” And this
                              would be the end of the session.
                            </p>
                            <h3>
                              How to Configure Watson Actions or Dialog for
                              1Chatbot in the event of scenario #2 (above)
                            </h3>
                          </div>
                          <ul>
                            <li>
                              <strong>For Watson Actions</strong>
                              <div className="info-content font-weight-normal font-16 mt-2">
                                <p>
                                  For 1Chatbot to detect that slot filling, or
                                  that a conversation is ongoing (scenario #2
                                  above) session variables must be manually
                                  added to various conversation steps.
                                </p>
                                <p>
                                  <em>Which steps to add session variables</em>
                                </p>
                                <div className="image-block">
                                  <img src={wt1Img} alt="For Watson Actions" />
                                </div>
                                <p>
                                  If scenario #2 above exists then likely the
                                  1st step is where information is being
                                  collected from the end user. Whenever the
                                  information collecting begins (usually Step 1)
                                  then a session variable named “InProgress”
                                  with a type “boolean” should be created, and a
                                  value set to true.
                                </p>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          Go to the first conversation step in the action
                          details screen. Since this is the first step when slot
                          filling begins, the session variable should be added
                          here.
                        </li>
                        <li>
                          Click on the blue add variable icon in the top right
                          to set variable values
                        </li>
                        <li>
                          Click on “Set new value” then select “+ New session
                          variable”.
                        </li>
                        <li>
                          Enter the name “InProgress” with a type “boolean” and
                          “initial value” set to false.
                          <div className="info-content font-weight-normal font-16 mt-3">
                            <p>
                              <em className="i-icon icon-info-black" /> By
                              setting the “initial value” to false you will also
                              cover cases when “Fallback” or “No action matches”
                              is chosen – this way 1Chatbot knows to end the
                              conversation
                            </p>
                            <div className="image-block">
                              <img src={wt2Img} alt="watson" />
                            </div>
                          </div>
                        </li>
                        <li>
                          The variable now exists in the drop down for “Set new
                          value” — choose the newly created variable InProgress
                          and select “to Expression”.
                        </li>
                        <li>
                          In the Expression input field type true.
                          <div className="info-content font-weight-normal font-16 mt-3">
                            <div className="image-block">
                              <img src={wt2Img} alt="watson" />
                            </div>
                            <p>
                              This sets the start of the conversation steps with
                              the InProgress session variable set to true.
                            </p>
                            <p>
                              In Step 1 a session variable “InProgress” with
                              type “boolean” was added and set to true. If Step
                              2 also contains more information gathering from
                              the end user, this session variable does not need
                              to be set again, as it already exists with the
                              value of true. Only when the session is meant to
                              be terminated (denoted by the setting “End the
                              Action”) is it required to set “InProgress” to
                              false.
                            </p>
                            <p>
                              To reiterate, the addition of the session variable
                              InProgress = true only needs to be placed in the
                              first step of a series of conversation steps. In
                              this example, Step 2 does not need such editing.
                            </p>
                            <p>
                              To summarize when to set InProgress to false: when
                              all info has been obtained and the conversation
                              ends (usually the last step of the conversation
                              steps, but it can also be an action such as “Can I
                              help with anything else?”)
                            </p>
                            <div className="image-block">
                              <img src={wt3Img} alt="watson" />
                            </div>
                            <p>
                              In the above screenshot the last step in the
                              conversation step sequence is the end of the
                              action, since slot filling has been completed,
                              here the “InProgress” global variable is set to
                              false.
                            </p>
                            <p>
                              For actions that only contain 1 conversation step
                              (with no slot filling), no edits need to be made
                              and no global variables need to be added as the
                              interaction is just a 1-step conversation
                              involving a question/response.
                            </p>
                          </div>
                          <ul>
                            <li>
                              <strong>For Watson Dialog</strong>
                              <div className="info-content font-weight-normal font-16 mt-2">
                                For 1Chatbot to detect that slot filling, or a
                                conversation flow that is ongoing (scenario #2
                                above) we need to manually add a context
                                variable in the Context editor (InProgress =
                                <em>true</em>), and when the conversation is
                                completed to InProgress = <em>false</em>.
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          Click Dialog in the left menu the select the node
                          which handles the intent.
                          <div className="image-block">
                            <img src={wt4Img} alt="watson" />
                          </div>
                        </li>
                        <li>
                          Click Customize in the top right to open the menu
                          option to select “Slots” and “Multiple Conditioned
                          Responses” — ensure these are set to “On.”
                          <div className="image-block">
                            <img src={wt5Img} alt="watson" />
                          </div>
                        </li>
                        <li>
                          In the flow to the right, go to the “Then check for”
                          section and click on the cogwheel to the right of
                          required.
                          <div className="image-block">
                            <img src={wt6Img} alt="watson" />
                          </div>
                        </li>
                        <li>
                          Click on the 3-dot menu to the right of “If slot
                          context variable is not present ask” and select “Open
                          context editor.”
                          <div className="image-block">
                            <img src={wt7Img} alt="watson" />
                          </div>
                        </li>
                        <li>
                          Add a new variable called “InProgress” (automatically
                          changed to $InProgress) with a value of true. This
                          denotes that, if the end user is in this step, there
                          is still ongoing conversational activity such as slot
                          filling occurring.
                          <div className="info-content font-weight-normal font-16 mt-3">
                            <div className="image-block">
                              <img src={wt8Img} alt="watson" />
                            </div>
                            <p>
                              If this has been added to a required slot then it
                              is not necessary to add it to additional slots and
                              parameters in this node, as it is required and
                              just 1 update to “true” is sufficient.
                            </p>
                            <p>
                              <strong>
                                Lastly, the setting of “InProgress” to false
                                needs to be handled.
                              </strong>
                            </p>
                            <p>
                              When all slot filling is completed and there is no
                              transition to another node, or an action within
                              the assistant then the “InProgress” context
                              variable should be set to false (in the last step
                              of the node under the “Assistant responds”
                              section).
                            </p>
                          </div>
                        </li>
                        <li>
                          In the “Assistant responds” section, select the
                          cogwheel next to the response.
                          <div className="image-block">
                            <img src={wt6Img} alt="watson" />
                          </div>
                        </li>
                        <li>
                          Click the 3-dot menu to "Open context editor".
                          <ul>
                            <li>
                              Set the context variable "InProgress" to{" "}
                              <em>false</em>.
                            </li>
                          </ul>
                          <div className="info-content font-weight-normal font-16 mt-3">
                            <div className="image-block">
                              <img src={wt5Img} alt="watson" />
                            </div>
                            <p>
                              To summarize, whenever Watson Dialog has ongoing
                              slot filling the context variable InProgress is
                              set to true in the first part of the conversation
                              under the “Then check for” section.
                            </p>
                            <p>
                              Once all variables have been obtained and the
                              conversation is considered closed, then the
                              context variable is updated to false in the
                              “Assistant responds” section.
                            </p>
                            <p>
                              It may be possible that you want the “InProgress”
                              to be set to false only in another dialog node or
                              action such as the follow up request “Can I help
                              with anything else” so the “InProgress” can be set
                              to false elsewhere, however it is required to
                              update the settings of true/false accordingly so
                              that 1Chatbot can determine when the same bot
                              should be contacted multiple times in a row.
                            </p>
                          </div>
                        </li>
                        <li>
                          Finally, ensure in your Watson Dialog Anything Else
                          (anything_else) node that these steps are repeated and
                          InProgress is set to false in the context editor for a
                          conditional response in the “Assistant responds”
                          section:
                          <div className="info-content font-weight-normal font-16 mt-3">
                            <div className="image-block">
                              <img src={wt9Img} alt="watson" />
                            </div>
                            <p>
                              (“If assistant recognizes” should be left blank in
                              this case, so the context variable is set in any
                              case)
                            </p>
                            <p>
                              <strong>
                                That’s it! If you’ve added global variables
                                InProgress = true/false to your Watson actions
                                and context variables InProgress = true/false to
                                your Watson dialog nodes then you’re all set and
                                can begin using 1Chatbot orchestration
                                effectively.
                              </strong>
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default DocsChatbotNluWTConfigGuide;
