import React from "react";

const Note = ({ children }) => (
  <div className="note-container">
    <div className="note-icon">
      <em className="i-icon icon-note-info" />
    </div>
    <div className="alert alert-primary note-text mb-0">
      <strong>Note</strong>
      {children}
    </div>
  </div>
);

export default Note;
