import React from "react";
import LoaderIcon from "../../public/images/loader.svg";

const Button = ({
  className,
  dataDismiss,
  ariaLabel,
  onClickFunc,
  imgSrc,
  ButtonValue,
  id,
  type,
  hidden = false,
  disabled = false,
  isLoading = false,
  customStyle
}) => {
  let style = customStyle || {};
  var typeBtn = "button";
  if (type) {
    typeBtn = type;
  }

  return (
    <button
      type={typeBtn}
      className={className}
      data-dismiss={dataDismiss}
      aria-label={ariaLabel}
      onClick={onClickFunc}
      style={style}
      id={id}
      disabled={disabled}
      hidden={hidden}
    >
      {imgSrc && (
        <span aria-hidden="true">
          <img src={imgSrc} alt="In-App Chat close" />
        </span>
      )}
      {isLoading && (
        <img
          className="loader"
          src={LoaderIcon}
          alt="In-App Chat loader"
          style={{
            paddingLeft: "3px",
            paddingBottom: "3px",
            paddingRight: "3px"
          }}
        />
      )}
      {ButtonValue}
    </button>
  );
};

export default Button;
