import React, { Component } from "react";
import SitePageContainer from "../../common/sitePageContainer";
import PeerToPeerContent from "../solution/peerToPeerContent";
import PeerToMachineContent from "../solution/peerToMachineContent";
import MachineToMachineContent from "../solution/machineToMachineContent";
import OneChatbotContent from "../solution/oneChatbotContent";

class SolutionPage extends Component {
  render() {
    const type = this.props.type;
    return (
      <SitePageContainer pageName={type} showChatbot={true}>
        {type === "p2p" && <PeerToPeerContent />}
        {type === "p2m" && <PeerToMachineContent />}
        {type === "m2m" && <MachineToMachineContent />}
        {type === "1chatbot" && <OneChatbotContent />}
      </SitePageContainer>
    );
  }
}

export default SolutionPage;
