import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

class DocCategoryNavBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const navItems = [
      {
        title: "Docs Home",
        url: "/docs",
        childUrls: []
      },
      {
        title: "Chat SDKs",
        url: "/docs/chatsdk",
        childUrls: [
          "/docs/chatsdk/ios",
          "/docs/chatsdk/android",
          "/docs/chatsdk/javascript"
        ]
      },
      /* {
        title: "UIKits",
        url: "/docs/uikit"
      }, */
      {
        title: "Chatbots",
        url: "/docs/chatbots",
        childUrls: []
      }
    ];
    const loc = this.props?.location?.pathname || "";
    return (
      <div className="nav-breadcrumb">
        <div className="container">
          <ul className="list-item">
            {navItems.map(item => (
              <li key={item.title}>
                <Link
                  to={item.url}
                  className={
                    [item.url, ...(item.childUrls || [])].includes(
                      loc.toLowerCase()
                    )
                      ? "active"
                      : ""
                  }
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(DocCategoryNavBar);
