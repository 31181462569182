import React, { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { getCurrentOrganization } from "../../../utils/commonUtil";
import Button from "../../common/button";
import Modal from "../../common/modal";
import InputText_v2 from "../../common/inputText_v2";
import connect from "react-redux/es/connect/connect";
import { showErrorToast, showSuccessToast } from "../../../actions/ui";

class ChangeOwnerModal extends Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.state = { isSubmitting: false };
  }

  handleFormSubmit(values) {
    this.setState({ isSubmitting: true });
    const organization = getCurrentOrganization(this.props.curUser);
    this.props
      .changeOwner(organization._id, this.props.newOwnerId, values.password)
      .then(() => {
        this.setState({ isSubmitting: false });
        setTimeout(() => {
          this.closeForm();
          //window.location.reload();
          this.props.fetch();
          this.props.ownerChangeSuccess();
        }, 2000);
        this.props.showSuccessToast("Owner changed successfully");
      })
      .catch(error => {
        console.log(error);
        this.setState({ isSubmitting: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  }

  closeForm() {
    this.setState({ isSubmitting: false });
    this.props.reset();
    this.props.onHide();
    //window.location.reload();
  }

  render() {
    const { handleSubmit, show } = this.props;
    return (
      <Modal
        show={show}
        id={"changeOwnerModal"}
        title={"Confirm change of ownership"}
        onCloseHandler={this.closeForm}
      >
        <Form
          id="changeOwnerForm"
          className="addAdminForm"
          onSubmit={handleSubmit(this.handleFormSubmit)}
        >
          <div className="row">
            <Field
              containerClass="form-group col-12"
              type="password"
              inputTextClass="form-control custom-input"
              id="password"
              labelFor="password"
              label="Enter your password to authenticate this request"
              placeholder="Password"
              name="password"
              component={InputText_v2}
            />
            <div className="modal-action col-12">
              <button
                className="btn btn-only-text text-gray"
                type="button"
                onClick={this.closeForm}
              >
                Cancel
              </button>
              <Button
                className="btn btn-primary btn-md"
                type="submit"
                isLoading={this.state.isSubmitting}
                ButtonValue={"Confirm"}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
}

const validate = values => {
  const errors = {};
  if (!values.password) {
    errors.password = "Please Enter Password !";
  }
  return errors;
};

const reduxFormComp = reduxForm({
  form: "changeOwnerForm",
  validate,
  enableReinitialize: true
});

export default reduxFormComp(
  connect(null, {
    showSuccessToast,
    showErrorToast
  })(ChangeOwnerModal)
);
