import React, { useState } from "react";

const ExpandableCardsBlock = props => {
  //const [expandedItem, setExpandedItem] = useState(props.cards.length > 0 ? props.cards[0].id : "");
  const [expandedItems, setExpandedItems] = useState(
    props.cards.length > 0 ? [props.cards[0].id] : []
  );

  const onClickCard = id => {
    if (expandedItems.includes(id)) {
      const arr = expandedItems.filter(item => item !== id);
      setExpandedItems(arr);
    } else {
      setExpandedItems(arr => [...arr, id]);
    }
  };

  return (
    <>
      {props.cards.map(card => {
        return (
          <div
            className="card wow fadeInUp"
            data-wow-delay={card.wowDelay}
            key={card.id}
          >
            <div
              className={`card-header ${
                expandedItems.includes(card.id) ? "" : "collapsed"
              }`}
              onClick={() => onClickCard(card.id)}
            >
              <h3>{card.title}</h3>
            </div>
            <div
              id="collapseMoreFun"
              className={`collapse ${
                expandedItems.includes(card.id) ? "show" : ""
              }`}
            >
              <div className="card-body">
                <p>{card.bodyJsx}</p>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ExpandableCardsBlock;
