import React, { Component } from "react";
import PortalLogoIcon from "../../../public/resources/common/images/logo-mark.svg";
import { connect } from "react-redux";
import { GET_USER_CALL_KEY } from "../../../actions/users";
import { withRouter, Link } from "react-router-dom";
import ProfileDropdown from "../../common/profileDropdown";
//const isBuyNowEnabled = config.CLOUD_HOSTED_ENABLED || config.ON_PREMISE_ENABLED;

class CustomerSupportHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //profileopen: false,
      isSideBarOpen: false,
      showSolutionMenu: false
    };
    //this.onProfileClick = this.onProfileClick.bind(this);
    //this.onLogoutClick = this.onLogoutClick.bind(this);
    //this.onProjectListClick = this.onProjectListClick.bind(this);
    //this.handleBuyNowClick = this.handleBuyNowClick.bind(this);
    //this.handleTryFreeClick = this.handleTryFreeClick.bind(this);
    //this.onModuleClick = this.onModuleClick.bind(this);
    this.onTogglePopupMenuClick = this.onTogglePopupMenuClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleClickOutsideOptions = this.handleClickOutsideOptions.bind(this);
    this.sidebarOptionsRef = React.createRef();
  }

  /*onModuleClick(category) {
        return () => {
            if (category.code) {
                window.location.href = `/features?key=${category.code}`;
                //history.push({
                //pathname: '/marketplace',
                //search: `key=${category.code}`
              //});
            }
        };
    }*/

  /*onProfileClick() {
        this.setState({profileopen: !this.state.profileopen, supportopen: false});
    }*/

  /*onLogoutClick() {
        this.props.logoutUser();
    }*/

  /*onHandleMouseUp(e){
      e.stopPropagation();
      if(this==e.target){
        return;
      }
      this.setState({ profileopen: false });
    }*/

  onTogglePopupMenuClick() {
    if (this.state.isSideBarOpen) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.classList.add("overflow-hidden");
    }
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  }

  handleClickOutsideOptions(event) {
    if (
      this.sidebarOptionsRef &&
      this.sidebarOptionsRef.current &&
      !this.sidebarOptionsRef.current.contains(event.target) &&
      this.state.isSideBarOpen === true
    ) {
      this.setState({ isSideBarOpen: false });
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("mousedown", this.handleClickOutsideOptions);
    //this.getCategories();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("mousedown", this.handleClickOutsideOptions);
  }

  handleScroll() {
    let target = document.documentElement.scrollTop
      ? document.documentElement
      : document.body;
    if (target.scrollTop > 10) {
      this.setState({
        scrollMore: true
      });
    } else {
      this.setState({
        scrollMore: false
      });
    }
  }

  /*getCategories() {
        this.setState({categoryLoading: true});
        this.props.getCategories()
            .then(result => {
                this.setState({categoryResultObj: result.actionDataObj, categoryLoading: false});
            })
            .catch(error => {
                this.setState({categoryResultObj: error.actionDataObj, categoryLoading: false});
            });
    }*/

  render() {
    // Adding showUserAccount to display login button/user account logo(if user is logged in)
    // We don't need to show login/user account on Login/SignUp pages
    const { fixedHeaderOnScroll = true } = this.props;
    var mainClass = "main-dashboard-header support-header";
    if (fixedHeaderOnScroll) {
      if (this.state.scrollMore) {
        mainClass = "main-dashboard-header support-header";
      }
    }

    let showProjectsButton = false;
    //let showTryFreeButton = null;

    if (
      this.props.isLoggedIn === true /*&& this.props.isProjectLoaded === true*/
    ) {
      if (this.props.projects.length >= 0) {
        showProjectsButton = true;
      }
      //showTryFreeButton = !checkForFreeProject(this.props.projects);
    } /*else if (this.props.isLoggedIn === false) {
            showTryFreeButton = true;
        }*/

    return (
      <>
        <header id="main-header" className={mainClass}>
          <nav className="navbar navbar-expand-md navbar-light">
            <div className="container-fluid p-0">
              <Link className="logo" to={"/home"}>
                <img src={PortalLogoIcon} alt="In-App Chat Logo" />
                <span>Support Center</span>
              </Link>
              <div
                className={`collapse navbar-collapse ${
                  this.state.isSideBarOpen ? "open" : ""
                }`}
              >
                {this.showMenuItems(showProjectsButton)}
              </div>
              {/*this.props.user && <ProfileDropdown/>*/}
              <div
                className={`bar-icon toggle-sidebar ${
                  this.props.user ? "ml-2" : ""
                } ${this.state.isSideBarOpen ? "toggle" : ""}`}
                onClick={this.onTogglePopupMenuClick}
              >
                <div className="line1" />
                <div className="line2" />
              </div>
              {/*<div className="d-lg-none">
                            <div
                              className={`collapse navbar-collapse d-lg-none ${this.state.isSideBarOpen ? "open" : ""}`} onClick={this.onTogglePopupMenuClick}>
                              <ul className="navbar-nav ml-auto">
                                <li className={"nav-item"}>
                                  <Link className={`nav-link ${this.props.selected === "home" ? "active" : ""}`} style={{cursor: "pointer"}}
                                        to='/home'>Home</Link>
                                </li>
                                <li className={"nav-item"}>
                                  <Link className={`nav-link ${this.props.selected === "features" ? "active" : ""}`} style={{cursor: "pointer"}}
                                        to='/features'>Features</Link>
                                </li>
                                <li className={"nav-item"}>
                                  <Link className={`nav-link ${this.props.selected === "pricing" ? "active" : ""}`} style={{cursor: "pointer"}}
                                        to='/pricing'>Pricing</Link>
                                </li>
                                <li className={"nav-item"}>
                                  <Link className={`nav-link ${this.props.selected === "contact" ? "active" : ""}`} style={{cursor: "pointer"}}
                                        to='/contact-us'>Contact</Link>
                                </li>
                                {this.showProjectsButton(showProjectsButton)}
                                {this.showSignInButton()}
                              </ul>
                            </div>
                          </div>*/}
            </div>
          </nav>
        </header>
        {this.props.headerContent}
      </>
    );
  }

  showMenuItems(showProjectsButton) {
    const { showMenu = true, user } = this.props;

    if (showMenu) {
      return (
        <div className="navbar-nav ml-auto">
          {/*<li className={"nav-item"}>
                    <a href="/home" className={`nav-link hvr-underline-from-center ${this.props.selected === "home" ? "active" : ""}`}>Home</a>
                  </li>
                  <li className={"nav-item"}>
                    <a href="/features" className={`nav-link hvr-underline-from-center ${this.props.selected === "features" ? "active" : ""}`}>Features</a>
                  </li>
                  <li className={"nav-item"}>
                    <a href="/pricing" className={`nav-link hvr-underline-from-center ${this.props.selected === "pricing" ? "active" : ""}`}>Pricing</a>
                  </li>*/}
          {/*<li className={`nav-item dropdown nav-dropdown ${this.state.showSolutionMenu ? 'show':''}`}>
                    <a href="#" className={'nav-link hvr-underline-from-center'} onClick={(e)=>{e.preventDefault(); this.setState({showSolutionMenu:!this.state.showSolutionMenu})}}>Solution</a>
                    <ul>
                      <li><a href="/peer-to-peer" className={this.props.selected === "p2p" ? "active" : ""}>Peer-to-Peer</a></li>
                      <li><a href="/conversational-ai" className={this.props.selected === "p2m" ? "active" : ""}>Chatbots</a></li>
                      <li><a href="/iot-controller" className={this.props.selected === "m2m" ? "active" : ""}>ChatOps</a></li>
                    </ul>
                  </li>*/}
          {/*<li className={"nav-item"}>
                    <a href="/contact-us" className={`nav-link hvr-underline-from-center ${this.props.selected === "contact" ? "active" : ""}`}>Contact</a>
                  </li>*/}
          {/*showProjectsButton && <li className={"nav-item"}><a href="/myprojects" className={`nav-link hvr-underline-from-center ${this.props.selected === "myprojects" ? "active" : ""}`}>Projects</a></li>*/}
          {showProjectsButton && (
            <div className={"nav-item"}>
              <Link to="/myprojects" className="nav-link menu">
                <em className="i-icon icon-projects" />
              </Link>
            </div>
          )}
          {/*typeof this.props.user === "undefined" && this.showSignInButton()*/}
          {user && <ProfileDropdown />}
        </div>
      );
    }
    return null;
  }

  showSignInButton() {
    //const {fixedHeaderOnScroll = true} = this.props;
    /*var loginButtonClass = "ertc-btn-white";
        if (fixedHeaderOnScroll) {
            if (this.state.scrollMore) {
                loginButtonClass = "ertc-btn-blue";
            }
        }*/
    /* KIRAN Fixed */
    //loginButtonClass = "hvr-grow-shadow";
    if (
      this.props.getUserCallState &&
      this.props.getUserCallState.isLoading === true
    ) {
      return (
        <li className="nav-item login login-btn">
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </li>
      );
    } else {
      if (typeof this.props.user === "undefined") {
        return (
          <>
            <li className="btn-primary">
              <Link to="/signup">Sign Up</Link>
            </li>
            <li className="btn-white">
              <Link to="/login">Login</Link>
            </li>
            {/*<li className="nav-item">
                            <Link
                                className={`nav-link btn btn-primary || ""}`}
                                style={{cursor: "pointer"}}
                                to='/signup'>Sign Up</Link>
                        </li>
                        <li className="nav-item login-dropdown dropdown custom-dropdown">
                            <Link
                                className={`nav-link btn || ""}`}
                                style={{cursor: "pointer"}}
                                to='/login'>Log In</Link>
                        </li>*/}
          </>
        );
      }
    }
  }
}

function mapStatsToProps(stats) {
  return {
    user: stats.user.user,
    isLoggedIn: stats.user.isLoggedIn,
    getUserCallState:
      stats.ui.apiCallStates && stats.ui.apiCallStates[GET_USER_CALL_KEY],
    projects: stats.project.projects,
    isProjectLoaded: stats.project.isProjectLoaded
  };
}

export default connect(mapStatsToProps, {})(withRouter(CustomerSupportHeader));
