import React, { Component } from "react";
import { change, Field, Form, reduxForm } from "redux-form";
import { ActionSuccess } from "../../../utils/httpResponseErrorHandler";
import {
  capitalizeFirstLetter,
  getCurrentOrganization
} from "../../../utils/commonUtil";
import connect from "react-redux/es/connect/connect";
import { createRole, getRole, updateRole } from "../../../actions/organization";
import { withRouter, Link } from "react-router-dom";
import { UserContext } from "../../common/context/userContextProvider";
import { inviteUser } from "../../../actions/users";
import AddNewAdminModal from "./addNewAdminModal";
import LoadingDisplay from "../../common/loadingDisplay";
import history from "../../../history";
import { getProjects } from "../../../actions/projects";
import InputText_v2 from "../../common/inputText_v2";
import { scroller } from "react-scroll/modules";
import { isPageEditable } from "../../../utils/pageAccessUtil";
import CustomDropdown_v4 from "../../common/customDropdown_v4";
import SubmitButton from "../../common/submitButton";
import { showErrorToast, showSuccessToast } from "../../../actions/ui";

class OrganizationManageRolesContent extends Component {
  organization;
  static contextType = UserContext;
  data = [];
  rolesData = [];
  currentRole = {};
  activeMemberData = [];
  invitedMemberData = [];
  orgPermission = [];
  projPermission = [];
  projectAccessList = [];

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "active",
      isRoleLoading: true,
      isViewOnly: false,
      ascendingSorting: true,
      addMemberModalShow: false,
      removeUserResultObj: null,
      removeUserLoading: false,
      inviteUserLoading: false,
      dropDownMenuId: "",
      deleteMemberId: "",
      resendInviteEmail: "",
      changeOwnerId: "",
      searchInputText: "",
      projectAccess: "1",
      isProjectsLoading: true,
      selectedRoleForInvite: "",
      isSubmitting: false
    };

    this.onAddNewAdminClick = this.onAddNewAdminClick.bind(this);
    this.fetchRole = this.fetchRole.bind(this);
    this.onProjectAccessDropDownValueChange = this.onProjectAccessDropDownValueChange.bind(
      this
    );
    this.onDuplicateRoleClick = this.onDuplicateRoleClick.bind(this);
    this.checkBoxClick = this.checkBoxClick.bind(this);
    this.projectAccessCheckBoxClick = this.projectAccessCheckBoxClick.bind(
      this
    );
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  setDefaultPermissions() {
    this.orgPermission.push({
      key: "ORG_GENERAL",
      label: "General",
      permission: ""
    });
    this.orgPermission.push({
      key: "ORG_MEMBERS",
      label: "Members",
      permission: ""
    });
    this.orgPermission.push({
      key: "ORG_ROLES",
      label: "Roles",
      permission: ""
    });
    this.orgPermission.push({
      key: "ORG_CREATEPROJECT",
      label: "Create Project",
      permission: "",
      hideView: true
    });

    this.projPermission.push({
      key: "PROJ_OVERVIEW",
      label: "Overview",
      permission: ""
    });
    this.projPermission.push({
      key: "PROJ_USERS",
      label: "Users",
      permission: ""
    });
    this.projPermission.push({
      key: "PROJ_EXPORTMESSAGE",
      label: "Export Message",
      permission: ""
    });
    this.projPermission.push({
      key: "PROJ_MODERATION",
      label: "Moderation",
      permission: ""
    });
    this.projPermission.push({
      key: "PROJ_CHATBOT",
      label: "Bots",
      permission: ""
    });
    this.projPermission.push({
      key: "PROJ_SECURITY",
      label: "Security",
      permission: "",
      hideAll: true
    });
    this.projPermission.push({
      key: "PROJ_COMPLIANCE",
      label: "Compliance",
      permission: "",
      hideAll: true
    });
    this.projPermission.push({
      key: "PROJ_INFRASTRUCTURE",
      label: "Infrastructure",
      permission: "",
      hideAll: true
    });
    this.projPermission.push({
      key: "PROJ_USAGE",
      label: "Usage",
      permission: "",
      hideAll: true
    });
    this.projPermission.push({
      key: "PROJ_BILLING",
      label: "Billing",
      permission: ""
    });
    this.projPermission.push({
      key: "PROJ_ANALYTICS",
      label: "Analytics",
      permission: "",
      hideAll: true
    });
    this.projPermission.push({
      key: "PROJ_SETTINGS",
      label: "Settings",
      permission: ""
    });
    this.projPermission.push({
      key: "PROJ_UPGRADEPROJECT",
      label: "Upgrade Project",
      permission: "",
      hideView: true
    });
  }

  UNSAFE_componentWillMount() {
    this.setDefaultPermissions();
    this.fetchProjects();

    this.setState({ isViewOnly: this.props.pageName === "viewrole" });
  }

  onAddNewAdminClick() {
    if (this.currentRole && this.currentRole.id) {
      this.setState({ selectedRoleForInvite: this.currentRole.id });
    }
    this.setState({ addMemberModalShow: !this.state.addMemberModalShow });
  }

  fetchProjects() {
    if (this.props.user) {
      const organization = getCurrentOrganization(this.props.user);
      this.setState({ isProjectsLoading: true });
      this.props
        .getProjects(organization._id)
        .then(result => {
          const resultData = result.actionDataObj;
          if (resultData && resultData instanceof ActionSuccess) {
            if (resultData.data && resultData.data.projects) {
              resultData.data.projects.map(item => {
                if (
                  item.status &&
                  item.status.state &&
                  item.status.state !== "inactive"
                ) {
                  this.projectAccessList.push({
                    key: item.projectId,
                    label: item.name,
                    permission: ""
                  });
                }
              });

              const { role } = this.props.location;
              const params = new URLSearchParams(this.props.location.search);
              const roleId = params.get("id");
              if (roleId) this.fetchRole(roleId);
              else {
                this.setState({ isRoleLoading: false });
                if (role) this.initPermissionObj(role);
              }
            }
          }
          this.setState({ isProjectsLoading: false });
        })
        .catch(error => {
          console.log(error);
          this.setState({ isProjectsLoading: false });
          this.props.showErrorToast(
            "Fetch Projects: " + error.actionDataObj.message
          );
        });
    }
  }

  convertToDefaultRoleObj(item) {
    const find1 = item.indexOf("_");
    const find2 = item.indexOf("__");
    if (find1 > 0 && find2 > 0) {
      const key = item.substring(0, find2);
      const label = item.substring(find1 + 1, find2).toLowerCase();
      const permission = item.substr(find2 + 2);
      return { key: key, label: label, permission: permission };
    }
    return null;
  }

  initPermissionObj(role) {
    role.orgPermissions &&
      role.orgPermissions.map(item => {
        const roleObj = this.convertToDefaultRoleObj(item);
        const index = this.orgPermission.findIndex(
          obj => obj.key === roleObj.key
        );
        if (index > -1)
          this.orgPermission[index].permission = roleObj.permission;
      });

    let projaccess = "1";
    if (
      role.projectPermissions &&
      role.projectPermissions.length > 0 &&
      role.projectPermissions[0]
    ) {
      if (role.projectPermissions[0].permissions) {
        role.projectPermissions[0].permissions.map(item => {
          const roleObj = this.convertToDefaultRoleObj(item);
          const index = this.projPermission.findIndex(
            obj => obj.key === roleObj.key
          );
          if (index > -1)
            this.projPermission[index].permission = roleObj.permission;
        });
      }

      if (
        role.projectPermissions[0].projects.length > 0 &&
        role.projectPermissions[0].projects[0]
      ) {
        if (role.projectPermissions[0].projects[0].toLowerCase() === "all") {
          this.setState({ projectAccess: "1" });
          projaccess = "1";
        } else {
          this.setState({ projectAccess: "2" });
          projaccess = "2";
          role.projectPermissions[0].projects.map(projId => {
            const index = this.projectAccessList.findIndex(
              obj => obj.key === projId
            );
            if (index > -1) this.projectAccessList[index].permission = "1";
          });
        }
      }
    }

    if (role.name === "admin" || role.name === "superAdmin") {
      for (let i = 0; i < this.orgPermission.length; i++) {
        this.orgPermission[i].permission = "all";
        if (this.orgPermission[i].hideAll)
          this.orgPermission[i].permission = "view";
        if (this.orgPermission[i].hideView)
          this.orgPermission[i].permission = "all";

        if (
          role.name === "admin" &&
          this.orgPermission[i].key === "ORG_GENERAL"
        )
          this.orgPermission[i].permission = "view";
      }

      for (let i = 0; i < this.projPermission.length; i++) {
        this.projPermission[i].permission = "all";
        if (this.projPermission[i].hideAll)
          this.projPermission[i].permission = "view";
        if (this.projPermission[i].hideView)
          this.projPermission[i].permission = "all";
      }
      this.setState({ projectAccess: "1" });
      projaccess = "1";
    }

    this.props.initialize({
      roleName: role.displayName,
      keyRole: role.keyRole,
      projectAccess: projaccess
    });
    this.props.dispatch(change("manageRoleForm", "projectAccess", projaccess));
  }

  fetchRole(roleId) {
    const organization = getCurrentOrganization(this.context.user);
    this.setState({ isRoleLoading: true });
    this.props
      .getRole(organization._id, roleId)
      .then(result => {
        const resultObj = result.actionDataObj;
        this.currentRole = resultObj.data || {};
        this.initPermissionObj(this.currentRole);

        this.setState({ isRoleLoading: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isRoleLoading: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  }

  onProjectAccessDropDownValueChange(value) {
    this.setState({ projectAccess: value });
  }

  onDuplicateRoleClick() {
    history.push({
      pathname: "/organizations/createrole",
      role: this.currentRole
    });
  }

  projectAccessCheckBoxClick(e, item) {
    var cbvalue = e.target.checked;
    if (cbvalue) {
      item.permission = "1";
    } else {
      item.permission = "";
    }
    this.forceUpdate();
  }

  checkBoxClick(e, item, permission) {
    var cbvalue = e.target.checked;
    if (cbvalue) {
      item.permission = permission;
    } else {
      item.permission = "";
    }
    this.forceUpdate();
  }

  handleFormSubmit(values) {
    const organization = getCurrentOrganization(this.context.user);
    const orgPermission = [];
    this.orgPermission.map(item => {
      if (item.permission !== "") {
        const permission = `${item.key}__${item.permission.toUpperCase()}`;
        orgPermission.push(permission);
      }
    });

    const projPermission = [];
    this.projPermission.map(item => {
      if (item.permission !== "") {
        const permission = `${item.key}__${item.permission.toUpperCase()}`;
        projPermission.push(permission);
      }
    });

    let projects = [];
    if (this.state.projectAccess === "1") {
      projects.push("ALL");
    } else {
      this.projectAccessList.map(item => {
        if (item.permission === "1") {
          projects.push(item.key);
        }
      });
    }

    if (orgPermission.length === 0 && projPermission.length === 0) {
      this.props.showErrorToast(
        "At least one permission should be selected for a role"
      );
      return;
    }

    if (projects.length === 0) {
      this.props.showErrorToast("Select project for this role to access");
      return;
    }

    this.setState({ isSubmitting: true });
    const role = {
      displayName: values.roleName.trim(),
      keyRole: values.keyRole && values.keyRole.trim(),
      orgPermissions: orgPermission,
      projectPermissions: [{ projects: projects, permissions: projPermission }]
    };
    if (this.props.pageName === "editrole") {
      this.props
        .updateRole(organization._id, this.currentRole.id, role)
        .then(() => {
          setTimeout(() => {
            history.push({
              pathname: "/organizations/viewrole",
              search: `id=${this.currentRole.id}`
            });
          }, 2000);

          this.props.showSuccessToast("Role updated successfully");
          this.setState({ isSubmitting: false });
        })
        .catch(error => {
          console.log(error);
          this.setState({ isSubmitting: false });
          this.props.showErrorToast(error.actionDataObj.message);
        });
    } else {
      this.props
        .createRole(organization._id, role)
        .then(() => {
          setTimeout(() => {
            history.push("/organizations/roles");
          }, 2000);
          this.setState({ isSubmitting: false });
          this.props.showSuccessToast("Role created successfully");
        })
        .catch(error => {
          console.log(error);
          this.setState({ isSubmitting: false });
          this.props.showErrorToast(error.actionDataObj.message);
        });
    }
  }

  renderHeader(name) {
    const isViewOnly = this.state.isViewOnly;
    return (
      <thead>
        <tr className={isViewOnly ? "bg-gray" : ""}>
          <th>{name}</th>
          {isViewOnly && <th className="text-center w300">Access</th>}
          {!isViewOnly && <th className="text-center">All</th>}
          {!isViewOnly && <th className="text-center">View</th>}
        </tr>
      </thead>
    );
  }

  renderBlankRow() {
    const isViewOnly = this.state.isViewOnly;
    return (
      <tr className="blank-td">
        <td />
        <td />
        {!isViewOnly && <td />}
      </tr>
    );
  }

  isSectionHasPermissionForViewOnly(permissionData) {
    let hasPermission = false;
    permissionData.forEach(item => {
      if (item.permission.trim().length > 0) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  renderPermissionData(permissionData) {
    const isViewOnly = this.state.isViewOnly;
    const items = [];
    permissionData.map(item => {
      if (!isViewOnly || item.permission.trim().length > 0) {
        const viewKey = `${item.key}__VIEW`;
        const allKey = `${item.key}__ALL`;
        const allChecked =
          item.permission && item.permission.toLowerCase() === "all";
        const viewChecked =
          item.permission && item.permission.toLowerCase() === "view";
        items.push(
          <tr key={item.key}>
            <td>{capitalizeFirstLetter(item.label)}</td>
            {isViewOnly ? (
              <td className="text-center w300">
                <span>{capitalizeFirstLetter(item.permission)}</span>
              </td>
            ) : (
              <>
                <td className="checkbox text-center w100">
                  {item.hideAll ? (
                    <></>
                  ) : (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={allChecked}
                        onChange={e => this.checkBoxClick(e, item, "all")}
                        id={allKey}
                      />
                      <label
                        className="custom-control-label pl-0"
                        htmlFor={allKey}
                      />
                    </div>
                  )}
                </td>
                <td className="checkbox text-center w100">
                  {item.hideView ? (
                    <></>
                  ) : (
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={viewChecked}
                        onChange={e => this.checkBoxClick(e, item, "view")}
                        id={viewKey}
                      />
                      <label
                        className="custom-control-label pl-0"
                        htmlFor={viewKey}
                      />
                    </div>
                  )}
                </td>
              </>
            )}
          </tr>
        );
      }
    });
    return items;
  }

  renderProjectCheckboxes(isCheckBoxDisabled) {
    const items = [];
    const totalRowItems = 2;
    let projectList = this.projectAccessList;
    /*if(isCheckBoxDisabled) {
        projectList = this.projectAccessList.filter(item => item.permission === '1') ;
      }*/

    for (let i = 0; i < projectList.length; i++) {
      const projectBlock = [];
      for (let j = i; j < projectList.length && j < i + totalRowItems; j++) {
        projectBlock.push(projectList[j]);
      }

      items.push(
        <div className="project-name-block" key={`main_${projectBlock[0].key}`}>
          {projectBlock.map(item => {
            return (
              <div className="custom-control custom-checkbox" key={item.key}>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  onChange={e => this.projectAccessCheckBoxClick(e, item)}
                  id={item.key}
                  disabled={isCheckBoxDisabled}
                  checked={item.permission === "1"}
                />
                <label className="custom-control-label mb-0" htmlFor={item.key}>
                  {item.label}
                </label>
              </div>
            );
          })}
        </div>
      );
      i += totalRowItems - 1;
    }
    return items;
  }

  renderProjectList(isCheckBoxDisabled) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="title pb-0">
            <h2 className="h5">Select Projects</h2>
          </div>
          <div className="select-project-block">
            {this.state.isProjectsLoading || this.state.isRoleLoading ? (
              <LoadingDisplay key={"loading1"} marginTop="20px" left="50%" />
            ) : (
              this.renderProjectCheckboxes(isCheckBoxDisabled)
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { handleSubmit } = this.props;
    const projectAccessOptions = [];
    projectAccessOptions.push({ value: "1", displayValue: "All Projects" });
    projectAccessOptions.push({
      value: "2",
      displayValue: "Selected Projects"
    });

    const isViewOnly = this.state.isViewOnly;
    const showProjectList = this.state.projectAccess === "2";

    const isEditable = isPageEditable(
      "/organizations/roles",
      this.context.user
    );

    const hideInvitationButton =
      this.currentRole &&
      this.currentRole.name &&
      this.currentRole.name.toLowerCase() === "superadmin";
    const title =
      this.currentRole && this.currentRole.displayName
        ? this.currentRole.displayName
        : "New Role";
    const keyRole =
      this.currentRole && this.currentRole.keyRole
        ? this.currentRole.keyRole
        : "Only have access to assigned permissions";
    if (this.state.isProjectsLoading || this.state.isRoleLoading) {
      return <LoadingDisplay marginTop="20px" />;
    } else {
      return (
        <>
          <div className="content">
            <div className="title-box mb-1">
              <div className="title">
                <h1>{title}</h1>
              </div>
            </div>
            <Link to="/organizations/roles" className="back-link">
              <em className="i-icon icon-back-arrow" />
              Back to Roles
            </Link>
            <div className="overview-boxes">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 m-30">
                  <div className="overview-box">
                    <Form
                      id="manageRoleForm"
                      onSubmit={handleSubmit(this.handleFormSubmit)}
                    >
                      <div className="row">
                        {isViewOnly && (
                          <>
                            <div className="col-12 col-sm-12 col-md-5 col-lg-6">
                              <div className="title pb-0">
                                <h2 className="h5">Key Role</h2>
                              </div>
                              <p className="desc-black mb-md-0">{keyRole}</p>
                            </div>
                            {isEditable && (
                              <div className="col-12 col-sm-12 col-md-7 col-lg-6 text-right">
                                <button
                                  className="btn btn-outline-primary btn-w160 mr-2 mr-md-3"
                                  type="button"
                                  onClick={this.onDuplicateRoleClick}
                                >
                                  Duplicate Role
                                </button>
                                {!hideInvitationButton && (
                                  <button
                                    className="btn btn-primary btn-w160"
                                    type="button"
                                    onClick={this.onAddNewAdminClick}
                                  >
                                    Send Invitation
                                  </button>
                                )}
                              </div>
                            )}
                          </>
                        )}
                        {!isViewOnly && (
                          <>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                              <Field
                                containerClass="form-group"
                                type="text"
                                inputTextClass="custom-input form-control"
                                name="roleName"
                                id="roleName"
                                labelFor="roleName"
                                label="Role Name"
                                placeholder="Add Name"
                                component={InputText_v2}
                              />
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                              <Field
                                containerClass="form-group"
                                type="text"
                                inputTextClass="custom-input form-control"
                                name="keyRole"
                                id="keyRole"
                                labelFor="keyRole"
                                label="Key Role"
                                placeholder="Optional"
                                component={InputText_v2}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-12">
                          <div className="title pb-0">
                            <h2 className="h5">Permissions</h2>
                          </div>
                          {!isViewOnly && (
                            <>
                              <p className="desc-gray font-14">
                                Determine the level of access this role will
                                have for each menu in your organization and
                                projects below. Please select from the
                                following:
                              </p>
                              <div>
                                <span className="desc-gray">
                                  <strong className="desc-black">All:</strong>{" "}
                                  This role has access to all features in a
                                  menu.
                                </span>
                                <span className="desc-gray pl-3">
                                  <strong className="desc-black">View:</strong>{" "}
                                  This role can only view a menu
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {!isViewOnly && <hr />}
                      <div className="record-table-list">
                        <div className="table-responsive">
                          {(!isViewOnly ||
                            (isViewOnly &&
                              this.isSectionHasPermissionForViewOnly(
                                this.orgPermission
                              ))) && (
                            <table
                              className={`table ${
                                isViewOnly ? "padding-x" : ""
                              }`}
                            >
                              {this.renderHeader("Organization")}
                              <tbody>
                                {this.renderPermissionData(this.orgPermission)}
                              </tbody>
                            </table>
                          )}
                          {!isViewOnly && <hr />}
                          {(!isViewOnly ||
                            (isViewOnly &&
                              this.isSectionHasPermissionForViewOnly(
                                this.projPermission
                              ))) && (
                            <table
                              className={`table ${
                                isViewOnly ? "padding-x mt-2" : ""
                              }`}
                            >
                              {this.renderHeader("Project")}
                              <tbody>
                                {this.renderPermissionData(this.projPermission)}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <div className="title pb-0">
                            <h2 className="h5">Project Access</h2>
                          </div>
                          <p className="desc-gray font-14">
                            {isViewOnly
                              ? `Allow access for ${
                                  showProjectList ? "the following" : "all the"
                                } projects`
                              : `This role can access ${
                                  showProjectList ? "the following" : "all the"
                                } projects`}
                          </p>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3" />
                        <div className="col-12 col-sm-12 col-md-3">
                          <div className="form-group mb-3">
                            {!isViewOnly && (
                              <div className="select-option">
                                <CustomDropdown_v4
                                  defaultValue={this.state.projectAccess}
                                  data={projectAccessOptions}
                                  name={"projectAccess"}
                                  onValueChange={
                                    this.onProjectAccessDropDownValueChange
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {showProjectList && this.renderProjectList(isViewOnly)}
                      {!isViewOnly && isEditable && (
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <div className="action mt-2">
                              <button
                                className="btn btn-only-text text-gray mr-5"
                                type="button"
                                onClick={() =>
                                  history.push("/organizations/roles")
                                }
                              >
                                Cancel
                              </button>
                              <SubmitButton
                                buttonClass="btn btn-primary btn-md"
                                isSubmitting={this.state.isSubmitting}
                              >
                                Save
                              </SubmitButton>
                            </div>
                          </div>
                        </div>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.addMemberModalShow && (
            <AddNewAdminModal
              show={this.state.addMemberModalShow}
              onHide={() =>
                this.setState({
                  addMemberModalShow: false,
                  selectedRoleForInvite: ""
                })
              }
              currentRoleId={this.state.selectedRoleForInvite}
              user={this.props.user}
              inviteUser={this.props.inviteUser}
              fetch={() => {}}
            />
          )}
        </>
      );
    }
  }
}

const validate = values => {
  const errors = {};

  const roleNameResult = validateRoleName(values.roleName);
  if (roleNameResult) errors.roleName = roleNameResult;

  const keyRoleResult = validateKeyRole(values.keyRole);
  if (keyRoleResult) errors.keyRole = keyRoleResult;

  return errors;
};

function validateRoleName(roleName) {
  if (!roleName || roleName.trim().length === 0) {
    return "Please Enter Role Name !";
  }

  if (roleName.trim().length < 3) {
    return "Length must be at least 3 characters long";
  }

  return null;
}

function validateKeyRole(keyRole) {
  if (!keyRole || keyRole.trim().length === 0) {
    return null;
  }

  if (keyRole.trim().length > 250) {
    return "Length must be less than or equal to 250 characters long";
  }

  return null;
}

function mapStatsToProps(state) {
  return {
    user: state.user.user
    //dirty: isDirty('orgsetting-form')(state),
  };
}

function scrollToError() {
  //window.scrollTo(0,0);
  scroller.scrollTo("roleName", {
    duration: 500,
    delay: 0,
    smooth: "easeInOutQuart",
    offset: -200
  });
}

const reduxFormComp = reduxForm({
  form: "manageRoleForm",
  validate,
  onSubmitFail: errors => scrollToError(errors)
});

export default reduxFormComp(
  connect(mapStatsToProps, {
    inviteUser,
    getProjects,
    createRole,
    updateRole,
    getRole,
    showSuccessToast,
    showErrorToast
  })(withRouter(OrganizationManageRolesContent))
);
