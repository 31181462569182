import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";

class ChatbotsDocSidebar extends Component {
  constructor(props) {
    super(props);
  }

  isLinkActive = url => {
    const loc = this.props?.location?.pathname || "";
    const hash = this.props?.location?.hash || "";
    const curUrl = `${loc}${hash}`;
    return curUrl.toLowerCase() === url;
  };

  render() {
    const sidebarItems = [
      {
        title: "Connecting To Nlu Provider",
        items: [
          {
            title: "Google DialogFlow ES",
            url: "/docs/chatbots/nlu/google-dialogflow",
            subItems: []
          },
          {
            title: "Amazon Lex",
            url: "/docs/chatbots/nlu/amazon-lex",
            subItems: []
          },
          {
            title: "IBM Watson",
            url: "/docs/chatbots/nlu/ibm-watson",
            subItems: [
              {
                title: "Watson Configuration Guide",
                url: "/docs/chatbots/nlu/ibm-watson/config-guide"
              }
            ]
          }
        ]
      },
      {
        title: "1chatbot Configuration",
        items: [
          {
            title: "Default Replies",
            url: "/docs/chatbots/1chatbot/default-replies",
            subItems: [
              {
                title: "Default reply naming conventions",
                url:
                  "/docs/chatbots/1chatbot/default-replies#naming-conventions",
                hash: "naming-conventions"
              },
              {
                title: "Default Reply Priority Chatbot",
                url: "/docs/chatbots/1chatbot/default-replies#priority-chatbot",
                hash: "priority-chatbot"
              }
            ]
          },
          {
            title: "Session Timeout",
            url: "/docs/chatbots/1chatbot/session-timeout",
            subItems: []
          }
        ]
      },
      {
        title: "Installing a Chat Widget",
        items: [],
        url: "", // "/docs/chatbots/installing-chat-widget",
        comingSoon: true
      } /*,
      {
        title: "Bot Factory",
        items: [],
        url: "",
        comingSoon: true
      }*/
    ];

    return (
      <div className="sticky-block">
        {sidebarItems.map(mainItem => (
          <div
            className={`nav flex-column credentials-list ${
              mainItem.url || mainItem.comingSoon ? "py-2" : ""
            }`}
            key={mainItem.title}
          >
            {mainItem.url ? (
              <Link
                className={`nav-link ${
                  this.isLinkActive(mainItem.url) ? "active" : ""
                }`}
                to={mainItem.url}
              >
                {mainItem.title}
              </Link>
            ) : (
              <div
                className={mainItem.comingSoon ? "nav-link" : "provider-title"}
              >
                {mainItem.title}
                {mainItem.comingSoon && <span>Coming Soon</span>}
              </div>
            )}
            {mainItem.items.map(item => (
              <Fragment key={item.title}>
                {item.subItems.length === 0 ? (
                  <Link
                    className={`nav-link ${
                      this.isLinkActive(item.url) ? "active" : ""
                    }`}
                    to={item.url}
                  >
                    {item.title}
                  </Link>
                ) : (
                  <div className="nav-sub-links">
                    <Link
                      className={`nav-link ${
                        this.isLinkActive(item.url) ? "active" : ""
                      }`}
                      to={item.url}
                    >
                      {item.title}
                    </Link>
                    <div className="is-scroll-y">
                      {item.subItems.map(subItem => (
                        <Fragment key={subItem.title}>
                          {subItem.hash ? (
                            <a
                              key={subItem.title}
                              className={`nav-link-inner ${
                                this.isLinkActive(subItem.url) ? "active" : ""
                              }`}
                              href={`#${subItem.hash}`}
                            >
                              {subItem.title}
                            </a>
                          ) : (
                            <Link
                              key={subItem.title}
                              className={`nav-link-inner ${
                                this.isLinkActive(subItem.url) ? "active" : ""
                              }`}
                              to={subItem.url}
                            >
                              {subItem.title}
                            </Link>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                )}
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default withRouter(ChatbotsDocSidebar);
