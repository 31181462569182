import React, { Component } from "react";
import CreateProjectPage_v3 from "../pages/createProject_v3";

export default function(projectType) {
  return class extends Component {
    render() {
      let sku;
      if (this.props.location.search) {
        const params = new URLSearchParams(this.props.location.search);
        sku = params.get("sku");
      }
      return (
        <CreateProjectPage_v3
          {...this.props}
          projectType={projectType}
          projectSku={sku}
        />
      );
    }
  };
}
