import React from "react";
import PropTypes from "prop-types";

const NavItem = ({ itemClass, children }) => {
  return (
    <li className={`${itemClass ? `nav-item ${itemClass}` : "nav-item"}`}>
      {children}
    </li>
  );
};

/**
 * @param children -> Nav Items
 */
const NavBar = ({ children }) => {
  return <ul className="navbar-nav ml-auto">{children}</ul>;
};

NavBar.propTypes = {
  children: PropTypes.arrayOf(NavItem)
};

export { NavBar, NavItem };
