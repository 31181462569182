import React, { Component } from "react";
import webchatImg from "../../../../public/resources/site/images/webchatImg.png";

class OneWebchatTitleBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tutorials-img-block pb-0">
        <div className="container">
          <div className="tutorials-content-block">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="title-content mb-0">
                  <h1 className="font-lg">1Webchat</h1>
                  <div className="info-content">
                    Combine powerful bots and create a 1Webchat that is capable
                    of performing a variety of tasks all from one chat
                    interface.
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="image-block text-md-right">
                  <img src={webchatImg} alt="1Webchat" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OneWebchatTitleBlock;
