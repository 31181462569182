import React, { Component } from "react";
import Modal from "../../common/modal";
import ServerResponse from "../../common/serverResponse";
import LoaderIcon from "../../../public/images/loader.svg";
import history from "../../../history";

class ChangeMemberRolesModal extends Component {
  //rolesData = [];
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    //this.onRoleDropDownValueChange = this.onRoleDropDownValueChange.bind(this);
    this.state = {};
  }

  onClose() {
    //this.setState({resultObj: null});
    this.props.onHide();
  }

  /*onRoleDropDownValueChange(value){
      this.setState({currentRole:value});
    }*/

  handleFormSubmit() {
    //if(this.props.submitHandler)
    // this.props.submitHandler();
    history.push("/organizations/members");
  }

  render() {
    const { show, memberList, submitResult, submitButtonLoading } = this.props;

    /*const roleOptions = [];
      this.rolesData && this.rolesData.map(role=>{
        if(roleToDelete && roleToDelete.id && roleToDelete.id === role.id){

        }else{
          roleOptions.push({ value: role.id, displayValue: role.name });
        }
      });*/

    return (
      <Modal
        show={show}
        id={"changeMemberRoleModal"}
        onCloseHandler={this.onClose}
        modalDialogueClass={"mx-500"}
      >
        <h4 className="mt-0 mb-3 text-center">Delete role?</h4>
        <div className="row">
          <div className="col-12">
            <p className="text-black text-center mb-4">
              There are currently members who are assigned this role. A new role
              must be provided before you may continue.
            </p>
          </div>
          <div className="col-12">
            <div className="row member-role heading">
              <div className={"col col-sm-4"}>Member</div>
              <div className={"col col-sm-8"}>Email</div>
              {/*<div>Role</div>*/}
            </div>
            <div className="member-select-block">
              {memberList &&
                memberList.map(item => (
                  <div className="row member-role" key={item.id}>
                    <div className={"col col-sm-4 text-truncate"}>
                      {item.name}
                    </div>
                    <div className={"col col-sm-8 text-truncate"}>
                      {item.email}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="action mt-3 col-12">
            <button
              className="btn btn-outline-secondary btn-md mr-0 mr-sm-3 mb-2 btn-w160"
              type="button"
              onClick={this.onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={"btn btn-primary btn-md m-0 mb-2 btn-w160"}
              onClick={this.handleFormSubmit}
            >
              Change Role
              {submitButtonLoading && (
                <img
                  className="loader"
                  src={LoaderIcon}
                  alt="In-App Chat loader"
                  style={{
                    paddingLeft: "3px",
                    paddingBottom: "3px"
                  }}
                />
              )}
            </button>
          </div>
          <ServerResponse
            customClass="mt-3"
            customContainerStyle={{ marginBottom: "0px" }}
            resultObj={submitResult}
          />
        </div>
      </Modal>
    );
  }
}

export default ChangeMemberRolesModal;
