import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import SiteHeader from "../../../common/siteHeader";
import DocCategoryNavBar from "../docCategoryNavBar";
import Footer_v2 from "../../common/footer_v2";

import LanguageTab from "./components/languageTab";
import LeftSidebar from "./components/leftSidebar";
import ChatSDKContent from "./components/content";

const DocsChatSDK = ({ match }) => {
  const activeLanguage = useMemo(() => match?.params?.language ?? "android", [
    match
  ]);

  return (
    <>
      <SiteHeader fixedHeaderOnScroll={false} isDark />
      <DocCategoryNavBar />
      <main className="content-wrapper main-content">
        <div className="content-left-block connecting-grid-block --scrollpage --chatsdk">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-xl-3 tab-inner-content">
                <div className="sticky-block">
                  <LanguageTab language={activeLanguage} />
                  <LeftSidebar language={activeLanguage} />
                </div>
              </div>
              <div className="col-lg-8 col-xl-9">
                <ChatSDKContent language={activeLanguage} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer_v2 />
    </>
  );
};

export default withRouter(DocsChatSDK);
