import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PortalLogoIcon from "../../public/resources/common/images/logo.svg";
import PortalWhiteLogoIcon from "../../public/resources/common/images/logo-white.svg";
import { connect } from "react-redux";
import { GET_USER_CALL_KEY } from "../../actions/users";
import ProfileDropdown from "./profileDropdown";
import { getAuth0Url } from "../../utils/commonUtil";
// import config from "../../config/config";
// import SearchDoc from "../site/home/searchDoc";

//const isBuyNowEnabled = config.CLOUD_HOSTED_ENABLED || config.ON_PREMISE_ENABLED;

class SiteHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //profileopen: false,
      isSideBarOpen: false,
      showSolutionMenu: false,
      showDocMenu: false,
      showPricingManu: false
    };
    //this.onProfileClick = this.onProfileClick.bind(this);
    //this.onLogoutClick = this.onLogoutClick.bind(this);
    //this.onProjectListClick = this.onProjectListClick.bind(this);
    //this.handleBuyNowClick = this.handleBuyNowClick.bind(this);
    //this.handleTryFreeClick = this.handleTryFreeClick.bind(this);
    /*this.onHandleMouseUp = this.onHandleMouseUp.bind(this);*/
    //this.onModuleClick = this.onModuleClick.bind(this);
    this.onTogglePopupMenuClick = this.onTogglePopupMenuClick.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleClickOutsideOptions = this.handleClickOutsideOptions.bind(this);
    this.OnSignupClick = this.OnSignupClick.bind(this);
    this.onStartFreeTrial = this.onStartFreeTrial.bind(this);
    this.onSignIn = this.onSignIn.bind(this);
    this.showMenuItems = this.showMenuItems.bind(this);
    this.sidebarOptionsRef = React.createRef();
  }

  /*onModuleClick(category) {
        return () => {
            if (category.code) {
                window.location.href = `/features?key=${category.code}`;
                //history.push({
                //pathname: '/marketplace',
                //search: `key=${category.code}`
              //});
            }
        };
    }*/

  /*onProfileClick() {
        this.setState({profileopen: !this.state.profileopen});
    }*/

  /*onLogoutClick() {
        this.props.logoutUser();
    }*/

  /*onHandleMouseUp(e){
      e.stopPropagation();
      if(this==e.target){
        return;
      }
      this.setState({ profileopen: false });
    }*/

  onTogglePopupMenuClick() {
    if (this.state.isSideBarOpen) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.classList.add("overflow-hidden");
    }
    this.setState({ isSideBarOpen: !this.state.isSideBarOpen });
  }

  handleClickOutsideOptions(event) {
    if (
      this.sidebarOptionsRef &&
      this.sidebarOptionsRef.current &&
      !this.sidebarOptionsRef.current.contains(event.target) &&
      this.state.isSideBarOpen === true
    ) {
      this.onTogglePopupMenuClick();
    }
  }

  OnSignupClick() {
    if (window.location.href.search("signup") > 0) {
      window.location.reload(false);
    }
  }

  onStartFreeTrial() {
    // Create new account with email and default password.
    if (this.props.isLoggedIn) {
      this.props.history.push("/myprojects");
    } else if (this.props.onStartFreeTrial) {
      this.props.onStartFreeTrial();
    }
  }

  onSignIn(e) {
    e.preventDefault();
    window.location.href = getAuth0Url("login");
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    document.addEventListener("mousedown", this.handleClickOutsideOptions);
    //this.getCategories();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("mousedown", this.handleClickOutsideOptions);
  }

  handleScroll() {
    let target = document.documentElement.scrollTop
      ? document.documentElement
      : document.body;
    if (target.scrollTop > 10) {
      this.setState({
        scrollMore: true
      });
    } else {
      this.setState({
        scrollMore: false
      });
    }
  }

  /*getCategories() {
        this.setState({categoryLoading: true});
        this.props.getCategories()
            .then(result => {
                this.setState({categoryResultObj: result.actionDataObj, categoryLoading: false});
            })
            .catch(error => {
                this.setState({categoryResultObj: error.actionDataObj, categoryLoading: false});
            });
    }*/

  render() {
    // Adding showUserAccount to display login button/user account logo(if user is logged in)
    // We don't need to show login/user account on Login/SignUp pages
    const {
      fixedHeaderOnScroll = true,
      isDark = false,
      onlyContact = false,
      containerClass = "",
      isCenterHeaderItems = true
    } = this.props;
    let mainClass = `main-header fixed-top ${containerClass}`;
    if (fixedHeaderOnScroll) {
      if (this.state.scrollMore) {
        if (isDark) {
          mainClass += " bg-dark";
        } else {
          mainClass += " bg-white";
        }
      }
    } else {
      if (isDark) {
        mainClass += " bg-dark";
      }
    }

    if (isDark) {
      mainClass += " main-header-dark";
    }

    if (isCenterHeaderItems) {
      mainClass += " header-center";
    }

    let showProjectsButton = false;
    //let showTryFreeButton = null;

    if (
      this.props.isLoggedIn === true /*&& this.props.isProjectLoaded === true*/
    ) {
      if (this.props.projects.length >= 0) {
        showProjectsButton = true;
      }
      //showTryFreeButton = !checkForFreeProject(this.props.projects);
    } /*else if (this.props.isLoggedIn === false) {
            showTryFreeButton = true;
        }*/

    return (
      <>
        <header className={mainClass} ref={this.sidebarOptionsRef}>
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <Link className="navbar-brand" to={"/home"}>
                <img
                  src={isDark ? PortalWhiteLogoIcon : PortalLogoIcon}
                  alt="In-App Chat Logo"
                  width={242}
                  height={35}
                />
              </Link>
              <div
                className={`collapse navbar-collapse ${
                  this.state.isSideBarOpen ? "open" : ""
                }`}
              >
                {this.showMenuItems(showProjectsButton, isDark, onlyContact)}
              </div>
              {this.props.isLoggedIn ? (
                <button
                  className="btn btn-yellow btn-start-free-trial"
                  onClick={this.onStartFreeTrial}
                >
                  Go to dashboard
                </button>
              ) : (
                <>
                  <button className="btn btn-sign-in" onClick={this.onSignIn}>
                    Sign in
                  </button>
                  <button
                    className="btn btn-yellow btn-start-free-trial"
                    onClick={this.onStartFreeTrial}
                  >
                    Start free trial
                  </button>
                </>
              )}
              {isDark && !onlyContact && (
                <div className="search-user-icon">
                  {/* <SearchDoc
                    apiKey={config.ALGOLIA_SEARCH_KEY}
                    indexName={config.ALGOLIA_INDEX_NAME}
                    apiId={config.ALGOLIA_APP_ID}
                  /> */}
                  {this.props.user && (
                    <ProfileDropdown containerClass={isDark ? "pl-0" : ""} />
                  )}
                  <div
                    className={`bar-icon toggle-sidebar ${
                      this.props.user ? "ml-2" : ""
                    } ${this.state.isSideBarOpen ? "toggle" : ""}`}
                    onClick={this.onTogglePopupMenuClick}
                  >
                    <div className="line1" />
                    <div className="line2" />
                  </div>
                </div>
              )}
              {!isDark && !onlyContact && (
                <>
                  {this.props.user && <ProfileDropdown />}
                  <div
                    className={`bar-icon toggle-sidebar ${
                      this.props.user ? "ml-2" : ""
                    } ${this.state.isSideBarOpen ? "toggle" : ""}`}
                    onClick={this.onTogglePopupMenuClick}
                  >
                    <div className="line1" />
                    <div className="line2" />
                  </div>
                </>
              )}
              {/*<ul className="navbar-nav out-collapse ml-auto d-flex d-lg-none">
                            {this.props.user && <ProfileDropdown/>}
                          </ul>*/}

              {/* <div className="d-lg-none">
                            <div
                                className={`collapse navbar-collapse d-lg-none ${this.state.isSideBarOpen ? "open" : ""}`} onClick={this.onTogglePopupMenuClick}>
                                <ul className="navbar-nav ml-auto">
                                    <li className={"nav-item"}>
                                        <Link className={`nav-link ${this.props.selected === "home" ? "active" : ""}`} style={{cursor: "pointer"}}
                                              to='/home'>Home</Link>
                                    </li>
                                    <li className={"nav-item"}>
                                        <Link className={`nav-link ${this.props.selected === "features" ? "active" : ""}`} style={{cursor: "pointer"}}
                                              to='/features'>Features</Link>
                                    </li>
                                    <li className={"nav-item"}>
                                        <Link className={`nav-link ${this.props.selected === "pricing" ? "active" : ""}`} style={{cursor: "pointer"}}
                                              to='/pricing'>Pricing</Link>
                                    </li>
                                    <li className={"nav-item"}>
                                        <Link className={`nav-link ${this.props.selected === "contact" ? "active" : ""}`} style={{cursor: "pointer"}}
                                              to='/contact-us'>Contact</Link>
                                    </li>
                                    {this.showProjectsButton(showProjectsButton)}
                                    {this.showSignInButton()}
                                </ul>
                            </div>
                          </div>*/}
            </div>
          </nav>
        </header>
        {this.props.headerContent}
      </>
    );
  }

  showMenuItems(showProjectsButton, isDark, onlyContact) {
    const { showMenu = true } = this.props;

    if (showMenu) {
      if (onlyContact) {
        return (
          <ul className="navbar-nav ml-auto">
            <li>
              <Link to="/contact-us">Contact</Link>
            </li>
          </ul>
        );
      }

      const chatSDKMenus = [
        {
          id: "ios",
          title: "IOS",
          icon: "i-icon icon-apple",
          url: "/docs/chatsdk/ios",
          disabled: false
        },
        {
          id: "android",
          title: "Android",
          icon: "i-icon icon-android",
          url: "/docs/chatsdk/android",
          disabled: false
        },
        {
          id: "javascript",
          title: "Web JS",
          icon: "i-icon icon-react",
          url: "/docs/chatsdk/javascript",
          disabled: true
        }
      ];
      // const pricingMenus = [
      //   {
      //     id: "iac",
      //     title: "InApp-chat",
      //     url: "/pricing/#iac"
      //   },
      //   {
      //     id: "1webchat",
      //     title: "1Webchat",
      //     url: "/pricing/#1webchat"
      //   }
      // ];

      return (
        <ul className={`navbar-nav ${isDark ? "" : "ml-auto"}`}>
          {/*<li>
            <Link
              to="/home"
              className={this.props.selected === "home" ? "active" : ""}
            >
              Home
            </Link>
          </li>*/}
          <li>
            <Link
              to="/features"
              className={this.props.selected === "features" ? "active" : ""}
            >
              Features
            </Link>
          </li>
          <li
            className={`dropdown nav-dropdown-block ${
              this.state.showPricingManu ? "show" : ""
            }`}
          >
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  showPricingManu: !this.state.showPricingManu
                });
              }}
              className={this.props.selected === "pricing" ? "active" : ""}
            >
              Pricing
            </a>
            <div className="sub-menu grid-2">
              <div className="menu-item">
                <Link to="/pricing#iac">
                  <div className="info-content">In-App Chat</div>
                </Link>
              </div>
              <div className="menu-item">
                <Link to={{ pathname: "/pricing", hash: "#1webchat" }}>
                  <div className="info-content">1Webchat</div>
                </Link>
              </div>
            </div>
            {/* <Link
              to="/pricing"
              className={this.props.selected === "pricing" ? "active" : ""}
            >
              Pricing
            </Link> */}
          </li>
          <li
            className={`dropdown nav-dropdown-block ${
              this.state.showSolutionMenu ? "show" : ""
            }`}
          >
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  showSolutionMenu: !this.state.showSolutionMenu
                });
              }}
            >
              Solution
            </a>
            <div className="sub-menu grid-2">
              <div className="menu-item">
                <Link to="/peer-to-peer">
                  <div className="icon-block">
                    <em className="i-icon icon-users" />
                  </div>
                  <div className="title">Peer-to-Peer</div>
                  <div className="info-content">
                    Increase user engagement by adding chat to your app
                  </div>
                </Link>
              </div>
              <div className="menu-item">
                <Link to="/chatbots" className="active">
                  <div className="icon-block">
                    <em className="i-icon icon-bx-bot" />
                  </div>
                  <div className="title">1Chatbot</div>
                  <div className="info-content">
                    Add bots with a single purpose or bundle multiple bots
                    together
                  </div>
                </Link>
              </div>
            </div>
          </li>
          <li
            className={`dropdown nav-dropdown-block ${
              this.state.showDocMenu ? "show" : ""
            }`}
          >
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  showDocMenu: !this.state.showDocMenu
                });
              }}
            >
              Developer
            </a>
            <div
              className="sub-menu mw-400"
              style={{ maxWidth: "350px", overflow: "visible" }}
            >
              <div className="menu-item">
                <Link to="/docs" className="title mb-3">
                  Documentation
                </Link>
                <ul className="grid-column-3">
                  <li className="d-block">
                    <Link to="/docs/chatsdk" className="sub-title">
                      Chat SDKs
                    </Link>
                    <div className="icons-list">
                      {chatSDKMenus.map(menu =>
                        menu.disabled ? (
                          <React.Fragment key={menu.id}>
                            <a
                              data-tip
                              data-for={`header-${menu.id}`}
                              style={{ opacity: 0.3 }}
                            >
                              <em className={menu.icon} />
                            </a>
                            <ReactTooltip
                              id={`header-${menu.id}`}
                              place="bottom"
                              effect="solid"
                              style={{ zIndex: 9999 }}
                              getContent={() => "Coming Soon"}
                            />
                          </React.Fragment>
                        ) : (
                          <Link key={menu.id} to={menu.url}>
                            <em className={menu.icon} />
                          </Link>
                        )
                      )}
                    </div>
                  </li>
                  {/* <li className="d-block">
                    <Link to="/docs/uikit" className="sub-title">
                      UIKit for Chat
                    </Link>
                    <div className="icons-list">
                      <a href="#" data-toggle="tooltip" title="IOS">
                        <em className="i-icon icon-apple" />
                      </a>
                      <a href="#" data-toggle="tooltip" title="Android">
                        <em className="i-icon icon-android" />
                      </a>
                      <a href="#" data-toggle="tooltip" title="Web JS">
                        <em className="i-icon icon-react" />
                      </a>
                    </div>
                  </li> */}
                  <li className="d-block">
                    <div>
                      <Link to="/docs/chatbots" className="sub-title">
                        1Chatbot
                      </Link>
                    </div>
                    <Link
                      to="/docs/chatbots"
                      className="link-text text-lightorange"
                    >
                      View docs
                    </Link>
                  </li>
                  <li className="d-block">
                    <div>
                      <Link to="/docs/chatbots" className="sub-title">
                        1Webchat
                      </Link>
                    </div>
                    <Link
                      to="/docs/chatbots"
                      className="link-text text-lightorange"
                    >
                      View docs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          {/* <li
            className={`dropdown nav-dropdown ${
              this.state.showSolutionMenu ? "show" : ""
            }`}
          >
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                this.setState({
                  showSolutionMenu: !this.state.showSolutionMenu
                });
              }}
            >
              Solution
            </a>
            <ul>
              <li>
                <Link
                  to="/peer-to-peer"
                  className={this.props.selected === "p2p" ? "active" : ""}
                >
                  Peer-to-Peer
                </Link>
              </li>
              <li>
                <Link
                  to="/conversational-ai"
                  className={this.props.selected === "p2m" ? "active" : ""}
                >
                  Conversational AI
                </Link>
              </li>
              <li>
                <Link
                  to="/iot-controller"
                  className={this.props.selected === "m2m" ? "active" : ""}
                >
                  IOT Controller
                </Link>
              </li>
            </ul>
          </li> */}
          <li>
            <Link
              to="/contact-us"
              className={this.props.selected === "contact" ? "active" : ""}
            >
              Contact
            </Link>
          </li>
          {showProjectsButton && (
            <li>
              <Link
                to="/myprojects"
                className={this.props.selected === "myprojects" ? "active" : ""}
              >
                Projects
              </Link>
            </li>
          )}
          {typeof this.props.user === "undefined" &&
            !isDark &&
            this.showSignInButton()}
        </ul>
      );
    }
    return null;
  }

  /*renderCategories() {
        const rowSize = 4;
        if (this.state.categoryResultObj) {
            if (this.state.categoryResultObj instanceof ActionSuccess) {
                const categoriesEles = [];
                let columnEles = [];
                this.state.categoryResultObj.data.forEach((item, index) => {
                    columnEles.push((<ModuleList name={item.name} code={item.code} key={index}
                                                 onModuleClick={this.onModuleClick(item)}/>));
                    if ((index + 1) % rowSize === 0) {
                        categoriesEles.push(
                            <div className="col-12 col-sm-12 col-md-12 col-lg-6" key={index}>
                                {columnEles}
                            </div>
                        );
                        columnEles = [];
                    }
                });
                if (columnEles.length > 0) {
                    categoriesEles.push(
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6"
                             key={this.state.categoryResultObj.data.length}>
                            {columnEles}
                        </div>
                    );
                }
                return categoriesEles;
            }
        } else
            return null;
    }*/

  /*showUserAccount() {
        var profileClass = "nav-item profile-dropdown dropdown";
        var profileDdClass = "dropdown-menu";
        var ariaExpValue = "false";
        const {showUserAccount = true} = this.props;
        var bodyStyle = {};

        if (this.state.profileopen) {
            profileClass = profileClass + " show";
            ariaExpValue = "true";
            profileDdClass = profileDdClass + " show";
            bodyStyle = {display: "block"};
        } else {
            bodyStyle = {display: "none"};
        }

        const style = {
            margin: "-29px -15px -15px 15px",
            fontSize: "14px",
            height: "70px",
            padding: "20px 15px 0px 15px"
        };

        if (showUserAccount) {
            if (this.props.user) {
                return (
                    <div className={profileClass} onClick={this.onProfileClick} ref={this.optionsRef}
                         style={style}>
                        <a className="profile-item dropdown-toggle" href="javascript:void(0)" id="navbarDropdown"
                           role="button"
                           data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded={ariaExpValue}>
                            <div className="user-img"><img src={userIcon} alt={"user"}/></div>
                        </a>
                        <div className={profileDdClass} aria-labelledby="navbarDropdown"
                             style={bodyStyle}>
                            <div className="dropdown-item dropdown-username-info btm-border">
                                <strong>{this.props.user.name}</strong><br/>
                                <small>{this.props.user.email}</small>
                            </div>
                            <OrganizationSwitcher user={this.props.user} renderSamePage={true}/>
                            <Link className="dropdown-item" to='/accountAndProfile'
                                  style={{cursor: "pointer"}}>
                                My Account</Link>
                            <Link className="dropdown-item" to='/organizations'
                                  style={{cursor: "pointer"}}>
                                Organization Settings</Link>
                            <Link className="dropdown-item" to='/billing'
                                  style={{cursor: "pointer"}}>Billing</Link>
                            <a className="dropdown-item" onClick={this.onLogoutClick} style={{cursor: "pointer"}}>Log
                                Out</a>
                            <a className="dropdown-item" href="javascript:void(0)">Version : {packageJson.version}</a>
                        </div>
                    </div>);
            }
        } else {
            return null;
        }
    }*/

  showSignInButton() {
    //const {fixedHeaderOnScroll = true} = this.props;
    /*var loginButtonClass = "ertc-btn-white";
        if (fixedHeaderOnScroll) {
            if (this.state.scrollMore) {
                loginButtonClass = "ertc-btn-blue";
            }
        }*/
    /* KIRAN Fixed */
    //loginButtonClass = "hvr-grow-shadow";
    if (
      this.props.getUserCallState &&
      this.props.getUserCallState.isLoading === true
    ) {
      return (
        <li className="nav-item login login-btn">
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </li>
      );
    } else {
      if (typeof this.props.user === "undefined") {
        return (
          <>
            <li className="btn-white">
              <Link to="/login">Sign In</Link>
            </li>
            <li className="btn-primary">
              <Link to="/signup">Sign Up</Link>
            </li>
            {/*<li className="nav-item">
                            <Link
                                className={`nav-link btn btn-primary || ""}`}
                                style={{cursor: "pointer"}}
                                to='/signup'>Sign Up</Link>
                        </li>
                        <li className="nav-item login-dropdown dropdown custom-dropdown">
                            <Link
                                className={`nav-link btn || ""}`}
                                style={{cursor: "pointer"}}
                                to='/login'>Log In</Link>
                        </li>*/}
          </>
        );
      }
    }
  }

  /*showProjectsButton(showProjectsButton) {
        const elements = [];
        if (showProjectsButton) {
            elements.push(
                <li className="nav-item" key="1">
                    <Link className="nav-link" style={{cursor: "pointer"}}
                          to='/myprojects'>Projects</Link>
                </li>
            );
        }
        return elements;
    }*/

  /*showFreeBuyOptions(showTryFreeButton) {
        const elements = [];
        const {showFreeBuyOptions = true} = this.props;
        if (showFreeBuyOptions) {
            // Added null check to show only when we decide whether it is true or false.
            if (showTryFreeButton != null) {
                elements.push(
                    <li className="nav-item" hidden={!showTryFreeButton} key="1">
                        <a className="nav-link btn btn-primary" style={{cursor: "pointer"}}
                           onClick={this.handleTryFreeClick}>Try Free</a>
                    </li>
                );
            }
            elements.push(
                <li className="nav-item" key="2" style={{padding: "0px 20px"}}>
                    <a className="nav-link btn btn-primary"
                       style={{cursor: `${isBuyNowEnabled === true ? "pointer" : "notAllowed"}`}}
                       onClick={this.handleBuyNowClick}>Buy Now</a>
                </li>
            );
        }
        return elements;
    }*/

  /*handleTryFreeClick() {
        handleTryForFreeRedirection(this.props.isLoggedIn);
    }*/

  /*handleBuyNowClick() {
        if (isBuyNowEnabled) {
            handleBuyNowRedirection(this.props.isLoggedIn);
        }
    }*/
}

/*const ModuleList = ({name, onModuleClick, code}) => {

    // Handlinging this in static way for now.

    const iconClassMap = {
        DIRECT_MESSAGING: "rbn-icon-direct-messages",
        MEDIA_SHARING: "rbn-icon-media-sharing",
        GROUP_MESSAGING: "rbn-icon-group-messaging",
        PROFILE_MANAGEMENT: "rbn-icon-profile-management",
        PRESENCE: "rbn-icon-presence",
        PUSH_NOTIFICATIONS: "rbn-icon-m-security",
        CHANNELS: "rbn-icon-encryption",
        UI_COMPONENTS: "rbn-icon-encryption"
    };
    var iconClassName = "rbn-icon " + iconClassMap[code];
    return (
        <a onClick={onModuleClick}>
            <em className={iconClassName}/>
            {name}
            <em className="rbn-icon rbn-icon-arrow-right-blue"/>
        </a>
    );
};*/

function mapStatsToProps(stats) {
  return {
    user: stats.user.user,
    isLoggedIn: stats.user.isLoggedIn,
    getUserCallState:
      stats.ui.apiCallStates && stats.ui.apiCallStates[GET_USER_CALL_KEY],
    projects: stats.project.projects,
    isProjectLoaded: stats.project.isProjectLoaded
    //cart: stats.order.cart
  };
}

export default connect(mapStatsToProps, {})(withRouter(SiteHeader));
