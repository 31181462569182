import React, { Component } from "react";
import {
  getCurrentOrganization,
  getRoleInCurrentOrganization
} from "../../../utils/commonUtil";
import connect from "react-redux/es/connect/connect";
import { updateOrganization } from "../../../actions/organization";
import { withRouter } from "react-router-dom";
import { UserContext } from "../../common/context/userContextProvider";
import {
  changeOwner,
  getUsers,
  inviteUser,
  removeUser,
  resendInvitation
} from "../../../actions/users";
import AddNewAdminModal from "./addNewAdminModal";
import ChangeOwnerModal from "./changeOwnerModal";
import moment from "moment";
import LoadingDisplay from "../../common/loadingDisplay";
import { showErrorToast, showSuccessToast } from "../../../actions/ui";
import ConfirmationModal from "../../common/confirmationModal";
import history from "../../../history";
import { isPageEditable } from "../../../utils/pageAccessUtil";

class OrganizationMembersContent extends Component {
  organization;
  static contextType = UserContext;
  data = [];
  activeMemberData = [];
  invitedMemberData = [];

  constructor(props) {
    super(props);
    this.state = {
      activeTab: "active",
      isLoading: true,
      addMemberModalShow: false,
      editRoleData: "",
      removeUserResultObj: null,
      ownerChangeSuccess: false,
      removeUserLoading: false,
      inviteUserLoading: false,
      dropDownMenuId: "",
      deleteMemberId: "",
      resendInviteEmail: "",
      changeOwnerId: "",
      searchInputText: ""
    };

    this.optionsRef = React.createRef();
    this.onClickTab = this.onClickTab.bind(this);
    this.onAddNewAdminClick = this.onAddNewAdminClick.bind(this);
    this.onRemoveUserClick = this.onRemoveUserClick.bind(this);
    this.onMakeOwnerClick = this.onMakeOwnerClick.bind(this);
    this.onResendInvitationClick = this.onResendInvitationClick.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.ownerChangeSuccess = this.ownerChangeSuccess.bind(this);
    this.onDropDownMenuClick = this.onDropDownMenuClick.bind(this);
    this.handleClickOutsideOptions = this.handleClickOutsideOptions.bind(this);
    this.onChangeSearchInput = this.onChangeSearchInput.bind(this);
  }

  UNSAFE_componentWillMount() {
    const params = new URLSearchParams(this.props.location.search);
    const tabId = params.get("tab");
    if (tabId === "invite") {
      this.setState({ activeTab: "invite" });
    }
  }

  componentDidUpdate() {
    const params = new URLSearchParams(this.props.location.search);
    const tabId = params.get("tab");
    if (tabId && this.state.activeTab && tabId !== this.state.activeTab) {
      this.setState({ activeTab: tabId });
    }
  }

  componentDidMount() {
    this.fetchUsers();
    document.addEventListener("mousedown", this.handleClickOutsideOptions);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutsideOptions);
  }

  handleClickOutsideOptions(event) {
    if (
      this.optionsRef &&
      this.optionsRef.current &&
      !this.optionsRef.current.contains(event.target) &&
      this.state.dropDownMenuId !== ""
    ) {
      this.setState({ dropDownMenuId: "" });
    }
  }

  onAddNewAdminClick(e, editRoleData) {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (editRoleData !== undefined) {
      this.setState({ editRoleData: editRoleData });
    }
    this.setState({
      addMemberModalShow: !this.state.addMemberModalShow,
      dropDownMenuId: ""
    });
  }

  onMakeOwnerClick(e, Id) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ dropDownMenuId: "", changeOwnerId: Id });
  }

  ownerChangeSuccess() {
    this.setState({ ownerChangeSuccess: true });
  }

  onRemoveUserClick(e, userId) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dropDownMenuId: "", deleteMemberId: userId });
  }

  removeUser = () => {
    this.setState({ removeUserLoading: true });
    const organization = getCurrentOrganization(this.context.user);
    this.props
      .removeUser(organization._id, this.state.deleteMemberId)
      .then(() => {
        this.removeAdminFromUI(this.state.deleteMemberId);
        setTimeout(() => {
          this.setState({ deleteMemberId: "" });
        }, 2000);
        //this.props.showSuccessToast('Admin user removed successfully');
        this.setState({ removeUserLoading: false });
        this.props.showSuccessToast("Member deleted successfully");
      })
      .catch(error => {
        console.log(error);
        this.setState({ removeUserLoading: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  };

  onResendInvitationClick(e, userEmail) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dropDownMenuId: "", resendInviteEmail: userEmail });
  }

  resendInvitation = () => {
    this.setState({ inviteUserLoading: true });
    const organization = getCurrentOrganization(this.context.user);
    this.props
      .resendInvitation(organization._id, this.state.resendInviteEmail)
      .then(() => {
        setTimeout(() => {
          this.setState({ resendInviteEmail: "" });
        }, 2000);
        this.setState({ inviteUserLoading: false });
        this.props.showSuccessToast("Member invited successfully");
      })
      .catch(error => {
        console.log(error);
        this.setState({ inviteUserLoading: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  };

  removeAdminFromUI(removedUserId) {
    const isActiveTab = this.state.activeTab === "active";
    if (isActiveTab)
      this.activeMemberData = this.activeMemberData.filter(
        item => item.id !== removedUserId
      );
    else
      this.invitedMemberData = this.invitedMemberData.filter(
        item => item.id !== removedUserId
      );
  }

  onDropDownMenuClick(e, id) {
    this.setState({ dropDownMenuId: id });
  }

  onChangeSearchInput(e) {
    this.setState({ searchInputText: e.target.value });
  }

  fetchUsers() {
    const organization = getCurrentOrganization(this.context.user);
    this.setState({ isLoading: true });
    this.props
      .getUsers(organization._id)
      .then(result => {
        const resultObj = result.actionDataObj;
        const users = (resultObj.data && resultObj.data.users) || [];
        const userData = users
          .filter(uFilter => {
            const curStates = uFilter.states || [];
            return !curStates.find(item => item.state === "deleted");
          })
          .map(user => {
            const states = user.organization.states || [];
            const timeStampState =
              states.find(item => item.state === "invited") ||
              states.find(item => item.state === "signup") ||
              states.find(item => item.state === "joined");
            const addedOn =
              (timeStampState &&
                moment(timeStampState.timeStamp).format("MMMM Do, YYYY")) ||
              "NA";
            const role =
              (user.organization.roleRef &&
                user.organization.roleRef.displayName) ||
              user.organization.role;
            const internalRoleName =
              (user.organization.roleRef && user.organization.roleRef.name) ||
              user.organization.role;
            const roleId =
              user.organization.roleRef && user.organization.roleRef._id;
            const status = this.getStatus(states, internalRoleName);
            return {
              id: user._id,
              name: user.name || "NA",
              email: user.email,
              addedOn: addedOn,
              role: role,
              internalRoleName: internalRoleName,
              roleId: roleId,
              status: status
            };
          });

        this.activeMemberData = userData.filter(
          user => user.status === "" || user.status === "Joined"
        );
        this.invitedMemberData = userData.filter(
          user => user.status === "Invited"
        );
        this.setState({ isLoading: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  }

  getStatus(states, internalRoleName) {
    if (internalRoleName === "superAdmin") {
      return "";
    }
    return states.findIndex(s => s.state === "joined") > -1
      ? "Joined"
      : "Invited";
  }

  onClickTab(e, curTab) {
    e.preventDefault();
    if (this.state.activeTab !== curTab) {
      this.setState({ searchInputText: "" });
    }
    this.setState({ activeTab: curTab });
    history.push({
      pathname: "/organizations/members",
      search: `tab=${curTab}`
    });
  }

  render() {
    const isActiveTab = this.state.activeTab === "active";
    const members = isActiveTab
      ? this.activeMemberData
      : this.invitedMemberData;
    let filterMembers = members;
    if (this.state.searchInputText.trim() !== "") {
      filterMembers = members.filter(item =>
        item.email
          .toLowerCase()
          .includes(this.state.searchInputText.toLowerCase().trim())
      );
    }

    let totalMemberText = `${members.length} ${
      isActiveTab ? "Member" : "Invitation"
    }${members.length > 1 ? "s" : ""}`;
    if (members.length !== filterMembers.length) {
      totalMemberText = `${filterMembers.length} of ${totalMemberText}`;
    }

    const isEditable = isPageEditable(
      "/organizations/members",
      this.context.user
    );

    return (
      <>
        <div className="overview-box">
          <div className="member-status-block">
            <div className="switch-content-block">
              <a
                href="#"
                onClick={e => this.onClickTab(e, "active")}
                className={isActiveTab ? "active" : ""}
              >
                Active
              </a>
              <a
                href="#"
                onClick={e => this.onClickTab(e, "invite")}
                className={!isActiveTab ? "active" : ""}
              >
                Invited
              </a>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-md"
              onClick={this.onAddNewAdminClick}
              data-toggle="modal"
              data-target="#invite-member"
            >
              Invite New Member
            </button>
          </div>
          <div className="title border-bottom pb-3">
            <h2 className="h4">{totalMemberText}</h2>
            <div className="action-block">
              <div className="search-input-block">
                <div className="search-input">
                  <div className="form-group pre-icon-input">
                    <span className="pre-icon">
                      <em className="i-icon icon-search" />
                    </span>
                    <input
                      type="text"
                      className="form-control custom-input input-light"
                      placeholder="Search"
                      value={this.state.searchInputText}
                      aria-describedby="search"
                      onChange={this.onChangeSearchInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="record-table-list">
            <div className="table-responsive">
              <table className="table">
                <thead>{this.renderHeaderRow()}</thead>
                <tbody>{this.renderAdminData(filterMembers, isEditable)}</tbody>
              </table>
            </div>
          </div>
        </div>
        {this.renderDropDowns(filterMembers)}
        {this.state.addMemberModalShow && (
          <AddNewAdminModal
            show={this.state.addMemberModalShow}
            onHide={() =>
              this.setState({ addMemberModalShow: false, editRoleData: "" })
            }
            currentEmail={
              (this.state.editRoleData && this.state.editRoleData.email) || null
            }
            currentRoleId={
              (this.state.editRoleData && this.state.editRoleData.roleId) ||
              null
            }
            user={this.context.user}
            showInvitePage={!this.state.editRoleData}
            inviteUser={this.props.inviteUser}
            fetch={this.fetchUsers}
          />
        )}
        {this.state.changeOwnerId !== "" && (
          <ChangeOwnerModal
            show={this.state.changeOwnerId !== ""}
            onHide={() => this.setState({ changeOwnerId: "" })}
            curUser={this.context.user}
            newOwnerId={this.state.changeOwnerId}
            changeOwner={this.props.changeOwner}
            ownerChangeSuccess={this.ownerChangeSuccess}
            fetch={this.fetchUsers}
          />
        )}
        {this.renderDeleteConfirmationModal()}
        {this.renderResendInviteConfirmationModal()}
      </>
    );
  }

  renderDeleteConfirmationModal() {
    if (this.state.deleteMemberId === "") return null;

    const isActiveTab = this.state.activeTab === "active";
    const message = `Are you sure you want to delete this ${
      isActiveTab ? "member" : "invite"
    }?`;
    const subMessage = isActiveTab
      ? "Member will no longer be able to access this organization"
      : "The recipient of the invitation will no longer be able to access this organization";

    return (
      <ConfirmationModal
        actionText={message}
        actionSubText={subMessage}
        actionButtonText="Delete"
        actionHandler={this.removeUser}
        actionButtonBtnClass={"btn btn-danger btn-md"}
        cancelButtonBtnClass={"btn btn-only-text text-gray"}
        show={this.state.deleteMemberId !== ""}
        cancelHandler={() => this.setState({ deleteMemberId: "" })}
        actionButtonLoading={this.state.removeUserLoading}
      />
    );
  }

  renderResendInviteConfirmationModal() {
    if (this.state.resendInviteEmail === "") return null;

    const message = "Resend this invitation?";
    //const subMessage = 'User will not be able to access project until they are reactivated by an administrator';

    return (
      <ConfirmationModal
        actionText={message}
        actionButtonText="Invite"
        actionHandler={this.resendInvitation}
        actionButtonBtnClass={"btn btn-primary btn-md"}
        cancelButtonBtnClass={"btn btn-only-text text-gray"}
        show={this.state.resendInviteEmail !== ""}
        cancelHandler={() => this.setState({ resendInviteEmail: "" })}
        actionButtonLoading={this.state.inviteUserLoading}
      />
    );
  }

  isSuperAdmin() {
    if (this.state.ownerChangeSuccess) return false;

    return getRoleInCurrentOrganization(this.context.user) === "superAdmin";
  }

  renderAdminData(AdminData, isEditable) {
    const items = [];
    const isActiveTab = this.state.activeTab === "active";
    if (this.state.isLoading) {
      items.push(
        <tr key={"loading1"}>
          <td colSpan="5">
            <LoadingDisplay marginTop="20px" height="50px" />
          </td>
        </tr>
      );
      return items;
    }

    if (AdminData.length === 0) {
      items.push(
        <tr key={"nomember"}>
          <td colSpan="5" style={{ textAlign: "center" }}>{`No ${
            isActiveTab ? "Member" : "Invitation"
          }s Found`}</td>
        </tr>
      );
      return items;
    }

    AdminData.forEach((item, index) => {
      items.push(this.renderMembers(item, isEditable, index, AdminData.length));
    });
    return items;
  }

  renderMembers(item, isEditable, index, totalItems) {
    const { id, name, email, addedOn, role, internalRoleName, status } = item;
    const isActiveTab = this.state.activeTab === "active";
    const isCurrentRoleSuperAdmin = internalRoleName === "superAdmin";
    const showOptions = isEditable && !isCurrentRoleSuperAdmin;

    //const xPlacement = !isLast ? "top-start" : "bottom-start" ;
    //const transform = !isLast ? "translate3d(-92px, 36px, 0px)" : "translate3d(-92px, -106px, 0px)" ;
    //const dropStyle={position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: transform} ;
    const showDropUp = totalItems >= 4 && index === totalItems - 1;

    return (
      <tr key={id}>
        {isActiveTab && <td>{name}</td>}
        <td>{email}</td>
        {isActiveTab && <td>{addedOn}</td>}
        {!isActiveTab && <td>{status}</td>}
        <td>{role}</td>
        <td className="action">
          {showOptions && (
            <a
              href="#"
              id="user-dropdown"
              className="icon-action"
              data-horizontal-offset="-20"
              data-vertical-offset={showDropUp ? "60" : "-65"}
              data-jq-dropdown={`#jq_${id}`}
              onClick={e => this.onDropDownMenuClick(e, item._id)}
            >
              <em className="i-icon icon-vertical-dots" />
            </a>
          )}
        </td>
      </tr>
    );
  }

  renderDropDowns(adminData) {
    const isActiveTab = this.state.activeTab === "active";
    const items = [];
    adminData &&
      adminData.length > 0 &&
      adminData.map((item, index) => {
        const showDropUp =
          adminData.length >= 4 && index === adminData.length - 1;
        const isSuperAdmin = this.isSuperAdmin();
        const isCurrentRoleSuperAdmin = item.internalRoleName === "superAdmin";

        items.push(
          <div
            id={`jq_${item.id}`}
            key={`jq_${item.id}`}
            className={`jq-dropdown jq-dropdown-tip jq-dropdown-anchor-right ${
              showDropUp ? "jq-dropdown-anchor-down" : ""
            }`}
          >
            <ul className="jq-dropdown-menu">
              {isActiveTab && isSuperAdmin && !isCurrentRoleSuperAdmin && (
                <li className="dropdown-item" key={"1"}>
                  <a href="#" onClick={e => this.onMakeOwnerClick(e, item.id)}>
                    Make owner
                  </a>
                </li>
              )}
              {isActiveTab && !isCurrentRoleSuperAdmin && (
                <li className="dropdown-item" key={"2"}>
                  <a href="#" onClick={e => this.onAddNewAdminClick(e, item)}>
                    Edit role
                  </a>
                </li>
              )}
              {isActiveTab && !isCurrentRoleSuperAdmin && (
                <li className="dropdown-item" key={"3"}>
                  <a href="#" onClick={e => this.onRemoveUserClick(e, item.id)}>
                    Delete member
                  </a>
                </li>
              )}
              {!isActiveTab && (
                <li className="dropdown-item" key={"4"}>
                  <a
                    href="#"
                    onClick={e => this.onResendInvitationClick(e, item.email)}
                  >
                    Resend invite
                  </a>
                </li>
              )}
              {!isActiveTab && (
                <li className="dropdown-item" key={"5"}>
                  <a href="#" onClick={e => this.onRemoveUserClick(e, item.id)}>
                    Delete invite
                  </a>
                </li>
              )}
            </ul>
          </div>
        );
      });
    return items;
  }

  renderHeaderRow() {
    const isActiveTab = this.state.activeTab === "active";
    return (
      <tr>
        {isActiveTab && <th>Name</th>}
        <th>Email</th>
        {isActiveTab && <th>Added On</th>}
        {!isActiveTab && <th>Status</th>}
        <th>Role</th>
        <th />
      </tr>
    );
  }
}

export default connect(null, {
  updateOrganization,
  getUsers,
  changeOwner,
  inviteUser,
  resendInvitation,
  removeUser,
  showSuccessToast,
  showErrorToast
})(withRouter(OrganizationMembersContent));
