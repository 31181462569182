import { GET_PROJECT, GET_PROJECT_BULK } from "../actions/projects";

const INITIAL_STATE = { projects: [], isProjectLoaded: false };

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PROJECT:
      return {
        ...state,
        projects: newProjectState(state.projects, action.project),
        isProjectLoaded: true
      };
    case GET_PROJECT_BULK:
      return { ...state, projects: action.project, isProjectLoaded: true };
    default:
      return state;
  }
}

function newProjectState(currentProjectState, project) {
  const index = currentProjectState.findIndex(
    p => p.projectId === project.projectId
  );
  if (index > 0) {
    currentProjectState[index] = project;
  }
  return currentProjectState;
}
