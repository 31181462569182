import React, { Component } from "react";
import { Field, Form, isDirty, reduxForm } from "redux-form";
import SubmitButton from "../../common/submitButton";
import { ActionSuccess } from "../../../utils/httpResponseErrorHandler";
import { getCurrentOrganization } from "../../../utils/commonUtil";
import connect from "react-redux/es/connect/connect";
import { updateOrganization } from "../../../actions/organization";
import { withRouter } from "react-router-dom";
import InputText_v2 from "../../common/inputText_v2";
import { UserContext } from "../../common/context/userContextProvider";
import { getUser } from "../../../actions/users";
import { isPageEditable } from "../../../utils/pageAccessUtil";
import { showErrorToast, showSuccessToast } from "../../../actions/ui";

class OrganizationGeneralContent extends Component {
  organization;
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = { isSubmitting: false };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.organization = getCurrentOrganization(this.context.user);
    if (this.organization) {
      this.props.initialize({
        orgName: this.organization.name
      });
    }
  }

  handleFormSubmit(values) {
    this.setState({ isSubmitting: true });
    this.props
      .updateOrganization(this.organization._id, values.orgName)
      .then(() => {
        this.props.getUser();
        this.props.initialize({
          orgName: values.orgName
        });
        this.setState({ isSubmitting: false });
        this.props.showSuccessToast("Organization name updated successfully.");
      })
      .catch(error => {
        console.log(error);
        this.setState({ isSubmitting: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  }

  render() {
    const { handleSubmit } = this.props;

    if (this.state.resultObj && this.state.resultObj instanceof ActionSuccess) {
      this.organization = this.state.resultObj.data;
    } else {
      this.organization = getCurrentOrganization(this.context.user);
    }

    const isEditable = isPageEditable(
      "/organizations/general",
      this.context.user
    );

    return (
      <div className="overview-boxes">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 m-30">
            <Form
              id="orgsetting-form"
              onSubmit={handleSubmit(this.handleFormSubmit)}
            >
              <div className="overview-box">
                <div className="one-input-block">
                  <Field
                    containerClass="form-group"
                    labelFor="orgName"
                    label="Organization Name"
                    labelClass="font-16"
                    type="text"
                    inputTextClass="custom-input form-control"
                    name="orgName"
                    id="orgName"
                    placeholder="Organization Name"
                    readOnly={!isEditable}
                    component={InputText_v2}
                  />

                  {isEditable && (
                    <SubmitButton
                      containerClass="action"
                      buttonClass="btn btn-primary btn-md"
                      disabled={!this.props.dirty}
                      isSubmitting={this.state.isSubmitting}
                    >
                      Save
                    </SubmitButton>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

const validate = values => {
  const errors = {};

  const orgNameResult = validateName(values.orgName);
  if (orgNameResult) errors.orgName = orgNameResult;

  return errors;
};

function validateName(orgName) {
  if (!orgName || orgName.trim().length === 0) {
    return "Please Enter Organization Name !";
  }

  if (orgName.trim().length < 3) {
    return "Length must be at least 3 characters long";
  }

  return null;
}

function mapStatsToProps(state) {
  return {
    dirty: isDirty("orgsetting-form")(state)
  };
}

const reduxFormComp = reduxForm({ form: "orgsetting-form", validate });
export default reduxFormComp(
  connect(mapStatsToProps, {
    updateOrganization,
    getUser,
    showSuccessToast,
    showErrorToast
  })(withRouter(OrganizationGeneralContent))
);
