import React from "react";
import { withRouter } from "react-router";
import { ProjectContext } from "../context/projectContextProvider";
import connect from "react-redux/es/connect/connect";
import { Link } from "react-router-dom";
import { logoutUser } from "../../../actions/users";
import { getProjects } from "../../../actions/projects";
import { NavBar, NavItem } from "../../common/navComponents";
import PortalProfileDropdown from "../../common/portalProfileDropdown";
import DashboardOrgProjectSwitcher from "../dashboard/dashboardOrgProjectSwitcher";
// import { shouldShowUpgradeButton } from "../../../utils/commonUtil";
import smallLogoIcon from "../../../public/resources/common/images/gradient-logo-mark.svg";
import PortalLogoIcon from "../../../public/resources/common/images/logo.svg";
import PortalWhiteLogoIcon from "../../../public/resources/common/images/logo-white.svg";
import {
  getCurrentOrganization,
  numberAbbreviator,
} from "../../../utils/commonUtil";
import {
  getOrgMessageSentCount,
  getOrgUsageSummary,
} from "../../../actions/organization";
import moment from "moment";
import FreeTrialStatus from "../../common/freeTrialStatus";

//const isBuyNowEnabled = config.CLOUD_HOSTED_ENABLED || config.ON_PREMISE_ENABLED;

class MyProjectsHeader_V2 extends React.Component {
  static contextType = ProjectContext;
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false,
      messageSentCount: { current: 0, max: 0 },
      activeUserCount: { current: 0, max: 0 },
      storageCount: { current: 0, max: 0 },
    };
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.renderNavBar = this.renderNavBar.bind(this);
  }

  componentDidMount() {
    const organization = getCurrentOrganization(this.props.user);
    if (organization) {
      this.props
        .getOrgUsageSummary(organization._id)
        .then((res) => {
          this.setState({
            activeUserCount: {
              current: res.actionDataObj.data.totalMAU,
              max: res.actionDataObj.data.MaxMAU,
            },
            storageCount: {
              current: res.actionDataObj.data.totalStorage,
              max: res.actionDataObj.data.MaxStorage,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.props
        .getOrgMessageSentCount(organization._id)
        .then((res) => {
          this.setState({
            messageSentCount: {
              current: res.actionDataObj.data.totalMessageCount,
              max: res.actionDataObj.data.maxMessageCount,
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onSidebarClick() {
    if (this.props.showSidebar)
      this.props.onSidebarToggleClick && this.props.onSidebarToggleClick();
    else {
      if (this.state.sidebarOpen) {
        document.body.classList.remove("overflow-hidden");
      } else {
        document.body.classList.add("overflow-hidden");
      }
      this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }
  }

  render() {
    const project = this.context && this.context.project;
    const { isDark } = this.props;
    return (
      <header
        id="main-header"
        className={`main-dashboard-header ${!project ? "with-logo" : ""} ${
          isDark ? "dark-bg" : ""
        }`}
      >
        <nav className="navbar navbar-expand-md navbar-light">
          {project && (
            <div className="container-fluid">
              <div
                className="bar-icon toggle-sidebar"
                onClick={this.onSidebarClick}
              >
                <div className="line1" />
                <div className="line2" />
                <div className="line3" />
              </div>
              <Link className="logo" to="/home">
                <img
                  src={smallLogoIcon}
                  className="d-md-none d-block"
                  alt="In-App Chat Logo"
                />
              </Link>
              <DashboardOrgProjectSwitcher
                projectName={project.name}
                projectId={project.projectId}
                user={this.props.user}
                getProjects={this.props.getProjects}
              />
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {this.renderNavBar()}
              </div>
            </div>
          )}
          {!project && (
            <>
              <Link className="logo" to="/home">
                <img
                  src={isDark ? PortalWhiteLogoIcon : PortalLogoIcon}
                  alt="In-App Chat Logo"
                />
              </Link>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {this.renderNavBar()}
              </div>
            </>
          )}
        </nav>
      </header>
    );
  }

  renderUsageWarning() {
    const { messageSentCount, activeUserCount, storageCount } = this.state;
    let warnTitle = "";
    let description = "";
    let title = "";
    let curUsage = 0;
    const monthStartEnd = `${moment()
      .clone()
      .startOf("month")
      .format("MMM DD, YYYY")} - ${moment()
      .clone()
      .endOf("month")
      .format("MMM DD, YYYY")}`;
    const usageTier = ["0"];
    if (
      messageSentCount.current > 0 &&
      messageSentCount.current >= messageSentCount.max
    ) {
      warnTitle = "Message sent warning";
      description = "message sent count";
      title = "Message sent this month";
      curUsage = messageSentCount.current;
      const gap = messageSentCount.max / 5;
      let curVal = 0;
      for (let i = 0; i < 5; i += 1) {
        usageTier.push(numberAbbreviator(curVal + gap, 1));
        curVal += gap;
      }
    } else if (
      activeUserCount.current > 0 &&
      activeUserCount.current >= activeUserCount.max
    ) {
      warnTitle = "Active user warning";
      description = "active user count";
      title = "Active users this month";
      curUsage = activeUserCount.current;
      const gap = activeUserCount.max / 5;
      let curVal = 0;
      for (let i = 0; i < 5; i += 1) {
        usageTier.push(numberAbbreviator(curVal + gap, 1));
        curVal += gap;
      }
    } else if (
      storageCount.current > 0 &&
      storageCount.current >= storageCount.max
    ) {
      warnTitle = "Data usage warning";
      description = "storage";
      title = "Storage used this month";
      const curStore = storageCount.current / (1024 * 1024 * 1024);
      curUsage = `${curStore} GB`;
      const gap = storageCount.max / (1024 * 1024 * 1024 * 5);
      let curVal = 0;
      for (let i = 0; i < 5; i += 1) {
        usageTier.push(`${+(curVal + gap).toFixed(1)} GB`);
        curVal += gap;
      }
    }

    return (
      <div
        className={`upgrade-plan-dropdown ${
          warnTitle.length > 0 ? "is-show" : ""
        }`}
      >
        <div className="card-items data-warning-block">
          <strong className="title-text">{warnTitle}</strong>
          <div className="info-content">
            {`Hi, we have noticed that you are approaching the limit of your
            ${description}. If you would like to upgrade to a larger plan please
            click the `}
            <strong>Upgrade Plan</strong> button at the top of the page.
          </div>
        </div>
        <div className="card-items">
          <div className="description-block">
            <div className="desc">
              <span className="heading">{title}</span>
              <span className="info font-20">{curUsage}</span>
              <small className="duretion">{monthStartEnd}</small>
            </div>
            <div className="progress-bar-block">
              <div className="progress-bar">
                <span
                  className="progress message-bar"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="progress-count">
                {usageTier.map((item) => (
                  <span key={item}>{item}</span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNavBar() {
    const project = this.context && this.context.project;
    const { hideMyProjectMenu, user } = this.props;
    const { messageSentCount, activeUserCount, storageCount } = this.state;
    let showWarning = false;
    if (
      (messageSentCount.current > 0 &&
        messageSentCount.current >= messageSentCount.max) ||
      (activeUserCount.current > 0 &&
        activeUserCount.current >= activeUserCount.max) ||
      (storageCount.current > 0 && storageCount.current >= storageCount.max)
    ) {
      showWarning = true;
    }

    return (
      <NavBar>
        <NavItem itemClass="pr-0" />
        <NavItem
          itemClass={`data-upgrade-plan ${showWarning ? "is-open" : ""}`}
        >
          {project?.projectType === "1widget" ? (
            <FreeTrialStatus user={user} project={project} />
          ) : (
            <Link
              className="nav-link btn btn-orange upgrade-plan-btn"
              to="/pricing"
            >
              <em className="i-icon icon-upgrade" />
              <span>Upgrade Plan</span>
            </Link>
          )}
          {this.renderUsageWarning()}
        </NavItem>
        {!hideMyProjectMenu && (
          <NavItem>
            <Link className="nav-link" to="/myprojects">
              <em className="i-icon icon-projects" />
              <span>My Projects</span>
            </Link>
          </NavItem>
        )}
        <PortalProfileDropdown />
      </NavBar>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

export default connect(mapStateToProps, {
  getProjects,
  logoutUser,
  getOrgMessageSentCount,
  getOrgUsageSummary,
})(withRouter(MyProjectsHeader_V2));
