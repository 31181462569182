import React from "react";
import LoaderIcon from "../../public/images/loader.svg";

const SubmitButton = ({
  containerClass,
  customStyle,
  buttonClass,
  buttonStyle,
  buttonId,
  onClick,
  children,
  isSubmitting,
  responseComponent,
  hidden = false,
  disabled = false,
  isPrimary = true,
  form
}) => {
  let style = customStyle || null;
  let btnStyle = buttonStyle || {};
  /*if(disabled) {
      buttonClass = (buttonClass || '') + ' ertc-btn-disabled';
  }*/

  const buttonProps = {
    className: `${buttonClass} btn ${isPrimary ? "btn-primary" : ""}`,
    id: buttonId,
    name: buttonId,
    disabled: isSubmitting || disabled,
    onClick: onClick,
    hidden: hidden
  };

  if (form) {
    buttonProps.form = form;
  }

  const buttonJsx = (
    <>
      <button type="submit" {...buttonProps} style={btnStyle}>
        {children}
        {isSubmitting && (
          <img
            className="loader"
            src={LoaderIcon}
            alt="In-App Chat loader"
            style={{
              paddingLeft: "3px",
              paddingBottom: "3px"
            }}
          />
        )}
      </button>
      {responseComponent && <div>{responseComponent}</div>}
    </>
  );

  return (
    <>
      {containerClass || style ? (
        <div className={containerClass} style={style}>
          {buttonJsx}
        </div>
      ) : (
        buttonJsx
      )}
    </>
  );
};

export default SubmitButton;
