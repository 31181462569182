import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import RequireAuth from "./hoc/requireAuth";
import OptionalAuth from "./hoc/optionalAuth";
import SignupContainer from "./components/site/hoc/signupContainer";
import SolutionContainer from "./components/site/hoc/solutionContainer";
import DocumentationContainer from "./components/site/hoc/documentationContainer";
import SuccessPageContainer from "./components/site/hoc/successPageContainer";
import CreateProjectContainer_v2 from "./components/portal/myProjects/createProjectContainer_v2";
import TabbedPage from "./hoc/tabbedPage";
const DocumentationIOSSdkContent = lazy(() => import("./components/site/documentation/documentation-ios-sdk-content"));
const DocumentationAndroidSdkContent = lazy(() => import("./components/site/documentation/documentation-android-sdk-content"));
const DocumentationJavascriptSdkContent = lazy(() => import("./components/site/documentation/documentation-javascript-sdk-content"));
const DocumentationInfrastructureContent = lazy(() => import("./components/site/documentation/documentation-infrastructure-content"));
//const DocumentationTutorialsContent from './components/site/documentation/documentation-tutorials-content';
const DocumentationSecurityContent = lazy(() => import("./components/site/documentation/documentation-security-content"));
const DocumentationeRTCPlansContent = lazy(() => import("./components/site/documentation/documentation-ertc-plans-content"));
const DocumentationBillingContent = lazy(() => import("./components/site/documentation/documentation-billing-content"));
const DocumentationChatUsersContent = lazy(() => import("./components/site/documentation/documentation-chat-users-content"));
const DocumentationTermsContent = lazy(() => import("./components/site/documentation/documentation-terms-content"));
const DocumentationApplicationContent = lazy(() => import("./components/site/documentation/documentation-application-content"));
const ContactSupportPage = lazy(() => import("./components/site/pages/contactSupport"));

const HomePage = lazy(() => import("./components/site/pages/home"));
const LoginPage = lazy(() => import("./components/site/pages/login"));
const SignUpPage = lazy(() => import("./components/site/pages/signUp"));
const ContactUsPage = lazy(() => import("./components/site/pages/contactUs"));
const CareersPage = lazy(() => import("./components/site/pages/careersPage"));
const QuestionnairePage = lazy(() => import("./components/site/pages/questionnair"));
const MyProjectsPage = lazy(() => import("./components/portal/pages/myProjects"));
const InstanceTypePage = lazy(() => import("./components/portal/pages/instanceType"));
const ChatUserPage = lazy(() => import("./components/portal/pages/chatUser"));
const ModerationPage = lazy(() => import("./components/portal/pages/moderation"));
const WebStepFlowPage = lazy(() => import("./components/portal/pages/webStepFlow"));
const IOSStepFlowPage = lazy(() => import("./components/portal/pages/iOSStepFlow"));
const AndroidStepFlowPage = lazy(() => import("./components/portal/pages/androidStepFlowPage"));
const JavascriptStepFlowPage = lazy(() => import("./components/portal/pages/javascriptStepFlowPage"));
const DashboardPage = lazy(() => import("./components/portal/pages/dashboard"));
const ExportMessagePage = lazy(() => import("./components/portal/pages/exportMessage"));
const PermissionsPage = lazy(() => import("./components/portal/pages/permisions"));
const SettingPage_V2 = lazy(() => import("./components/portal/pages/setting_v2"));
const VerifyEmailPage = lazy(() => import("./components/portal/pages/verifyEmail"));
const AcceptInvitationPage = lazy(() => import("./components/portal/pages/acceptInvitations"));
const NotFoundPage = lazy(() => import("./components/portal/pages/notFoundPage"));
const CompliancePage = lazy(() => import("./components/portal/pages/compliance"));
const CompliancePolicySummaryPage = lazy(() => import("./components/portal/pages/compliancePolicySummary"));
const ComplianceStatusPage = lazy(() => import("./components/portal/pages/complianceStatus"));
const SecurityPage = lazy(() => import("./components/portal/pages/security"));
const SecurityDetailsPage = lazy(() => import("./components/portal/pages/securityDetails"));
const InfrastructurePage = lazy(() => import("./components/portal/pages/infrastructure"));
const UsagePage = lazy(() => import("./components/portal/pages/usage"));
const AnalyticsPage = lazy(() => import("./components/portal/pages/analytics"));
const PricingPage = lazy(() => import("./components/site/pages/pricing"));
const ForgotPasswordPage = lazy(() => import("./components/site/pages/forgotPassword"));
const PaymentPage_V2 = lazy(() => import("./components/site/pages/payment_v2"));
const MarketplacePage = lazy(() => import("./components/site/pages/marketplace"));
const PrivacyPolicyPage = lazy(() => import("./components/site/pages/privacyPolicy"));
const CookiePolicyPage = lazy(() => import("./components/site/pages/cookiePolicy"));
const LicenseAgreementPage = lazy(() => import("./components/site/pages/licenseAgreement"));
const TermsOfServicesPage = lazy(() => import("./components/site/pages/termsOfServices"));
const HarborSecurityDetailsPage = lazy(() => import("./components/portal/pages/harborSecurityDetails"));
const QualysLoginPage = lazy(() => import("./components/portal/pages/qualysLogin"));
import OrganizationsPage from "./components/site/pages/organizations";
const BillingPage_V2 = lazy(() => import("./components/portal/pages/billing"));
const PaymentSuccessPage = lazy(() => import("./components/site/pages/paymentSuccess"));
const AccountAndProfilePage = lazy(() => import("./components/portal/pages/accountAndProfile"));
const ChangePasswordPage = lazy(() => import("./components/portal/pages/changePassword"));
const AboutPage = lazy(() => import("./components/site/pages/about"));
const CommunityPage = lazy(() => import("./components/site/pages/community"));
const ChatbotAutomationPage = lazy(() => import("./components/site/pages/chatbotAutomation"));
const config = lazy(() => import("./config/config"));
const ChatBotsPage = lazy(() => import("./components/portal/pages/chatBots"));
const AuthenticatePage = lazy(() => import("./components/site/pages/authenticate"));
const AppSumoPage = lazy(() => import("./components/site/pages/appSumo"));
const OnboardingPage = lazy(() => import("./components/site/pages/onboarding"));
const PlanPage = lazy(() => import("./components/portal/pages/plan"));
const ManageSubscriptionPage = lazy(() => import("./components/site/pages/manage-subscription"));
const BookingConfirmation = lazy(() => import("./components/portal/pages/bookingConfirmation"));
import Helmet from "react-helmet";

import Notification from "./components/common/notification";

const AppRouter = () => {
  const location = useLocation();
  /* const sendGAEvent = () => {
    if (window && window.gtag) {
      const path = window.location.pathname + window.location.search;
      if (config.GA_CODE) {
        window.gtag("config", config.GA_CODE, {
          page_title: path,
          page_path: path
        });
      }

      if (config.GADS_CODE) {
        window.gtag("config", config.GADS_CODE, {
          page_title: path,
          page_path: path
        });
      }
    }
  }; */

  useEffect(() => {
    /* if (history && window && (config.GA_CODE || config.GADS_CODE)) {
      setTimeout(() => {
        sendGAEvent();
      });

      history.listen(() => {
        setTimeout(() => {
          sendGAEvent();
        });
      });
    } */
    let hubSpotScript = document.getElementById("hs-script-loader");
    if (!hubSpotScript) {
      hubSpotScript = document.createElement("script");
      hubSpotScript.setAttribute("type", "text/javascript");
      hubSpotScript.setAttribute("src", "//js.hs-scripts.com/22677078.js");
      hubSpotScript.setAttribute("id", "hs-script-loader");
      hubSpotScript.async = true;
      hubSpotScript.defer = true;
      hubSpotScript.onload = () => {
        /* setTimeout(() => {
          window.HubSpotConversations.widget.refresh();
        }, 1000); */
      };
      document.body.appendChild(hubSpotScript);
    } else if (window?.HubSpotConversations?.widget) {
      window.HubSpotConversations.widget.refresh();
    }
    return () => {
      window?.RokoInstabot?.controller?.botClose();
      /* const el = document.getElementById("hs-script-loader");
      if (el) {
        console.log("nirav remove");
        el.remove();
      } */
    };
  }, [location]);

  return (
    <>
      {(config.GA_CODE || config.GADS_CODE) && (
        <Helmet>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_CODE ||
              config.GADS_CODE}`}
          />
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            window.gtag = gtag;
            gtag('js', new Date());
            ${config.GA_CODE && `gtag('config', '${config.GA_CODE}');`}
            ${config.GADS_CODE && `gtag('config', '${config.GADS_CODE}');`}`}
          </script>
        </Helmet>
      )}
      <>
        <div>
          <Suspense fallback={<div>...Loading</div>}>
            <Switch>
              <Route exact path="/" component={OptionalAuth(HomePage)} />
              {/* Site pages */}
              <Route exact path="/home" component={OptionalAuth(HomePage)} />
              <Route
                exact
                path="/pricing"
                component={OptionalAuth(PricingPage)}
              />
              <Route
                exact
                path="/pricing/:projectId"
                component={RequireAuth(PricingPage)}
              />
              <Route
                exact
                path="/features"
                component={OptionalAuth(MarketplacePage)}
              />
              <Route
                exact
                path="/peer-to-peer"
                component={OptionalAuth(SolutionContainer("p2p"))}
              />
              <Route
                exact
                path="/chatbot"
                render={() => <Redirect to="/conversational-ai" />}
              />
              <Route
                exact
                path="/conversational-ai"
                component={OptionalAuth(SolutionContainer("p2m"))}
              />
              <Route
                exact
                path="/chatbots"
                component={OptionalAuth(SolutionContainer("1chatbot"))}
              />
              <Route
                exact
                path="/chatbot-automation"
                component={ChatbotAutomationPage}
              />
              <Route
                exact
                path="/chatops"
                render={() => <Redirect to="/iot-controller" />}
              />
              <Route
                exact
                path="/iot-controller"
                component={OptionalAuth(SolutionContainer("m2m"))}
              />
              <Route
                exact
                path="/questionnair"
                component={OptionalAuth(QuestionnairePage)}
              />
              {/*<Route exact path="/marketplace" component={OptionalAuth(MarketplacePage)}/>*/}
              <Route
                exact
                path="/product"
                render={() => <Redirect to="/features" />}
              />
              <Route
                exact
                path="/privacy-policy"
                component={OptionalAuth(PrivacyPolicyPage)}
              />
              <Route
                exact
                path="/privacy-policy/:type"
                component={OptionalAuth(PrivacyPolicyPage)}
              />
              <Route
                exact
                path="/cookie-policy"
                component={OptionalAuth(CookiePolicyPage)}
              />
              <Route
                exact
                path="/privacyPolicy"
                component={OptionalAuth(PrivacyPolicyPage)}
              />
              <Route
                exact
                path="/license-agreement"
                component={OptionalAuth(LicenseAgreementPage)}
              />
              {/*<Route exact path="/supportPolicy" component={OptionalAuth(SupportPolicyPage)}/>*/}
              <Route
                exact
                path="/terms-of-services"
                component={OptionalAuth(TermsOfServicesPage)}
              />
              <Route exact path="/signup" component={SignUpPage} />
              <Route
                exact
                path="/starter/signup"
                component={SignupContainer("starter")}
              />
              <Route
                exact
                path="/start-free-trial"
                component={RequireAuth(OnboardingPage)}
              />
              <Route
                exact
                path="/professional/signup"
                component={SignupContainer("professional")}
              />
              <Route
                exact
                path="/professional/instancetype"
                component={InstanceTypePage}
              />
              <Route
                exact
                path="/professional/instancetype/:projectId"
                component={InstanceTypePage}
              />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/authenticate" component={AuthenticatePage} />
              <Route
                exact
                path="/forgotPassword"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/payment"
                component={RequireAuth(PaymentPage_V2)}
              />
              <Route
                exact
                path="/payment/success"
                component={RequireAuth(PaymentSuccessPage)}
              />
              {/* Portal pages */}
              {/*<Route exact path="/login" component={LoginPage}/>*/}
              {/*<Route exact path="/signup" component={SignUpPage}/>*/}
              <Route
                exact
                path="/verifyEmail/:id"
                component={VerifyEmailPage}
              />
              <Route
                exact
                path="/acceptInvitation/:organization/:encryptedEmail"
                component={AcceptInvitationPage}
              />
              <Route
                exact
                path="/contact-us"
                component={OptionalAuth(ContactUsPage)}
              />
              <Route
                exact
                path="/contact-support"
                component={OptionalAuth(ContactSupportPage)}
              />
              <Route
                exact
                path="/support-thank-you"
                component={OptionalAuth(
                  SuccessPageContainer("contactSupportThanks")
                )}
              />
              {/*<Route exact path="/contactus" component={OptionalAuth(ContactUsPage)}/>*/}
              {/*<Route
            exact
            path="/contactSaleTeam"
            component={RequireAuth(ContactSaleTeamPage)}
          />*/}
              <Route
                exact
                path="/careers"
                component={OptionalAuth(CareersPage)}
              />
              <Route exact path="/about" component={OptionalAuth(AboutPage)} />
              <Route exact path="/community" component={CommunityPage} />
              <Route
                exact
                path="/success-subscribe"
                component={SuccessPageContainer("subscribeMail")}
              />
              <Route
                exact
                path="/success-chatbot-automation"
                component={SuccessPageContainer("chatbotAutomationThanks")}
              />
              <Route
                exact
                path="/thank-you"
                component={SuccessPageContainer("contactThanks")}
              />
              <Route
                exact
                path="/starter/project/create"
                component={RequireAuth(CreateProjectContainer_v2("freesdk"))}
              />
              <Route
                exact
                path="/professional/project/create"
                component={RequireAuth(CreateProjectContainer_v2("premium"))}
              />
              <Route
                exact
                path="/project/create"
                component={RequireAuth(CreateProjectContainer_v2("premium"))}
              />
              {/*Portal*/}
              <Route
                exact
                path="/myprojects"
                component={RequireAuth(MyProjectsPage)}
              />
              <Route
                exact
                path="/accountAndProfile"
                component={RequireAuth(AccountAndProfilePage)}
              />
              <Route
                exact
                path="/changePassword"
                component={RequireAuth(ChangePasswordPage)}
              />
              <Route
                exact
                path="/organizations"
                component={RequireAuth(OrganizationsPage("general"))}
              />
              <Route
                exact
                path="/organizations/general"
                component={RequireAuth(OrganizationsPage("general"))}
              />
              <Route
                exact
                path="/organizations/members"
                component={RequireAuth(OrganizationsPage("members"))}
              />
              <Route
                exact
                path="/organizations/roles"
                component={RequireAuth(OrganizationsPage("roles"))}
              />
              <Route
                exact
                path="/organizations/createrole"
                component={RequireAuth(OrganizationsPage("createrole"))}
              />
              <Route
                exact
                path="/organizations/editrole"
                component={RequireAuth(OrganizationsPage("editrole"))}
              />
              <Route
                exact
                path="/organizations/viewrole"
                component={RequireAuth(OrganizationsPage("viewrole"))}
              />

              {/*<Route exact path="/addOns" component={RequireAuth(AddOnsPage)}/>*/}
              {/*<Route exact path="/billing" component={RequireAuth(BillingPage)}/>*/}

              {/*Dashboard urls*/}
              <Route
                exact
                path="/dashboard/:projectId"
                component={RequireAuth(DashboardPage)}
              />
              {/*<Route exact path="/dashboardEmpty" component={DashboardEmptyPage}/>*/}
              {/*<Route exact path="/dashboardAppFreePlan" component={DashboardAppFreePlanPage}/>*/}

              {/* IOS, android application pages*/}
              <Route exact path="/webStepFlow" component={WebStepFlowPage} />
              <Route
                exact
                path="/iosStepFlow/:projectId"
                component={RequireAuth(IOSStepFlowPage)}
              />
              <Route
                exact
                path="/androidStepFlow/:projectId"
                component={RequireAuth(AndroidStepFlowPage)}
              />
              <Route
                exact
                path="/javascriptStepFlow/:projectId"
                component={RequireAuth(JavascriptStepFlowPage)}
              />

              {/* Chat User add pages*/}
              <Route
                exact
                path="/chatUsers/:projectId"
                component={RequireAuth(ChatUserPage)}
              />

              {/* Chat bot page*/}
              <Route
                exact
                path="/bots/:projectId"
                component={RequireAuth(ChatBotsPage)}
              />

              {/* Moderation pages*/}
              {config.MODERATION_FEATURE && (
                <Route
                  exact
                  path="/moderation/:projectId"
                  component={RequireAuth(ModerationPage)}
                />
              )}

              {/* Qualys Compliance Pages*/}
              <Route
                exact
                path="/qualys/login/:projectId"
                component={RequireAuth(QualysLoginPage)}
              />
              <Route
                exact
                path="/compliance/policies/:projectId"
                component={RequireAuth(TabbedPage(CompliancePage, "policies"))}
              />
              <Route
                exact
                path="/compliance/summary/:projectId/policies/:id"
                component={RequireAuth(
                  TabbedPage(CompliancePolicySummaryPage, "summary")
                )}
              />
              <Route
                exact
                path="/compliance/status/:projectId/policies/:id"
                component={RequireAuth(
                  TabbedPage(ComplianceStatusPage, "status")
                )}
              />

              {/* Qualys Security Pages*/}
              <Route
                exact
                path="/security/software/:projectId"
                component={RequireAuth(TabbedPage(SecurityPage, "software"))}
              />
              <Route
                exact
                path="/security/runtime/:projectId"
                component={RequireAuth(TabbedPage(SecurityPage, "runtime"))}
              />
              <Route
                exact
                path="/security/harbor/:projectId"
                component={RequireAuth(TabbedPage(SecurityPage, "harbor"))}
              />
              <Route
                exact
                path="/security/summary/:projectId/:type/:id"
                component={RequireAuth(
                  TabbedPage(SecurityDetailsPage, "summary")
                )}
              />
              <Route
                exact
                path="/security/vulnerabilities/:projectId/:type/:id"
                component={RequireAuth(
                  TabbedPage(SecurityDetailsPage, "vulnerabilities")
                )}
              />
              <Route
                exact
                path="/security/harbor/details/:projectId/:image/:tagid"
                component={RequireAuth(
                  TabbedPage(HarborSecurityDetailsPage, "details")
                )}
              />

              {/* Infrastructure page*/}
              <Route
                exact
                path="/infrastructure/:projectId"
                component={RequireAuth(InfrastructurePage)}
              />

              <Route
                exact
                path="/usage/:projectId"
                component={RequireAuth(UsagePage)}
              />
              <Route
                exact
                path="/billing/:projectId"
                component={RequireAuth(BillingPage_V2)}
              />
              <Route
                exact
                path="/1webchat-plan/:projectId"
                component={RequireAuth(PlanPage)}
              />
              <Route
                exact
                path="/manage-subscription/:projectId"
                component={RequireAuth(ManageSubscriptionPage)}
              />
              {config.ANALYTICS_FEATURE && (
                <Route
                  exact
                  path="/analytics/:projectId"
                  component={RequireAuth(AnalyticsPage)}
                />
              )}

              {/*Export message pages*/}
              {config.DATAEXPORT_FEATURE && (
                <Route
                  exact
                  path="/exportMessage/:projectId"
                  component={RequireAuth(ExportMessagePage)}
                />
              )}
              {config.DATAEXPORT_FEATURE && (
                <Route
                  exact
                  path="/exportMessagePermissions/:projectId"
                  component={RequireAuth(PermissionsPage)}
                />
              )}

              {/*Project setting page*/}
              <Route
                exact
                path="/settings/:projectId"
                component={RequireAuth(SettingPage_V2)}
              />
              {/*<Route exact path="/settings/general/:projectId" component={RequireAuth(TabbedPage(SettingPage, 'general'))}/>
          <Route exact path="/settings/features/:projectId" component={RequireAuth(TabbedPage(SettingPage, 'features'))}/>*/}

              <Route
                exact
                path="/docs"
                component={OptionalAuth(DocumentationContainer("home"))}
              />
              <Route
                exact
                path="/docs/chatsdk"
                component={OptionalAuth(DocumentationContainer("chat"))}
              />
              <Route
                exact
                path="/docs/chatsdk/:language"
                component={OptionalAuth(DocumentationContainer("chatsdk-doc"))}
              />
              <Route
                exact
                path="/docs/uikit"
                component={OptionalAuth(DocumentationContainer("uikit"))}
              />
              <Route
                exact
                path="/docs/chatbots"
                component={OptionalAuth(DocumentationContainer("chatbot"))}
              />
              <Route
                exact
                path="/docs/chatbots/nlu/google-dialogflow"
                component={OptionalAuth(
                  DocumentationContainer("chatbots_nlu_gd")
                )}
              />
              <Route
                exact
                path="/docs/chatbots/nlu/amazon-lex"
                component={OptionalAuth(DocumentationContainer("amazon_lex"))}
              />
              <Route
                exact
                path="/docs/chatbots/nlu/ibm-watson"
                component={OptionalAuth(DocumentationContainer("ibm_watson"))}
              />
              <Route
                exact
                path="/docs/chatbots/nlu/ibm-watson/config-guide"
                component={OptionalAuth(
                  DocumentationContainer("ibm_watson_config")
                )}
              />
              <Route
                exact
                path="/docs/chatbots/installing-chat-widget"
                component={OptionalAuth(
                  DocumentationContainer("install-chat-widget")
                )}
              />
              <Route
                exact
                path="/docs/chatbots/1chatbot/default-replies"
                component={OptionalAuth(
                  DocumentationContainer("1chatbot-default-replies")
                )}
              />
              <Route
                exact
                path="/docs/chatbots/1chatbot/session-timeout"
                component={OptionalAuth(
                  DocumentationContainer("1chatbot-session-timeout")
                )}
              />
              <Route
                exact
                path="/docs/chatbots/1webchat/default-replies"
                component={OptionalAuth(
                  DocumentationContainer("1webchat-default-replies")
                )}
              />
              <Route
                exact
                path="/docs/chatbots/1webchat/suggestions"
                component={OptionalAuth(
                  DocumentationContainer("1webchat-suggestions")
                )}
              />
              <Route
                exact
                path="/docs/chatbots/1webchat/install-chat-widget"
                component={OptionalAuth(
                  DocumentationContainer("1webchat-install-chat-widget")
                )}
              />
              <Route
                exact
                path="/docs/chatbots/1webchat/session-timeout"
                component={OptionalAuth(
                  DocumentationContainer("1webchat-session-timeout")
                )}
              />
              <Route
                exact
                path="/documentation"
                component={OptionalAuth(DocumentationIOSSdkContent)}
              />
              <Route
                exact
                path="/documentation/ios"
                component={OptionalAuth(DocumentationIOSSdkContent)}
              />
              <Route
                exact
                path="/documentation/android"
                component={OptionalAuth(DocumentationAndroidSdkContent)}
              />
              <Route
                exact
                path="/documentation/javascript"
                component={OptionalAuth(DocumentationJavascriptSdkContent)}
              />
              <Route
                exact
                path="/documentation/infrastructure"
                component={OptionalAuth(DocumentationInfrastructureContent)}
              />
              <Route
                exact
                path="/documentation/security"
                component={OptionalAuth(DocumentationSecurityContent)}
              />
              <Route
                exact
                path="/documentation/inappplans"
                component={OptionalAuth(DocumentationeRTCPlansContent)}
              />
              <Route
                exact
                path="/documentation/billing"
                component={OptionalAuth(DocumentationBillingContent)}
              />
              <Route
                exact
                path="/documentation/chatusers"
                component={OptionalAuth(DocumentationChatUsersContent)}
              />
              <Route
                exact
                path="/documentation/terms"
                component={OptionalAuth(DocumentationTermsContent)}
              />
              <Route
                exact
                path="/documentation/application"
                component={OptionalAuth(DocumentationApplicationContent)}
              />
              <Route
                exact
                path="/booking-confirmation"
                component={OptionalAuth(BookingConfirmation)}
              />
              <Route
                exact
                path="/appsumo"
                component={RequireAuth(AppSumoPage)}
              />
              {/*<Route exact path="/documentationOverview" component={OptionalAuth(DocumentationOverviewPage)}/>*/}
              {/*<Route exact path="/documentation/tutorials" component={OptionalAuth(DocumentationTutorialsContent)}/>*/}
              {/*<Route exact path="/documentationHome" component={OptionalAuth(DocumentationHomePage)}/>*/}
              {/*<Route exact path="/tutorialFirstTime" component={RequireAuth(TutorialFirstTimePage)}/>*/}
              {/*<Route exact path="/internalError" component={InternalServerErrorPage}/>*/}
              <Route path="*" component={OptionalAuth(NotFoundPage)} />
            </Switch>
          </Suspense>
        </div>
      </>
      <Notification />
    </>
  );
};

export default AppRouter;
