import React from "react";
import Modal from "./modal";
import SubmitButton from "./submitButton";

const ConfirmationModal = ({
  id,
  show,
  actionText,
  actionSubTextLabel,
  actionSubText,
  actionButtonText = "OK",
  actionButtonBtnClass,
  actionHandler,
  cancelText = "Cancel",
  cancelButtonBtnClass,
  cancelHandler,
  actionButtonLoading = false
  /*actionResult = null*/
}) => {
  return (
    <Modal
      onCloseHandler={cancelHandler}
      id={id}
      show={show}
      title={actionText}
    >
      <div className="row">
        <div className="form-group col-12">
          {actionSubTextLabel && <label>{actionSubTextLabel}</label>}
          {actionSubText && <p className="info m-0">{actionSubText}</p>}
        </div>
        <div className="modal-action col-12">
          <button
            type="button"
            className={cancelButtonBtnClass}
            onClick={cancelHandler}
            data-dismiss="modal"
          >
            {cancelText}
          </button>
          <SubmitButton
            buttonClass={actionButtonBtnClass}
            isSubmitting={actionButtonLoading}
            onClick={actionHandler}
          >
            {actionButtonText}
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
