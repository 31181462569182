import store from "../store/store";
import {
  genericFetchApiCatchHandler,
  handleHttpResponse
} from "../utils/httpResponseErrorHandler";
import { endpoint } from "../utils/restCallUtil";
import history from "../history";
import { loadCookie, removeCookie, saveCookie } from "../utils/commonUtil";

export const TOKEN_RECEIVED = "token_received";
export const TOKEN_REMOVED = "token_removed";

export function getAuthToken(dispatch) {
  return new Promise((resolve, reject) => {
    const tokenState = store.getState().auth.token;
    const expiresAt = tokenState.expiresAt;
    const currentTime = new Date().getTime() / 1000;
    // consider expiresIn minus 30 mins
    if (currentTime >= expiresAt - 30 * 60) {
      getAccessTokenFromRefreshToken(
        tokenState.refreshToken,
        dispatch,
        resolve,
        reject
      );
    } else {
      const accessToken = loadCookie(`token`);
      resolve({ accessToken: accessToken });
    }
  });
}

function getAccessTokenFromRefreshToken(
  refreshToken,
  dispatch,
  resolve,
  reject
) {
  if (!refreshToken || refreshToken === undefined) {
    history.push("/login");
    return;
  }
  const RefreshTokenEndpoint = endpoint("auth0/refreshToken");
  fetch(RefreshTokenEndpoint, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({ refreshToken: refreshToken }),
    headers: new Headers({
      "content-type": "application/json"
    })
  })
    .then(response => {
      handleHttpResponse(
        response,
        refreshTokenSuccessHandler(resolve, dispatch),
        refreshTokenErrorHandler(reject, dispatch)
      );
    })
    .catch(error => {
      genericFetchApiCatchHandler(error, reject);
    });

  /*
    * response.json().then(data => {
                const result = handleHttpResponse(response, data);
                if (result instanceof ActionSuccess) {
                    cookie.save(`token`, response.data.token.token, { path: "/", domain: DOMAIN });
                    //cookie.save('token', response.data.token.token, { path: '/' });
                   // dispatch({ type: AUTH_USER });
                    dispatch({
                        type: TOKEN_RECEIVED,
                        token: result.data.token
                    });
                    resolve({ accessToken: result.data.token.token });
                } else {
                    reject({ result: result });
                }
            });*/
}

function refreshTokenSuccessHandler(resolve, dispatch) {
  return actionSuccess => {
    const accessToken = actionSuccess.data.token.token;
    saveCookie(`token`, accessToken);
    dispatch({
      type: TOKEN_RECEIVED,
      token: actionSuccess.data.token
    });
    resolve({ accessToken: accessToken });
  };
}

function refreshTokenErrorHandler(reject, dispatch) {
  return (/*actionError*/) => {
    reject({ result: "Error in refreshing session" });
    removeCookie("token");
    dispatch({
      type: TOKEN_REMOVED
    });
    history.push("/login");
  };
}
