import React, { Component } from "react";
import { SEO_HOME } from "../../../utils/seoutils";
import DocumentationHome from "../documentation/documentationHome";
import SitePageContainer from "../../common/sitePageContainer";
import DocsChatHome from "../documentation/docsChatHome";
import DocsUiKitHome from "../documentation/docsUiKitHome";
import DocsChatbotHome from "../documentation/docsChatbotHome";
import DocsChatbotNluGD from "../documentation/chatbots/docsChatbotNluGD";
import DocsChatbotNluLX from "../documentation/chatbots/docsChatbotNluLX";
import DocsChatbotNluWT from "../documentation/chatbots/docsChatbotNluWT";
import DocsChatbotNluWTConfigGuide from "../documentation/chatbots/docsChatbotNluWTConfigGuide";
import DocsChatbotChatWidget from "../documentation/chatbots/docsChatbotChatWidget";
import Docs1ChatbotDefaultReplies from "../documentation/chatbots/docs1ChatbotDefaultReplies";
import Docs1ChatbotSessionTimeout from "../documentation/chatbots/docs1ChatbotSessionTimeout";
import Docs1WebchatDefaultReplies from "../documentation/webchat/docs1WebchatDefaultReplies";
import Docs1WebchatSessionTimeout from "../documentation/webchat/docs1WebchatSessionTimeout";
import DocWebchatSuggestions from "../documentation/webchat/docWebchatSuggestions";
import DocWebchatInstallingChatWidget from "../documentation/webchat/docWebchatInstallChatWIdget";
import DocsChatSDK from "../documentation/chatsdk";

class DocumentationPage extends Component {
  render() {
    const type = this.props.type;
    return (
      <SitePageContainer pageName={SEO_HOME}>
        {type === "home" && <DocumentationHome />}
        {type === "chat" && <DocsChatHome />}
        {type === "uikit" && <DocsUiKitHome />}
        {type === "chatbot" && <DocsChatbotHome />}
        {type === "chatbots_nlu_gd" && <DocsChatbotNluGD />}
        {type === "amazon_lex" && <DocsChatbotNluLX />}
        {type === "ibm_watson" && <DocsChatbotNluWT />}
        {type === "ibm_watson_config" && <DocsChatbotNluWTConfigGuide />}
        {type === "install-chat-widget" && <DocsChatbotChatWidget />}
        {type === "1chatbot-default-replies" && <Docs1ChatbotDefaultReplies />}
        {type === "1chatbot-session-timeout" && <Docs1ChatbotSessionTimeout />}
        {type === "chatsdk-doc" && <DocsChatSDK />}
        {type === "1webchat-default-replies" && <Docs1WebchatDefaultReplies />}
        {type === "1webchat-session-timeout" && <Docs1WebchatSessionTimeout />}
        {type === "1webchat-suggestions" && <DocWebchatSuggestions />}
        {type === "1webchat-install-chat-widget" && (
          <DocWebchatInstallingChatWidget />
        )}
      </SitePageContainer>
    );
  }
}
export default DocumentationPage;
