import React, { Component } from "react";
import SiteHeader from "../../../common/siteHeader";
import NluTitleBlock from "./nluTitleBlock";
import ChatbotsDocSidebar from "./chatbotsDocSidebar";
import ChatbotsCategoryNavBar from "./chatbotsCategoryNavBar";

class DocsChatbotNluLX extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <ChatbotsCategoryNavBar />
        <main className="content-wrapper main-content">
          <NluTitleBlock />
          <div className="content-left-block connecting-grid-block --scrollpage">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xl-3 tab-inner-content">
                  <ChatbotsDocSidebar />
                </div>
                <div className="col-lg-8 col-xl-9">
                  <div className="tab-content tutorials-details-block">
                    <div className="tab-pane fade show active" id="amazon">
                      <h2>How to get credentials</h2>
                      <h3>Required fields</h3>
                      <ul className="field-list">
                        <li>Access key ID & Secret access key</li>
                        <li>Region ID</li>
                        <li>Language Setting</li>
                        <li>Bot ID 5</li>
                        <li>Bot Alias ID</li>
                      </ul>
                      <ul>
                        <li>
                          Access Key ID and Secret Access Key
                          <ul>
                            <li>
                              Sign in to the AWS Management Console and open the
                              IAM console at:{" "}
                              <a
                                href="https://console.aws.amazon.com/iam/"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                https://console.aws.amazon.com/iam/
                              </a>
                            </li>
                            <li>
                              In the navigation pane, choose Users
                              <ul>
                                <li>
                                  Select Add user (blue button on the top right)
                                </li>
                                <li>
                                  Under “Set user details” write the username
                                  “1Chatbot”
                                </li>
                                <li>
                                  Under “Select AWS access type” select the
                                  checkbox for “Access key - programmatic
                                  access”
                                </li>
                                <li>
                                  Select “Next Permissions” (blue button) to
                                  continue
                                </li>
                                <li>
                                  Under “Add user to group” select “Create
                                  Group”
                                </li>
                                <li>In the Group name write “1Chatbot”</li>
                                <li>
                                  In the “Filter policies” search bar type
                                  “AmazonLexRunBotsOnly”
                                </li>
                                <li>
                                  Select the checkbox next to
                                  “AmazonLexRunBotsOnly” which appears in the
                                  search results
                                </li>
                                <li>Next, search for “AmazonLexReadOnly”</li>
                                <li>
                                  Select the checkbox next to
                                  “AmazonLexReadOnly”
                                </li>
                                <li>Select “Create group” (blue button)</li>
                                <li>Select “Next tags” (blue button)</li>
                                <li>Select “Next: Review” (blue button)</li>
                                <li>Select “Create user” (blue button)</li>
                              </ul>
                              <div className="info-content">
                                <p>
                                  To download the key pair, choose Download .csv
                                  file. Store the keys in a secure location. You
                                  will not have access to the secret access key
                                  again after this dialog box closes.
                                </p>
                                <p>
                                  Your credentials will look something like
                                  this:
                                </p>
                                <p>
                                  <strong>Access key ID:</strong>
                                  <br /> AKIAIOSFODNN7EXAMPLE
                                </p>
                                <p>
                                  <strong>Secret access key: </strong>
                                  <br />{" "}
                                  wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY
                                </p>
                                <p>
                                  Keep the keys confidential in order to protect
                                  your AWS and 1Chatbot account and never email
                                  them. Do not share them outside of your
                                  1Chatbot integration, even if an inquiry
                                  appears to come from AWS or Amazon.com. No one
                                  who legitimately represents Amazon will ever
                                  ask you for your secret key.
                                </p>
                              </div>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Get Region ID
                          <ul>
                            <li>
                              Search for Lex in your AWS console search bar and
                              select “Amazon Lex, Build Voice and Text Chatbots”
                            </li>
                            <li>
                              In the left side menu select “Bots” to view your
                              list of Lex bots (you may have to click Get
                              Started first)Select your bot from the list
                            </li>
                            <li>
                              In the upper righthand of the screen there is a
                              menu option with the name of the city where you
                              selected the region during setup
                            </li>
                            <li>
                              Select the menu and view the dropdown to get the
                              region ID
                            </li>
                            <li>
                              For example, if your city was “N. Virginia” in the
                              dropdown menu you see us-east-1 on the right side
                              which is the region ID
                            </li>
                            <li>
                              Alternatively, when you are in the bot overview
                              screen you can look in the address bar of your
                              browser to view the region ID within the URL.
                            </li>
                            <li>
                              In the “Filter policies” search bar type
                              “AmazonLexRunBotsOnly”
                            </li>
                            <li>
                              Example:{" "}
                              <a
                                href="https://us-east-1.console.aws.amazon.com/lexv2/home?region=us-east-1#bot/0PEXAMPLE"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://us-east-1.console.aws.amazon.com/lexv2/home?region=us-east-1#bot/0PEXAMPLE
                              </a>
                            </li>
                            <li>Copy the region ID or note it down</li>
                          </ul>
                        </li>
                        <li>
                          Get Language Settings
                          <ul>
                            <li>
                              Search for Lex in your AWS console search bar and
                              select “Amazon Lex, Build Voice and Text Chatbots”
                            </li>
                            <li>
                              In the left side menu select “Bots” to view your
                              list of Lex bots (you may have to click Get
                              Started first)Select your bot from the list
                            </li>
                            <li>
                              On the left side menu select “All languages”
                            </li>
                            <li>
                              Your language (and region) is listed here.
                              <br /> Example: English (US)
                            </li>
                          </ul>
                        </li>
                        <li>
                          Get Bot ID
                          <ul>
                            <li>
                              Search for Lex in your AWS console search bar and
                              select “Amazon Lex, Build Voice and Text Chatbots”
                            </li>
                            <li>
                              In the left side menu select “Bots” to view your
                              list of Lex bots (you may have to click Get
                              Started first)Select your bot from the list
                            </li>
                            <li>
                              In the in the Bot details section, you can find
                              the ID
                            </li>
                            <li>
                              Your Bot ID will look something like this:
                              UFIDGBA7FE
                            </li>
                          </ul>
                        </li>
                        <li>
                          Get Bot Alias ID
                          <ul>
                            <li>
                              On the Amazon Lex V2 console, navigate to your
                              bot.
                            </li>
                            <li>
                              In the navigation pane, under Bot versions, under
                              Deployment, choose Aliases.Choose your alias.
                            </li>
                            <li>For this post, we use TestBotAlias.</li>
                            <li>
                              In the Details section, you can find the alias ID.
                            </li>
                            <li>
                              Your Bot Alias ID will look something like this:
                              TSTALIASID
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default DocsChatbotNluLX;
