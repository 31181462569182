import React, { Component } from "react";
import SuccessPage from "../pages/successPage";

export default function(successType) {
  return class extends Component {
    render() {
      return <SuccessPage {...this.props} type={successType} />;
    }
  };
}
