import React from "react";
import SiteHeader from "../../../common/siteHeader";
import WebchatDocSidebar from "./webchatDocSidebar";
import ChatbotsCategoryNavBar from "../chatbots/chatbotsCategoryNavBar";
import OneWebchatTitleBlock from "./oneWebchatTitleBlock";
import CodeBlock from "../codeBlock";
import {
  WEBCHAT_DIALOGFLOW_SUGGESTION_CHIPS,
  WEBCHAT_LEX_SUGGESTION_CHIPS
} from "../code";

import suggestionChipsDialog0 from "../../../../public/resources/site/images/suggestion-chips-dialog0.png";
import suggestionChipsDialog1 from "../../../../public/resources/site/images/suggestion-chips-dialog1.png";
import suggestionChipsDialog2 from "../../../../public/resources/site/images/suggestion-chips-dialog2.png";
import suggestionChipsDialog3 from "../../../../public/resources/site/images/suggestion-chips-dialog3.png";
import suggestionChipsWatsonDialog1 from "../../../../public/resources/site/images/suggestion-chips-watson-dialog1.png";
import suggestionChipsWatsonDialog2 from "../../../../public/resources/site/images/suggestion-chips-watson-dialog2.png";
import suggestionChipsWatsonDialog3 from "../../../../public/resources/site/images/suggestion-chips-watson-dialog3.png";
import suggestionChipsLex1 from "../../../../public/resources/site/images/suggestion-chips-lex1.png";
import suggestionChipsLex2 from "../../../../public/resources/site/images/suggestion-chips-lex2.png";
import suggestionChipsWatsonActions0 from "../../../../public/resources/site/images/suggestion-chips-watson-actions0.png";
import suggestionChipsWatsonActions1 from "../../../../public/resources/site/images/suggestion-chips-watson-actions1.png";
import suggestionChipsWatsonActions2 from "../../../../public/resources/site/images/suggestion-chips-watson-actions2.png";
import suggestionChipsWatsonActions3 from "../../../../public/resources/site/images/suggestion-chips-watson-actions3.png";
import suggestionChipsWatsonActions4 from "../../../../public/resources/site/images/suggestion-chips-watson-actions4.png";
import suggestionChipsWatsonActions5 from "../../../../public/resources/site/images/suggestion-chips-watson-actions5.png";

const DocWebchatSuggestions = () => {
  return (
    <>
      <SiteHeader fixedHeaderOnScroll={false} isDark />
      <ChatbotsCategoryNavBar />
      <main className="content-wrapper main-content">
        <OneWebchatTitleBlock />
        <div className="content-left-block connecting-grid-block --scrollpage">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-xl-3 tab-inner-content">
                <WebchatDocSidebar />
              </div>
              <div className="col-lg-8 col-xl-9">
                <div className="tab-content tutorials-details-block">
                  <div className="tab-pane fade show active" id="onechatbot">
                    <h3 className="top-gap">Suggestion Chips for Dialogflow</h3>
                    <p className="mb-2">
                      Conversations made easier with Suggestion Chips
                    </p>
                    <ul className="field-list">
                      <li>
                        1Chatbot can display response suggestions in order to
                        save time and the trouble of typing for end-users.
                      </li>
                      <li>
                        Response suggestions can also greatly improve the
                        performance of your conversation flow as users receive
                        guidance on what to say or choose next.
                      </li>
                    </ul>
                    <div className="image-block mb-5">
                      <img
                        src={suggestionChipsDialog0}
                        alt="Suggestion Chips for Dialog 0"
                      />
                    </div>
                    <h3>To add suggestion chips for Dialogflow:</h3>
                    <ul className="field-list">
                      <li>
                        Navigate to the Intent you are trying to modify the
                        response for
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsDialog1}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                      <li>
                        Scroll down to the “Responses” section of the intent
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsDialog2}
                            alt="Suggestion Chips for Dialog 2"
                          />
                        </div>
                      </li>
                      <li>
                        Click the “Add Responses” button and select “Custom
                        Payload”
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsDialog3}
                            alt="Suggestion Chips for Dialog 3"
                          />
                        </div>
                      </li>
                      <li>
                        Use the following JSON format in the Custom Payload
                        section
                        <CodeBlock
                          code={WEBCHAT_DIALOGFLOW_SUGGESTION_CHIPS}
                          language="json"
                        />
                      </li>
                    </ul>
                    <h6 style={{ fontWeight: "bold" }}>action</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        The identification of the JSON type, must always be
                        “response”
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Options: “response”</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>type</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p>
                        Specifying the type of response, must always be
                        “suggestionChips”
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Options: “suggestionChips”</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>output</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        Your app can displayed the specified options in any
                        desired user-interface control (buttons are recommended
                        for up to 5 options and a drop-down list is recommended
                        for up to 10 options).
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Options: “button” or “dropdown”</li>
                        <li>Required: No</li>
                        <li>Default value: “button”</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>data</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        An array containing the response data. Currently the
                        data array can only contain 1 object for 1 response.
                      </p>
                      <ul className="field-list">
                        <li>Type: Array</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>textReply</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        The text reply sent to the end-user.
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>suggestionChips</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        An array containing the suggestion chips’ label and
                        associated input text. Currently the suggestionChips
                        array can only contain 1 object with all of the
                        suggestion options.
                      </p>
                      <ul className="field-list">
                        <li>Type: Array</li>
                        <li>
                          Options: The maximum amount of options that can be
                          displayed is 10
                        </li>
                        <li>
                          Required: Yes (minimum of 1 suggestion chip is
                          required when utilizing the type “suggestionChips”)
                        </li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>label</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        The title displayed as the suggestion chip (button
                        title, or element in a dropdown list)
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>
                          Options: Any string with a character count limit of
                          20-characters per label title
                        </li>
                        <li>
                          Required: Yes (minimum of 1 label is required when
                          utilizing the type “suggestionChips”)
                        </li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>inputText</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-0">
                        The associated input text sent to the bot when a label
                        is selected by an end-user. This input text will be the
                        trigger for the next intent in the NLU provider, or
                        variable for slot-filling, or parameter/entity
                        collection in Dialogflow, Lex, or Watson.
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>
                          Required: Yes (minimum of 1 inputText is required when
                          utilizing the type “suggestionChips”)
                        </li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>platform</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        Identification of the chatbot platform. Must always be
                        “dialogflow” when originating from Dialogflow
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Options: “dialogflow”</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    {/* Lex Console */}
                    <h3 id="lex-console" className="top-gap">
                      Suggestion Chips for Lex: Using the Lex console and
                      Closing response
                    </h3>
                    <h6 style={{ fontWeight: "bold" }}>
                      Conversations made easier with Suggestion Chips
                    </h6>
                    <p className="mb-2">
                      1Chatbot can display response suggestions in order to save
                      time and the trouble of typing for end-users. Response
                      suggestions can also greatly improve the performance of
                      your conversation flow as users receive guidance on what
                      to say or choose next.
                    </p>
                    <div className="image-block mb-5">
                      <img
                        src={suggestionChipsDialog0}
                        alt="Suggestion Chips for Lex Console"
                      />
                    </div>
                    <p>
                      To add suggestion chips for Lex using the Lex console and
                      Closing response:
                    </p>
                    <ul className="field-list">
                      <li>
                        Navigate to your AWS Management Console and select
                        Amazon Lex
                      </li>
                      <li>
                        Select your Amazon Lex bot from the{" "}
                        <strong>Bots</strong> table
                      </li>
                      <li>
                        Select <strong>Intents</strong> on the left side menu
                        and select the intent that you want to modify the
                        response for in the <strong>Intents</strong> table
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsLex1}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                      <li>
                        Scroll down to <strong>Closing response</strong> section
                        and under Variations select “More response options”
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsLex2}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                      <li>
                        Select the “Add” dropdown menu in the top right and
                        choose “Add custom payload”
                      </li>
                      <li>
                        Use the following JSON format in the{" "}
                        <strong>Custom payload response</strong>, and click
                        “Update response” when complete
                        <CodeBlock
                          code={WEBCHAT_LEX_SUGGESTION_CHIPS}
                          language="json"
                        />
                      </li>
                    </ul>
                    <h6 style={{ fontWeight: "bold" }}>action</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        The identification of the JSON type, must always be
                        “response”
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Options: “response”</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>type</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p>
                        Specifying the type of response, must always be
                        “suggestionChips”
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Options: “suggestionChips”</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>output</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        Your app can displayed the specified options in any
                        desired user-interface control (buttons are recommended
                        for up to 5 options and a drop-down list is recommended
                        for up to 10 options).
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Options: “button” or “dropdown”</li>
                        <li>Required: No</li>
                        <li>Default value: “button”</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>data</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        An array containing the response data. Currently the
                        data array can only contain 1 object for 1 response.
                      </p>
                      <ul className="field-list">
                        <li>Type: Array</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>textReply</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        The text reply sent to the end-user.
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>suggestionChips</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        An array containing the suggestion chips’ label and
                        associated input text. Currently the suggestionChips
                        array can only contain 1 object with all of the
                        suggestion options.
                      </p>
                      <ul className="field-list">
                        <li>Type: Array</li>
                        <li>
                          Options: The maximum amount of options that can be
                          displayed is 10
                        </li>
                        <li>
                          Required: Yes (minimum of 1 suggestion chip is
                          required when utilizing the type “suggestionChips”)
                        </li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>label</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        The title displayed as the suggestion chip (button
                        title, or element in a dropdown list)
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>
                          Options: Any string with a character count limit of
                          20-characters per label title
                        </li>
                        <li>
                          Required: Yes (minimum of 1 label is required when
                          utilizing the type “suggestionChips”)
                        </li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>inputText</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-0">
                        The associated input text sent to the bot when a label
                        is selected by an end-user. This input text will be the
                        trigger for the next intent in the NLU provider, or
                        variable for slot-filling, or parameter/entity
                        collection in Dialogflow, Lex, or Watson.
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>
                          Required: Yes (minimum of 1 inputText is required when
                          utilizing the type “suggestionChips”)
                        </li>
                      </ul>
                    </div>
                    <h6 style={{ fontWeight: "bold" }}>platform</h6>
                    <div style={{ paddingLeft: "1rem" }}>
                      <p className="mb-2">
                        Identification of the chatbot platform. Must always be
                        “dialogflow” when originating from Dialogflow
                      </p>
                      <ul className="field-list">
                        <li>Type: String</li>
                        <li>Options: “dialogflow”</li>
                        <li>Required: Yes</li>
                      </ul>
                    </div>
                    {/* Watson Dialog */}
                    <h3 id="watson-dialog" className="top-gap">
                      Suggestion Chips for Watson: Using Watson Dialog
                    </h3>
                    <h6 style={{ fontWeight: "bold" }}>
                      Conversations made easier with Suggestion Chips
                    </h6>
                    <p className="mb-2">
                      1Chatbot can display response suggestions in order to save
                      time and the trouble of typing for end-users. Response
                      suggestions can also greatly improve the performance of
                      your conversation flow as users receive guidance on what
                      to say or choose next.
                    </p>
                    <div className="image-block mb-5">
                      <img
                        src={suggestionChipsDialog0}
                        alt="Suggestion Chips for Lex Console"
                      />
                    </div>
                    <p>
                      To add suggestion chips for Watson using Watson Dialog:
                    </p>
                    <ul className="field-list">
                      <li>Login to the Watson Assistant console</li>
                      <li>Select your bot from the drop down menu</li>
                      <li>
                        Choose “Dialog” from the left side menu
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonDialog1}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                      <li>
                        Select Dialog in the options that appear on the left and
                        choose the node that you want to modify the response for
                        with suggestion chips.
                      </li>
                      <li>
                        In the “Assistant responds” section click “Add response
                        type +”
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonDialog2}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                      <li>
                        For the response type choose “Option”
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonDialog3}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                      <li>
                        Fill in the following fields:
                        <div className="pl-4 pt-4">
                          <h6 style={{ fontWeight: "bold" }}>Title:</h6>
                          <p className="mb-4">
                            Any string, this is what will precede the suggestion
                            chip options. Required.
                          </p>
                          <h6 style={{ fontWeight: "bold" }}>Description:</h6>
                          <p className="mb-4">
                            Any string, this will appear as subtext below the
                            title. Optional.
                          </p>
                          <h6 style={{ fontWeight: "bold" }}>List label:</h6>
                          <p className="mb-4">
                            Any string, this will appear as the suggestion chip
                            option for the end-user to choose. At least one is
                            required.
                          </p>
                          <h6 style={{ fontWeight: "bold" }}>Value:</h6>
                          <p className="mb-4">
                            Any string, the associated input text sent to the
                            bot when a label is selected by an enduser. This
                            input text will be the trigger for the next intent
                            in the NLU provider, or variable for slot-filling,
                            or parameter/entity collection in Dialogflow, Lex,
                            or Watson. At least one is required.
                          </p>
                          <p className="mb-4">
                            Only the title, description and labels will be shown
                            to the end-user. If additional response types are
                            contained in the same response, such as “text” they
                            will not be displayed.
                          </p>
                          <a
                            href="#"
                            className="highlight"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Note: [Insert text here about how to handle dropdown
                            vs buttons for the UI]
                          </a>
                        </div>
                      </li>
                    </ul>
                    {/* Watson Actions */}
                    <h3 id="watson-actions" className="top-gap">
                      Suggestion Chips for Watson: Using Watson Actions
                    </h3>
                    <h6 style={{ fontWeight: "bold" }}>
                      Conversations made easier with Suggestion Chips
                    </h6>
                    <p className="mb-2">
                      1Chatbot can display response suggestions in order to save
                      time and the trouble of typing for end-users. Response
                      suggestions can also greatly improve the performance of
                      your conversation flow as users receive guidance on what
                      to say or choose next.
                    </p>
                    <div className="image-block mb-5">
                      <img
                        src={suggestionChipsDialog0}
                        alt="Suggestion Chips for Lex Console"
                      />
                    </div>
                    <p>
                      To add suggestion chips for Watson using Watson Actions:
                    </p>
                    <ul className="field-list">
                      <li>Login to the Watson Assistant console</li>
                      <li>Select your bot from the drop down menu</li>
                      <li>
                        Choose “Actions” from the left side menu
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonActions0}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                      <li>
                        Choose the action that you want to modify the response
                        for with suggestion chips.
                      </li>
                      <li>
                        Under the “Assistant says” response, select “Define
                        customer response”
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonActions1}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                      <li>Choose “Options” as the response type</li>
                      <li>
                        Enter the options to appear as suggestion chips and
                        click Apply. The options listed here “Option 1,” “Option
                        2,” and “Option 3” would be what your end-user sees as
                        buttons or a dropdown (depending on your UI
                        configuration). In Watson Actions, by default the
                        options will also be the input text that will trigger
                        the next intent in the NLU provider, or variable for
                        slotfilling, or parameter/entity collection in
                        Dialogflow, Lex, or Watson. At least one option is
                        required.
                        <p className="mb-2 mt-2">
                          <strong>Note:</strong>&nbsp;{" "}
                          {`[Insert text here about how to handle dropdown vs buttons for the UI]`}
                        </p>
                        <p
                          className="mb-2"
                          style={{ textDecoration: "underline" }}
                        >
                          Additional steps to modify the input text associated
                          to an option (Optional):
                        </p>
                      </li>
                      <li>
                        This step is only for changing the value of the input
                        text that is associated with the button label. By
                        default Watson Actions button labels and input text are
                        the same. To change this, click on the JSON editor
                        button in the “Assistant says” section.
                      </li>
                      <li>
                        Scroll down the JSON view and locate the button labels
                        just created. Here you can see each button label is
                        associated with a string value called “text” — modify
                        the value of “text” for each button label to change the
                        input text that is triggered when a user selects the
                        menu option. Your user will only see the value of the
                        “label” variable, the “text” value will be what is sent
                        back to 1Chatbot in order to trigger the next step(s).
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonActions2}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonActions3}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonActions4}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                        <p className="mb-2">
                          With the changes shown above, when a user selects
                          “Option 1” from a dropdown or group of buttons, it is
                          the equivalent of typing “New input text” as we have
                          modified the associated input text.
                        </p>
                        <div className="image-block mb-5">
                          <img
                            src={suggestionChipsWatsonActions5}
                            alt="Suggestion Chips for Dialog 1"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default DocWebchatSuggestions;
