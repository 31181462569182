import React from "react";
import { Link } from "react-router-dom";
import { Form, Field, isDirty, reduxForm } from "redux-form";
import { connect } from "react-redux";
import history from "../../../history";
import { resendEmailVerification, signUpUser } from "../../../actions/users";
import SubmitButton from "../../common/submitButton";
import { ActionSuccess } from "../../../utils/httpResponseErrorHandler";
import whiteLogoIcon from "../../../public/resources/site/images/logo-white.svg";
import ServerResponse from "../../common/serverResponse";
import { getUniquePlayerId } from "../../../utils/commonUtil";
import config from "../../../config/config";
import RelativeLoadingDisplay from "../../common/relativeLoadingDisplay";
import InputText_v2 from "../../common/inputText_v2";
import {
  normalizeNumber,
  validateCompanyName,
  validatePersonName,
  validateEmail,
  validatePassword
} from "../../../utils/validationUtil";

class SignUpContent_v2 extends React.Component {
  email;
  invitedOrg = false;

  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleResendEmail = this.handleResendEmail.bind(this);
    this.onSubscribeblogCheckChange = this.onSubscribeblogCheckChange.bind(
      this
    );
    this.onCommunicationCheckChange = this.onCommunicationCheckChange.bind(
      this
    );
    this.onPrivacyCheckChange = this.onPrivacyCheckChange.bind(this);
    this.state = {
      emailSent: false,
      isSignUpSubmitting: false,
      isResendEmailVerifySubmitting: false,
      resendVerificationReq: false,
      privacyCheck: false,
      subscribeblogCheck: false,
      communicationCheck: false
    };
  }

  componentDidMount() {
    this.props.initialize({
      uemail: this.props.email,
      uorganization: this.props.organization
    });
  }

  handleFormSubmit(values) {
    this.setState({ isSignUpSubmitting: true });
    const name = values.ufirstname.trim() + " " + values.ulastname.trim();
    const email = values.uemail.trim().toLowerCase();
    const password = values.upassword;
    const organizationName = values.uorganization.trim();
    const position = values.urole;
    const department = values.udepartment;
    const industry = values.uindustry;
    const numOfEmployees = values.uemployeecount;
    const subscribeBlog = this.state.subscribeblogCheck;
    const sendMarketingEmail = this.state.communicationCheck;
    const acceptTermCondition = this.state.privacyCheck;

    this.email = email;
    this.playerId = getUniquePlayerId();
    this.organization = organizationName;
    this.context = this.getContextPage();

    this.props
      .signUpUser({
        email,
        name,
        password,
        organizationName,
        position,
        department,
        industry,
        numOfEmployees,
        subscribeBlog,
        sendMarketingEmail,
        acceptTermCondition,
        invitedOrg: this.invitedOrg,
        context: this.context,
        playerId: this.playerId
      })
      .then(result => {
        this.setState({
          resultObj: result.actionDataObj,
          isSignUpSubmitting: false
        });
        if (this.invitedOrg) {
          history.push("/login");
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          resultObj: error.actionDataObj,
          isSignUpSubmitting: false
        });
      });
  }

  onPrivacyCheckChange() {
    this.setState({ privacyCheck: !this.state.privacyCheck });
  }

  onSubscribeblogCheckChange() {
    this.setState({ subscribeblogCheck: !this.state.subscribeblogCheck });
  }

  onCommunicationCheckChange() {
    this.setState({ communicationCheck: !this.state.communicationCheck });
  }

  getContextPage() {
    if (this.props.referrer === "starter") {
      return "/starter/project/create";
    } else if (this.props.referrer === "professional") {
      return "/professional/instancetype";
    } else {
      return "/home";
    }
  }

  renderBacklinkBox() {
    return (
      <div className="back-link-box">
        <Link to="/pricing" className="back">
          <i className="i-icon icon-back-gray" />
          Back
        </Link>
        <div className="sign-in-link">
          <p>
            Already have an account? <Link to="/login">Sign In</Link>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const emailVerified =
      this.state.resultObj &&
      this.state.resultObj instanceof ActionSuccess &&
      !this.invitedOrg;

    return (
      <div className="image-content-block">
        <div className="container-fluid">
          <div className="row m-0">
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
              <div className="image-block">
                <Link to="/home" className="navbar-brand">
                  <img src={whiteLogoIcon} alt="In-App chat logo" />
                </Link>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-0">
              <div
                className={`contant-info-block ${
                  emailVerified ? "pt-0 pb-0" : ""
                }`}
              >
                {!emailVerified ? this.renderBacklinkBox() : null}
                {!emailVerified
                  ? this.renderSignup()
                  : this.state.resendVerificationResultObj instanceof
                    ActionSuccess
                  ? this.renderResendEmail()
                  : this.renderEmailVerified()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSignup() {
    const inputTextClass = "form-control custom-input";
    const inputTextContainerClass =
      "form-group _form-group col-12 col-sm-6 col-md-6 col-lg-6";
    const { handleSubmit } = this.props;

    const { organization, email } = this.props;
    if (organization && email) this.invitedOrg = true;

    return (
      <div className="content-block">
        <h1 className="h3">Let’s get started!</h1>
        <p>To begin, please provide the following information</p>
        <Form
          onSubmit={handleSubmit(this.handleFormSubmit)}
          id="login-form"
          className="form"
        >
          <div className="row">
            <Field
              containerClass={inputTextContainerClass}
              label="First Name*"
              name="ufirstname"
              id="ufirstname"
              type="text"
              inputTextClass={inputTextClass}
              component={InputText_v2}
            />
            <Field
              containerClass={inputTextContainerClass}
              label="Last Name*"
              name="ulastname"
              id="ulastname"
              type="text"
              inputTextClass={inputTextClass}
              component={InputText_v2}
            />
          </div>
          <div className="row">
            <Field
              containerClass={inputTextContainerClass}
              label="Email Address*"
              name="uemail"
              id="uemail"
              type="text"
              inputTextClass={inputTextClass}
              component={InputText_v2}
              valueTxt={email ? email : ""}
              readOnly={this.invitedOrg}
            />
            <Field
              containerClass={inputTextContainerClass}
              label="Company Name*"
              name="uorganization"
              id="uorganization"
              type="text"
              inputTextClass={inputTextClass}
              component={InputText_v2}
              valueTxt={organization ? organization : ""}
              readOnly={this.invitedOrg}
            />
          </div>
          <div className="row">
            <Field
              containerClass={inputTextContainerClass}
              label="Role"
              name="urole"
              id="urole"
              type="text"
              inputTextClass={inputTextClass}
              component={InputText_v2}
            />
            <Field
              containerClass={inputTextContainerClass}
              label="Department"
              name="udepartment"
              id="udepartment"
              type="text"
              inputTextClass={inputTextClass}
              component={InputText_v2}
            />
          </div>
          <div className="row">
            <Field
              containerClass={inputTextContainerClass}
              label="Industry"
              name="uindustry"
              id="uindustry"
              type="text"
              inputTextClass={inputTextClass}
              component={InputText_v2}
            />
            <Field
              containerClass={inputTextContainerClass}
              label="Number of Employees"
              name="uemployeecount"
              id="uemployeecount"
              type="text"
              inputTextClass={inputTextClass}
              component={InputText_v2}
              normalize={normalizeNumber}
            />
          </div>
          <div className="row">
            <Field
              containerClass={inputTextContainerClass}
              name="upassword"
              id="upassword"
              type="password"
              label="Password*"
              inputTextClass={inputTextClass}
              component={InputText_v2}
            >
              {/*<span className="info" style={{fontSize: "10px"}}>Password must be 8 characters long and have at least one uppercase,lowercase, digit and special character</span>*/}
            </Field>
            <Field
              containerClass={inputTextContainerClass}
              name="confirm_upassword"
              id="confirm_upassword"
              type="password"
              label="Confirm Password*"
              inputTextClass={inputTextClass}
              component={InputText_v2}
            />
          </div>
          <div className="row">
            <div className="form-group col-12 mb-2">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="subscribeblog"
                  checked={this.state.subscribeblogCheck}
                  onChange={this.onSubscribeblogCheckChange}
                />
                <label className="custom-control-label" htmlFor="subscribeblog">
                  Subscribe to Blog
                </label>
              </div>
            </div>
            <div className="form-group col-12 mb-2">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="communication"
                  checked={this.state.communicationCheck}
                  onChange={this.onCommunicationCheckChange}
                />
                <label className="custom-control-label" htmlFor="communication">
                  I agree to receive marketing and other communication
                </label>
              </div>
            </div>
            <div className="form-group col-12 mb-2">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="privacy"
                  checked={this.state.privacyCheck}
                  onChange={this.onPrivacyCheckChange}
                />
                <label className="custom-control-label" htmlFor="privacy">
                  I agree to the{" "}
                  <Link to="/privacy-policy" target="_blank">
                    Privacy Policy
                  </Link>{" "}
                  and{" "}
                  <Link to="/terms-of-services" target="_blank">
                    Terms of Use
                  </Link>
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <SubmitButton
              containerClass="col-12 text-center"
              buttonClass="btn btn-primary btn-full"
              buttonId="signup_btn"
              isSubmitting={this.state.isSignUpSubmitting}
              disabled={!this.props.isDirty || !this.state.privacyCheck}
            >
              Register Account
            </SubmitButton>
          </div>
        </Form>
        {this.renderError()}
      </div>
    );
  }

  renderEmailVerified() {
    return (
      <div>
        <main className="content-wrapper height-auto m-0">
          <div className="content-form-block pt-0 pb-0 d-flex justify-content-center align-items-center">
            <div className="form-block notification-content">
              <h2 className="title text-center">Account Created</h2>
              <div className="notification-block">
                <span>Verification email sent to</span>
                <div className="email-link">{this.email}</div>
                <div className="info">
                  Check your email and click the verification link to begin.
                </div>
                {this.state.resendVerificationReq === false ? (
                  <button
                    className="forgot-link"
                    onClick={this.handleResendEmail}
                    style={{
                      backgroundColor: "transparent",
                      border: "0px"
                    }}
                  >
                    Didn’t get an email?
                  </button>
                ) : (
                  this.state.isResendEmailVerifySubmitting === true && (
                    <RelativeLoadingDisplay />
                  )
                )}
              </div>
            </div>
            {/*<div className="bg-img bottom-bg">
                            <img src={AccountIcon} className="img-fluid" alt="In-App Chat SignUp"/>
                        </div>*/}
          </div>
        </main>
      </div>
    );
  }

  renderResendEmail() {
    return (
      <main className="content-wrapper height-auto m-0">
        <div className="content-form-block pt-0 pb-0 d-flex justify-content-center align-items-center">
          <div className="form-block notification-content">
            <h2 className="title text-center">Account Created</h2>
            <div className="notification-block">
              <span>Verification Email is resent to</span>
              <div className="email-link">{this.email}</div>
              <div className="info">
                Check your email and click the verification link to begin.
              </div>
              {this.state.isResendEmailVerifySubmitting === true && (
                <RelativeLoadingDisplay />
              )}
              <Link className="forget-link" to={config.SUPPORT_URL}>
                If you are still not getting email, Please contact support for
                further assistance
              </Link>
            </div>
            {this.renderError()}
          </div>
        </div>
      </main>
    );
  }

  renderError() {
    let resultObj;
    if (this.state.resendVerificationReq === true) {
      resultObj = this.state.resendVerificationResultObj;
    } else {
      resultObj = this.state.resultObj;
    }

    return (
      <div className="row mt-2">
        <div className="col-12">
          <ServerResponse
            resultObj={resultObj}
            showErrorOnly={!this.state.resendVerificationReq}
            customSuccessMessage="Success!! Resent verification email."
          />
        </div>
      </div>
    );
  }

  handleResendEmail() {
    this.setState({
      isResendEmailVerifySubmitting: true,
      resendVerificationReq: true
    });
    this.props
      .resendEmailVerification(
        this.email,
        this.playerId,
        this.getContextPage(),
        this.organization
      )
      .then(result => {
        this.setState({
          resendVerificationResultObj: result.actionDataObj,
          isResendEmailVerifySubmitting: false
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({
          resendVerificationResultObj: error.actionDataObj,
          isResendEmailVerifySubmitting: false
        });
      });
  }
}

const validate = values => {
  const errors = {};

  const userFNResult = validatePersonName(values.ufirstname, "First name");
  if (userFNResult) errors.ufirstname = userFNResult;

  const userLNResult = validatePersonName(values.ulastname, "Last name");
  if (userLNResult) errors.ulastname = userLNResult;

  const userEmailResult = validateEmail(values.uemail);
  if (userEmailResult) errors.uemail = userEmailResult;

  const userOrganizationResult = validateCompanyName(
    values.uorganization,
    "Company name"
  );
  if (userOrganizationResult) errors.uorganization = userOrganizationResult;

  /*const userRoleResult = validateOptionalAlphaField(values.urole, "Role");
    if (userRoleResult) errors.urole = userRoleResult;

    const userDepartmentResult = validateOptionalAlphaField(values.udepartment, "Department");
    if (userDepartmentResult) errors.udepartment = userDepartmentResult;

    const userIndustryResult = validateOptionalAlphaField(values.uindustry, "Industry");
    if (userIndustryResult) errors.uindustry = userIndustryResult;*/

  const userPasswordResult = validatePassword(values.upassword);
  if (userPasswordResult) errors.upassword = userPasswordResult;

  const userConfPasswordResult = validateConfPassword(
    values.upassword,
    values.confirm_upassword
  );
  if (userConfPasswordResult) errors.confirm_upassword = userConfPasswordResult;
  return errors;
};

function validateConfPassword(password, confpassword) {
  if (!password) {
    const msg = validatePassword(password);
    if (msg) {
      return "Please provide valid password above.";
    }
  } else if (!confpassword) {
    return "Please Provide Confirm Password!";
  } else if (password !== confpassword) {
    return "Passwords do not match!";
  }
  return null;
}

const reduxFormComp = reduxForm({
  form: "signupform",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
});

function mapStateToProps(state) {
  return {
    isDirty: isDirty("signupform")(state)
  };
}

export default reduxFormComp(
  connect(mapStateToProps, { signUpUser, resendEmailVerification })(
    SignUpContent_v2
  )
);
