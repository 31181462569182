import React from "react";
import { connect } from "react-redux";
import { hideToast } from "../../actions/ui";

class ToastMessage extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.toast.visible !== nextProps.toast.visible) {
      if (nextProps.toast.visible === true) {
        setTimeout(() => {
          // hiding toast after sometime.
          this.props.hideToast();
        }, 3000);
      }
    }
  }

  curMessage = "";
  render() {
    const { visible, message, success, error } = this.props.toast;

    if (message) this.curMessage = message;

    let statusClass = "";
    let iconClass = "";
    if (success && success === true) {
      statusClass = "alert-success";
      iconClass = "icon-check-circle";
    } else if (error && error === true) {
      statusClass = "alert-danger";
      iconClass = "icon-exclamation-circle";
    }

    let toastClass = `${statusClass} ${
      this.props.className ? this.props.className : "custom-alert"
    }`;
    toastClass =
      visible === false || message.trim().length === 0
        ? `${toastClass}`
        : `${toastClass} is-show`;

    /*let style = customStyle || {};
        if(!fixed) {
            style = {...style, position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'};
            if (left) style = {...style, left: left};
            if (top) style = {...style, top: top};
        }

        if (success && success === true) {
            style = {...style, color: '#155724', backgroundColor: '#d4edda', borderColor: '#c3e6cb'};
        } else if (error && error === true) {
            style = {...style, color: '#721c24', backgroundColor: '#f8d7da', borderColor: '#f5c6cb'};
        }*/

    //    left: 43%;
    //     top: 75%;
    // position fixed:
    return (
      <div className={`alert ${toastClass}`} style={{ zIndex: 99999 }}>
        <span className="icon">
          <em className={`i-icon ${iconClass}`} />
        </span>
        <span className="alert-desc">{this.curMessage}</span>
        <span className="alert-close" onClick={this.props.hideToast}>
          <em className="i-icon icon-alert-close" />
        </span>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    toast: state.ui.toast
  };
}

export default connect(mapStateToProps, { hideToast })(ToastMessage);
