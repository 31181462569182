import React from "react";
import { scroller } from "react-scroll/modules";

const HashAnchor = ({ id, children, addId = true, offset = -90 }) => {
  const anchorClick = e => {
    e.preventDefault();
    scroller.scrollTo(id, {
      duration: 1000,
      delay: 0,
      smooth: "easeInOutQuart",
      offset
    });
  };

  return (
    <a
      className={"text-dec-none"}
      href={`#${id}`}
      id={addId ? id : null}
      onClick={anchorClick}
    >
      {children}
    </a>
  );
};

export default HashAnchor;
