import React from "react";
import HashAnchor from "../../../../common/hashAnchor";
import Note from "./note";
import CodeBlock from "../../codeBlock";

const CODE_EXAMPLES = [
  `$ npm install ertc`,
  `<script src="lib/ertc.js"></script>`,
  `<script
src="https://cdnjs.cloudflare.com/ajax/libs/paho-mqtt/1.1.0/paho-mqtt.js"
type="text/javascript"></script>`,
  `new eRTCSDK().initialise("com.ripbull.ertc");`,
  `new TenantModule().validateTenant(namespace, accessCode)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new TenantModule().login(username, password)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new TenantModule().changePassword(oldPassword, newPassword)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new TenantModule().forgetPassword(emailAddress)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new UserModule().GetChatUsers(false)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new UserModule().LogOutUser()
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `EventManager.subscribe("UserUpdated", function (message) {
  console.log(message)
});`,
  `new UserModule().UpdateUser(this.currentUser.profileStatus, (this.file != null ? this.file : null))
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new UserModule().setUserAvailability(value)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `EventManager.subscribe("Availability", function (message) {
  console.log(message)
});`,
  `let groupRecord: GroupRecord = {
  name: groupName,
  groupDesc: groupDescription,
  groupUsers: users,
  groupType: groupType
};

new GroupChatModule().createGroup(groupRecord, (this.file != null ? this.file : null))
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `new GroupChatModule().getGroups()
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new GroupChatModule().getGroupById(groupId)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new GroupChatModule().updateGroup(groupId, groupName, groupDescription, groupImage, groupType)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new GroupChatModule().addParticipants(groupId, users)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new GroupChatModule().removeParticipants(groupId, users)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new GroupChatModule().addAdmin(groupId, user)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new GroupChatModule().removeAdmin(groupId, user)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new GroupChatModule().exitGroup(groupId)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new GroupChatModule().removeGroupPic(groupId)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `EventManager.subscribe("GroupUpdated", function (message) {
  console.log(message)
});`,
  `new ChatModule().createThread(userChatId)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `// Text Message
let message: Message = {
  type: MessageType.Text,
  message: textMessage
}

// Image
let message: Message = {
  type: MessageType.Image,
  media: File,
}

// Document
let message: Message = {
  type: MessageType.File,
  media: File
}

// Contact
let message: Message = {
  type: MessageType.Contact,
  contact: {
    contactName: user.name,
    eMailRecords: [email],
    phoneNumberRecord: []
  }
}

// Giphy
let message: Message = {
  type: MessageType.Gify,
  gifPath: result
}

new ChatModule().sendMessage(threadId, message)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `new ChatModule().getMessages(threadId)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `let message: Reaction = {
  emojiCode: emojiCode,
  msgId: messageId,
  chatReactionType: ChatReactionType.SET,
  sendToChannel: 0,
}

new ChatModule().sendReaction(threadId, message)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `EventManager.subscribe('ChatRecieved', , function (message) {
  console.log(message)
});`,
  `EventManager.subscribe(''ChatUpdated', , function (message) {
  console.log(message)
});`,
  `//Set forward properties while creating the new chat message
message.isForwarded = true;
message.forwardedMsgId = forwardChatId;`,
  `/* there are two option for outgoing message deleteForMe and deleteForEveryOne And one option for incoming message is delete for me
*/
let deleteChat: DeleteMessage = {
  deleteType: deleteType //deleteForMe and deleteForEveryOne,
  msgIds: [chatId],
  threadId: threadId,
}

new ChatModule().deleteChat(deleteChat)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `let updateMessage: UpdateMessage = {
  threadId: threadId,
  msgId: chatId,
  message: message
}

new ChatModule().updateMessage(updateMessage)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `new ChatModule().messageHistory(threadId, threadType, pagesize: number, direction, currentMsgId, replyChatId, includeCurrent, fetchAll)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new ChatModule().searchLocalMessageHistory(threadId, searchText)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new ChatModule().searchMessageHistory(threadId, searchText, skip, limit)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new ChatModule().msgReadStatus(chat, MessageStatus.SEEN);`,
  `EventManager.subscribe('MessageRead', function (message) {  console.log(message);});`,
  `// To block a user: action = “block”
// To unblock a user: action = “unblock”
new ChatModule().blockUnblockUser(action, userChatId)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `/***********
msgFavList = ArrayList<MessageRecord>
isFavorite = true/false
***********/
new ChatModule().markAsFavorite(msgFavList, isFavorite)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `EventManager.subscribe('TypingStatus', function (message) {
  console.log(message);
});`,
  `let typing = {
  threadId: threadId,
  threadType: "single",
  userId: userErtcId,
  typingStatus: "on"
}
new TypingModule().typingStatus(typing);`,
  `new FCMModule().registerFCMToServer(token);`,
  `let updateMessage: UpdateMessage = {
  threadId: threadId,
  msgId: chatId,
  follow: true/false
}

new ChatModule().updateMessage(updateMessage)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `let followThreadRequest: ChatHistoryRequest = {
  threadId: "",
  threadType: "single",
  pagesize: 20,
  direction: "past",
  currentMsgId: "",
  replyChatId: "",
  fetchAll: false,
  includeCurrent: false,
  follow: true
}

new ChatModule().FollowThreads(followThreadRequest)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `let chatReportRequest: CreateChatReportRequest = {
  chatId: chatID,
  category: category,
  reason: reason
}

new ChatReportModule().CreateChatReport(chatReportRequest)
  .then(
    result => { console.log(result); })
  .catch(
    error => { console.log(error); });`,
  `new ChatReportModule().DeleteChatReport(chatReportId)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`,
  `new ChatReportModule().ChatReportList(threadId, category, status, msgType, skip, limit)
.then(
  result => { console.log(result); })
.catch(
  error => { console.log(error); });`
];

const JavascriptChatSDKContent = () => {
  const renderOverviewContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="overview" offset={-150}>
          <h2 className="h3">Overview</h2>
        </HashAnchor>
        <p className="mb-4">Ertc javascript SDK: efficiency at its finest</p>
        <p className="mb-4">The following principles enhance our product :-</p>
        <ul className="field-list">
          <li>Modularity: Code is segregated into multiple modules.</li>
          <li>
            Ease of Integration: ERTC sdk can be integrated into any web app
            which is supporting javascript.
          </li>
          <li>
            Scalability: SDK is highly scalable and can support Enterprise
            traffic
          </li>
          <li>
            Data ownership: SDK provides a data layer to effectively manage and
            control data
          </li>
          <li>Firebase powered by Google : Highly reliable and scalable</li>
          <li>Plug and Play</li>
          <li>Easily customisable</li>
        </ul>
        <h3>Some basic features :-</h3>

        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>Individual messages</li>
              <li>Push notifications</li>
              <li>Text, Image, Video, Audio messages</li>
              <li>Typing Indicators</li>
              <li>Delivered and Read receipts</li>
              <li>Chat history</li>
              <li>Chat user list</li>
              <li>Contact details</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  const renderQuickstart = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="quickstart" offset={-150}>
          <h2 className="h3 mb-3">Quickstart</h2>
        </HashAnchor>
        <p className="mb-4">
          You must download and install NodeJS if your system doesn't already
          have it. Once downloaded, use node package manager to install the ertc
          javascript library.
        </p>
        <ul className="field-list">
          <li>
            Open the terminal and move to your project path. ERTC JS SDK is
            hosted as a node package and can be directly installed in a project
            using the “npm install” command.
            <CodeBlock code={CODE_EXAMPLES[0]} language="javascript" />
          </li>
          <li>
            Include “ertcjs” into your default or main page.
            <CodeBlock language="html" code={CODE_EXAMPLES[1]} />
          </li>
          <li>
            Include paho mqtt client library from CDN. This uses MQTT to connect
            the sender and receiver.
            <CodeBlock language="html" code={CODE_EXAMPLES[2]} />
          </li>
        </ul>
        <p className="mb-4">How to initialize ERTC sdk to start using it?</p>
        <h3>Initializing the Chat SDK</h3>
        <CodeBlock language="javascript" code={CODE_EXAMPLES[3]} />
        <table className="table-border w-100">
          <tr>
            <td>API</td>
            <td>initialize(package : string)</td>
          </tr>
          <tr>
            <td>param</td>
            <td>
              To initialize the SDK, we have to add the package name of ertc
              sdk. Package : “com.ripbull.ertc”
            </td>
          </tr>
        </table>
      </div>
    </div>
  );

  const render1ChatbotContent = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="onechatbot" offset={-150}>
          <h2 className="h3">Using 1Chatbot with the SDK</h2>
        </HashAnchor>
        <p className="mt-4 mb-4">
          Once a 1Chatbot agent has been created in the portal the chatbot is
          considered as a user within the eRTC SDK.
        </p>
        <p className="mt-4 mb-2">
          Some functions will not apply to 1Chatbot such as:
        </p>
        <ul className="field-list">
          <li>
            <h3>User Login</h3>
          </li>
          <li>
            <h3>Change password</h3>
          </li>
        </ul>
        <p className="mt-4 mb-2">
          Special consideration should be given to other functions such as:
        </p>
        <ul className="field-list">
          <li>
            <strong>Get user details: </strong>The response will include isBot =
            true/false which allows for handling of the bot in various ways. For
            example: choose whether or not to label the 1Chatbot agent as a bot
            in the group chat.
          </li>
          <li>
            <strong>Send message: </strong>1Chatbot agents only support Text
            Messages with the type MessageType.Text.
          </li>
        </ul>
      </div>
    </div>
  );

  const renderApiDocumentation = () => (
    <div className="tab-content tutorials-details-block">
      <div className="tab-pane fade show active">
        <HashAnchor id="api-doc" offset={-150}>
          <h2 className="h3">API Documentation</h2>
        </HashAnchor>
        <h2>Tenant Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Namespace validation</h3>
                <p className="mt-4 mb-4">
                  This will validate the namespace information provided by the
                  user and return Boolean value if information is validated or
                  not.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[4]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>validateTenant(namespace:string, accessCode:string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>namespace:</strong> Namespace information will be
                      sent to you in your mailbox.
                      <b />
                      <strong>accessCode:</strong> Access Code information will
                      be sent to you
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>User Login</h3>
                <p className="mt-4 mb-4">
                  After namespace validation the user must login with valid
                  credentials; these credentials will be received in his mailbox
                  along with namespace information.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[5]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>login(username:string, password:string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>Username:</strong> your email id
                      <br />
                      <strong>Password:</strong> your password
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Change password</h3>
                <p className="mt-4 mb-4">
                  This api allows users to change existing passwords to new
                  passwords.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[6]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      changePassword(oldPassword:string, newPassword:string)
                    </td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>oldPassword:</strong> enter your old password
                      <br />
                      <strong>newPassword:</strong> enter your new password
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Forgot password</h3>
                <p className="mt-4 mb-4">
                  This will trigger a new password to the provided email
                  address.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[7]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>forgetPassword(emailAddress:string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>emailAddress:</strong> email address of the user
                      for which the mail needs to be triggered.
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Chat Users</h3>
                <p className="mt-4 mb-4">
                  This api call returns all the chat users data for the logged
                  in users.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[8]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>GetChatUsers(loadMore:boolean)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>loadMore:</strong> pass true/false to load more
                      user information
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Logout</h3>
                <p className="mt-4 mb-4">
                  This will log out the user and invalidate the current ongoing
                  session.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[9]} />
              </li>
              <li>
                <h3>
                  User Metadata On – MQTT subscription (To get user updates in
                  real time)
                </h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[10]} />
              </li>
              <li>
                <h3>Update user profile (with & without profile image)</h3>
                <p className="mt-4 mb-4">
                  To update the profile update and profile image use the
                  following api call:
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[11]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>UpdateUser(profileStatus:string, imageFile : file)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>profileStatus:</strong> profile status message
                      <br />
                      <strong>imageFile:</strong> profile picture file object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>
                  Call the following to Set user availability status. Status can
                  be of the following 4 types:
                </h3>
                <p className="mt-4 mb-4">
                  val status = AvailabilityStatus.AUTO or
                  AvailabilityStatus.AWAY or AvailabilityStatus.INVISIBLE or
                  AvailabilityStatus.DND
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[12]} />
              </li>
              <li>
                <h3>
                  This call is used to get user availability status pings.
                </h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[13]} />
              </li>
            </ul>
          </li>
        </ul>
        <h2>Group Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Create Group</h3>
                <p className="mt-4 mb-4">
                  This is used to create a new group. Group name, description,
                  participants, and group type needs to be provided in order to
                  run this api call.
                </p>
                <p className="mt-4 mb-4">groupType - “public”/”private”</p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[14]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      createGroup(groupRecord: GroupRecord, groupImage: File)
                    </td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupRecord:</strong> group information object
                      <br />
                      <strong>groupImage:</strong> profile picture file object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get all groups</h3>
                <p className="mt-4 mb-4">
                  This is used to get all groups for a given user.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[15]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getGroups</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>No parameter needed</td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get group info by groupId</h3>
                <p className="mt-4 mb-4">This is used to get group info.</p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[16]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getGroupById(groupId)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupId:</strong> it is groupID
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Update Group details</h3>
                <p className="mt-4 mb-4">
                  This api is used to update the group information.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[17]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      updateGroup(groupId: string, groupName: string,
                      groupDescription: string, groupImage: File, groupType:
                      string)
                    </td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupId:</strong> unique group id
                      <br />
                      <strong>groupName:</strong> group Name
                      <br />
                      <strong>groupDescription:</strong> group description
                      <br />
                      <strong>groupImage:</strong> group image object
                      <br />
                      <strong>groupType:</strong> group type private/public
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Add participants</h3>
                <p className="mt-4 mb-4">
                  This is used to add participants for a group. Group unique id
                  and new participant details are required in this call.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[18]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>addParticipants(groupId: string, users: string[])</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupId:</strong> unique id of the group
                      <br />
                      <strong>users:</strong> list of users to be added
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove participants</h3>
                <p className="mt-4 mb-4">
                  This is used to remove participants from a group.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[19]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      removeParticipants(groupId: string, users: string[])
                    </td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupId:</strong> unique id of the group
                      <br />
                      <strong>users:</strong> list of users to be removed
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Add admin</h3>
                <p className="mt-4 mb-4">
                  This is used to create users as admin.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[20]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>addAdmin(groupId: string, user: string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupId:</strong> unique id of the group
                      <br />
                      <strong>user:</strong> user to be made the Admin
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove admin</h3>
                <p className="mt-4 mb-4">
                  This api is used to remove users as admin.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[21]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeAdmin(groupId: string, user: string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupId:</strong> unique id of the group
                      <br />
                      <strong>user:</strong> user to be removed as Admin
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Exit group</h3>
                <p className="mt-4 mb-4">
                  This api is used to exit a given group.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[22]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>exitGroup(groupId: string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupId:</strong> unique id of the group
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Remove group pic</h3>
                <p className="mt-4 mb-4">
                  This api is used to remove group pic.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[23]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>removeGroupPic(groupId: string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>groupId:</strong> unique id of the group
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Group Metadata On</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[24]} />
              </li>
            </ul>
          </li>
        </ul>
        <h2>Chat Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Create Thread</h3>
                <p className="mt-4 mb-4">
                  To initiate a chat between two users, first a thread must be
                  created. A thread id is then returned on successful creation
                  and any further chat message is posted to that unique thread
                  id. If there is a thread that already exists and createThread
                  is called, the existing thread id will be returned.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[25]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>createThread(recipientId: string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>recipientId:</strong> This is the unique mail for
                      the user i.e. user’s login email
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Send Message</h3>
                <p className="mt-4 mb-4">
                  A chat between two users is considered a thread of
                  communication. To initiate a chat between users, you need to
                  create threads and send messages over that threadID.
                </p>
                <Note>
                  1Chatbot agents only support Text Messages with the type
                  MessageType.Text.
                </Note>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[26]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>sendMessage(threadId: string, message: Message)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>threadId:</strong> unique thread id
                      <br />
                      <strong>message:</strong> sending message object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Messages</h3>
                <p className="mt-4 mb-4">
                  This api is used to get messages. This is used to fetch the
                  main chat history. Here we have used pagination.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[27]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>getMessages(threadId: string)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>threadId:</strong> unique thread id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Send Reaction</h3>
                <p className="mt-4 mb-4">
                  This api is used to send reactions on received or sent
                  messages.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[28]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>sendReaction(threadId: string, reaction: Reaction)</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>threadId:</strong> unique thread id
                      <br />
                      <strong>reaction:</strong> reaction object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message metadata On</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[29]} />
              </li>
              <li>
                <h3>Chat updated</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[30]} />
              </li>
              <li>
                <h3>Forward chat</h3>
                <p className="mt-4 mb-4"></p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[31]} />
              </li>
              <li>
                <h3>Delete Message</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[32]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`deleteChat(deletedChat: DeleteMessage)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>deletedChat:</strong> delete message object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Edit Message</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[33]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`updateMessage(updateMessage: UpdateMessage)`}</td>
                  </tr>
                  <tr>
                    <td>param</td>
                    <td>
                      <strong>updateMessage:</strong> update message object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Chat Restore</h3>
                <p className="mt-4 mb-4">
                  This api call is used to restore history messages.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[34]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      messageHistory(chatHistoryRequest: ChatHistoryRequest)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>chatHistoryRequest</strong> chat history object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Search message(local search)</h3>
                <p className="mt-4 mb-4">
                  This api is used to search text in messages or in files
                  locally.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[35]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`searchLocalMessageHistory(threadId: string, searchText: string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> unique thread id
                      <br />
                      <strong>searchText:</strong> search text
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Search message(global search)</h3>
                <p className="mt-4 mb-4">
                  This api is used to search text in messages or in files.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[36]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`searchMessageHistory(threadId: string, searchText: string, searchType: string, skip: string, limit: string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadId:</strong> unique thread id
                      <br />
                      <strong>searchText:</strong> search text
                      <br />
                      <strong>searchType:</strong> weather to search in all
                      categories then pass “all” or pass “messages” or “files”
                      <br />
                      <strong>Skip:</strong> pass number to skip
                      <br />
                      <strong>Limit:</strong> pass how many to fetch1
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Mark as Read</h3>
                <p className="mt-4 mb-4">
                  This api is used to update the read status.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[37]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`msgReadStatus(messageRecord: MessageRecord, messageStatus: MessageStatus`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>messageRecord:</strong> message object
                      <br />
                      <strong>messageStatus:</strong> use following values
                      <br />
                      <br />
                      {`MessageStatus {`}
                      <br />
                      &nbsp;&nbsp;{`NONE = "none",`}
                      <br />
                      &nbsp;&nbsp;{`SENDING = "sending",`}
                      <br />
                      &nbsp;&nbsp;{`UPLOADING = "uploading",`}
                      <br />
                      &nbsp;&nbsp;{`SENT = "sent",`}
                      <br />
                      &nbsp;&nbsp;{`DELIVERED = "delivered",`}
                      <br />
                      &nbsp;&nbsp;{`SEEN = "seen",`}
                      <br />
                      &nbsp;&nbsp;{`FAILED = "failed"`}
                      <br />
                      {`}`}
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Message Read status – MQTTListener</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[38]} />
              </li>
              <li>
                <h3>Block/unblock user</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[39]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`blockUnblockUser(action: string, appUserId: string)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>action:</strong> action to be perform
                      <br />
                      <strong>appUserId:</strong> user logged-in email
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Mark as favorite</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[40]} />
              </li>
            </ul>
          </li>
        </ul>
        <h2>Typing Indicator Module</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Typing indicator subscription</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[41]} />
              </li>
              <li>
                <h3>Typing indicator publish</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[42]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>{`typingStatus(typingRecord: TypingIndicatorRecord)`}</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>typingRecord:</strong> typing publish object
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h2>FCM implementation Module</h2>
        <p className="mt-4 mb-4">
          You need FCM registration before logging into the API. To call, write
          the below line of code
        </p>
        <CodeBlock language="javascript" code={CODE_EXAMPLES[43]} />
        <h2>Follow/UnFollow Thread</h2>
        <p className="mt-4 mb-4">
          Follow/Unfollow Thread can be called by using the updated chat method
          and toggling the value of follow property.
        </p>
        <CodeBlock language="javascript" code={CODE_EXAMPLES[44]} />
        <table className="table-border w-100">
          <tr>
            <td>API</td>
            <td>{`updateMessage(updateMessage: UpdateMessage)`}</td>
          </tr>
          <tr>
            <td>parameter</td>
            <td>
              <strong>updateMessage:</strong> update message object
            </td>
          </tr>
        </table>
        <h2>Get Follow Thread</h2>
        <CodeBlock language="javascript" code={CODE_EXAMPLES[45]} />
        <table className="table-border w-100">
          <tr>
            <td>API</td>
            <td>{`FollowThreads(chatHistoryRequest: ChatHistoryRequest)`}</td>
          </tr>
          <tr>
            <td>parameter</td>
            <td>
              <strong>chatHistoryRequest:</strong> pass chat history object with
              follow option to true
            </td>
          </tr>
        </table>
        <h2>Message Moderation</h2>
        <ul className="dot-list mb-3">
          <li>
            <ul>
              <li>
                <h3>Report Message</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[46]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      CreateChatReport(createChatReport Request:
                      CreateChatReportRequest)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>createChatReportRequest:</strong> create chat
                      report object
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Delete report/Undo Report</h3>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[47]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>DeleteChatReport(chatReportId: string)</td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>chatReportId:</strong> unique chat report id
                    </td>
                  </tr>
                </table>
              </li>
              <li>
                <h3>Get Reports</h3>
                <p className="mt-4 mb-4">
                  Chat report list is applicable to admin view.
                </p>
                <CodeBlock language="javascript" code={CODE_EXAMPLES[48]} />
                <table className="table-border w-100">
                  <tr>
                    <td>API</td>
                    <td>
                      ChatReportList(threadId: string, category: string, status:
                      string, msgType: string, skip: string, limit: string)
                    </td>
                  </tr>
                  <tr>
                    <td>parameter</td>
                    <td>
                      <strong>threadID:</strong> thread id
                      <br />
                      <strong>Category:</strong> category
                      <br />
                      <strong>Status:</strong> status
                      <br />
                      <strong>msgType:</strong> pass the message type
                      <br />
                      <strong>Skip:</strong> skip the messages
                      <br />
                      <strong>limit:</strong> limit message
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className="document-content chatsdk-content">
      <h1 className="h2">JAVASCRIPT SDK</h1>
      {renderOverviewContent()}
      {renderQuickstart()}
      {render1ChatbotContent()}
      {renderApiDocumentation()}
    </div>
  );
};

export default JavascriptChatSDKContent;
