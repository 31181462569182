import React, { Component } from "react";
import SiteHeader from "../../../common/siteHeader";
import NluTitleBlock from "./nluTitleBlock";
import ChatbotsDocSidebar from "./chatbotsDocSidebar";
import ChatbotsCategoryNavBar from "./chatbotsCategoryNavBar";
import bankingBotImg from "../../../../public/resources/site/images/banking-bot.png";
import draftImg from "../../../../public/resources/site/images/draft.png";
import draftSettingImg from "../../../../public/resources/site/images/draft-setting.png";
import envImg from "../../../../public/resources/site/images/environment.png";

class DocsChatbotNluWT extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <ChatbotsCategoryNavBar />
        <main className="content-wrapper main-content">
          <NluTitleBlock />
          <div className="content-left-block connecting-grid-block --scrollpage">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-xl-3 tab-inner-content">
                  <ChatbotsDocSidebar />
                </div>
                <div className="col-lg-8 col-xl-9">
                  <div className="tab-content tutorials-details-block">
                    <div className="tab-pane fade show active" id="watson">
                      <a
                        className="mb-4 link-orange"
                        href="#"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Watson requires special instructions to work with
                        1Chatbot — Click to open Guide{" "}
                        <em className="i-icon icon-link-external-orange ml-2" />
                      </a>
                      <h2>How to get credentials</h2>
                      <h3>Required fields</h3>
                      <ul className="field-list">
                        <li>Assistant ID (Environment ID)</li>
                        <li>API Key</li>
                        <li>Service URL (Environment URL)</li>
                      </ul>
                      <h3>
                        How to find the Assistant ID, API Key, and Service URL
                      </h3>
                      <ul>
                        <li>
                          If you are already logged in to the Watson Assistant
                          Console, you may skip to step #6.
                        </li>
                        <li>
                          Login to IBM Cloud
                          <ul>
                            <li>
                              <a
                                href="https://cloud.ibm.com/login"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://cloud.ibm.com/login
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          Navigate to "Resources"
                          <ul>
                            <li>
                              Visit the link directly at{""}
                              <a
                                href="https://cloud.ibm.com/resources"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                https://cloud.ibm.com/resources
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          On your Resource List, scroll down to "Services and
                          Software"
                          <ul>
                            <li>
                              Select the bot service matching the Watson
                              Assistant
                            </li>
                          </ul>
                        </li>
                        <li>Click "Launch Watson Assistant"</li>
                        <li>
                          Ensure that the correct bot is selected in the top
                          menu
                          <div className="image-block">
                            <img
                              src={bankingBotImg}
                              alt="Ensure that the correct"
                            />
                          </div>
                        </li>
                        <li>
                          Select "Draft environment" from the left side menu
                          <div className="image-block">
                            <img src={draftImg} alt="Draft" />
                          </div>
                        </li>
                        <li>
                          Select the cog wheel icon next to "Draft environment"
                          at the top
                          <div className="image-block">
                            <img src={draftSettingImg} alt="Draft setting" />
                          </div>
                        </li>
                        <li>
                          Here are the required details: Environment ID,
                          Environment URL, and API Key
                          <div className="image-block">
                            <img src={envImg} alt="Environment ID" />
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default DocsChatbotNluWT;
