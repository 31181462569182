import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getUser } from "../actions/users";
import { ActionSuccess } from "../utils/httpResponseErrorHandler";
import { getProjects } from "../actions/projects";
import { getCurrentOrganization, loadCookie } from "../utils/commonUtil";
import InternalServerErrorPage from "../components/site/pages/internalServerError";
import UserContextProvider from "../components/common/context/userContextProvider";
import history from "../history";

export default function(ComposedComponent) {
  class Authentication extends Component {
    static contextTypes = {
      router: PropTypes.object
    };

    constructor(props) {
      super(props);
      this.state = { userLoad: false };

      this.processAuthentication = this.processAuthentication.bind(this);
      this.checkProfileCompleted = this.checkProfileCompleted.bind(this);
    }

    componentDidMount() {
      this.processAuthentication();
    }

    async processAuthentication() {
      const token = loadCookie(`token`);
      if (typeof token === "undefined") {
        const destinationPath = this.props.history.location.pathname;
        const location = {
          pathname: "/login",
          state: { fromPathName: destinationPath }
        };
        history.push(location);
      } else {
        let profileDetails = {};

        if (this.props.user) {
          this.setState({ userLoad: true });
          profileDetails.user = this.props.user;
          try {
            if (this.props.projects && this.props.projects.isProjectLoaded) {
              profileDetails.projects = this.props.projects;
            } else {
              const curOrg = getCurrentOrganization(this.props.user);
              if (curOrg) {
                const organizationId = curOrg._id;
                const response = await this.props.getProjects(organizationId);
                profileDetails.projects =
                  response?.actionDataObj?._data?.projects;
              }
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          const response = await this.props.getUser();
          this.setState({
            userLoad: true,
            resultObj: response.actionDataObj
          });
          profileDetails.user = response.actionDataObj?._data;
          try {
            if (this.props.projects && this.props.projects.isProjectLoaded) {
              profileDetails.projects = this.props.projects;
            } else {
              const organizations = response.actionDataObj.data.organizations;
              if (organizations.length > 0) {
                const organizationId = organizations[0].organization._id;
                const response = await this.props.getProjects(organizationId);
                profileDetails.projects =
                  response?.actionDataObj?._data?.projects;
              }
            }
            //redirectForUnauthorizedPage(currentPathName,result.actionDataObj.data);
          } catch (e) {
            console.log(e);
          }
        }
        this.checkProfileCompleted(profileDetails);
      }
    }

    checkProfileCompleted(profileDetails) {
      const { user, projects } = profileDetails;
      const curOrg = getCurrentOrganization(user);

      if (!curOrg || (projects || []).length === 0) {
        const currentPath = this.props.history.location.pathname;
        if (currentPath !== "/start-free-trial") {
          history.push("/start-free-trial");
        }
      }
    }

    render() {
      const token = loadCookie(`token`);

      if (!token) {
        return null;
      }

      if (this.state.userLoad) {
        if (this.props.user) {
          return this.renderUsingContextProvider(ComposedComponent);
        } else {
          if (this.state.resultObj instanceof ActionSuccess) {
            return this.renderUsingContextProvider(ComposedComponent);
          } else {
            return <InternalServerErrorPage />;
          }
        }
      } else {
        //TODO: add loading design
        return null; //<div>Loading</div>
      }
    }

    renderUsingContextProvider(ComposedComponent) {
      return (
        <UserContextProvider user={this.props.user}>
          <ComposedComponent {...this.props} />
        </UserContextProvider>
      );
    }
  }

  function mapStatsToProps(stats) {
    return {
      user: stats.user.user,
      projects: stats.project.projects,
      isProjectLoaded: stats.project.isProjectLoaded
    };
  }

  return connect(mapStatsToProps, { getUser, getProjects })(Authentication);
}
