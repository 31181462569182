import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import userReducer from "./userReducer";
import authReducer from "./authReducer";
import provintAuthReducer from "./provintAuthReducer";
import projectReducer from "./projectReducer";
import uiReducer from "./uiStateReducer";
import orderReducer from "./orderReducer";
import productReducer from "./productReducer";

const rootReducer = combineReducers({
  form: formReducer,
  user: userReducer,
  auth: authReducer,
  provintAuth: provintAuthReducer,
  project: projectReducer,
  ui: uiReducer,
  order: orderReducer,
  product: productReducer
});

export default rootReducer;
