import React, { useEffect } from "react";

const LoadingDisplay = ({
  marginTop,
  position,
  left,
  top,
  width,
  height,
  noChatbot = true,
  style = {}
}) => {
  const marginTopPx = marginTop ? marginTop : "100px";
  useEffect(() => {
    if (noChatbot) {
      document.body.classList.add("nochatbot");
    }
  });

  return (
    <div
      className="text-center"
      style={{
        marginTop: marginTopPx,
        position: position ? position : "relative",
        left: left ? left : "",
        top: top ? top : "",
        width: width ? width : "",
        height: height ? height : "75px",
        ...(style || {})
      }}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingDisplay;
