import store from "../store/store";
import {
  genericFetchApiCatchHandler,
  genericHttpErrorHandler,
  genericHttpSuccessHandler,
  handleHttpResponse
} from "../utils/httpResponseErrorHandler";
import { Request, restCall, provEndpoint } from "../utils/restCallUtil";
import history from "../history";
import { loadState } from "../store/localStorage";
import { loadCookie, removeCookie, saveCookie } from "../utils/commonUtil";

export const PROVINT_TOKEN_UPDATED = "provint_token_received";
export const PROVINT_TOKEN_REMOVED = "provint_token_removed";

export function getProvintAuthToken(tenantId, userId, dispatch) {
  return new Promise((resolve, reject) => {
    let tokenState = store.getState().provintAuth.token;
    if (!tokenState && tenantId) {
      // store value reset issue
      let projectTokens = JSON.parse(localStorage.getItem("projectTokens"));
      if (projectTokens)
        tokenState = projectTokens.filter(obj => obj && obj.id === tenantId)[0]
          .token;
    }
    const accessToken =
      (tokenState && tokenState.accessToken) || loadCookie(`token-${tenantId}`);
    if (accessToken) {
      if (tokenState) {
        const expiresAt = tokenState.timestamp / 1000 + tokenState.expiresIn;
        const currentTime = Date.now() / 1000;
        if (currentTime >= expiresAt - 60) {
          getProvintAccessTokenFromRefreshToken(
            tenantId,
            userId,
            tokenState.refreshToken,
            dispatch,
            resolve,
            reject
          );
        } else {
          resolve({ accessToken: accessToken });
        }
      } else {
        resolve({ accessToken: accessToken });
      }
    }
    if (!accessToken && tenantId) {
      console.error(
        "#### access token for " +
          tenantId +
          " is null. Need to loadProjectState"
      );
    }
  });
}

export function getProvintAccessTokenFromRefreshToken(
  tenantId,
  userId,
  refreshToken,
  dispatch,
  resolve,
  reject
) {
  if (!refreshToken || refreshToken === undefined) {
    history.push("/myProjects");
    return;
  }
  const RefreshTokenEndpoint = provEndpoint(
    tenantId,
    userId,
    "auth0/refreshToken"
  );
  const headerObj = {
    method: "POST",
    mode: "cors",
    headers: new Headers({
      Authorization: `Bearer ${refreshToken}`,
      "content-type": "application/json"
    })
  };
  fetch(RefreshTokenEndpoint, headerObj)
    .then(response => {
      handleHttpResponse(
        response,
        refreshProvintTokenSuccessHandler(tenantId, resolve, dispatch),
        refreshProvintTokenErrorHandler(
          tenantId,
          userId,
          resolve,
          reject,
          dispatch
        )
      );
    })
    .catch(error => {
      genericFetchApiCatchHandler(error, reject);
    });
}

function refreshProvintTokenSuccessHandler(tenantId, resolve, dispatch) {
  return actionSuccess => {
    const tokenState = actionSuccess.data;
    tokenState.timestamp = Date.now(); //TODO REMOVE this

    saveCookie(`token-${tenantId}`, accessToken);
    //TODO
    //update provint token from localstorage
    dispatch({
      type: PROVINT_TOKEN_UPDATED,
      // token: tokenState.token  <-- change to this once backend sends proeprly
      token: tokenState
    });
    const accessToken = tokenState && tokenState.accessToken;
    resolve({ accessToken: accessToken });
  };
}

function refreshProvintTokenErrorHandler(
  tenantId,
  userId,
  resolve,
  reject,
  dispatch
) {
  return actionError => {
    removeCookie(`token-${tenantId}`);
    localStorage.removeItem("projectTokens");
    //remove provint token from localstorage
    dispatch({
      type: PROVINT_TOKEN_REMOVED
    });
    if (actionError.httpCode === "401" || actionError.httpCode === 401) {
      // Again call login API to get access token in case refresh token is expired.
      getProjectToken(tenantId, userId, dispatch)
        .then(() => {
          // Do nothing
          resolve({ accessToken: loadCookie(`token-${tenantId}`) });
        })
        .catch(() => {
          history.push("/myProjects");
          reject({ result: "Error in project's refreshing session" });
        });
    }
  };
}

const provTokenEndpoint = (projectId, userId) =>
  provEndpoint(projectId, userId, `auth0/login`);
export function getProjectToken(projectId, userId, dispatch) {
  const tokenStateObj = loadState();
  return new Promise((resolve, reject) => {
    const request = Request({
      url: provTokenEndpoint(projectId, userId),
      method: "POST",
      body: JSON.stringify({ provToken: tokenStateObj.token.refreshToken }),
      headerObj: {
        "content-type": "application/json"
      },
      authEnabled: true
    });
    restCall(request, dispatch)
      .then(response => {
        handleHttpResponse(
          response,
          saveProjectToken(projectId, dispatch, resolve),
          genericHttpErrorHandler(reject)
        );
      })
      .catch(error => {
        genericFetchApiCatchHandler(error, reject);
      });
  });
}

export const saveProjectToken = (projectId, dispatch, resolve) => {
  return actionSuccess => {
    try {
      let token =
        (actionSuccess && actionSuccess.data && actionSuccess.data.token) ||
        null;
      if (!token) throw "Invalid token.";
      const stateSerialized = { id: projectId, token: token };
      let projectData = JSON.parse(localStorage.getItem("projectTokens"));
      if (projectData == null) {
        projectData = [stateSerialized];
      } else {
        projectData = projectData.filter(p => p.id !== projectId);
        projectData = [...projectData, stateSerialized];
      }
      localStorage.setItem("projectTokens", JSON.stringify(projectData));
      saveCookie(`token-${projectId}`, stateSerialized.token.accessToken);
      dispatch({
        type: PROVINT_TOKEN_UPDATED,
        token: stateSerialized.token
      });
    } catch (e) {
      console.log("Error in saving state", e);
    }
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
};
