import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";

const IOSDocSidebar = withRouter(({ location: { hash } }) => {
  const items = useMemo(() => [
    {
      title: "Overview",
      url: "#overview"
    },
    {
      title: "Quickstart",
      url: "#quickstart"
    },
    {
      title: "How-tos",
      url: "#how-to-add-sdk"
    },
    {
      title: "1Chatbot",
      url: "#onechatbot"
    },
    {
      title: "API",
      url: "#api-doc"
    },
    {
      title: "Resources",
      url: "#resources"
    },
    {
      title: "Design Diagram",
      url: "#design-diagram"
    }
  ]);

  return (
    <>
      <div className="provider-title">iOS SDK</div>
      {items.map(item => (
        <a
          key={item.title}
          className={`nav-link ${
            (hash || "#overview") === item.url ? "active" : ""
          }`}
          href={item.url}
        >
          {item.title}
        </a>
      ))}
    </>
  );
});

const AndroidDocSidebar = withRouter(({ location: { hash } }) => {
  const items = useMemo(() => [
    {
      title: "Overview",
      url: "#overview"
    },
    {
      title: "Quickstart",
      url: "#quickstart"
    },
    {
      title: "How-tos",
      url: "#how-to-add-sdk"
    },
    {
      title: "1Chatbot",
      url: "#onechatbot"
    },
    {
      title: "API",
      url: "#api-doc"
    },
    {
      title: "Resources",
      url: "#resources"
    },
    {
      title: "Design Diagram",
      url: "#design-diagram"
    }
  ]);

  return (
    <>
      <div className="provider-title">Android SDK</div>
      {items.map(item => (
        <a
          key={item.title}
          className={`nav-link ${
            (hash || "#overview") === item.url ? "active" : ""
          }`}
          href={item.url}
        >
          {item.title}
        </a>
      ))}
    </>
  );
});

const JavascriptDocSidebar = withRouter(({ location: { hash } }) => {
  const items = useMemo(() => [
    {
      title: "Overview",
      url: "#overview"
    },
    {
      title: "Quickstart",
      url: "#quickstart"
    },
    {
      title: "1Chatbot",
      url: "#onechatbot"
    },
    {
      title: "API",
      url: "#api-doc"
    }
  ]);

  return (
    <>
      <div className="provider-title">Javascript SDK</div>
      {items.map(item => (
        <a
          key={item.title}
          className={`nav-link ${
            (hash || "#overview") === item.url ? "active" : ""
          }`}
          href={item.url}
        >
          {item.title}
        </a>
      ))}
    </>
  );
});

const LeftSidebar = ({ language }) => {
  return (
    <div className="nav flex-column credentials-list ">
      {language === "ios" && <IOSDocSidebar />}
      {language === "android" && <AndroidDocSidebar />}
      {language === "javascript" && <JavascriptDocSidebar />}
    </div>
  );
};

export default LeftSidebar;
