import React, { Component } from "react";
import SignUpPage_v2 from "../../site/pages/signUp_v2";

export default function(projectType) {
  return class extends Component {
    render() {
      return <SignUpPage_v2 {...this.props} referrer={projectType} />;
    }
  };
}
