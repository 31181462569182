import { GET_CATEGORIES, GET_ENTITLEMENTS } from "../actions/product";

const INITIAL_STATE = {
  entitlements: {},
  categories: {},
  isCategoriesLoaded: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.category,
        isCategoriesLoaded: true
      };
    case GET_ENTITLEMENTS:
      return {
        ...state,
        entitlements: {
          ...state.entitlements,
          [action.projectId]: getNewProjectEntitlements(state, action)
        }
      };
    default:
      return state;
  }
}

function getNewProjectEntitlements(currentState, action) {
  const projectEntitlements = currentState.entitlements[action.projectId] || {};
  return { ...projectEntitlements, ...action.entitlements };
}

//{action.projectId: getNewProjectEntitlements(state,action)}
