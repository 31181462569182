import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import PortalPageContainer from "../../common/portalPageContainer";
import MyProjectsHeader_V2 from "../myProjects/myProjectsHeader_v2";
import DashboardSideBar from "../admin/dashboardSideBar";
import { SEO_P_MYPROJECTS } from "../../../utils/seoutils";
import ToastMessage from "../../common/toastMessage";

const PortalRootContentWrapper = ({ customClass, children }) => {
  return (
    <main className={`content-wrapper${customClass ? ` ${customClass}` : ""}`}>
      {children}
    </main>
  );
};

const PortalMainContentWrapper = ({ customClass, children }) => {
  return (
    <main
      className={`main-content-wrapper${customClass ? ` ${customClass}` : ""}`}
    >
      {children}
    </main>
  );
};

const PortalFooter = ({ containerClass }) => {
  return (
    <div className={`main-footer ${containerClass}`}>
      <div className="content-block">
        <div className="left-menu">
          <Link to="/contact-support" className="menu-link">
            Support
          </Link>
          <span>|</span>
          <Link to="/docs/chatsdk" className="menu-link">
            Documents
          </Link>
        </div>
        <div className="copyright">
          <span>&copy; Copyright - Ripbull Networks - 2023</span>
        </div>
      </div>
    </div>
  );
};

const PortalCommonLayout = ({
  wrapProjectContext,
  showSidebar,
  showFooter,
  isDashboardSideBar = true,
  activePageId,
  customSidebarContent,
  pageName,
  isDark = false,
  children
}) => {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const onSidebarToggleClick = () => {
    if (sidebarToggle) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.classList.add("overflow-hidden");
    }
    setSidebarToggle(!sidebarToggle);
  };

  const isMyProjectPage = pageName === SEO_P_MYPROJECTS;
  let footerContainerClass = "d-block d-md-none";
  if (showFooter) footerContainerClass = "";

  return (
    <PortalPageContainer
      wrapProjectContext={wrapProjectContext}
      pageName={pageName}
    >
      <MyProjectsHeader_V2
        onSidebarToggleClick={onSidebarToggleClick}
        showSidebar={showSidebar}
        hideMyProjectMenu={isMyProjectPage}
        isDark={isDark}
      />
      <PortalRootContentWrapper
        customClass={showSidebar ? "" : "without-sidebar"}
      >
        {showSidebar && !customSidebarContent && (
          <Fragment>
            <DashboardSideBar
              active={activePageId}
              isDashboardSideBar={isDashboardSideBar}
              sidebarToggle={sidebarToggle}
              onSidebarToggleClick={onSidebarToggleClick}
            />
          </Fragment>
        )}
        <PortalMainContentWrapper>{children}</PortalMainContentWrapper>
      </PortalRootContentWrapper>
      <PortalFooter containerClass={footerContainerClass} />
      <ToastMessage top="91%" left="18%" />
    </PortalPageContainer>
  );
};

export {
  PortalRootContentWrapper,
  PortalMainContentWrapper,
  PortalCommonLayout
};
