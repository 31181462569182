import React from "react";
import {
  getCurrentOrganizationObject,
  saveCookie
} from "../../utils/commonUtil";
import history from "../../history";
import { withRouter } from "react-router-dom";
//import config from "../../config/config";

//const DOMAIN = config.DOMAIN;

class OrganizationSwitcher extends React.Component {
  constructor(props) {
    super(props);
    this.switchOrganization = this.switchOrganization.bind(this);
  }

  render() {
    const currentOrganization = getCurrentOrganizationObject(this.props.user);
    if (!currentOrganization) {
      return null;
    }

    const totalOrgItems = this.props.user.organizations.length;
    return (
      <li
        className="dropdown-item organization-dropdown"
        style={totalOrgItems > 1 ? {} : { cursor: "unset" }}
      >
        <div className="org-box">
          {totalOrgItems > 1 && (
            <div className="icon">
              <i className="ri-arrow-right-s-line arrow" />
            </div>
          )}
          <div className="name">
            <span>Organizations</span>
            <span
              style={{
                maxWidth: "250px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
            >
              <strong>{currentOrganization.organization.name}</strong>
            </span>
          </div>
        </div>
        {totalOrgItems > 1 && (
          <ul className="dropdown-menu submenu">
            {this.renderDropDownItems(
              this.props.user.organizations,
              currentOrganization
            )}
          </ul>
        )}
      </li>
    );
  }

  renderDropDownItems(organizations, currentOrganization) {
    const dropDownitems = [];
    // render current Organization first and make it selected
    dropDownitems.push(
      this.renderDropDownItem(
        currentOrganization,
        organizations.length === 1,
        true,
        0
      )
    );
    organizations
      .filter(
        item => item.organization._id !== currentOrganization.organization._id
      )
      .forEach((item, index) =>
        dropDownitems.push(
          this.renderDropDownItem(
            item,
            index === organizations.length - 1,
            false,
            index + 1
          )
        )
      );
    return dropDownitems;
  }

  renderDropDownItem(organizationObj, isLast, isSelected, index) {
    const isOwner = organizationObj.role === "superAdmin";
    const orgName = organizationObj.organization.name;
    const orgId = organizationObj.organization._id;
    return (
      <li
        className={`dropdown-item${isLast ? "" : " btm-border"}`}
        key={index}
        onClick={this.switchOrganization(orgId)}
      >
        <a className={`with-label ${isSelected ? " active" : ""}`}>
          <small>{isOwner ? "Owner" : "Administrator"}</small>
          <span
            style={{
              maxWidth: "250px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "block"
            }}
          >
            {orgName}
          </span>
        </a>
        {/*<div className="org-box">
                    <div className="radio-box">
                        <span className="radio"/>
                    </div>
                    <div className="info-box">
                        <small>{isOwner ? 'Owner' : 'Administrator'}</small>
                        <strong>{orgName}</strong>
                    </div>
                </div>*/}
      </li>
    );

    /* */
  }

  switchOrganization(organizationId) {
    const { renderSamePage = false } = this.props;
    return () => {
      saveCookie("org", organizationId);
      if (
        renderSamePage === true ||
        this.props.location.pathname === "/myProjects"
      ) {
        window.location.reload();
      } else {
        history.push("/myProjects");
      }
    };
  }
}

export default withRouter(OrganizationSwitcher);
