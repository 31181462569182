import React, { useCallback, useState, useRef, useEffect } from "react";
import {
  getDefaultProjectPageUrl,
  isProjectAccessible
} from "../../../utils/pageAccessUtil";
import { getCurrentOrganization } from "../../../utils/commonUtil";

const DashboardOrgProjectSwitcher = ({
  projectName,
  projectId,
  user,
  getProjects
}) => {
  const [projectDropdownOpen, setProjectDowndownOpen] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const optionRef = useRef(null);

  useEffect(() => {
    const organization = getCurrentOrganization(user);
    getProjects(organization._id)
      .then(result => {
        if (result.actionDataObj.data && result.actionDataObj.data.projects) {
          setAllProjects(result.actionDataObj.data.projects);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (projectDropdownOpen) {
      addEventListener();
    } else {
      removeEventListener();
    }

    return function cleanup() {
      removeEventListener();
    };
  }, [projectDropdownOpen]);

  const onProjectSwitch = useCallback(project => {
    window.location.href = getDefaultProjectPageUrl(project, user);
    setProjectDowndownOpen(false);
  }, []);

  const handleClickOutsideOptions = event => {
    if (
      optionRef &&
      optionRef.current &&
      !optionRef.current.contains(event.target) &&
      projectDropdownOpen === true
    ) {
      setProjectDowndownOpen(false);
    }
  };

  const addEventListener = () => {
    document.addEventListener("mousedown", handleClickOutsideOptions);
  };

  const removeEventListener = () => {
    document.removeEventListener("mousedown", handleClickOutsideOptions);
  };

  return (
    <div className="form-group">
      <div className="custom-dropdown input-dropdown" ref={optionRef}>
        <button
          className="dropdown-input dropdown-toggle"
          type="button"
          id="option-select"
          data-toggle="dropdown"
          onClick={() => setProjectDowndownOpen(!projectDropdownOpen)}
          aria-expanded="false"
        >
          <span>{projectName}</span>
        </button>
        <ul
          className={`dropdown-menu ${projectDropdownOpen ? "show" : ""}`}
          aria-labelledby="option-select"
        >
          {allProjects &&
            allProjects
              .filter(
                project =>
                  project.status &&
                  project.status.state === "active" &&
                  isProjectAccessible(project, user)
              )
              .map(project =>
                renderProjectOptions(
                  project,
                  project.projectId === projectId,
                  onProjectSwitch
                )
              )}
        </ul>
      </div>
    </div>
  );
};

function renderProjectOptions(project, active, onProjectSwitch) {
  return (
    <li key={project.projectId}>
      <button
        className={`dropdown-item ${active ? "active" : ""}`}
        data-value={project.name}
        onClick={() => onProjectSwitch(project)}
      >
        {project.name}
      </button>
    </li>
  );
}

export default DashboardOrgProjectSwitcher;
