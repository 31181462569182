import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import connect from "react-redux/es/connect/connect";
import ChatOpsImage from "../../../public/resources/site/images/chatops-img.png";
import MachineToMachineImage from "../../../public/resources/site/images/machine-to-machine-chatbot.png";
import IssueTrackingIcon from "../../../public/resources/common/icons/issue-tracking-orange.svg";
import InfraCodeIcon from "../../../public/resources/common/icons/infrastructure-code-orange.svg";
import DeviceProvisionIcon from "../../../public/resources/common/icons/device-provision-orange.svg";
import DeviceManagementIcon from "../../../public/resources/common/icons/device-management-orange.svg";
import OperationMonitoringIcon from "../../../public/resources/common/icons/operation-monitoring-orange.svg";
import Footer_v2 from "../common/footer_v2";
import { getSignupOrProjectRedirectionUrl } from "../../../utils/planUtil";
import TitleFeatureBox from "../../common/titleFeatureBox";
import { getLatestBlogs } from "../../../actions/blog";
import LoadingDisplay from "../../common/loadingDisplay";
import { ActionSuccess } from "../../../utils/httpResponseErrorHandler";
import ServerResponse from "../../common/serverResponse";

class MachineToMachineContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    this.props
      .getLatestBlogs()
      .then(result => {
        this.setState({ resultObj: result.actionDataObj, isLoading: false });
      })
      .catch(error => {
        this.setState({ resultObj: error.actionDataObj, isLoading: false });
      });
  }

  renderBannerSection() {
    return (
      <div className="banner-section chatops-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-6 col-xl-5">
              <div className="content">
                <h1 className="wow fadeInDown">
                  <span className="text-orange">IOT Controller</span>
                </h1>
                <p className="wow fadeInUp">
                  Your machines are waiting for you to lead them into the
                  future. Get there faster with ChatOps.
                </p>
                <div className="action wow fadeInUp" data-wow-delay="0.4s">
                  <Link
                    to={getSignupOrProjectRedirectionUrl(this.props.isLoggedIn)}
                    className="btn btn-primary "
                  >
                    Get Started
                  </Link>
                </div>
                <div className="img-block d-block d-md-none wow fadeIn">
                  <img src={ChatOpsImage} alt="Banner" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="animation-shape d-none d-md-block">
          <span className="shape1" />
          <span className="shape2" />
          <span className="shape3" />
          <span className="shape4" />
          <span className="shape5" />
        </div>
        <div className="tag-line">
          <div className="container">
            <p className="wow fadeInUp">MACHINE TO MACHINE CHAT</p>
          </div>
        </div>
      </div>
    );
  }

  renderStreamLinesSection() {
    return (
      <div className="bg-gray">
        <div className="content-center-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="title-content center-title">
                  <div className="tag wow fadeInDown">streamlines</div>
                  <h2 className="wow fadeInUp">ChatOps Streamlines</h2>
                  <p className="wow fadeInUp mw-800">
                    Our chat API replaces terminals, providing you with a new
                    command line to run your operations on. Reduce the number of
                    manual tasks with ChatOps.
                  </p>
                </div>
                <div className="content-icon-box-block center-icon-box">
                  <div className="row">
                    <TitleFeatureBox
                      title={"Issue Tracking"}
                      imgSrc={IssueTrackingIcon}
                      wowDelay={"0.1s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Infrastructure as Code"}
                      imgSrc={InfraCodeIcon}
                      wowDelay={"0.2s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"System & Device Provisioning"}
                      imgSrc={DeviceProvisionIcon}
                      wowDelay={"0.3s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Property & Device Management"}
                      imgSrc={DeviceManagementIcon}
                      wowDelay={"0.4s"}
                      titleBelowIcon={true}
                    />

                    <TitleFeatureBox
                      title={"Operations Monitoring"}
                      imgSrc={OperationMonitoringIcon}
                      wowDelay={"0.5s"}
                      titleBelowIcon={true}
                    />
                  </div>
                </div>
                <div className="content-action">
                  <p className="wow fadeInUp mw-1000" data-wow-delay="0.6s">
                    Automating your{" "}
                    <span className="text-orange">machine-to-machine</span>{" "}
                    commands has never been easier thanks to In-App Chat. When
                    paired with{" "}
                    <span className="text-orange">
                      Natural Language Processing
                    </span>
                    , we help eliminate the language barrier between multiple
                    devices. By converting the language “spoken” by one machine
                    into the receiving machine's commands you will no longer
                    need human intervention playing middle man and finally be
                    able to automate your processes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAdvantageSection() {
    return (
      <>
        <div className="content-left-block">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="content">
                  <div className="title-content">
                    <div className="tag wow fadeInDown">advantage</div>
                    <h2 className="wow fadeInUp">Benefits of ChatOps</h2>
                  </div>
                  <div className="content-check-list orange-list mt-3">
                    <ul>
                      <li className="wow fadeInUp" data-wow-delay="0.1s">
                        Use Commands To Set Meetings and Locations
                      </li>
                      <li className="wow fadeInUp" data-wow-delay="0.2s">
                        Enable Collaborations by Creating Command Lines
                      </li>
                      <li className="wow fadeInUp" data-wow-delay="0.3s">
                        Increase Response Times with Short Feedback Loops
                      </li>
                      <li className="wow fadeInUp" data-wow-delay="0.4s">
                        Reduce Start-Up Time After System Failures
                      </li>
                      <li className="wow fadeInUp" data-wow-delay="0.5s">
                        Create an Easily Accessible Activity History
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <div className="img-block mw-300 wow fadeIn">
                  <img
                    src={MachineToMachineImage}
                    alt="Machine-to-Machine with Chatbots"
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12">
                <p className="pt-4 wow fadeInUp" data-wow-delay="0.6s">
                  Need to deploy a machine action? How simple would it be if it
                  were just a message away? In-App Chat gives you that
                  capability! When paired with the appropriate devices and
                  systems a team member is just a text away from issuing actions
                  such as event responses, code deployments, and more!{" "}
                </p>
                <p className="wow fadeInUp" data-wow-delay="0.7s">
                  These “conversations” also make for exceptional tools. Know
                  exactly when specific commands were sent, when errors
                  occurred, and what changes need to be made mechanically or
                  within your training specifications. These chats become your
                  log of activities automatically which makes support,
                  compliance, and security that much easier to achieve.
                  Additionally, these tools benefit your business by increasing
                  domain knowledge on ChatOps, which accelerates machine
                  learning and helps to improve communications.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderChatSection() {
    return (
      <div className="content-center-block bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="title-content center-title">
                <div className="tag wow fadeInDown">chat</div>
                <h2 className="wow fadeInUp mb-3">Conversational AI</h2>
              </div>
              <div className="content-box-block">
                <div className="row">
                  <div
                    className="col-12 col-sm-12 col-md-12 col-lg-12 wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <div className="box-block">
                      <h3>Customer Service</h3>
                      <p>
                        Put your customers at the heart of every interaction
                        with Conversational AI. In-App Chat uses your favorite
                        Natural Language Processor to enable real conversations
                        with your audience.
                      </p>
                      <p>
                        Streamline customer service and more with smarter
                        chatbots and virtual agents. Traditional chatbots can be
                        limited from predefined scripts, In-App Chat ChatOps
                        deploys Machine Learning and NLP to get smarter with
                        every interaction, further personalizing each
                        connection. Leaving your audience satisfied with
                        immediate and personalized service.
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-6 wow fadeInUp"
                    data-wow-delay="0.2s"
                  >
                    <div className="box-block">
                      <h3>Eliminate Enormous FAQ Sections</h3>
                      <p>
                        Your customers can get real-time answers to their
                        questions in a format they are already used to. No more
                        digging through long lists of FAQs. Supporting your
                        customers has never been easier with ChatOps!
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-12 col-sm-12 col-md-6 col-lg-6 wow fadeInUp"
                    data-wow-delay="0.3s"
                  >
                    <div className="box-block">
                      <h3>Perfectly Timed Interactions</h3>
                      <p>
                        ChatOps can proactively engage with your customers in a
                        conversational manner. No more wrong responses reducing
                        your customer satisfaction thanks to Natural Language
                        Processing and Machine Learning. Finally, smarter
                        chatbots that don’t end in frustration and damaged
                        relationships!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-action pt-0 pb-100">
                <Link
                  to={"/conversational-ai"}
                  className="btn btn-primary wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  Explore More Chatbots
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderBlogSection() {
    const showBlogs =
      this.state.resultObj &&
      this.state.resultObj instanceof ActionSuccess &&
      this.state.resultObj.data;
    const blogContent =
      showBlogs &&
      Object.keys(this.state.resultObj.data).map(key => {
        const dataObj = this.state.resultObj.data[key];
        return (
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-6 wow fadeInUp"
            data-wow-delay="0.2s"
            key={key}
          >
            <div className="card">
              <img
                src={dataObj.newsimg}
                className="card-img-top"
                alt={dataObj.newstitle}
              />
              <div className="card-body">
                <h5 className="card-title">{dataObj.newstitle}</h5>
                <p className="card-text">{dataObj.newsexcerpt}</p>
              </div>
              <div className="card-footer">
                <a
                  href={dataObj.newslink}
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-outline-primary"
                >
                  Read Article
                </a>
              </div>
            </div>
          </div>
        );
      });

    return (
      <div className="content-center-block">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="title-content center-title">
                <div className="tag wow fadeInDown">blog</div>
                <h2 className="wow fadeInUp mb-3">Additional Resources</h2>
              </div>
              <div className="content-blog-block pt-4">
                <div className="card-group">
                  {this.state.isLoading && (
                    <div className="col-12">
                      <LoadingDisplay marginTop="5px" />
                    </div>
                  )}

                  <ServerResponse
                    customClass="mt-3 col-12"
                    showErrorOnly={true}
                    customErrorMessage={"Error in loading resources"}
                    resultObj={this.state.resultObj}
                  />
                  {showBlogs && <div className="row">{blogContent}</div>}
                  {/*<div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
                      <div className="card">
                        <img src={Blog1Image} className="card-img-top" alt="Natural Language Processing Blog"/>
                        <div className="card-body">
                          <h5 className="card-title">What is Natural Language Processing (NLP)?</h5>
                          <p className="card-text">While chatbots have grown in popularity, the rise of artificial
                            intelligence and machine learning is giving birth to a chatbot that is scalable. So much
                            so that it cannot be replicated by an internal team of humans</p>
                        </div>
                        <div className="card-footer">
                          <a href="#" className="btn btn-outline-primary">Read Article</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
                      <div className="card">
                        <img src={Blog2Image} className="card-img-top" alt="Customer Experience Blog"/>
                        <div className="card-body">
                          <h5 className="card-title">What is CX?</h5>
                          <p className="card-text">First off, unless you are “in the know,” you might be wondering
                            what those two letters mean. “CX” is shorthand in business for Customer Experience, and
                            this is getting renewed attention in the past several years as a way to differentiate your
                            business from your competitors.</p>
                        </div>
                        <div className="card-footer">
                          <a href="#" className="btn btn-outline-primary">Read Article</a>
                        </div>
                      </div>
                    </div>
                  </div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <SiteHeader fixedHeaderOnScroll={false} isDark selected="m2m" />
        <div className="content-wrapper main-content">
          {this.renderBannerSection()}
          {this.renderStreamLinesSection()}
          {this.renderAdvantageSection()}
          {this.renderChatSection()}
          {this.renderBlogSection()}
        </div>
        <Footer_v2 />
      </div>
    );
  }
}

function mapStatsToProps(stats) {
  return {
    user: stats.user.user,
    isLoggedIn: stats.user.isLoggedIn
  };
}

export default connect(mapStatsToProps, { getLatestBlogs })(
  MachineToMachineContent
);
