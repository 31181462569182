import React, { useEffect, useRef, useState } from "react";

const CustomDropdown_v4 = ({
  placeholder,
  data,
  defaultValue,
  containerClass,
  containerStyle,
  onValueChange,
  nameId,
  label,
  isLabelBottom = true,
  disabled = false,
  meta,
  input,
  hasIcon,
  renderIcon
}) => {
  const dropDownRef = useRef(null);
  const defaultValueIndex = defaultValue
    ? data.findIndex(data => data.value === defaultValue)
    : -1;
  const [value, setValue] = useState(
    defaultValueIndex > -1 ? data[defaultValueIndex].value : ""
  );
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    const onClickHandler = event => {
      if (open && !dropDownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", onClickHandler);
    }
    return function cleanup() {
      document.removeEventListener("mousedown", onClickHandler);
    };
  }, [open]);

  const onBlurHandler = () => {
    if (!open) setFocus(false);
  };

  const onFocusHandler = () => {
    setFocus(true);
  };

  const valueIndex = data.findIndex(data => data.value === value);
  const currentDisplayValue =
    valueIndex > -1 ? data[valueIndex].displayValue : "";
  const hasError = meta && meta.error && meta.touched;
  let containClass = containerClass;
  if (focus) {
    containClass = containerClass + " focused";
  }

  if (hasData) {
    containClass = containClass + " has-data";
  }

  if (hasError) {
    containClass = containClass + " error";
  }

  let btnStyle = {};
  if (disabled) {
    btnStyle = { backgroundColor: "#e9ecef" };
  }

  return (
    <div className={containClass} style={containerStyle}>
      {label && !isLabelBottom && <label htmlFor={nameId}>{label}</label>}
      <div
        className={`custom-dropdown input-dropdown ${hasError ? "error" : ""}`}
        ref={dropDownRef}
        role={"navigation"}
      >
        <button
          className="dropdown-input dropdown-toggle"
          style={btnStyle}
          type="button"
          name={nameId}
          id={nameId}
          onClick={() => setOpen(!open)}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          value={currentDisplayValue}
          onBlur={e => onBlurHandler(e)}
          onFocus={e => onFocusHandler(e)}
          disabled={disabled}
        >
          {hasIcon && renderIcon ? (
            renderIcon({ value: currentDisplayValue })
          ) : (
            <em
              className={`dropdown-input-icon i-icon ${
                currentDisplayValue ? "icon-info" : "icon-info-gray"
              }`}
            ></em>
          )}
          {currentDisplayValue || placeholder}
        </button>
        <ul
          className={`dropdown-menu${open ? " show" : ""}`}
          style={{ maxHeight: "300px", overflow: "auto" }}
          aria-labelledby={nameId}
        >
          {data.map(data => {
            return (
              <li key={data.value}>
                <button
                  type="button"
                  className="dropdown-item"
                  data-value={data.value}
                  onClick={() => {
                    setValue(data.value);
                    setOpen(false);
                    setFocus(false);
                    setHasData(true);
                    input && input.onChange(data.value);
                    onValueChange(data.value);
                  }}
                >
                  {hasIcon && data.icon && (
                    <em className={`dropdown-item-icon ${data.icon}`}></em>
                  )}
                  {data.displayValue}
                </button>
              </li>
            );
          })}
        </ul>
        {hasError && <span className="error-msg">{meta.error}</span>}
      </div>
      {label && isLabelBottom && <label htmlFor={nameId}>{label}</label>}
    </div>
  );
};

export default CustomDropdown_v4;
