import { endpoint, Request, restCall } from "../utils/restCallUtil";
import {
  genericFetchApiCatchHandler,
  genericHttpErrorHandler,
  genericHttpSuccessHandler,
  handleHttpResponse
} from "../utils/httpResponseErrorHandler";
import { loadProjectToken, saveProjectUrl } from "../store/localStorage";

export const GET_PROJECT = "get_project";
export const GET_PROJECT_BULK = "get_project_bulk";
export const SDKUrlSupplier = projectId => `projects/${projectId}/apps`;

const userAuthenticationEndPoint = projectId =>
  endpoint(`projects/${projectId}/feature/authConfig`);
const ProjectsEndpoint = endpoint("projects");
const validateProjectsEndpoint = endpoint("projects/validate");
const GetProjectEndpoint = projectId => {
  const endpointSuffix = `projects/${projectId}`;
  return endpoint(endpointSuffix);
};

const GetProjectsEndpoint = organization => {
  const endpointSuffix = `organizations/${organization}/projects`;
  return endpoint(endpointSuffix);
};

const GetFeatureUIConfigSetting = projectId => {
  const endpointSuffix = `projects/${projectId}/feature/uiconfig`;
  return endpoint(endpointSuffix);
};

const UpdateFeatureUIConfigSetting = projectId => {
  const endpointSuffix = `projects/${projectId}/feature/config`;
  return endpoint(endpointSuffix);
};

const usageSummaryEndpoint = (projectId, pageLimit, startIndex) => {
  const endpointSuffix = `projects/${projectId}/usageSummary?limit=${pageLimit}&skip=${startIndex}`;
  return endpoint(endpointSuffix);
};

const sendDeleteProjectSignalEndPoint = projectId =>
  endpoint(`projects/${projectId}/mail/deleteCode`);
const deleteProjectEndPoint = (projectId, confirmationCode) =>
  endpoint(`projects/${projectId}/?deleteCode=${confirmationCode}`);

export function addProject({
  name,
  nameSpace,
  projectType,
  moreDetails,
  organization,
  packageType,
  useInAppUserServer,
  availableNLUs
}) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: ProjectsEndpoint,
        method: "POST",
        body: JSON.stringify({
          name,
          nameSpace,
          projectType,
          moreDetails,
          organization,
          packageType,
          useInAppUserServer,
          availableNLUs
        }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function validateProject({
  name,
  nameSpace,
  projectType,
  moreDetails,
  organization,
  packageType
}) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: validateProjectsEndpoint,
        method: "POST",
        body: JSON.stringify({
          name,
          nameSpace,
          projectType,
          moreDetails,
          organization,
          packageType
        }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function updateProject(projectId, projectDetails) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: ProjectsEndpoint,
        method: "POST",
        body: JSON.stringify({ _id: projectId, ...projectDetails }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });
      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getProjects(organization) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: GetProjectsEndpoint(organization),
        method: "GET",
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            getBulkProjectSuccessHandler(dispatch, resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getProject(projectId, user, updateToken = false) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const url = GetProjectEndpoint(projectId);
      const request = Request({
        url: url,
        method: "GET",
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            getProjectSuccessHandler(
              projectId,
              user,
              dispatch,
              resolve,
              reject,
              updateToken
            ),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getUsageSummary(projectId, pageLimit, startIndex) {
  return dispatch => {
    const usageSummaryEndPoint = usageSummaryEndpoint(
      projectId,
      pageLimit,
      startIndex
    );
    return new Promise((resolve, reject) => {
      const request = Request({
        url: usageSummaryEndPoint,
        method: "GET",
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

function getBulkProjectSuccessHandler(dispatch, resolve) {
  return actionSuccess => {
    const projectData = actionSuccess.data;
    dispatch({
      type: GET_PROJECT_BULK,
      project: projectData.projects
    });
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
}

function getProjectSuccessHandler(
  projectId,
  user,
  dispatch,
  resolve,
  reject,
  updateToken
) {
  return actionSuccess => {
    const projectData = actionSuccess.data;
    dispatch({
      type: GET_PROJECT,
      project: projectData
    });

    if (updateToken) {
      saveProjectUrl(
        projectId,
        projectData.config.serverDetails.provintServer.url
      );
      loadProjectToken(
        projectId,
        user,
        dispatch,
        () => genericHttpSuccessHandler(resolve)(actionSuccess),
        actionError => genericHttpErrorHandler(reject)(actionError),
        projectData
      );
    } else {
      genericHttpSuccessHandler(resolve)(actionSuccess);
    }
  };
}

export function registerAppSDK(projectId, platform, bundleId) {
  return dispatch => {
    const RegisterSDKEndPoint = endpoint(SDKUrlSupplier(projectId));
    return new Promise((resolve, reject) => {
      const request = Request({
        url: RegisterSDKEndPoint,
        method: "POST",
        body: JSON.stringify({
          platform: platform,
          bundleId: bundleId,
          appType: "sdk"
        }),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        //registerAppSDKRestCallMock(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function registerFirebaseConfig(projectId, platform, jsonFile) {
  return dispatch => {
    var formData = new FormData();
    formData.append("fcmPrivateKey", jsonFile);
    formData.append("platform", platform);

    const RegisterSDKEndPoint = endpoint(SDKUrlSupplier(projectId));
    return new Promise((resolve, reject) => {
      const request = Request({
        url: RegisterSDKEndPoint,
        method: "POST",
        body: formData,
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getFeatureUIConfigSetting(projectId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: GetFeatureUIConfigSetting(projectId),
        method: "GET",
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function updateFeatureUIConfigSetting(projectId, requestObject) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: UpdateFeatureUIConfigSetting(projectId),
        method: "POST",
        body: JSON.stringify(requestObject),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function saveUserAuthenticationConfig(projectId, configObj) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: userAuthenticationEndPoint(projectId),
        method: "POST",
        body: JSON.stringify(configObj),
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function sendDeleteProjectSignal(projectId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: sendDeleteProjectSignalEndPoint(projectId),
        method: "POST",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function deleteProject(projectId, confirmationCode) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: deleteProjectEndPoint(projectId, confirmationCode),
        method: "DELETE",
        headerObj: {
          "content-type": "application/json"
        },
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}
