import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import Footer_v2 from "../common/footer_v2";
import chatbotDocImage from "../../../public/resources/site/images/chatbot-image.svg";
import demoImage from "../../../public/resources/site/images/demos-image-01.svg";
import DocCategoryNavBar from "./docCategoryNavBar";

class DocsChatbotHome extends Component {
  constructor(props) {
    super(props);
  }

  renderBannerSection() {
    return (
      <div className="tutorials-img-block docs-img-block">
        <div className="container">
          <div className="tutorials-content-block">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="title-content">
                  <h1>Chatbots</h1>
                  <p className="mt-0">
                    Our chatbot documentation provides developers with
                    everything they need, from connecting NLU provider bots to
                    building 1Chatbots and chat widgets.
                  </p>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="image-block text-md-right">
                  <img src={chatbotDocImage} alt="Chat bots Docs" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChatbotDocItemSection() {
    const docItems = [
      {
        title: "NLU Connecting Guide",
        description:
          "Add your NLU provider bots to In-App Chat. Once added, you can combine these bots to create a 1Chatbot or widget for your site or app.",
        path: "/docs/chatbots/nlu/google-dialogflow"
      },
      {
        title: "1Chatbot",
        description:
          "Combine powerful bots and create a 1Chatbot that is capable of performing a variety of tasks all from one chat interface.",
        path: "/docs/chatbots/1chatbot/default-replies"
      },
      {
        title: "1Webchat",
        description:
          "Ready to add a chat widget to your site or app? The process of creating and installing a bot can be completed in just minutes!",
        path: "/docs/chatbots/1webchat/default-replies"
      } /* ,
      {
        title: "Bot Factory Guide",
        description:
          "Create your own customized bots. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sit amet condimentum leo.",
        path: "/docs/chatsdk",
        isComing: true
      } */
    ];

    return (
      <div className="content-left-block docs-item-block">
        <div className="container">
          <div className="docs-items">
            <div className="title h3 mb-3">Documentation</div>
            <div className="row">
              {docItems.map(item => (
                <div className="col-md-6 col-lg-4 docs-column" key={item.title}>
                  <div className="card docs-item">
                    <h2 className="card-title">{item.title}</h2>
                    <div className="info-content">
                      <p>{item.description}</p>
                    </div>
                    <div className="text-right">
                      <Link
                        to={item.path}
                        className={`view-docs-btn ${
                          item.isComing ? "coming-soon" : "stretched-link"
                        }`}
                        onClick={e => item.isComing && e.preventDefault()}
                      >
                        {!item.isComing ? (
                          <>
                            View docs
                            <i className="ri-arrow-right-line" />
                          </>
                        ) : (
                          <>Coming Soon</>
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDemoSection() {
    return (
      <div className="content-left-block demos-block pt-0">
        <div className="container">
          <h3 className="mb-4">Demos</h3>
          <div className="demo-content-block">
            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="demo-content-item">
                  <div className="card-image">
                    <img src={demoImage} alt="In-App Chat demo" />
                  </div>
                  <div className="card-content">
                    <h4>In-App Chat</h4>
                    <div className="info-content">
                      <p>
                        Create a full-featured chat experience for your users
                        that's seamless and secure.
                      </p>
                    </div>
                    <div className="text-right">
                      <a
                        href="#"
                        onClick={e => e.preventDefault()}
                        className="view-link-purple"
                        title="Coming Soon"
                      >
                        Coming Soon <i className="ri-arrow-right-line" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRelatedSection() {
    const relatedItems = [
      {
        title: "Chat SDKs",
        description:
          "Build a messaging experience that provides the latest and most essential features with In-App Chat API.",
        routePath: "/docs/chatsdk"
      } /* ,
      {
        title: "UIKit for Chat",
        description:
          "Quickly integrate standard chat features into your app. Customize individual and overall theme components such as fonts and colors.",
        routePath: "/docs/uikit"
      } */
    ];

    return (
      <div className="content-left-block Related-block pt-0">
        <div className="container">
          <h3 className="mb-4">Related</h3>
          <div className="related-items">
            <div className="row">
              {relatedItems.map(item => (
                <div className="col-md-6" key={item.title}>
                  <Link to={item.routePath} className="related-item">
                    <h4 className="title-text">{item.title}</h4>
                    <div className="info-content">
                      <p>{item.description}</p>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <DocCategoryNavBar />
        <main className="content-wrapper main-content">
          {this.renderBannerSection()}
          {this.renderChatbotDocItemSection()}
          {this.renderRelatedSection()}
        </main>
        <Footer_v2 />
      </>
    );
  }
}

export default DocsChatbotHome;
