export function validateMandatoryField(fieldValue, fieldName) {
  if (!fieldValue) {
    return `Please Enter ${fieldName}`;
  }
  return null;
}

export function validateEmail(email) {
  if (!email) {
    return `Please Enter Email Address`;
  }

  // eslint-disable-next-line no-useless-escape
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
    return "Email Is Not Valid";
  }
  return null;
}

export function validateOptionalAlphaField(name, fieldName) {
  if (!name || name.trim().length === 0) {
    return null;
  }

  if (/^[A-Za-z']+$/.test(name) === false) {
    return `${fieldName} Is Not Valid`;
  }
  return null;
}

export function validatePersonName(name, fieldName) {
  if (!name || name.trim().length === 0) {
    return `Please Enter ${fieldName}`;
  }

  if (name.trim().length < 3) {
    return `${fieldName} must be at least 3 characters long`;
  }

  if (/^[A-Za-z']+$/.test(name) === false) {
    return `${fieldName} Is Not Valid`;
  }
  return null;
}

export function validatePersonFullName(name, fieldName) {
  if (!name || name.trim().length === 0) {
    return `Please Enter ${fieldName}`;
  }

  if (name.trim().length < 3) {
    return `${fieldName} must be at least 3 characters long`;
  }

  /*if (/^[A-Za-z']+$/.test(name) === false) {
    return `${fieldName} Is Not Valid !`;
  }*/
  return null;
}

export function validateCompanyName(name, fieldName) {
  if (!name || name.trim().length === 0) {
    return `Please Enter ${fieldName}`;
  }

  if (name.trim().length < 3) {
    return `${fieldName} must be at least 3 characters long`;
  }

  if (name.trim().length > 30) {
    return "Must be less than or equal to 30 characters long";
  }

  if (/^(?![0-9\s]*$)[A-Za-z0-9\s]+[^\s]$/.test(name) === false) {
    return `${fieldName} Is Not Valid`;
  }
  return null;
}

export function validateProjectName(projectName) {
  if (!projectName || projectName.trim().length === 0) {
    return "Please Enter Project Name";
  }
  if (projectName.trim().length < 3) {
    return "Must be at least 3 characters long";
  }
  if (projectName.trim().length > 30) {
    return "Must be less than or equal to 30 characters long";
  }
  return null;
}

export function validateProjectUrl(projectUrl) {
  if (!projectUrl || projectUrl.trim().length === 0) {
    return "Please Enter Namespace";
  }
  if (projectUrl.trim().length < 3) {
    return "Must be at least 3 characters long.";
  }
  if (projectUrl.trim().length > 30) {
    return "Max characters allowed is 30";
  }

  const numbRegx = /^\d$/;
  if (
    projectUrl
      .trim()
      .charAt(0)
      .match(numbRegx)
  ) {
    return "Must start with alphabet character";
  }

  // Commenting below regex as look behing is not working in Iphone browsers.
  //const regex = /^(?!-)[a-zA-Z0-9-]+(?<!-)$/;
  // eslint-disable-next-line no-useless-escape
  const regex = /^[a-z0-9]{1}[a-z0-9\-]+[a-z0-9]{1}$/;
  const result = projectUrl.match(regex);
  if (result === null) {
    return "Must be lowercase letters,numbers or '-'(middle only) only";
  }
  return null;
}

export function normalizeNumber(value) {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, "");
}

export function validateDomainName(domain) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\.*)?$/;
  const result = domain.match(regex);
  return result !== null;
}

export function validateBundleId(bundleId) {
  if (!bundleId || bundleId.trim().length === 0) {
    return "Please Enter Namespace";
  }
  if (bundleId.trim().length < 3) {
    return "Must be at least 3 characters long.";
  }

  const regex = /^[a-z][a-z0-9_]*(\.[a-z0-9_]+)+[0-9a-z_]$/i;
  const result = bundleId.match(regex);
  if (result === null) {
    return "Invalid bundle id";
  }
  return "";
}

export function validateURL(urlString) {
  /*var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ //port
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i');
  return pattern.test(urlString);*/

  try {
    new URL(urlString);
  } catch (e) {
    return false;
  }

  return true;
  //const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  //const  regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  /*const result = urlString.match(regex);
  if (result === null) {
    return false;
  }
  return true;*/

  /*var inputElement = document.createElement('input');
  inputElement.type = 'url';
  inputElement.value = urlString;

  if (!inputElement.checkValidity()) {
    return false;
  }
  return true;*/
}

export function validateChatbotName(name, fieldName) {
  if (!name || name.trim().length === 0) {
    return `Please Enter ${fieldName}`;
  }

  if (name.trim().length < 3) {
    return `${fieldName} must be at least 3 characters long`;
  }

  if (/^[A-Za-z0-9_ ']+$/.test(name) === false) {
    return `${fieldName} Is Not Valid`;
  }
  return null;
}

export function validatePassword(password) {
  if (!password) {
    return "Please provide password";
  }
  if (password.length < 8) {
    return "Password must be 8 characters long";
  }
  if (
    !(
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[^a-zA-Z\d]/g)
    )
  ) {
    return "Requires an uppercase letter, number & special character";
  }
  return null;
}
