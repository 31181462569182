import React from "react";
import SiteHeader from "../../../common/siteHeader";
import WebchatDocSidebar from "./webchatDocSidebar";
import ChatbotsCategoryNavBar from "../chatbots/chatbotsCategoryNavBar";
import InstallingChatWidget from "./installingChatWidgetTitle";

import installChatWidgetImg1 from "../../../../public/resources/site/images/install-chat-widget1.png";
import installChatWidgetImg2 from "../../../../public/resources/site/images/install-chat-widget2.JPG";
import installChatWidgetImg3 from "../../../../public/resources/site/images/install-chat-widget3.JPG";
import installChatWidgetImg4 from "../../../../public/resources/site/images/install-chat-widget4.JPG";
import installChatWidgetImg5 from "../../../../public/resources/site/images/install-chat-widget5.JPG";

const DocWebchatInstallingChatWidget = () => {
  return (
    <>
      <SiteHeader fixedHeaderOnScroll={false} isDark />
      <ChatbotsCategoryNavBar />
      <main className="content-wrapper main-content">
        <InstallingChatWidget />
        <div className="content-left-block connecting-grid-block --scrollpage">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-xl-3 tab-inner-content">
                <WebchatDocSidebar />
              </div>
              <div className="col-lg-8 col-xl-9">
                <div className="tab-content tutorials-details-block">
                  <div className="tab-pane fade show active" id="onechatbot">
                    <h3>Prerequisites</h3>
                    <p className="mb-2">
                      In order to integrate the chat widget, you will need the
                      following:
                    </p>
                    <ul className="field-list">
                      <li>Access to your website's code base</li>
                      <li>The chat widget JavaScript snippet</li>
                    </ul>
                    <p className="mb-4 pb-4"></p>
                    <ul className="field-list">
                      <li>
                        <strong>Get the chat widget JavaScript</strong>
                        <p>
                          In the In-App Chat portal, click 'Bots' in the
                          side-panel. This will take you to the page where you
                          will provide the options necessary to create your
                          widget. Select the button labeled{" "}
                          <strong>Create 1Webchat Widget</strong>
                        </p>
                        <div className="image-block mb-5">
                          <img
                            src={installChatWidgetImg1}
                            alt="Create 1Webchat Widget"
                          />
                        </div>
                        <p>
                          The modal that appears will first allow you to select
                          the NLU provider bots that will be operating in your
                          1Webchat widget. You will also need to provide a name
                          for your widget.
                        </p>
                        <div className="image-block mb-5">
                          <img
                            src={installChatWidgetImg2}
                            alt="Create 1Webchat Widget"
                          />
                        </div>
                        <p>
                          Next, you will determine things like your bot's
                          description, greeting message, etc. Once you are
                          satisfied with the options you have provided, select
                          the Next button.
                        </p>
                        <div className="image-block mb-5">
                          <img
                            src={installChatWidgetImg3}
                            alt="Create 1Webchat Widget"
                          />
                        </div>
                        <p>
                          Provide the whitelisted domains that your widget will
                          be added to. Clicking 'Next' will generate your
                          JavaScript snippet.
                        </p>
                        <div className="image-block mb-5">
                          <img
                            src={installChatWidgetImg4}
                            alt="Create 1Webchat Widget"
                          />
                        </div>
                        <p>
                          Once the JavaScript snippet has been created, select
                          the <strong> Copy Script </strong> button, and proceed
                          to step 2.
                        </p>
                        <div className="image-block mb-5">
                          <img
                            src={installChatWidgetImg5}
                            alt="Create 1Webchat Widget"
                          />
                        </div>
                      </li>
                      <li>
                        <strong>
                          Insert the JavaScript snippet on your website
                        </strong>
                        <p>
                          {`Paste the snippet you have copied into your HTML <head> element via your Content Management System, or through your app's code. In order to only have to do this once, insert the snippet into a header or template file that is shared across all pages of your website.`}
                        </p>
                        <p>
                          Note: Make sure the JavaScript is inserted into every
                          page you want the widget deployed on. If you only have
                          the widget inserted under inappchat.io/pricing, for
                          example, it would not be deployed on
                          inappchat.io/contactus without having to repeat this
                          step.
                        </p>
                        <p>
                          You can view your 1Webchat JavaScript snippet(s)
                          anytime in the Settings menu.
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default DocWebchatInstallingChatWidget;
