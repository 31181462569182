import React, { Component } from "react";
import WOW from "wow.js/dist/wow";
import { getHeader } from "../../utils/seoutils";
import PrivacyPolicyBanner from "../site/privacyPolicy/privacyPolicyBanner";
import ToastMessage from "./toastMessage";

/**
 *  This container is created to separate CSS for site pages and main pages
 */

export default class SitePageContainer extends Component {
  componentDidMount() {
    document.body.classList.add("website");
    if (this.props.showChatbot) {
      document.body.classList.remove("nochatbot");
      document.body.classList.add("chatbot");
    } else {
      document.body.classList.remove("chatbot");
      document.body.classList.add("nochatbot");
    }
    if (this.props.customBodyClass) {
      document.body.classList.add(this.props.customBodyClass);
    }
    // Initialize wow js plugin for animation.
    new WOW().init();
    setTimeout(() => window.scrollTo(0, 0), 100);
  }

  render() {
    return (
      <div className={`${this.props.className || ""}`}>
        {getHeader(this.props.pageName)}
        {this.props.children}
        <PrivacyPolicyBanner />
        <ToastMessage className="custom-toast-alert" top="91%" left="18%" />
      </div>
    );
  }

  componentWillUnmount() {
    document.body.classList.remove("website");
    document.body.classList.remove("chatbot");
    document.body.classList.remove("nochatbot");
    if (this.props.customBodyClass) {
      document.body.classList.remove(this.props.customBodyClass);
    }
  }
}
