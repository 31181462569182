import React, { Component } from "react";
import { withRouter } from "react-router";
import connect from "react-redux/es/connect/connect";
import { getProject } from "../../../actions/projects";
import { ActionError } from "../../../utils/httpResponseErrorHandler";
import ErrorCodes from "../../../utils/errorCodes";
import InternalServerErrorPage from "../../site/pages/internalServerError";
import DashboardErrorComp from "./dashboardErrorComp";
import LoadingDisplay from "../../common/loadingDisplay";

const ProjectContext = React.createContext();

class ProjectContextProvider extends Component {
  constructor() {
    super();
    this.state = { isProjectLoading: true };
  }

  componentDidMount() {
    const projectId = this.props.match.params.projectId;
    if (projectId) {
      const user = this.props.user;
      let updateToken = true;
      if (typeof this.props.updateToken !== "undefined")
        updateToken = this.props.updateToken;

      this.setState({ isProjectLoading: true });
      this.props
        .getProject(projectId, user, updateToken)
        .then(result => {
          this.setState({
            resultObj: result.actionDataObj,
            isProjectLoading: false
          });
        })
        .catch(error => {
          if (error.provIntTokenError && error.provIntTokenError === true) {
            this.setState({ resultObj: error, isProjectLoading: false });
          } else {
            this.setState({
              resultObj: error.actionDataObj,
              isProjectLoading: false
            });
          }
        });
    }
  }

  render() {
    const projectId = this.props.match.params.projectId;
    if (projectId) {
      if (this.state.isProjectLoading) {
        return this.renderLoading();
      } else {
        if (this.state.resultObj instanceof ActionError) {
          return this.renderError(this.state.resultObj);
        } else {
          let project;
          if (
            this.state.resultObj.provIntTokenError &&
            this.state.resultObj.provIntTokenError === true
          ) {
            project = this.state.resultObj.successData;
          } else {
            project = this.state.resultObj.data;
          }
          /* let contractPeriodExpired = true;
          if (project.config.contractPeriod) {
            if (project.config.contractPeriod.end > Date.now() / 1000) {
              contractPeriodExpired = false;
            }
          }
          if (contractPeriodExpired) {
            return this.renderError(
              this.state.resultObj.errorObj,
              contractPeriodExpired,
              isFreeProject(project)
            );
          } else */
          if (this.state.resultObj.provIntTokenError === true) {
            return this.renderError(this.state.resultObj.errorObj);
          } else {
            return (
              <ProjectContext.Provider value={{ project: project }}>
                {this.props.children}
              </ProjectContext.Provider>
            );
          }
        }
      }
    } else {
      return this.props.children;
    }
  }

  renderLoading() {
    return (
      <LoadingDisplay
        position="absolute"
        left="50%"
        top="50%"
        height="35px"
        marginTop="1px"
      />
    );
  }

  renderError(resultObj, contractPeriodExpired, isFreeProject) {
    if (
      contractPeriodExpired ||
      resultObj.serverErrorCode === ErrorCodes.ACCOUNT_PERMISSION_ERROR ||
      resultObj.serverErrorCode === ErrorCodes.ACCOUNT_NOTFOUND ||
      resultObj.httpCode === 404
    ) {
      return this.renderAccountError(
        resultObj,
        contractPeriodExpired,
        isFreeProject
      );
    }
    //const customErrorMessage = resultObj.serverErrorCode === ErrorCodes.ACCOUNT_NOTFOUND ? "No Such Project Found" : resultObj.message;
    return this.renderInternalServerError();
  }

  renderAccountError(resultObj, contractPeriodExpired, isFreeProject) {
    let message = "";
    let displayBuyNow = false;
    if (contractPeriodExpired) {
      if (isFreeProject) {
        message = (
          <>
            Your free project duration has been expired. Please buy a premium
            project.
          </>
        );
        displayBuyNow = true;
      } else {
        message =
          "Your premium project duration has been expired. Please extend the period via subscription payment.";
      }
    } else if (
      resultObj.serverErrorCode === ErrorCodes.ACCOUNT_PERMISSION_ERROR
    ) {
      message = "You don't have access to this project/url";
    } else if (
      resultObj.serverErrorCode === ErrorCodes.ACCOUNT_NOTFOUND ||
      resultObj.httpCode === 404
    ) {
      message = "No such project found";
    }
    return (
      <DashboardErrorComp message={message} displayBuyNow={displayBuyNow} />
    );
  }

  renderInternalServerError() {
    return <InternalServerErrorPage />;
  }
}

function mapStatsToProps(stats) {
  return {
    user: stats.user.user
  };
}

export { ProjectContext };
export default connect(mapStatsToProps, { getProject })(
  withRouter(ProjectContextProvider)
);
