import {
  errorResponseReceived,
  successResponseReceived
} from "../actions/restCall";
import history from "../history";
import { removeCookie } from "../utils/commonUtil";

export function genericResponseHandler(
  httpResponse,
  successMsg = null,
  resolve,
  reject,
  dispatch,
  requestName
) {
  httpResponse.json().then(data => {
    const actionResultObj = handleHttpResponse(httpResponse, data, successMsg);
    if (actionResultObj instanceof ActionSuccess) {
      dispatch &&
        dispatch(successResponseReceived(requestName, actionResultObj));
      resolve && resolve({ actionDataObj: actionResultObj });
    } else {
      dispatch(errorResponseReceived(requestName, actionResultObj));
      reject && reject({ actionDataObj: actionResultObj });
    }
  });
}

export function handleHttpResponseV2(
  httpResponse,
  successHandler,
  errorHandler
) {
  if (httpResponse) {
    // handle codes for which json may not come in response.
    if (httpResponse.status >= 500 && httpResponse.status <= 505) {
      const clientMessage =
        "Server is unable to fulfill request at the moment. Please try again later or contact support";
      errorHandler(new ActionError(httpResponse.status, clientMessage));
    } else if (
      httpResponse.status === 200 ||
      (httpResponse.status >= 400 && httpResponse.status <= 404)
    ) {
      // Get the json data out of the response and handle
      httpResponse
        .json()
        .then(jsonData => {
          if (httpResponse.status === 200) {
            successHandler(new ActionSuccess(jsonData.message, jsonData.body));
          } else if (httpResponse.status >= 400 && httpResponse.status <= 404) {
            // in case of client errors (starting with 4**)
            // bad request/ not found/ authorization/ authentications, set ui message same as server response message
            // here we wlogout from the browser
            if (
              httpResponse.status === 401 &&
              jsonData &&
              jsonData.errorCode === "AU0004"
            ) {
              removeCookie("token");
              history.push("/login");
            }
            errorHandler(
              getActionObjectFromJsonData(jsonData, httpResponse.status)
            );
          }
        })
        .catch(error => {
          if (httpResponse.status === 404) {
            errorHandler(new ActionError(httpResponse.status, "Not found"));
          } else {
            throw error;
          }
        });
    } else {
      errorHandler(
        new ActionError(httpResponse.status, null, "Error in executing request")
      );
    }
  }
}

export function handleHttpResponse(httpResponse, successHandler, errorHandler) {
  if (httpResponse) {
    // handle codes for which json may not come in response.
    if (httpResponse.status >= 500 && httpResponse.status <= 505) {
      const clientMessage =
        "Server is unable to fulfill request at the moment. Please try again later or contact support";
      errorHandler(new ActionError(httpResponse.status, clientMessage));
    } else if (
      httpResponse.status === 200 ||
      (httpResponse.status >= 400 && httpResponse.status <= 404)
    ) {
      // Get the json data out of the response and handle
      httpResponse
        .json()
        .then(jsonData => {
          if (httpResponse.status === 200) {
            if (jsonData.success && jsonData.success === true) {
              successHandler(getActionObjectFromJsonData(jsonData));
            } else {
              errorHandler(getActionObjectFromJsonData(jsonData));
            }
          } else if (httpResponse.status >= 400 && httpResponse.status <= 404) {
            // in case of client errors (starting with 4**)
            // bad request/ not found/ authorization/ authentications, set ui message same as server response message
            // here we wlogout from the browser
            if (
              httpResponse.status === 401 &&
              jsonData &&
              jsonData.errorCode === "AU0004"
            ) {
              removeCookie("token");
              history.push("/login");
            }
            errorHandler(
              getActionObjectFromJsonData(jsonData, httpResponse.status)
            );
          }
        })
        .catch(error => {
          if (httpResponse.status === 404) {
            errorHandler(new ActionError(httpResponse.status, "Not found"));
          } else {
            throw error;
          }
        });
    } else {
      errorHandler(
        new ActionError(httpResponse.status, null, "Error in executing request")
      );
    }
  }
}

// Depending on the need we can pass dispatch or resolve or both
// If we are passing dispatch then request name is needed.
export function genericHttpSuccessHandler(resolve, dispatch, requestName) {
  return actionSuccess => {
    if (actionSuccess instanceof ActionSuccess) {
      dispatch &&
        requestName &&
        dispatch(successResponseReceived(requestName, actionSuccess));
      resolve && resolve({ actionDataObj: actionSuccess });
    }
  };
}

export function genericHttpErrorHandler(reject, dispatch, requestName) {
  return actionError => {
    if (actionError instanceof ActionError) {
      dispatch &&
        requestName &&
        dispatch(errorResponseReceived(requestName, actionError));
      reject && reject({ actionDataObj: actionError });
    } else {
      reject && reject(actionError);
    }
  };
}

export function genericFetchApiCatchHandler(error, reject) {
  console.log(error);
  if (error instanceof TypeError) {
    reject({
      actionDataObj: new ActionError(-1, "Network Error! Request failed")
    });
  } else {
    console.log(error);
    reject({
      actionDataObj: new ActionError(-1, "Error in processing request")
    });
  }
}

/*function getErrorMessageFromResponse(httpResponseData) {
    let errorMessage = "Something wrong happened. Please try again.";
    if (httpResponseData && httpResponseData.message) {
        errorMessage = httpResponseData.message;
    }
    return errorMessage;
}*/

function getActionObjectFromJsonData(jsonData, httpStatus) {
  if (jsonData.success === true) {
    return new ActionSuccess(jsonData.msg, jsonData.result);
  } else {
    let msg = jsonData.msg;
    if (!msg && httpStatus === 404) {
      msg = "Not Found";
    }
    return new ActionError(
      httpStatus || 200,
      msg,
      jsonData.result,
      jsonData.errorCode
    );
  }
}

export class ActionError {
  _httpCode;
  _serverErrorCode;
  _message;
  _data;

  // send httpCode as -1 if it has not come from http and it is runtime exception during
  // action execution.
  constructor(httpCode, message, data, serverErrorCode) {
    this._httpCode = httpCode;
    this._serverErrorCode = serverErrorCode;
    this._message = message;
    this._data = data;
  }

  get httpCode() {
    return this._httpCode;
  }

  get serverErrorCode() {
    return this._serverErrorCode;
  }

  get message() {
    return this._message || "Something went wrong";
  }

  get data() {
    return this._data;
  }
}

export class ActionSuccess {
  _message;
  _data;

  constructor(message, data) {
    this._message = message;
    this._data = data;
  }

  get data() {
    return this._data;
  }

  get message() {
    return this._message;
  }
}

export function isSuccess(resultInstance) {
  return resultInstance instanceof ActionSuccess;
}

export function isError(resultInstance) {
  return resultInstance instanceof ActionError;
}
