import React, { Component } from "react";
import { Link } from "react-router-dom";
import Footer_v2 from "../common/footer_v2";
import SiteHeader from "../../common/siteHeader";
import config from "../../../config/config";

class ChatbotAutomationSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      if (
        window.gtag &&
        config.GADS_CODE &&
        config.CHATBOTAUTOMATION_EVENT_ID
      ) {
        window.gtag("event", "conversion", {
          send_to: `${config.GADS_CODE}/${config.CHATBOTAUTOMATION_EVENT_ID}`
        });
      }
    }, 2000);
  }

  renderBannerSection() {
    return (
      <div className="page-banner-section mh-100">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="content">
                <h1>Success! We’ll send more info soon to your inbox.</h1>
                <p>
                  In the meantime, you can get started with In-App Chat 1Chatbot
                  by clicking the link below.
                </p>
                <Link to="/signup" className="link orange-link mt-3">
                  Get Started with 1Chatbot for In-App Chat Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <SiteHeader
          containerClass="automation-header"
          fixedHeaderOnScroll={false}
          isDark
          onlyContact
          isCenterHeaderItems={false}
        />
        <main className="content-wrapper main-content new-content mh-auto">
          {this.renderBannerSection()}
        </main>
        <Footer_v2 />
      </>
    );
  }
}

export default ChatbotAutomationSuccess;
