import { endpoint, Request, restCall } from "../utils/restCallUtil";
import {
  genericFetchApiCatchHandler,
  genericHttpErrorHandler,
  genericHttpSuccessHandler,
  handleHttpResponse
} from "../utils/httpResponseErrorHandler";
//import config from "../config/config";

export const GET_CATEGORIES = "get_categories";
export const GET_ENTITLEMENTS = "get_entitlements";

//const DOMAIN = config.DOMAIN;
const GetProductsUrl = endpoint("products");
//const GetProductsByScreenUrl = (screen) => `${GetProductsUrl}?screen=${screen}`;
const GetCategoriesUrl = endpoint("products/productCategory");
const GetEntitlementStatusUrl = projectId =>
  endpoint(`projects/${projectId}/get-entitlement-status`);
const GetProductUrl = sku => endpoint("products?sku=" + sku);

export const GetProductsByConfigId = endpoint("products/productByConfigId");

export function getProductsByFilter(filter) {
  let url = GetProductsUrl;
  if (filter) {
    url = url + `?${filter}`;
  }
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: url,
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

export function getProducts(screen, category) {
  let urlSuffix = "";
  if (screen) {
    urlSuffix = urlSuffix + `screen=${screen}`;
  }
  if (category) {
    if (urlSuffix !== "") {
      urlSuffix += "&";
    }
    urlSuffix = urlSuffix + `category=${category}`;
  }
  return getProductsByFilter(urlSuffix);
}

export function getCategories() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: GetCategoriesUrl,
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            getCategoriesSuccessHandler(dispatch, resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

function getCategoriesSuccessHandler(dispatch, resolve) {
  return actionSuccess => {
    const categoryData = actionSuccess.data;
    dispatch({
      type: GET_CATEGORIES,
      category: categoryData
    });
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
}

export function getProduct(sku) {
  const productUrl = GetProductUrl(sku);

  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: productUrl,
        method: "GET",
        headerObj: {
          "content-type": "application/json"
        }
      });

      restCall(request, dispatch)
        //getProductMock(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

/*function getCartMock(requestObj, dispatch) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const httpResponse = {};
            httpResponse.status = 200;
            httpResponse.json = () => {
                return new Promise((resolve) => {
                    const responseData = {};
                    responseData.success = true;
                    responseData.msg = "Category Error";
                    responseData.result = {
                        categories: [
                            {
                                code: "MEDIA_SHARING",
                                name: "Media Sharing",
                                displayRank: 1
                            },
                            {
                                code: "PROFILE_MANAGEMENT",
                                name: "Profile Management",
                                displayRank: 2
                            },
                            {
                                code: "PRESENCE",
                                name: "Presence",
                                displayRank: 3
                            },
                            {
                                code: "END_TO_END_ENCRYPTION",
                                name: "End to end encryption",
                                displayRank: 4
                            },
                            {
                                code: "MISCELLANEOUS",
                                name: "Miscellaneous",
                                displayRank: 5
                            }
                        ]
                    };
                    resolve(responseData);
                });
            }
            resolve(httpResponse);
        }, 2000);
    })
}*/

/*const product = {
    "_id": "5da1a6d2eab5b3a2963ceee4",
    "name": "TEST_PRODUCT",
    "sku": "TEST_PRODUCT_12",
    "screen": "marketplace",
    "category": "PROFILE_MANAGEMENT",
    "baseProductSku": "HOSTED_DIRECT_MESSAGE_SUIT",
    "description": "Test Description",
    "config": {
        "features": {
            "groupChat": {
                "enabled": true
            },
            "deployment": {
                "type": "hosteddedicated"
            }
        }
    },
    "productImage": "file/productPic/TEST_PRODUCT_12_1570875090182.svg",
    "productImageThumb": "file/thumbnail/productPic/TEST_PRODUCT_12_1570875090182.svg",
    "__v": 0,
    "maintainanceVariations": [],
    "maintainanceOptions": [],
    "variations": [],
    "options": {
        "tenureType": {
            "name": "Hosted Tenure type",
            "key": "tenureType",
            "optionValues": [
                {
                    "name": "monthly1",
                    "value": {
                        "unit": 1,
                        "type": "month"
                    },
                    "priceType": "perEntity"
                },
                {
                    "name": "yearly1",
                    "value": {
                        "unit": 12,
                        "type": "year"
                    },
                    "priceType": "perEntity"
                }
            ],
            "valueType": "tenure"
        },
        "userTier": {
            "name": "Hosted Users tier",
            "key": "userTier",
            "optionValues": [
                {
                    "name": "tier1",
                    "value": {
                        "high": 10,
                        "low": 1
                    },
                    "priceType": "flat"
                },
                {
                    "name": "tier2",
                    "value": {
                        "high": 100,
                        "low": 11
                    },
                    "priceType": "perEntity"
                },
                {
                    "name": "tier3",
                    "value": {
                        "high": 250,
                        "low": 101
                    },
                    "priceType": "perEntity"
                },
                {
                    "name": "tier4",
                    "value": {
                        "high": 10000,
                        "low": 251
                    },
                    "priceType": "perEntity"
                }
            ],
            "valueType": "range"
        }
    },
    "type": "feature"
};*/

/*function getCategoryProductsMock(requestObj, dispatch, category) {
    let productName = 'Product_' + category;

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const httpResponse = {};
            httpResponse.status = 200;
            httpResponse.json = () => {
                return new Promise((resolve) => {
                    const responseData = {};
                    responseData.success = true;
                    responseData.msg = "Get Products Error";
                    responseData.result = [product];
                        resolve(responseData);
                });
            }
            resolve(httpResponse);
        }, 2000);
    })
}*/

/*function getProductMock(requestObj, dispatch) {

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const httpResponse = {};
            httpResponse.status = 200;
            httpResponse.json = () => {
                return new Promise((resolve) => {
                    const responseData = {};
                    responseData.success = true;
                    responseData.msg = "Get Products Error";
                    responseData.result = product;
                    resolve(responseData);
                });
            }
            resolve(httpResponse);
        }, 1000);
    })
}*/

export function getEntitlementStatus(projectId, productIds) {
  const Url = GetEntitlementStatusUrl(projectId);
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: Url,
        method: "POST",
        headerObj: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ productIds: productIds }),
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            getEntitlementStatusSuccessHandler(dispatch, resolve, projectId),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}

function getEntitlementStatusSuccessHandler(dispatch, resolve, projectId) {
  return actionSuccess => {
    dispatch({
      type: GET_ENTITLEMENTS,
      entitlements: actionSuccess.data,
      projectId: projectId
    });
    genericHttpSuccessHandler(resolve)(actionSuccess);
  };
}

export function getProductsByConfigId(configIds, deploymentType, selectFields) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const request = Request({
        url: GetProductsByConfigId,
        method: "POST",
        headerObj: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          configIds,
          selectFields,
          deploymentType
        }),
        authEnabled: true
      });

      restCall(request, dispatch)
        .then(response => {
          handleHttpResponse(
            response,
            genericHttpSuccessHandler(resolve),
            genericHttpErrorHandler(reject)
          );
        })
        .catch(error => {
          genericFetchApiCatchHandler(error, reject);
        });
    });
  };
}
