import React from "react";
import { withRouter } from "react-router";
import { ProjectContext } from "../context/projectContextProvider";
import connect from "react-redux/es/connect/connect";
import { Link } from "react-router-dom";
import { logoutUser } from "../../../actions/users";
import { getProjects } from "../../../actions/projects";
import { NavBar, NavItem } from "../../common/navComponents";
import PortalProfileDropdown from "../../common/portalProfileDropdown";
import DashboardOrgProjectSwitcher from "../dashboard/dashboardOrgProjectSwitcher";
import { shouldShowUpgradeButton } from "../../../utils/commonUtil";
import LogoIcon from "../../../public/resources/common/images/logo.svg";
import smallLogoIcon from "../../../public/resources/common/images/gradient-logo-mark.svg";

//const isBuyNowEnabled = config.CLOUD_HOSTED_ENABLED || config.ON_PREMISE_ENABLED;

class MyProjectsHeader_V2 extends React.Component {
  static contextType = ProjectContext;
  constructor(props) {
    super(props);
    this.state = { sidebarOpen: false };
    this.onSidebarClick = this.onSidebarClick.bind(this);
  }

  onSidebarClick() {
    if (this.props.showSidebar)
      this.props.onSidebarToggleClick && this.props.onSidebarToggleClick();
    else {
      if (this.state.sidebarOpen) {
        document.body.classList.remove("overflow-hidden");
      } else {
        document.body.classList.add("overflow-hidden");
      }
      this.setState({ sidebarOpen: !this.state.sidebarOpen });
    }
  }

  render() {
    const project = this.context && this.context.project;
    return (
      <header
        id="main-header"
        className={`main-dashboard-header ${!project ? "with-logo" : ""}`}
      >
        <nav className="navbar navbar-expand-md navbar-light">
          {project && (
            <div className="container-fluid">
              <div
                className="bar-icon toggle-sidebar"
                onClick={this.onSidebarClick}
              >
                <div className="line1" />
                <div className="line2" />
                <div className="line3" />
              </div>
              <Link className="logo" to="/home">
                <img
                  src={smallLogoIcon}
                  className="d-md-none d-block"
                  alt="In-App Chat Logo"
                />
              </Link>
              <DashboardOrgProjectSwitcher
                projectName={project.name}
                projectId={project.projectId}
                user={this.props.user}
                getProjects={this.props.getProjects}
              />
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {this.renderNavBar()}
              </div>
            </div>
          )}
          {!project && (
            <>
              <Link className="logo" to="/home">
                <img src={LogoIcon} alt="In-App Chat Logo" />
              </Link>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                {this.renderNavBar()}
              </div>
            </>
          )}
        </nav>
      </header>
    );
  }

  renderNavBar() {
    const navItems = [];
    const project = this.context && this.context.project;
    const isUpgarde = shouldShowUpgradeButton(project, this.props.user);

    if (project) {
      if (isUpgarde) {
        navItems.push(
          <NavItem key={"1"}>
            <Link
              className="nav-link btn btn-orange"
              to={`/pricing/${project.projectId}`}
            >
              <em className="i-icon icon-upgrade" />
              <span>Upgrade Plan</span>
            </Link>
          </NavItem>
        );
      }
    }

    if (!this.props.hideMyProjectMenu) {
      navItems.push(
        <NavItem key={"2"}>
          <Link className="nav-link" to="/myprojects">
            <em className="i-icon icon-projects" />
            <span>My Projects</span>
          </Link>
        </NavItem>
      );
    }

    navItems.push(<PortalProfileDropdown key={"3"} />);

    return <NavBar>{navItems}</NavBar>;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user
  };
}

export default connect(mapStateToProps, { getProjects, logoutUser })(
  withRouter(MyProjectsHeader_V2)
);
