import React, { Component } from "react";
import CreateProjectContent_v4 from "../myProjects/createProjectContent_v4";
import { SEO_CREATEPROJECT } from "../../../utils/seoutils";
import { PortalCommonLayout } from "../common/portlContentWrapper";

class CreateProjectPage_v3 extends Component {
  render() {
    return (
      <PortalCommonLayout
        wrapProjectContext={false}
        showSidebar={false}
        showFooter={true}
        pageName={SEO_CREATEPROJECT}
      >
        <CreateProjectContent_v4
          {...this.props}
          projectType={this.props.projectType}
        />
      </PortalCommonLayout>
    );
  }
}

export default CreateProjectPage_v3;
