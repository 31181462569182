import React, { Component } from "react";
import { Link } from "react-router-dom";
import OrganizationGeneralContent from "../settings/organizationGeneralContent";
import OrganizationMembersContent from "../settings/organizationMembersContent";
import OrganizationRolesContent from "../settings/organizationRolesContent";
import OrganizationManageRolesContent from "../settings/organizationManageRolesContent";
import { PortalCommonLayout } from "../../portal/common/portlContentWrapper";
import { SEO_P_ORGSETTINGS } from "../../../utils/seoutils";
import { UserContext } from "../../common/context/userContextProvider";
import {
  getDefaultOrganizationPageUrl,
  isPageAccessible
} from "../../../utils/pageAccessUtil";
import history from "../../../history";

/*class OrganizationsPage extends Component {

    render() {
        return (
            <PortalCommonLayout showSidebar={true} isDashboardSideBar={false} pageName={SEO_P_ORGSETTINGS}>
                <OrganizationsContent/>
            </PortalCommonLayout>
        );
    }
}*/

export default function(pageName) {
  return class extends Component {
    static contextType = UserContext;
    allTabs = [];

    constructor(props) {
      super(props);
      this.state = { curTab: "" };
    }

    UNSAFE_componentWillMount() {
      this.allTabs.push({
        name: "general",
        tabname: "General",
        enabled: isPageAccessible(
          "/organizations/general",
          this.context.user,
          ""
        ),
        renderItem: () => <OrganizationGeneralContent key={"1"} />
      });
      this.allTabs.push({
        name: "members",
        tabname: "Members",
        enabled: isPageAccessible(
          "/organizations/members",
          this.context.user,
          ""
        ),
        renderItem: () => <OrganizationMembersContent key={"1"} />
      });
      this.allTabs.push({
        name: "roles",
        tabname: "Roles",
        enabled: isPageAccessible(
          "/organizations/roles",
          this.context.user,
          ""
        ),
        renderItem: () => <OrganizationRolesContent key={"1"} />
      });
      const url = getDefaultOrganizationPageUrl(this.context.user);
      let pathName = "";
      if (pageName === "general") {
        pathName = "/organizations/general";
      } else if (pageName === "members") {
        pathName = "/organizations/members";
      } else if (
        pageName === "roles" ||
        pageName === "createrole" ||
        pageName === "viewrole" ||
        pageName === "editrole"
      ) {
        pathName = "/organizations/roles";
      }

      this.setState({ curTab: pageName });
      if (pathName && !isPageAccessible(pathName, this.context.user, ""))
        history.push(url ? url : "/home");
    }

    renderTabHeader() {
      let items = [];
      this.allTabs.forEach(tab => {
        if (tab.enabled) {
          items.push(
            <Link
              to={`/organizations/${tab.name}`}
              key={tab.name}
              onClick={() => this.setState({ curTab: tab })}
              className={`nav-link ${
                this.state.curTab === tab.name ? "active" : ""
              }`}
            >
              {tab.tabname}
            </Link>
          );
        }
      });
      return items;
    }

    render() {
      let activePageId =
        pageName === "createrole" ||
        pageName === "viewrole" ||
        pageName === "editrole"
          ? "roles"
          : pageName;
      return (
        <PortalCommonLayout
          showSidebar={false}
          showFooter={true}
          isDashboardSideBar={false}
          activePageId={activePageId}
          pageName={SEO_P_ORGSETTINGS}
        >
          {pageName === "createrole" ||
          pageName === "viewrole" ||
          pageName === "editrole" ? (
            <OrganizationManageRolesContent pageName={pageName} />
          ) : (
            <div className="content">
              <div className="title-box">
                <div className="title">
                  <h1>Organization Settings</h1>
                </div>
              </div>
              <div className="tab-block">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    {this.renderTabHeader()}
                  </div>
                </nav>
                <div
                  className="tab-content"
                  id="nav-tabContent"
                  style={{ zIndex: "inherit" }}
                >
                  <div
                    className="tab-pane fade show active"
                    id="nav-users"
                    role="tabpanel"
                    aria-labelledby="nav-users-tab"
                  >
                    {this.allTabs.map(
                      tab => tab.name === this.state.curTab && tab.renderItem()
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </PortalCommonLayout>
      );
    }
  };
}
