import React from "react";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import AppRouter from "./router";
import NetworkDetector from "./hoc/networkDetector";
import history from "./history";

/*const App = () => (
    <Provider store={store}>
        <AppRouter />
    </Provider>
);*/

const App = () => (
  /*<StripeProvider apiKey="pk_test_o5xuT9B2BS49TSWRtSD0FZBC00dyKDcz2l">
        <Provider store={store}>
            <AppRouter />
        </Provider>
    </StripeProvider>*/

  <Provider store={store}>
    <Router history={history}>
      <AppRouter />
    </Router>
  </Provider>
);

export default NetworkDetector(App);
