import React from "react";

import IOSChatSDKContent from "./iosContent";
import AndroidChatSDKContent from "./androidContent";
import JavascriptChatSDKContent from "./javascriptContent";

const ChatSDKContent = ({ language }) => {
  return (
    <>
      {language === "ios" && <IOSChatSDKContent />}
      {language === "android" && <AndroidChatSDKContent />}
      {language === "javascript" && <JavascriptChatSDKContent />}
    </>
  );
};

export default ChatSDKContent;
