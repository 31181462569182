export const REQUEST_CREATED = "request_created";
export const RESPONSE_RECEIVED = "response_received";
export const RESET_REQUEST = "reset_request";

export function requestCreated(requestName) {
  return {
    type: REQUEST_CREATED,
    requestName
  };
}

export function successResponseReceived(requestName, successObj) {
  return {
    type: RESPONSE_RECEIVED,
    requestName,
    success: true,
    actionDataObj: successObj,
    receivedAt: Date.now()
  };
}

export function errorResponseReceived(requestName, errorObj) {
  return {
    type: RESPONSE_RECEIVED,
    requestName,
    success: false,
    actionDataObj: errorObj,
    receivedAt: Date.now()
  };
}

export function resetRequest(requestName) {
  return {
    type: RESET_REQUEST,
    requestName
  };
}

export function dispatchRequestCreated(requestName) {
  return dispatch => {
    dispatch(requestCreated(requestName));
  };
}

export function dispatchSuccessResponseReceived(requestName) {
  return dispatch => {
    dispatch(successResponseReceived(requestName));
  };
}

export function dispatchErrorResponseReceived(requestName) {
  return dispatch => {
    dispatch(errorResponseReceived(requestName));
  };
}

export function dispatchResetRequest(requestName) {
  return dispatch => {
    dispatch(resetRequest(requestName));
  };
}
