import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../common/siteHeader";
import Footer_v2 from "../common/footer_v2";
import config from "../../../config/config";
import SearchDoc from "../home/searchDoc";
import iacDocImage from "../../../public/resources/site/images/iac-docs.svg";

class DocumentationHome extends Component {
  render() {
    const docItems = [
      {
        title: "Chat SDKs",
        description:
          "Build a messaging experience that provides the latest and most essential features with In-App Chat API.",
        path: "/docs/chatsdk"
      },
      {
        title: "UIKit for Chat",
        description:
          "Quickly integrate standard chat features into your app. Customize individual and overall theme components such as fonts and colors.",
        path: "/docs/uikit",
        isComing: true
      },
      {
        title: "Chatbots",
        description:
          "Create a chatbot to automate duties and workflows, or orchestrate multiple chatbots to function together in one app.",
        path: "/docs/chatbots"
      }
    ];

    return (
      <>
        <SiteHeader fixedHeaderOnScroll={false} isDark />
        <main className="content-wrapper main-content">
          <div className="tutorials-img-block docs-img-block">
            <div className="container">
              <div className="tutorials-content-block">
                <div className="row align-items-center">
                  <div className="col-md-6 order-2 order-md-1">
                    <div className="title-content">
                      <h1>In-App Chat Docs</h1>
                    </div>
                    <SearchDoc
                      apiKey={config.ALGOLIA_SEARCH_KEY}
                      indexName={config.ALGOLIA_INDEX_NAME}
                      apiId={config.ALGOLIA_APP_ID}
                    />
                  </div>
                  <div className="col-md-6 order-1 order-md-2">
                    <div className="image-block text-md-right">
                      <img src={iacDocImage} alt="In-App Chat Docs" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-left-block docs-item-block">
            <div className="container">
              <div className="docs-items">
                <div className="row">
                  {docItems.map(item => (
                    <div
                      className="col-md-6 col-lg-4 docs-column"
                      key={item.title}
                    >
                      <div className="card docs-item">
                        <h2 className="card-title">{item.title}</h2>
                        <div className="info-content">
                          <p>{item.description}</p>
                        </div>
                        <div className="text-right">
                          <Link
                            to={item.path}
                            className={`view-docs-btn ${
                              item.isComing ? "coming-soon" : "stretched-link"
                            }`}
                            onClick={e => item.isComing && e.preventDefault()}
                          >
                            {!item.isComing ? (
                              <>
                                View docs
                                <i className="ri-arrow-right-line" />
                              </>
                            ) : (
                              <>Coming Soon</>
                            )}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer_v2 />
      </>
    );
  }
}

export default DocumentationHome;
