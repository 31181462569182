import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import DefaultAvatar from "../../public/resources/common/icons/icon-user.svg";
import connect from "react-redux/es/connect/connect";
import { logoutUser } from "../../actions/users";
import { getAuthorizedImage } from "../../utils/commonUtil";
import PortalOrganizationSwitcher from "./portalOrganizationSwitcher";
import { getDefaultOrganizationPageUrl } from "../../utils/pageAccessUtil";

class PortalProfileDropdown extends Component {
  //static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {};
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  render() {
    //if(this.context) {
    //const {user} = this.context;
    const { name, profilePicThumb, email } = this.props.user;
    const projectId = this.props.match.params.projectId;

    const AvatarSrc = profilePicThumb
      ? getAuthorizedImage(profilePicThumb)
      : DefaultAvatar;
    const noImageClass = profilePicThumb ? "" : "no-img";

    /*style={{top: '30px', left: 'auto', right : '-2px', marginTop: '10px',
                paddingTop: '5px', maxWidth: '180px'}}*/
    const defaultOrgUrl = getDefaultOrganizationPageUrl(this.props.user);
    return (
      <li className="nav-item profile-dropdown dropdown" key={this.props.key}>
        <a
          className="nav-link"
          href="#"
          id="profile-dropdown"
          data-toggle="dropdown"
        >
          <div className="img">
            <img
              src={AvatarSrc}
              className={`profile-img ${noImageClass}`}
              alt="user"
            />
          </div>
          <div className="info d-none d-md-block">
            <span className="name">{name}</span>
            <span className="email">{email}</span>
          </div>
        </a>
        <ul className="dropdown-menu" aria-labelledby="profile-dropdown">
          <li className="pb-0 d-block d-md-none">
            <div className="dropdown-item org-box border-0">
              <strong>{name}</strong>
              <span>{email}</span>
            </div>
          </li>
          <PortalOrganizationSwitcher
            user={this.props.user}
            renderSamePage={!projectId}
          />
          <li>
            <Link className="dropdown-item" to="/accountAndProfile">
              Account and Profile
            </Link>
          </li>
          {defaultOrgUrl !== "" && (
            <li>
              <Link className="dropdown-item" to={defaultOrgUrl}>
                Organizations Settings
              </Link>
            </li>
          )}
          {projectId && (
            <li>
              <Link className="dropdown-item" to={`/manage-subscription/${projectId}`}>
                Manage Subscription
              </Link>
            </li>
          )}
          <li>
            <a
              href={"#"}
              className="text-orange dropdown-item"
              onClick={this.onLogoutClick}
            >
              Log Out
            </a>
          </li>
        </ul>
      </li>
    );
  }
}

PortalProfileDropdown.propTypes = {
  name: PropTypes.string
};

function mapStateToProps(state) {
  return {
    user: state.user.user
  };
}

export default connect(mapStateToProps, { logoutUser })(
  withRouter(PortalProfileDropdown)
);
