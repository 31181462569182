import React, { Component } from "react";
import { Link } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { handleTryForFreeRedirection } from "../../../utils/planUtil";
import PortalWhiteLogoIcon from "../../../public/resources/common/images/logo-white.svg";
import Tooltip from "../../common/tooltip";
import CustomLink from "../../common/customLink";

class Footer_v2 extends Component {
  render() {
    //const isFreeProjectCreated = this.props.projects && this.props.projects.filter(p => p.projectType === 'free').length > 0;
    //const { footerClass } = this.props;
    const QuickLinksDeatils = [];
    const DocumentationDetails = [];
    const CompanyDetails = [];
    const PolicyDetails = [];

    QuickLinksDeatils.push({ hrefLink: "/home", listItemName: "Home" });
    QuickLinksDeatils.push({ hrefLink: "/features", listItemName: "Features" });
    QuickLinksDeatils.push({ hrefLink: "/pricing", listItemName: "Pricing" });
    QuickLinksDeatils.push({
      hrefLink: "/contact-support",
      listItemName: "Contact Support"
    });
    QuickLinksDeatils.push({
      hrefLink: "/community",
      listItemName: "Community"
    });

    DocumentationDetails.push({
      hrefLink: "/docs/chatsdk/ios",
      listItemName: "iOS"
    });
    DocumentationDetails.push({
      hrefLink: "/docs/chatsdk/android",
      listItemName: "Android"
    });
    DocumentationDetails.push({
      hrefLink: "/docs/chatsdk/javascript",
      listItemName: "JavaScript"
    });
    DocumentationDetails.push({
      hrefLink: "/docs/chatbots",
      listItemName: "Chatbots"
    });

    CompanyDetails.push({ hrefLink: "/about", listItemName: "About" });
    CompanyDetails.push({ hrefLink: "/careers", listItemName: "Careers" });
    CompanyDetails.push({
      hrefLink: "https://blog.inappchat.io",
      listItemName: "Blog" /*, comingSoon: true*/
    });
    CompanyDetails.push({
      hrefLink: "/contact-us",
      listItemName: "Contact Us"
    });

    PolicyDetails.push({
      hrefLink: "/terms-of-services",
      listItemName: "Terms of Service"
    });
    PolicyDetails.push({
      hrefLink: "/privacy-policy",
      listItemName: "Privacy Policy"
    });
    PolicyDetails.push({
      hrefLink: "/cookie-policy",
      listItemName: "Cookie Policy"
    });
    PolicyDetails.push({
      hrefLink: "/license-agreement",
      listItemName: "EULA"
    });
    PolicyDetails.push({
      hrefLink: "/privacy-policy/gdpr",
      listItemName: "GDPR"
    });
    PolicyDetails.push({
      hrefLink: "/privacy-policy/ccpa",
      listItemName: "CCPA"
    });

    return (
      <footer className="main-footer footer-dark">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4">
              <div className="content">
                <Link to="/home">
                  <img src={PortalWhiteLogoIcon} alt="In-App Chat Logo" />
                </Link>
                <p>
                  Build texting features your customers will love inside their
                  favorite apps
                </p>
                <ul className="social-menu">
                  <li>
                    <Tooltip message="Like us on FaceBook">
                      <a
                        href="https://www.facebook.com/inappchat.io"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <em className="i-icon icon-facebook" />
                      </a>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip message="Follow us on Twitter">
                      <a
                        href="https://twitter.com/InAppChat1"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <em className="i-icon icon-twitter" />
                      </a>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip message="Visit Linkedin">
                      <a
                        href="https://www.linkedin.com/company/rip-bull-networks-inc"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <em className="i-icon icon-linkedin" />
                      </a>
                    </Tooltip>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <div className="row">
                {this.renderMenulist("Quick Links", QuickLinksDeatils)}
                {this.renderMenulist("Documentation", DocumentationDetails)}
                {this.renderMenulist("Company", CompanyDetails)}
                {this.renderMenulist("Policies", PolicyDetails)}
              </div>
            </div>
          </div>
          {this.renderCopyrightMenu()}
        </div>
      </footer>
    );
  }

  renderMenulist(Title, ListDetails) {
    const items = [];
    ListDetails.forEach((item, index) =>
      items.push(
        <Item
          hrefLink={item.hrefLink}
          comingSoon={item.comingSoon}
          listItemName={item.listItemName}
          key={index}
        />
      )
    );

    return (
      <div className="col-6 col-sm-6 col-md-3 col-lg-3">
        <div className="footer-title">{Title}</div>
        <ul>{items}</ul>
      </div>
    );
  }

  renderCopyrightMenu() {
    return (
      <div className="row">
        <div className="col-12">
          {/*<div className="copyright mt-15">All rights reserved. &copy; 2021, Rip Bull Networks Inc.</div>*/}
          <div className="copyright mt-15">
            <strong>Rip Bull Networks, Inc.</strong> 2023 copyright all rights
            reserved
          </div>
        </div>
      </div>
    );
  }

  handleTryForFreeClick() {
    handleTryForFreeRedirection(this.props.isLoggedIn);
  }
}

const Item = ({ hrefLink, listItemName, comingSoon }) => {
  if (comingSoon) {
    return (
      <li>
        <Tooltip message="Coming Soon" id={listItemName}>
          <a className="menu-link cursor-pointer">{listItemName}</a>
        </Tooltip>
      </li>
    );
  } else {
    return (
      <li>
        <CustomLink to={hrefLink}>{listItemName}</CustomLink>
      </li>
    );
  }
};

function mapStatsToProps(stats) {
  return {
    isLoggedIn: stats.user.isLoggedIn,
    projects: stats.project.projects
  };
}

export default connect(mapStatsToProps, null)(Footer_v2);
