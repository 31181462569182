import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const LanguageTab = ({ language }) => {
  const tabItem = useMemo(
    () => [
      {
        id: "ios",
        url: "/docs/chatsdk/ios",
        icon: "i-icon icon-apple",
        disabled: false
      },
      {
        id: "android",
        url: "/docs/chatsdk/android",
        icon: "i-icon icon-android"
      },
      {
        id: "javascript",
        url: "/docs/chatsdk/javascript",
        icon: "i-icon icon-react",
        disabled: true
      }
    ],
    []
  );

  return (
    <div className="documentation-small-tab">
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          {tabItem.map(item =>
            item.disabled ? (
              <React.Fragment key={item.id}>
                <a data-tip data-for={item.id} className={`nav-link inacitve`}>
                  <em className={item.icon}></em>
                </a>
                <ReactTooltip
                  id={item.id}
                  place="bottom"
                  effect="solid"
                  getContent={() => "Coming Soon"}
                />
              </React.Fragment>
            ) : (
              <Link
                key={item.id}
                className={`nav-link ${item.id === language ? "active" : ""}`}
                to={item.url}
              >
                <em className={item.icon}></em>
              </Link>
            )
          )}
        </div>
      </nav>
    </div>
  );
};

export default LanguageTab;
