import { Helmet } from "react-helmet";
import React from "react";
import config from "../config/config";

export const SEO_HOME = "home";
export const SEO_FEATURE = "feature";
export const SEO_PRICING = "pricing";
export const SEO_STORE = "store";
export const SEO_1CHATBOT = "1chatbot";
export const SEO_P2P = "p2p";
export const SEO_P2M = "p2m";
export const SEO_M2M = "m2m";
export const SEO_CONTACTUS = "contactus";
export const SEO_CONTACTSUPPORT = "contactsupport";
export const SEO_SIGNUP = "signUp";
export const SEO_LOGIN = "login";
export const SEO_AUTHENTICATE = "authenticate";
export const SEO_SCHEDULEDEMO = "scheduleDemo";
export const SEO_ABOUT = "about";
export const SEO_CAREERS = "careers";
export const SEO_DOCUMENTATION = "documentation";
export const SEO_IOS_DOCUMENTATION = "iosdocumentation";
export const SEO_ANDROID_DOCUMENTATION = "androiddocumentation";
export const SEO_JAVASCRIPT_DOCUMENTATION = "jsdocumentation";
export const SEO_INFRASTRUCTURE_DOCUMENTATION = "infrastructuredocumentation";
export const SEO_SECURITY_DOCUMENTATION = "securitydocumentation";
export const SEO_PLAN_DOCUMENTATION = "plandocumentation";
export const SEO_BILING_DOCUMENTATION = "billingdocumentation";
export const SEO_CHATUSER_DOCUMENTATION = "chatuserdocumentation";
export const SEO_TERM_DOCUMENTATION = "termdocumentation";
export const SEO_APPLICATION_DOCUMENTATION = "applicationdocumentation";
export const SEO_COMMUNITY = "community";
export const SEO_PRIVACYPOLICY = "privacypolicy";
export const SEO_COOKIEPOLICY = "cookiepolicy";
export const SEO_EULA = "eula";
export const SEO_TERMSOFSERVICE = "termsofservice";
export const SEO_SUPPORTPOLICY = "supportpolicy";
export const SEO_EMAILVERIFY = "emailverify";
export const SEO_CREATEPROJECT = "createproject";
export const SEO_CREATEPAIDPROJECT = "createpaidproject";
export const SEO_FORGOTPASSWORD = "forgotpassword";
export const SEO_PAYMENT = "payment";
export const SEO_INSTANCETYPE = "instancetype";
export const SEO_SUBSCRIBEMAIL = "subscribeMail";
export const SEO_CONTACTTHANKS = "contactThanks";
export const SEO_NOTFOUNDPAGE = "notfound";
export const SEO_CHATBOTAUTOMATION = "chatbotAutomation";
export const SEO_CHATBOTAUTOMATIONTHANKS = "chatbotAutomationThanks";

export const SEO_P_MYPROJECTS = "myprojects";
export const SEO_P_ACCOUNTPROFILE = "accountprofiles";
export const SEO_P_ORGSETTINGS = "orgsettings";
export const SEO_P_BILLING = "billing";
export const SEO_P_DASHBOARD = "dashboard";
export const SEO_P_EXPORTMESSAGES = "export";
export const SEO_P_USERS = "users";
export const SEO_P_MODERATION = "moderation";
export const SEO_P_BOTS = "bots";
export const SEO_P_SECURITY = "security";
export const SEO_P_INFRASTRUCTURE = "infrastructure";
export const SEO_P_USAGE = "usage";
export const SEO_P_ANALYTICS = "analytics";
export const SEO_P_SETTINGS = "settings";
export const SEO_P_CHANGEPASSWORD = "changepassword";
export const SEO_P_REGISTER_ANDROID = "registerandroid";
export const SEO_P_REGISTER_IOS = "registerios";
export const SEO_P_REGISTER_JAVASCRIPT = "registerjavascript";
export const SEO_BOOKING_CONFIRMATION = "bookingConfirmation";
export const SEO_APPSUMO = "appsumo";
export const SEO_1WEBCHAT_PLAN = "1Webchatplan";
export const SEO_MANAGE_SUBSCRIPTION = "manage-subscription";

export function getLocationOrigin() {
  return config.PROTOCOL + "//" + config.DOMAIN;
}
//not used right now
export function getSchema(headline, desc) {
  return `
  {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${getLocationOrigin()}"
      },
    "headline": "${headline}",
    "description": "${desc}",
    "image": {
    "@type": "ImageObject",
      "url": "${getLocationOrigin()}/images/inappchat-schema-image.png",
      "width": "2400",
      "height": "1260"
  },
    "author": {
    "@type": "Organization",
      "name": "In-App Chat"
  },
    "publisher": {
    "@type": "Organization",
      "name": "In-App Chat",
      "logo": {
      "@type": "ImageObject",
        "url": "${getLocationOrigin()}/favicon.ico",
        "width": "32",
        "height": "32"
    }
  }
  }`;
}

export const getHeader = page => {
  let title = "";
  let desc = "";
  let keyword = "";
  let canonicalUrl = "";
  let shareLinkImageName = "";
  if (page === SEO_HOME) {
    title += "In-App Chat | Powering the Future of Chat";
    desc =
      "In-App Chat is an instant messaging App, that Easily integrates and creates your own P2P messaging, Chatbots, and Chat Ops";
    keyword =
      "In-App Chat is an instant messaging App, that Easily integrates and creates your own P2P messaging, Chatbots, and Chat Ops";
    canonicalUrl += "home";
    shareLinkImageName = "home.png";
  } else if (page === SEO_FEATURE) {
    title += "Simple and Reliable Messaging";
    desc =
      "Empowering Your Chatbot To Work For Your Business with In-App Chat, the best chatting app";
    keyword = "in app chat features";
    canonicalUrl += "features";
    shareLinkImageName = "features.png";
  } else if (page === SEO_PRICING) {
    title += "Flexible Deployment Plans";
    desc =
      "In-App Chat features a traditional chat SDK cloud-hosted deployment offerings. Choose your plane for bot Conversation";
    keyword = "in app chat pricing";
    canonicalUrl += "pricing";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_STORE) {
    title += "Store";
    desc =
      "Direct Messaging. Media Sharing. Profile Management. Group Messaging. Online Presence. Push Notifications. Channels.";
    canonicalUrl += "marketplace";
  } else if (page === SEO_1CHATBOT) {
    title += "The future of Automated Chat | ChatBots";
    desc =
      "Easily integrate and create your own P2P messaging, Chatbots,1Chatbot and Bot factory with In-App Chat. Boost your customer service, streamline processes, and improve communication within your organization.";
    canonicalUrl += "chatbots";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_P2P) {
    title += " Peer-to-Peer Messaging";
    desc =
      "In-App Chat makes peer-to-peer messaging easy! Our Chat API allows you to embed chat into your apps with SDKs for iOS, Android, and web.";
    keyword = "Chat API, Best Text Messaging App, Messenger";
    canonicalUrl += "peer-to-peer";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_P2M) {
    title += "Conversational AI";
    desc =
      "Intelligent Virtual Agents Powering Human To Chatbot Interactions. In-App Chat is a Conversational AI chatbot";
    keyword = "chatbot app, chat with chatbot, bot conversation";
    canonicalUrl += "conversational-ai";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_M2M) {
    title += "IOT Controller";
    desc =
      "Your machines are waiting for you to lead them into the future. Get there faster with Chat Ops of In-App Chat.";
    keyword = "chatops, Ops Chat, slack chat ops, machine to machine app";
    canonicalUrl += "iot-controller";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_CONTACTUS) {
    title += "Contact Us";
    desc =
      "We're here to help you with the best texting app, which reduces the number of manual tasks with Chat Ops.";
    canonicalUrl += "contact-us";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_CONTACTSUPPORT) {
    //TODO - need to verify description
    title += "Contact Support";
    desc =
      "We're here to help you estimate your pricing, explore our feature set, and evaluate how our platform fits your needs.";
    canonicalUrl += "contact-support";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_SIGNUP) {
    title += "Sign Up";
    desc =
      "Sign Up and try  In-App Chat services for free best AI Chatbot and ChatOps.";
    canonicalUrl += "signup";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_LOGIN) {
    title += "Log In to Your Account";
    desc =
      "Log in to your  In-App Chat account to access current projects or create new ones.";
    canonicalUrl += "login";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_AUTHENTICATE) {
    title += "Authenticate";
  } else if (page === SEO_SCHEDULEDEMO) {
    title += "Schedule Demo";
    desc = "";
    canonicalUrl += "schedule-demo";
    //shareLinkImageName = "login.png";
  } else if (page === SEO_ABOUT) {
    title += "About In-App Chat | Chat API | messaging SDKs";
    desc =
      "Rip Bull Networks flagship product is a chat API and messaging SDKs that embed rich chat features for more advanced texting functionality";
    canonicalUrl += "about";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_CAREERS) {
    title += "Careers with In-App Chat | Interested in joining our team?";
    desc =
      "Interested in joining our team? View the list of open positions below or use the dropdown menu to sort by the departments.";
    canonicalUrl += "careers";
  } else if (page === SEO_DOCUMENTATION) {
    title += "Documentation";
    desc =
      "In-App Chat SDK is a fully-featured enterprise solution for an instant messaging framework for all platforms.";
    //canonicalUrl += "documentation";
  } else if (page === SEO_IOS_DOCUMENTATION) {
    title += "IOS Chat Documentation";
    desc =
      "eRTC SDK is a fully-featured enterprise solution for an instant messaging framework for iOS.";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_ANDROID_DOCUMENTATION) {
    title += "Android Chat Documentation";
    desc =
      "eRTC SDK is highly scalable and can support Enterprise traffic, Built on Latest Tech Stack, SDK provides a data layer to effectively manage and control data.";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_JAVASCRIPT_DOCUMENTATION) {
    title += "JS Chat Documentation";
    desc =
      "eRTC java script SDK is secure and easy to integrate, deploy and manage.";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_INFRASTRUCTURE_DOCUMENTATION) {
    title += "Infrastructue Documentation";
    desc =
      "The administrator dashboard provides insights to the administrator of eRTC infrastructure where the application is running.";
  } else if (page === SEO_SECURITY_DOCUMENTATION) {
    title += "Security Documentation";
    desc =
      "The eRTC container scanner does the layer-wise static analysis of the container that is running on the client’s machine.";
  } else if (page === SEO_PLAN_DOCUMENTATION) {
    title += "Plans Documentation";
    desc =
      "eRTC provides free and premium plans as part of its hosting. Free project and Premium Projects.";
  } else if (page === SEO_BILING_DOCUMENTATION) {
    title += "Billing Documentation";
    desc =
      "There are two types of plans under eRTC. One is hosted cloud deployment and the other is on-premise deployment.";
  } else if (page === SEO_CHATUSER_DOCUMENTATION) {
    title += "Chatusers Documentation";
    desc =
      "In-App Chat provides a way to add SDK users to the portal so they can communicate. There are two ways of adding users: single users and multiple users.";
  } else if (page === SEO_TERM_DOCUMENTATION) {
    title += "Terms Documentation";
    desc = "Let’s get familiar with some of the terms eRTC uses.";
  } else if (page === SEO_APPLICATION_DOCUMENTATION) {
    title += "Applications Documentation";
    desc =
      "Once a project is active and the admin can launch the project dashboard, the first task is to set-up the application context.";
  } else if (page === SEO_COMMUNITY) {
    title += "Community";
    desc = "";
    canonicalUrl += "community";
  } else if (page === SEO_PRIVACYPOLICY) {
    title += "Privacy Policy";
    desc =
      "This Policy details how we receive, collect and use information in connection with our software.";
    canonicalUrl += "privacy-policy";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_COOKIEPOLICY) {
    //TODO - need to verify
    title += "Cookie Policy";
    desc =
      "This Policy details how we receive, collect and use information collected by Cookies.";
    canonicalUrl += "cookie-policy";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_EULA) {
    //TODO - need to verify
    title += "EULA";
    desc = "End user software license agreement.";
    canonicalUrl += "license-agreement";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_TERMSOFSERVICE) {
    title += "Terms of Service";
    desc =
      "Terms of Use, together with our Privacy Policy and any other terms specifically referred to in any of those documents, constitute a legally binding agreement.";
    canonicalUrl += "terms-of-services";
    shareLinkImageName = "inappchat_256.png";
  } else if (page === SEO_SUPPORTPOLICY) {
    title += "Support Policy";
    desc = "";
  } else if (page === SEO_EMAILVERIFY) {
    title += "Email Verification";
    desc = "You have successfully verified your email address.";
  } else if (page === SEO_CREATEPROJECT) {
    title += "Create Free Project";
    desc =
      "To begin, please provide your contact information, organization name, and a password of your choosing.";
  } else if (page === SEO_INSTANCETYPE) {
    title += "Select Instance Type";
    desc = "";
  } else if (page === SEO_SUBSCRIBEMAIL) {
    title += "Successfully Subscribed";
    desc = "";
  } else if (page === SEO_CONTACTTHANKS) {
    title += "Thank You";
    desc = "";
  } else if (page === SEO_NOTFOUNDPAGE) {
    title += "Page Not Found";
    desc = "";
  } else if (page === SEO_CHATBOTAUTOMATION) {
    title += "Chatbot Automation";
    desc = "";
  } else if (page === SEO_CHATBOTAUTOMATIONTHANKS) {
    title += "Success";
    desc = "";
  } else if (page === SEO_CREATEPAIDPROJECT) {
    title += "Create License Project";
    desc =
      "Choose between Inclusive and Essential Licensing, and select your number of users.";
  } else if (page === SEO_FORGOTPASSWORD) {
    title += "Forgot Password";
    desc = "That way when I forget it, it always reminds me.";
  } else if (page === SEO_PAYMENT) {
    title += "Payment";
    desc =
      "A cloud server is a virtual server running in a cloud computing environment. It is built, hosted and delivered via a cloud computing platform via the internet.";
  } else if (page === SEO_P_MYPROJECTS) {
    title += "My Projects";
    desc = "Create your In-App Chat project.";
  } else if (page === SEO_P_ACCOUNTPROFILE) {
    title += "Account & Profile";
    desc = "Access account & profile details";
  } else if (page === SEO_P_ORGSETTINGS) {
    title += "Organization Settings";
    desc =
      "Administrators customize their organizational settings and company details.";
  } else if (page === SEO_P_BILLING) {
    title += "Billing";
    desc =
      "Enter your payment information for pay-as-you-go services. Read about what to expect in the FAQ.";
  } else if (page === SEO_P_DASHBOARD) {
    title += "Dashboard";
    desc = "All project settings can be found on this page";
  } else if (page === SEO_P_EXPORTMESSAGES) {
    //TODO - need to verify
    title += "Messages";
    desc = "Export Messages";
  } else if (page === SEO_P_USERS) {
    title += "Users";
    desc = "Invite, and deactivate users";
  } else if (page === SEO_P_MODERATION) {
    title += "Moderation";
    desc = "Moderate contents";
  } else if (page === SEO_P_BOTS) {
    title += "Bots";
    desc = "Bots";
  } else if (page === SEO_P_SECURITY) {
    title += "Security";
    desc =
      '"Review and adjust security settings, as well as get recommendations for keeping your account secure.';
  } else if (page === SEO_P_INFRASTRUCTURE) {
    title += "Infrastructure";
    desc =
      "CPU, Memory, and Pods usage high please raise maintenance ticket to the eRTC Support.";
  } else if (page === SEO_P_USAGE) {
    title += "Usage";
    desc = "";
  } else if (page === SEO_P_ANALYTICS) {
    title += "Analytics";
    desc = "";
  } else if (page === SEO_P_SETTINGS) {
    title += "Settings";
    desc = "Edit your project settings.";
  } else if (page === SEO_P_CHANGEPASSWORD) {
    title += "Change Password";
    desc = "";
  } else if (page === SEO_P_REGISTER_ANDROID) {
    title += "Register Android Application";
    desc = "";
  } else if (page === SEO_P_REGISTER_IOS) {
    title += "Register IOS Application";
    desc = "";
  } else if (page === SEO_P_REGISTER_JAVASCRIPT) {
    title += "Register Javascript Application";
    desc = "";
  } else if (page === SEO_BOOKING_CONFIRMATION) {
    title += "Booking Confirmation";
    desc = "Booking Confirmation";
  } else if (page === SEO_APPSUMO) {
    title += "Redeem AppSumo code";
    desc = "Redeem your AppSumo code by entering it below";
    keyword = "redeem appsumo code";
    canonicalUrl += "appsumo";
  } else if (page === SEO_1WEBCHAT_PLAN) {
    title += "Select your plan";
    desc = "Select your plan";
    keyword = "select your plan";
    canonicalUrl += "1webchat";
  } else if (page === SEO_MANAGE_SUBSCRIPTION) {
    title += "Manage subscription";
    desc = "Manage subscription";
    keyword = "manage subscription";
    canonicalUrl += "1webchat";
  } else {
    title = "In-App Chat";
    desc = "";
  }

  if (
    title !== "In-App Chat" &&
    page !== SEO_HOME &&
    page !== SEO_ABOUT &&
    page !== SEO_CAREERS
  )
    title += " | In-App Chat";

  return setHelmetData(
    page,
    title,
    desc,
    keyword,
    canonicalUrl,
    shareLinkImageName
  );
};

export const setHelmetData = (
  page,
  title,
  desc,
  keyword = "",
  canonicalUrl = "",
  shareLinkImageName = ""
) => {
  const addFbTwitterMeta = shareLinkImageName.length > 0;
  const originUrl = `${getLocationOrigin()}/`;
  const canonicalFullUrl = `${originUrl}${canonicalUrl}`;
  const shareLinkImageUrl = `${originUrl}images/${shareLinkImageName}`;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
      />
      <meta name="theme-color" content="#000000" />
      {keyword !== "" && <meta name="keywords" content={keyword} />}
      <meta name="author" content="In-App Chat | www.inappchat.io" />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={desc} />

      {addFbTwitterMeta && <meta property="og:type" content="website" />}
      {addFbTwitterMeta && (
        <meta property="og:url" content={canonicalFullUrl} />
      )}
      {addFbTwitterMeta && <meta property="og:title" content={title} />}
      {addFbTwitterMeta && <meta property="og:description" content={desc} />}
      {addFbTwitterMeta && (
        <meta property="og:image" content={shareLinkImageUrl} />
      )}

      {addFbTwitterMeta && (
        <meta property="twitter:card" content="summary_large_image" />
      )}
      {addFbTwitterMeta && (
        <meta property="twitter:url" content={canonicalFullUrl} />
      )}
      {addFbTwitterMeta && <meta property="twitter:title" content={title} />}
      {addFbTwitterMeta && (
        <meta property="twitter:description" content={desc} />
      )}
      {addFbTwitterMeta && (
        <meta property="twitter:image" content={shareLinkImageUrl} />
      )}

      {/*(page === SEO_PAYMENT || page === SEO_P_BILLING) && <script src="https://js.chargebee.com/v2/chargebee.js"></script>*/}
      {(!config.ALLOW_GOOGLE_CRAWL ||
        config.ALLOW_GOOGLE_CRAWL === "0" ||
        page === SEO_AUTHENTICATE) && <meta name="robots" content="noindex" />}
      {canonicalUrl && <link rel="canonical" href={canonicalFullUrl} />}
      <script type="application/ld+json">{getSchema(title, desc)}</script>
    </Helmet>
  );
};
