import React, { Component } from "react";
import chatWidgetImg from "../../../../public/resources/site/images/chatWidgetImg.png";

class InstallingChatWidget extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="tutorials-img-block pb-0">
        <div className="container">
          <div className="tutorials-content-block">
            <div className="row align-items-center">
              <div className="col-md-6 order-2 order-md-1">
                <div className="title-content mb-0">
                  <h1 className="font-lg">Installing a Chat Widget</h1>
                  <div className="info-content">
                    Ready to add a chat widget to your site or app? The process
                    of creating and installing a bot can be completed in just
                    minutes!
                  </div>
                </div>
              </div>
              <div className="col-md-6 order-1 order-md-2">
                <div className="image-block text-md-right">
                  <img src={chatWidgetImg} alt="1Webchat" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstallingChatWidget;
