import React, { Component } from "react";
import { Field, Form, reduxForm } from "redux-form";
import { getCurrentOrganization } from "../../../utils/commonUtil";
import SubmitButton from "../../common/submitButton";
import Modal from "../../common/modal";
import InputText_v2 from "../../common/inputText_v2";
import { validateEmail } from "../../../utils/validationUtil";
import CustomDropdown_v4 from "../../common/customDropdown_v4";
import { getAllRoles } from "../../../actions/organization";
import { connect } from "react-redux";
import history from "../../../history";
import { showErrorToast, showSuccessToast } from "../../../actions/ui";

class AddNewAdminModal extends Component {
  rolesData = [];
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onRoleDropDownValueChange = this.onRoleDropDownValueChange.bind(this);
    this.state = {
      defaultRole: "",
      isSubmitting: false,
      currentRoleId: ""
    };
  }

  UNSAFE_componentWillMount() {
    const selectedRole = this.props.currentRoleId;
    if (selectedRole) this.setState({ currentRoleId: selectedRole });

    if (this.props.allRoles) {
      this.rolesData = this.props.allRoles;
    } else {
      this.fetchRoles();
    }
  }

  componentDidMount() {
    if (this.props.currentEmail && this.props.currentEmail !== "") {
      this.props.initialize({ email: this.props.currentEmail });
    }
  }

  fetchRoles() {
    const organization = getCurrentOrganization(this.props.user);
    this.setState({ isLoading: true });
    this.props
      .getAllRoles(organization._id)
      .then(result => {
        const resultObj = result.actionDataObj;
        this.rolesData = resultObj.data || [];

        this.rolesData.sort((a, b) =>
          a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
        );
        this.setState({ isLoading: false });
      })
      .catch(error => {
        console.log(error);
        this.setState({ isLoading: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  }

  onClose() {
    this.props.reset();
    this.props.onHide();
  }

  onRoleDropDownValueChange(value) {
    this.setState({ currentRoleId: value });
  }

  handleFormSubmit(values) {
    this.setState({ isSubmitting: true });
    const organization = getCurrentOrganization(this.props.user);
    let roleId = this.state.currentRoleId;
    if (roleId === "" && this.rolesData.length > 0) {
      roleId = this.rolesData[0].id;
    }
    const email = this.props.currentEmail;
    const successMsg =
      email && email !== ""
        ? "Role updated successfully"
        : "Member invited successfully";
    this.props
      .inviteUser(organization._id, values.email, roleId)
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.showSuccessToast(successMsg);
        setTimeout(() => {
          this.onClose();
          this.props.fetch();
          if (this.props.showInvitePage)
            history.push({
              pathname: "/organizations/members",
              search: `tab=invite`
            });
        }, 2000);
      })
      .catch(error => {
        console.log(error);
        this.setState({ isSubmitting: false });
        this.props.showErrorToast(error.actionDataObj.message);
      });
  }

  render() {
    const { handleSubmit, show, currentEmail } = this.props;

    const roleOptions = [];
    this.rolesData &&
      this.rolesData
        .filter(item => item.name !== "superAdmin")
        .map(role => {
          roleOptions.push({ value: role.id, displayValue: role.displayName });
        });

    return (
      <Modal
        show={show}
        id={"addNewAdminModal"}
        title="Invite New Member"
        onCloseHandler={this.onClose}
      >
        <Form
          id="inviteMemberForm"
          className="inviteMemberForm"
          onSubmit={handleSubmit(this.handleFormSubmit)}
        >
          <div className="row">
            <Field
              containerClass="form-group col-12"
              type="text"
              inputTextClass="form-control custom-input"
              placeholder="Email Address"
              name="email"
              id="email"
              labelFor="email"
              label="Email Address"
              readOnly={currentEmail && currentEmail !== ""}
              component={InputText_v2}
            />

            <div className="form-group col-12">
              <label>Role</label>
              {this.state.isLoading ? (
                <div className="spinner-border ml-5" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <CustomDropdown_v4
                  defaultValue={
                    this.state.currentRoleId !== ""
                      ? this.state.currentRoleId
                      : roleOptions[0].value
                  }
                  data={roleOptions}
                  onValueChange={this.onRoleDropDownValueChange}
                />
              )}
            </div>
            <div className="form-group col-12">
              <p className="info">
                Admins can invite new users to the organization, add users to a
                project, etc.
              </p>
            </div>
            <div className="modal-action col-12">
              <button
                className="btn btn-only-text text-gray"
                type="button"
                data-dismiss="modal"
                onClick={this.onClose}
              >
                Cancel
              </button>
              <SubmitButton
                buttonClass="btn btn-primary btn-md"
                isSubmitting={this.state.isSubmitting}
              >
                {currentEmail && currentEmail !== "" ? "Save" : "Invite"}
              </SubmitButton>
            </div>
          </div>
        </Form>
      </Modal>
    );
  }
}

const validate = values => {
  const errors = {};

  //const userNameResult = validateUserName(values.adminName);
  //if (userNameResult) errors.adminName = userNameResult;

  const userEmailResult = validateEmail(values.email);
  if (userEmailResult) errors.email = userEmailResult;

  return errors;
};

/*function validateUserName(userName) {
    if (!userName) {
        return "Please Enter Username !";
    }
    return null;
}*/

const reduxFormComp = reduxForm({
  form: "inviteMemberForm",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
});
export default reduxFormComp(
  connect(null, {
    getAllRoles,
    showSuccessToast,
    showErrorToast
  })(AddNewAdminModal)
);
