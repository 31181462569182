import React, { Component } from "react";
import { Link } from "react-router-dom";
import { isExternalUrl } from "../../utils/commonUtil";

export default class CustomLink extends Component {
  render() {
    const { to, children } = this.props;
    return isExternalUrl(to) ? (
      <a href={to} {...this.props} target={"_blank"} rel="noreferrer">
        {children}
      </a>
    ) : (
      <Link {...this.props}>{children}</Link>
    );
  }
}
